import React, { Component } from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'
import AddCircleIcon from '@material-ui/icons/AddCircle'
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
//import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
//import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { Select, MenuItem, FormControl, InputLabel, TextField } from '@material-ui/core'
import { withFirebase } from '../Fb'
import { DataGrid } from '@mui/x-data-grid'
import PlusMinusButton from '../Buttons/PlusMinusButton'
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

class AddCardRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
      note: '',
      saving: this.props.saving,
      benefitcards: this.props.benefitcards,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.saving !== prevProps.saving) {
      this.setState({ saving: this.props.saving })
    }
    if (this.props.benefitcards !== prevProps.benefitcards) {
      this.setState({ selected: this.props.benefitcards[0].id })
    }
  }

  onChangeId = (event) => {
    this.setState({
      selected: event.target.value,
    })
  }

  onChangeNote = (event) => {
    this.setState({
      note: event.target.value,
    })
  }

  handleCancel = () => {
    this.setState({
      selected: '',
      note: '',
    })
    this.props.onCancel()
  }


  render() {
    const { onSubmit, onCancel } = this.props
    if (!this.state.benefitcards) return <div>Ei sarjakortteja</div>
    return (
      <>
        <Row>
          <Col xs={12} md={4}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='benefitcard-label'>Sarjakortti</InputLabel>
              <Select
                id='demo-simple-select'
                value={this.state.selected}
                onChange={this.onChangeId}
                required
                labelId='benefitcard-label'
                fullWidth
              >
                {this.state.benefitcards &&
                  this.state.benefitcards.map((benefitcard) => (
                    <MenuItem key={benefitcard.id} value={benefitcard.id}>
                      {benefitcard.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} md={4}>
            <Button disable={this.state.saving} outline color='success' size='sm' onClick={() => onSubmit(this.state.selected, this.state.note)}>
              {this.state.saving ? <Spinner size={'sm'}/> : 'Lisää'}
            </Button>{' '}
            <Button disabled={this.state.saving}  outline color='primary' size='sm' onClick={onCancel}>
              Peruuta
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <TextField
              fullWidth
              id='note'
              label='Muistiinpano'
              type='text'
              value={this.state.note || ''}
              onChange={this.onChangeNote}
            />
          </Col>
        </Row>
      </>
    )
  }
}

class AddTimedCardRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
      note: '',
      startdate: moment.utc().format('YYYY-MM-DD'),
      enddate: moment.utc().endOf('year').format('YYYY-MM-DD'),
      permanent: false,
      saving: this.props.saving,
      benefitcards: this.props.benefitcards,
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.saving !== prevProps.saving) {
      this.setState({ saving: this.props.saving })
    }
    if (this.props.benefitcards !== prevProps.benefitcards) {
      this.setState({ selected: this.props.benefitcards[0].id })
    }
  }

  onChangeId = (event) => {
    this.setState({
      selected: event.target.value,
    })
  }

  onChangeNote = (event) => {
    this.setState({
      note: event.target.value,
    })
  }

  handleStartdate = (event) => {
    this.setState({
      startdate: event.target.value,
    })
  }

  handleEnddate = (event) => {
    this.setState({
      enddate: event.target.value,
    })
  }

  handlePermanent = (event) => {
    this.setState({
      permanent: event.target.checked,
    })
  }

  render() {
    const isValidSave = this.state.selected !== '' && this.state.startdate !== '' && (this.state.permanent || this.state.enddate !== '')
    const { onSubmit, onCancel } = this.props
    if (!this.state.benefitcards) return <div>Ei varauskortteja</div>
    return (
      <>
        <Row style={{ marginTop: 10 }}>
          {this.state.benefitcards.length > 0 ? (
            <Col md='4'>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id='membership'>Varauskortti</InputLabel>
                <Select
                  fullWidth
                  required
                  labelId='benefit-label'
                  id='benefitid'
                  value={this.state.selected || ''}
                  onChange={this.onChangeId}
                >
                {this.state.benefitcards &&
                  this.state.benefitcards.map((benefitcard) => (
                    <MenuItem key={benefitcard.id} value={benefitcard.id}>
                      {benefitcard.name}
                    </MenuItem>
                  ))}
              </Select>
              </FormControl>
            </Col>
          ) : null}
          <Col md='3'>
            <TextField
              id='startdate'
              label='Alkupäivä'
              type='date'
              value={this.state.startdate || moment.utc().format('YYYY-MM-DD')}
              onChange={this.handleStartdate}
            />
          </Col>
          <Col md='3'>
            <TextField
              id='enddate'
              label='Loppupäivä'
              type='date'
              value={this.state.enddate || moment.utc().endOf('year').format('YYYY-MM-DD')}
              onChange={this.handleEnddate}
              disabled={this.state.permanent}
            />
          </Col>
          <Col md='2'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.permanent}
                  onChange={this.handlePermanent}
                  name='permanent'
                  color='primary'
                />
              }
              label='Ei loppupäivää'
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col md='8'>
            <TextField
              fullWidth
              id='note'
              label='Muistiinpano'
              type='text'
              value={this.state.note || ''}
              onChange={this.onChangeNote}
            />
          </Col>
          <Col md='4'>
            <Button
              outline
              size='sm'
              color='success'
              disabled={this.state.saving || isValidSave === false}
              onClick={() => onSubmit(this.state.selected, this.state.note, this.state.startdate, this.state.enddate, this.state.permanent)}
            >
              {this.state.saving ? <Spinner size='sm' /> : 'Lisää'}
            </Button>
            <Button outline color='primary' size='sm' onClick={onCancel}  disabled={this.state.saving} >
              Peruuta
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

class PersonBenefitCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      personBenefitCards: this.props.cards || [],
      benefitcards: this.props.benefitcards,
      showAddCardRow: false,
      showAddTimedCardRow: false,
      saving: false,
      loadingPlusMinus: {},
    }
  }

  getColumns = () => {
    const columns = [
      { field: 'name', headerName: 'Nimi', width: 200 },
      { field: 'collection', headerName: 'Käyttö', width: 120, valueFormatter: (params) => params.value === 'reservations' ? 'Varauskortti' : 'Aktiviteettikortti' },
      { field: 'sport', headerName: 'Laji', width: 130 },
      {
        field: 'price',
        headerName: 'Hinta',
        width: 100,
        valueFormatter: (params) => {
          return params.value / 100 + ' €'
        },
      },
      {
        field: 'type',
        headerName: 'Tyyppi',
        width: 110,
        valueFormatter: (params) => {
          return params.value === 'time' ? 'Aika' : 'Kerta'
        },
      },
      {
        field: 'remaining', headerName: 'Jäljellä', width: 160, renderCell: (params) => {
          if (params.value === 0) return 'Käytetty'
          if (params.row.type === 'count') {
            return <>{params.value + ' kertaa '} <span style={{ marginLeft: 10 }}><PlusMinusButton onPlus={() => this.handlePlus(params.row.id)} onMinus={() => this.handleMinus(params.row.id)} loading={this.state.loadingPlusMinus[params.row.id]} /></span></>
          } else {
            return ''
          }
        }
      },
      { field: 'note', headerName: 'Muistiinpano', width: 200 },
      { field: 'start', headerName: 'Voimassa alkaen', width: 200, valueFormatter: (params) => moment.utc(params.value, 'YYYY.MM.DD').format('DD.MM.YYYY')},
      { field: 'validuntil', headerName: 'Voimassa asti', width: 200, valueFormatter: (params) => params.value === '2300-01-01' ? 'Ei päättymisaikaa' : moment.utc(params.value).format('DD.MM.YYYY') },
      { field: 'permanent', headerName: 'Pysyvä', width: 100, valueFormatter: (params) => params.value ? 'Kyllä' : 'Ei' },
    ]
    return columns
  }

  componentDidUpdate(prevProps) {
    if (this.props.cards !== prevProps.cards) {
      this.setState({
        personBenefitCards: this.props.cards,
      })
    }
  }

  toggleAddCard = () => {
    this.setState({
      showAddCardRow: !this.state.showAddCardRow,
    })
  }

  toggleAddTimedCard = () => {
    this.setState({
      showAddTimedCardRow: !this.state.showAddTimedCardRow,
    })
  }

  handleSaved = () => {
    this.setState({ showAddCardRow: false, showAddTimedCardRow: false })
    this.props.onSaved()
  }

  onSaveBenefitCard = async (cardid, note) => {
    this.setState({ saving: true })
    const userid = this.props.personId
    await this.props.firebase.addNewPersonBenefitCard(userid, cardid, note)
    this.setState(
      {
        saving: false,
      },
      this.handleSaved
    )
  }

  onSaveTimedBenefitCard = async (cardid, note, start, end, permanent) => {
    console.log('onSaveTimedBenefitCard', cardid, note, start, end, permanent)
    this.setState({ saving: true })
    const userid = this.props.personId
    await this.props.firebase.addNewPersonTimedBenefitCard(userid, cardid, note, start, end, permanent)
    this.setState(
      {
        saving: false,
      },
      this.handleSaved
    )
  }


  handlePlus = async (id) => {
    this.setState({ loadingPlusMinus: { ...this.state.loadingPlusMinus, [id]: true } })
    let remaining = this.state.personBenefitCards.find((card) => card.id === id).remaining
    if (!remaining) {
      remaining = 1
    } else {
      remaining++
    }
    await this.props.firebase.updatePersonBenefitCard(this.props.personId, id, remaining)
    this.setState({ loadingPlusMinus: { ...this.state.loadingPlusMinus, [id]: false } }, this.props.onSaved)
  }

  handleMinus = async (id) => {
    this.setState({ loadingPlusMinus: { ...this.state.loadingPlusMinus, [id]: true } })
    let remaining = this.state.personBenefitCards.find((card) => card.id === id).remaining
    if (!remaining) {
      remaining = 0
    } else {
      remaining--
    }

    await this.props.firebase.updatePersonBenefitCard(this.props.personId, id, remaining)
    this.setState({ loadingPlusMinus: { ...this.state.loadingPlusMinus, [id]: false } }, this.props.onSaved)
  }


  render() {
    if (this.state.showAddCardRow) {
      return (
        <AddCardRow
          onSubmit={this.onSaveBenefitCard}
          benefitcards={this.state.benefitcards ? this.state.benefitcards.filter((card) => card.collection === 'activities') : []}
          onCancel={this.toggleAddCard}
          saving={this.state.saving}
        />
      )
    } else if (this.state.showAddTimedCardRow) {
      return (
        <AddTimedCardRow
          onSubmit={this.onSaveTimedBenefitCard}
          benefitcards={this.state.benefitcards ? this.state.benefitcards.filter((card) => card.collection === 'reservations') : []}
          onCancel={this.toggleAddTimedCard}
          saving={this.state.saving}
        />
      )
    } else {
      return (
        <div className='animated fadeIn'>
          <Row style={{ marginTop: 5, marginBottom: 10 }}>
            <Col>
              <div style={{ cursor: 'pointer' }} onClick={this.toggleAddCard}>
                <AddCircleIcon fontSize='small' onClick={this.toggleAddCard} /> Lisää aktiviteettikortti
              </div>
            </Col>
            <Col>
              <div style={{ cursor: 'pointer' }} onClick={this.toggleAddTimedCard}>
                <AddCircleIcon fontSize='small' onClick={this.toggleAddTimedCard} /> Lisää varauskortti
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ display: 'flex', minHeight: 'calc(100vh - 400px)', backgroundColor: '#FFFFFF' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={this.state.personBenefitCards}
                    columns={this.getColumns()}
                    density='compact'
                    pageSize={20}
                    disableSelectionOnClick={true}
                    rowsPerPageOptions={[20, 50, 100]}
                    pagination
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )
    }
  }
}
export default withFirebase(PersonBenefitCards)
