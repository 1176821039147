import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { Grid, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withFirebase } from '../Fb'
import { v4 as uuidv4 } from 'uuid'

class FilterDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedResources: null,
      filtername: null
    }
  }

  componentDidMount() {
    this.getSavedFilters()
  }

  handleResourceOptionLabel = (opt) => {
    return opt.displayName ? opt.displayName : ''
  }

  handleChange = (event, value) => {
    this.setState({ selectedResources: value })
  }

  handleCloseAndApply = () => {
    this.props.onClose({ name: 'oma valinta', resources: this.state.selectedResources })
  }

  handleCloseCancel = () => {
    this.props.onClose(null)
  }

  getSavedFilters = () => {
    this.props.firebase.getPersonVenueManagerConfigs().then((snapshot) => {
      if (snapshot.exists && snapshot.data().resourcefilters) {
        this.setState({ filters: snapshot.data().resourcefilters })
      } else {
        this.setState({ filters: [] })
      }
    })
  }

  saveFilters = async () => {
    const _f = this.state.filters
    _f.push({ name: this.state.filtername, resources: this.state.selectedResources, id: uuidv4() })
    let config = { resourcefilters: _f }
    // console.log('config', config)
    await this.props.firebase.savePersonVenueManagerConfigs(config)
    this.setState({ filtername: null })
  }

  handleNameChange = (event) => {
    this.setState({ filtername: event.target.value })
  }

  selectSavedFilter = (id) => {
    const filter = this.state.filters.find((f) => f.id === id)
    this.setState({ selectedResources: filter.resources }, )
    this.props.onClose(filter)
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseCancel}
        aria-labelledby='form-dialog-title'
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id='form-dialog-title'>Omat filtterit</DialogTitle>
        <DialogContent>
          <Typography variant='body2'>Tee uusi filtteri</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={this.props.resources || []}
                getOptionLabel={this.handleResourceOptionLabel} // {option => option.name}
                onChange={this.handleChange}
                getOptionSelected={(option, val) => {
                  return option.id === val.id
                }}
                value={this.state?.selectedResources || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='standard'
                    label='Lisää resursseja ...'
                    margin='normal'
                    fullWidth
                    autoFocus
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Button onClick={() => this.handleCloseAndApply()} variant="contained" color="primary">Käytä</Button>
              </Grid>
            <Grid item xs={5}>
              <TextField margin='dense' id='filtername' label='Tallenna nimellä' value={this.state.filtername || ''} onChange={this.handleNameChange} type='text' fullWidth />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" onClick={this.saveFilters} color="primary" disabled={this.state.filtername === null || this.state.selectedResources === null || (this.state.selectedResources && this.state.selectedResources.length < 1)}>Tallenna</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2' style={{ marginTop: 30 }}>
                Tallennetut filtterit
              </Typography>
              <List dense={false}>
                {this.state.filters ? this.state.filters.map((f) => 
                  <ListItem  key={f.id + '-li'} button onClick={() => this.selectSavedFilter(f.id)}>
                    <ListItemText key={f.id} primary={f.name} />
                  </ListItem>
                ): null}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseCancel} color='primary'>
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withFirebase(FilterDialog)
