import React from 'react'
import { withFirebase } from '../Fb'

// reactstrap components
import { Row, Col, Button, Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import FalconModal from '../Inputs/FalconModal'
import { getShopProductSchema, getShopProductUISchema } from '../Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from './ReactTableWrapper'
// core components
// import moment from 'moment'
// import 'moment/locale/fi'
import { filterCaseInsensitive } from '../FalconTable'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DeleteButton from '../Buttons/DeleteButton'
import EditButton from '../Buttons/EditButton'

const transformToDB = (data) => {
  const _data = { ...data }
  _data.price = {}
  _data.price.g = _data.pricegeneral * 100
  delete _data.pricegeneral
  return _data
}

const transformFromDB = (data) => {
  const _data = { ...data }
  _data.pricegeneral = _data.price.g / 100
  delete _data.price
  return _data
}

class ShopProducts extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, shopProcuctModalOpen: false, selected: null }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    this.setState({ loading: true })
    this.props.firebase.getShopProducts().then((values) => {
      if (!values.empty) {
        console.log('values get')
        let _d = values.docs
          .map((sh) => {
            return { ...sh.data(), id: sh.id }
          })
          .map(transformFromDB)
        console.log('_d', _d)
        this.setState(
          {
            products: _d,
            loading: false,
          },
          this.filterData
        )
      } else {
        this.setState(
          {
            products: [],
            loading: false,
          },
          this.filterData
        )
      }
    })
  }

  deleteProduct = (id) => {
    return this.props.firebase.deleteShopProduct(id).then(() => {
      this.updateData()
    })
  }

  getActions = (id) => {
    return (
      <>
        <div className='actions-right'>
          <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.handleRowSelect(id)
            }}
          >
            <EditIcon fontSize={'inherit'} />
            {' Muokkaa'}
          </Button>
          <Button
            color='danger'
            size='sm'
            onClick={(el) => {
              this.deleteShopProduct(id)
            }}
          >
            <DeleteIcon fontSize={'inherit'} />
            {' Poista'}
          </Button>
        </div>
      </>
    )
  }

  filterData = () => {
    console.log('filterData', this.state.products)
    if (this.state.products) {
      const data = this.state.products.map((r) => {
        const _r = { ...r }
        _r.actions = this.getActions(r.id)
        return _r
      })
      console.log('done filtering', data)
      this.setState({ loading: false, products: data })
    }
  }

  handleRowSelect = (e) => {
    // console.log(e)
    const resource = this.state.products.find((r) => r.id === e)
    this.setState({ selectedResource: resource }, this.toggleShopProductModal)
  }

  addNewShopProduct = () => {
    this.setState({ selectedResource: {} }, this.toggleShopProductModal)
  }

  toggleShopProductModal = () => {
    this.setState({
      shopProcuctModalOpen: !this.state.shopProcuctModalOpen,
    })
  }

  updateShopProduct = async (e) => {
    console.log('updateShopProduct', e)
    return this.props.firebase.updateShopProduct(transformToDB(e)).then(() => {
      this.updateData()
      this.toggleShopProductModal()
    })
  }

  createShopProduct = async (e) => {
    console.log('createExtra', e)
    return this.props.firebase.createShopProduct(transformToDB(e)).then(() => {
      this.updateData()
      this.toggleShopProductModal()
    })
  }

  createShopProductModal = () => {
    return (
      <FalconModal
        onUpdate={this.updateShopProduct}
        onCreate={this.createShopProduct}
        toggle={this.toggleShopProductModal}
        resource={this.state.selectedResource}
        isOpen={this.state.shopProcuctModalOpen}
        uiSchema={getShopProductUISchema()}
        dataSchema={getShopProductSchema()}
        title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa tuotetta' : 'Uusi tuote'}
      />
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>Loading ...</div>
        </>
      )
    } else {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' md='5' sm='5'>
                  <CardTitle tag='h5'>Shop tuotteet</CardTitle>
                  <CardBody className='text-muted'>
                    Voit luoda tuotteet joita voit laittaa asiakkaiden laskuille.
                  </CardBody>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Button onClick={this.addNewShopProduct}>Lisää tuote</Button>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <ReactTable
                    data={this.state.products}
                    filterable
                    noDataText='Ei löytynyt yhtään tuotetta'
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                    columns={[
                      {
                        Header: 'Nimi',
                        accessor: 'name',
                      },
                      {
                        Header: 'Kuvaus',
                        accessor: 'desc',
                      },
                      {
                        Header: 'Hinta',
                        accessor: 'pricegeneral',
                      },
                      {
                        Header: ' ',
                        accessor: 'edit',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <EditButton
                              onClick={(el) => {
                                this.handleRowSelect(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                      {
                        Header: ' ',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <DeleteButton
                              onClick={(el) => {
                                this.deleteProduct(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    // getTrProps={(state, rowInfo) => {
                    //   if (rowInfo && rowInfo.row) {
                    //     // console.log(rowInfo.row)
                    //     return {
                    //       onClick: (e) => {
                    //         this.handleRowSelect(rowInfo.row._original.id)
                    //       },
                    //     }
                    //   } else {
                    //     return {}
                    //   }
                    // }}
                    className='-striped -highlight primary-pagination'
                  />
                </Col>
              </Row>
              <Row>
                <Col md='12'></Col>
              </Row>
            </CardBody>
          </Card>
          {this.state.alert}
          {this.createShopProductModal()}
          {/* </div> */}
        </>
      )
    }
  }
}

export default withFirebase(ShopProducts)
