import React from 'react';

function TarmoLogo() {
  return (
    <div>
      <img
        src='https://tarmopalvelut.fi/wp-content/themes/tarmopalvelut-theme/img/tarmo-palvelut-logo.png'
        alt='Tarmo Palvelut'
      />
    </div>
  );
}

export default TarmoLogo;
