import { Drawer } from '@material-ui/core'
import React, { Component } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { isMobile } from 'react-device-detect'

class CustomerSideDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      draweropen: props.open,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({ draweropen: this.props.open })
    }
  }

  render() {
    let _width = '30vw'
    if (isMobile) {
      _width = '100vw'
    }
    return (
      <React.Fragment>
        <Drawer anchor={'right'} open={this.state.draweropen} onClose={this.props.onClose}>
          <Row style={{ width: _width }}>
            <Col className='text-right'>
              <Button size={'xs'} onClick={this.props.onClose}>
                Sulje
              </Button>
            </Col>
          </Row>
          <div style={{ height: '100%' }}>
            <iframe
              title='asiakasnakyma'
              src='https://ramstadt.cintoia.com'
              width='100%'
              height='100%'
              frameBorder='0'
              style={{ border: 0 }}
              allowFullScreen=''
              aria-hidden='false'
              tabIndex='0'
            />
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}

export default CustomerSideDrawer
