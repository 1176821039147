import React, { Component } from 'react';
import DateSelector from '../../components/Inputs/DateSelector';
import moment from 'moment';
import { Divider } from '@material-ui/core';
import { withFirebase } from '../../components/Fb';
import SimplePosList from '../../components/Lists/SimplePosList';

class SimplePosContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reservations: [],
      selectedDate: moment()//.format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    // this.props.firebase.getResourses().then((resources) => {
    //   this.setState({ resources: resources.docs.map((doc) => doc.data()) }, this.listenBookings)
    // })
  }

  componentDidUpdate(prevProps) {
    // if (this.props.date !== prevProps.date) {
    //   this.fetchReservations();
    // }
  }

  // flattenBookings = (bookings) => {
  //   console.log('flattenBookings', bookings, this.state.resources)
  //   if (!bookings) return []
  //   if (!this.state.resources) return []
  //   const _b = bookings.filter((x) => !(x.id.startsWith('ph-') || x.id.startsWith('placeholder-')))
  //   const _flattened = _b.map((x) => {
  //     console.log(x)
  //     let _court = this.state.resources.find((y) => y.id === x.resourceId)
  //     const courtname = _court ? _court.displayName : ''
  //     return {
  //       id: x.id,
  //       start: moment.utc(x.start).format('HH:mm'),
  //       end: moment.utc(x.end).format('HH:mm'),
  //       court: x.resourceId,
  //       courtname: courtname,
  //       isrecurring: x.data.isrecurring,
  //       cost: x.paymentstatus.cost / 100,
  //       paid: x.paymentstatus.cost - x.paymentstatus.paid > 0 ? 'Ei' : 'Kyllä',
  //       title: x.title,
  //       occurency: { date: moment.utc(x.start).format('YYYY-MM-DD') },
  //     }
  //   })
  //   return _flattened
  // }

  // filterData = () => {
  // }

  // listenBookings = () => {
  //   const date = moment(this.state.selectedDate)
  //   if (date.isValid()) {
  //     if (date.year() < 2030 && date.year() > 2020) {
  //       if (this.bookingsRef) {
  //         this.setState({ shards: null })
  //         this.unsubscribeBookings()
  //       }
  //       // sharded
  //       this.bookingsRef = this.props.firebase.listenBookingIndex(
  //         moment.utc(this.state.selectedDate, 'YYYY-MM-DD').format('YYYYMMDD')
  //       )
  //       this.unsubscribeBookings = this.bookingsRef.onSnapshot((snap) => {
  //         const combinedBookings = /*this.state.rawReservations ||*/ []
  //         const shards = this.state.shards || {}
  //         snap.forEach((doc) => {
  //           shards[doc.id] = Object.keys(doc.data()).map((key) => {
  //             return { ...doc.data()[key], id: key }
  //           })
  //           combinedBookings.push(
  //             ...Object.keys(doc.data()).map((key) => {
  //               return { ...doc.data()[key], id: key }
  //             })
  //           )
  //         })
  //         console.log('shards handled', combinedBookings)
  //         const bookings = this.flattenBookings(combinedBookings)
  //         this.setState({ rawReservations: bookings }, this.filterData)
  //       })
  //       // ---sharded
  //     }
  //   }
  // }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date })
  }

  render() {
    return (
      <React.Fragment>
        <div className='content'>
          <DateSelector onChange={this.handleDateChange} />
          <Divider />
          <SimplePosList date={this.state.selectedDate} profile={this.props.profile} user={this.props.user} />
        </div>
      </React.Fragment>
    );
  }
}

export default withFirebase(SimplePosContainer)
