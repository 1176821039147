import React from 'react'
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, FormGroup, Label } from 'reactstrap'
import Select from 'react-select'
import { Typography } from '@material-ui/core'
import Infobox from '../Infos/Infobox'


const CONTRACTTYPES = [
  { value: 'fixedterm', label: 'Määräaikainen' },
  { value: 'untilfurthernotice', label: 'Toistaiseksi voimassaoleva' },
]

const LANGUAGES = [
  { value: 'fi', label: 'Suomi' },
  { value: 'sv', label: 'Svensk' },
  { value: 'en', label: 'English' },
]

const DEFAULT_TERMS = {
  default: {
    fixedterm: {
      text: {
        fi: '',
        sv: '',
        en: '',
      },
      coveringnote: {
        fi: '',
        sv: '',
        en: '',
      },
    },
    untilfurthernotice: {
      text: {
        fi: '',
        sv: '',
        en: '',
      },
      coveringnote: {
        fi: '',
        sv: '',
        en: '',
      },
    },
  },
}

class RecurrentContractTerms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      terms: {},
      loading: true,
      saved: true,
      selectedLanguage: 'fi',
      selectedContractType: 'fixedterm',
      selectedVenue: 'default',
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.getRecurrentTerms().onSnapshot((doc) => {
      if (doc.exists) {
        this.setState({
          terms: doc.data(),
          loading: false,
        })
      } else {
        this.setState({
          loading: false,
          terms: DEFAULT_TERMS,
        })
      }
    })
    this.getVenues()
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  getVenues = async () => {
    const _v = await this.props.firebase.getVenues()
    if (!_v.empty) {
      const venues = _v.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      console.log('venues', venues)
      this.setState({ venues, selectedVenue: venues[0] })
    }
  }

  setVenue = (selection, action) => {
    this.setState({ selectedVenue: selection.value })
  }

  setLanguage = (selection, action) => {
    this.setState({ selectedLanguage: selection.value })
  }

  setContractType = (selection, action) => {
    this.setState({ selectedContractType: selection.value })
  }

  handleCompanyChange = (event) => {
    console.log(event.target.name, event.target.value)
    let _t = this.state.terms
    if (!_t[this.state.selectedVenue]) _t[this.state.selectedVenue] = {}
    if (!_t[this.state.selectedVenue].company) _t[this.state.selectedVenue].company = {}
    _t[this.state.selectedVenue].company[event.target.name] = event.target.value
    this.setState({ terms: _t, saved: false })
  }

  handleTermsChange = (event) => {
    let _t = this.state.terms
    if (!_t[this.state.selectedVenue]) _t[this.state.selectedVenue] = {}
    if (!_t[this.state.selectedVenue][this.state.selectedContractType]) _t[this.state.selectedVenue][this.state.selectedContractType] = { text: { fi: '', en: '', sv: '' }, coveringnote: { fi: '', en: '', sv: ''} }
    _t[this.state.selectedVenue][this.state.selectedContractType].text[this.state.selectedLanguage] = event.target.value
    this.setState({ terms: _t, saved: false })
  }

  handlecoveringnoteChange = (event) => {
    let _t = this.state.terms
    if (!_t[this.state.selectedVenue]) _t[this.state.selectedVenue] = {}
    if (!_t[this.state.selectedVenue][this.state.selectedContractType]) _t[this.state.selectedVenue][this.state.selectedContractType] = { text: { fi: '', en: '', sv: '' }, coveringnote: { fi: '', en: '', sv: ''} }
    _t[this.state.selectedVenue][this.state.selectedContractType].coveringnote[this.state.selectedLanguage] =
      event.target.value
    this.setState({ terms: _t, saved: false })
  }

  onSave = (event) => {
    event.preventDefault()
    this.props.firebase.addRecurrentTerms(this.state.terms).then((dataSnapshot) => {
      this.setState({ saved: true })
    })
  }

  render() {
    if (!this.state.loading) {
      const termsExists =
        this.state.terms &&
        this.state.terms[this.state.selectedVenue] &&
        this.state.terms[this.state.selectedVenue][this.state.selectedContractType]
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md='5' sm='5'>
                        <CardTitle tag='h5'>Varausehdot</CardTitle>
                      </Col>
                      <Col>
                        <Infobox index={'0'} />
                      {/* <Alert severity='info'>
                        <strong>Info!</strong> Sähköisten sopimusten tekeminen maksaa 5€/allekirjoitus.
                      </Alert> */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.venues && this.state.venues.length > 1 ? (
                      <Row>
                        <Col md={3}>
                          {/* <FormControl style={{ width: '20%' }}>
                            <InputLabel id='venue'>Paikka</InputLabel>
                            <MuiSelect
                              labelId='venue-label'
                              id='venue'
                              value={this.state.selectedVenue || ''}
                              onChange={this.setVenue}
                            >
                              {this.state.venues.map((v, i) => {
                                return (
                                  <MenuItem key={v.name.toLowerCase()} value={v.name.toLowerCase()}>
                                    {v.name}
                                  </MenuItem>
                                )
                              })}
                            </MuiSelect>
                          </FormControl> */}
                          <FormGroup>
                            <Label for={'label.lang'}>Paikka</Label>
                            <Select
                              className='react-select primary'
                              classNamePrefix='react-select'
                              name={'lang.loc'}
                              id={'lang.loc'}
                              value={
                                this.state.selectedVenue
                                  ? this.state.venues
                                      .map((v) => {
                                        return { value: v.name, label: v.name }
                                      })
                                      .find((x) => x.value === this.state.selectedVenue)
                                  : ''
                              }
                              // defaultValue={LANGUAGES[0]}
                              onChange={this.setVenue}
                              options={this.state.venues.map((v) => {
                                return { value: v.name, label: v.name }
                              })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                    <Row style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
                      <Col sm='2' md='2' xs='2'>
                        <FormGroup>
                          <Label for={'name'}>Yrityksen/yhdistyksen nimi</Label>
                          <Input
                            type={'text'}
                            name={'name'}
                            id={'companyname'}
                            value={this.state.terms[this.state.selectedVenue]?.company?.name}
                            onChange={this.handleCompanyChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm='2' md='2' xs='2'>
                        <FormGroup>
                          <Label for={'streetaddress'}>Osoite</Label>
                          <Input
                            type={'text'}
                            name={'streetaddress'}
                            id={'companyfulladdress'}
                            value={this.state.terms[this.state.selectedVenue]?.company?.streetaddress}
                            onChange={this.handleCompanyChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm='2' md='2' xs='2'>
                        <FormGroup>
                          <Label for={'businessid'}>Y-tunnus</Label>
                          <Input
                            type={'text'}
                            name={'businessid'}
                            id={'companyvatid'}
                            value={this.state.terms[this.state.selectedVenue]?.company?.businessid}
                            onChange={this.handleCompanyChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm='2' md='2' xs='2'>
                        <FormGroup>
                          <Label for={'vatid'}>Sähköposti</Label>
                          <Input
                            type={'text'}
                            name={'email'}
                            id={'companyemail'}
                            value={this.state.terms?.[this.state.selectedVenue]?.company?.email}
                            onChange={this.handleCompanyChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm='2' md='2' xs='2'>
                        <FormGroup>
                          <Label for={'phone'}>Puhelin</Label>
                          <Input
                            type={'text'}
                            name={'phone'}
                            id={'companyphone'}
                            value={this.state.terms?.[this.state.selectedVenue]?.company?.phone}
                            onChange={this.handleCompanyChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
                      <Col sm='2' md='2' xs='2'>
                        <FormGroup>
                          <Label for={'label.lang'}>Kieli</Label>
                          <Select
                            className='react-select primary'
                            classNamePrefix='react-select'
                            name={'lang.lang'}
                            id={'lang.lang'}
                            value={
                              this.state.selectedLanguage
                                ? LANGUAGES.find((x) => x.value === this.state.selectedLanguage)
                                : ''
                            }
                            defaultValue={LANGUAGES[0]}
                            onChange={this.setLanguage}
                            options={LANGUAGES}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm='2' md='2' xs='2'>
                        <FormGroup>
                          <Label for={'label.type'}>Sopimustyyppi</Label>
                          <Select
                            className='react-select primary'
                            classNamePrefix='react-select'
                            name={'type.type'}
                            id={'type.type'}
                            value={
                              this.state.selectedContractType
                                ? CONTRACTTYPES.find((x) => x.value === this.state.selectedContractType)
                                : ''
                            }
                            defaultValue={CONTRACTTYPES[0]}
                            onChange={this.setContractType}
                            options={CONTRACTTYPES}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm='3' md='3' xs='3' className='pt-3'>
                        <Typography variant='caption'>
                          Termit jotka määritellään sopimuksen standardiosassa. VUOKRANANTAJA = halliyhtiö,
                          VAKIOVUOROLAINEN = asiakas. Käytä niitä viitataksesi ehdoissa.
                        </Typography>
                      </Col>
                      <Col sm='3' md='3' xs='3' className='pt-3'>
                        <a
                          href='https://cfalconassets.s3.eu-north-1.amazonaws.com/public/fixedterm.pdf'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <Typography variant='caption'>Standardiosa - Määräaikainen</Typography>
                        </a>
                        <br />
                        <a
                          href='https://cfalconassets.s3.eu-north-1.amazonaws.com/public/untilfurthernotice.pdf'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <Typography variant='caption'>Standardiosa - Toistaiseksi voimassa</Typography>
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label for='terms'>Vakiovuoron ehdot</Label>
                          <Input
                            rows='15'
                            value={
                              termsExists
                                ? this.state.terms[this.state.selectedVenue][this.state.selectedContractType].text[
                                    this.state.selectedLanguage
                                  ]
                                : ''
                            }
                            id='terms'
                            name='terms'
                            type='textarea'
                            style={{ maxHeight: 400 }}
                            onChange={this.handleTermsChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={6}>
                        <div style={{ whiteSpace: 'pre-line' }}>
                          {termsExists
                            ? this.state.terms[this.state.selectedVenue][this.state.selectedContractType].text[
                                this.state.selectedLanguage
                              ]
                            : ''}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label for='terms'>Saateteksti sähköistä allekirjoitus lähetettäessä.</Label>
                          <Input
                            rows='15'
                            value={
                              termsExists
                                ? this.state.terms[this.state.selectedVenue][this.state.selectedContractType]
                                    .coveringnote[this.state.selectedLanguage]
                                : ''
                            }
                            id='coveringnote'
                            name='coveringnote'
                            type='textarea'
                            style={{ maxHeight: 200 }}
                            onChange={this.handlecoveringnoteChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={6}>
                        <div style={{ whiteSpace: 'pre-line' }}>
                          {termsExists
                            ? this.state.terms[this.state.selectedVenue][this.state.selectedContractType].coveringnote[
                                this.state.selectedLanguage
                              ]
                            : ''}
                        </div>
                      </Col>
                    </Row>{' '}
                    <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button disabled={this.state.saved} color='primary' onClick={this.onSave}>
                          Tallenna muutokset
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default RecurrentContractTerms
