import React from 'react'
import { withFirebase } from '../../components/Fb'
import moment from 'moment-timezone'

// reactstrap components
import { Row, Col, Button } from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import { getActivitySchema, getActivityUISchema } from '../../components/Inputs/dataschemas'
// import Typography from '@material-ui/core/Typography'
// import ReactTable from 'react-table'
// import ReactTable from '../../components/Lists/ReactTableWrapper'
// core components
// import moment from 'moment'
import 'moment/locale/fi'
// import { filterCaseInsensitive } from '../../components/FalconTable'
import ActivityDetailsWizard from '../../components/Details/ActivityDetailsWizard'
import ActivityDetails from '../../components/Details/ActivityDetails2'
import EditButton from '../../components/Buttons/EditButton'
import DeleteButton from '../../components/Buttons/DeleteButton'
import ParticipantsButton from '../../components/Buttons/ParticipantsButton'
import { DataGrid } from '@mui/x-data-grid'
//import SettingsIcon from '@material-ui/icons/Settings'
import { Chip } from '@material-ui/core'
import Infobox from '../../components/Infos/Infobox'
//import PersonSearch from '../../components/Inputs/PersonSearch'

const STATES = [
  { label: 'Luonnos', value: 'draft' },
  { label: 'Julkaistu', value: 'published' },
  { label: 'Peruttu', value: 'cancelled' },
  { label: 'Poistettu', value: 'deleted' },
]

const GENDERS = [
  { label: 'Avoin', value: 'open' },
  { label: 'Miehet', value: 'men' },
  { label: 'Naiset', value: 'female' },
]

const DEFAULT_TYPES = [
  { key: 'valmennus', label: 'Valmennus' },
  { key: 'kilpailu', label: 'Kilpailu' },
  { key: 'peli', label: 'Peli' },
  { key: 'tapahtuma', label: 'Tapahtuma' },
  { key: 'kurssi', label: 'Kurssi' },
]

const DEFAULT_SPORTS = [
  { key: 'tennis', label: 'Tennis' },
  { key: 'padel', label: 'Padel' },
  { key: 'sulkapallo', label: 'Sulkapallo' },
  { key: 'squash', label: 'Squash' },
  { key: 'pingis', label: 'Pingis' },
  { key: 'biljardi', label: 'Biljardi' },
  { key: 'petanque', label: 'Petanque' },
  { key: 'keilaus', label: 'Keilaus' },
  { key: 'curling', label: 'Curling' },
  { key: 'darts', label: 'Darts' },
  { key: 'jousiammunta', label: 'Jousiammunta' },
  { key: 'pickleball', label: 'Pickleball' },
  { key: 'jalkapallo', label: 'Jalkapallo' },
  { key: 'koripallo', label: 'Koripallo' },
  { key: 'salibandy', label: 'Salibandy' },
  { key: 'lentopallo', label: 'Lentopallo' },
  { key: 'kuntosali', label: 'Kuntosali' },
  { key: 'kuntonyrkkeily', label: 'Kuntonyrkkeily'},
  { key: 'ryhmaliikunta', label: 'Ryhmäliikunta' },
  { key: 'beachvolley', label: 'Beach volley' },
  { key: 'golf', label: 'Golf' },
  { key: 'jääkiekko', label: 'Jääkiekko' },
  { key: 'luistelu', label: 'Luistelu' },
  { key: 'uinti', label: 'Uinti' },
  { key: 'sukellus', label: 'Sukellus' },
  { key: 'ratsastus', label: 'Ratsastus' },
  { key: 'hiihto', label: 'Hiihto' },
  { key: 'laskettelu', label: 'Laskettelu' },
  { key: 'pyöräily', label: 'Pyöräily' },
  { key: 'juoksu', label: 'Juoksu' },
  { key: 'kävely', label: 'Kävely' },
  { key: 'melonta', label: 'Melonta' },
  { key: 'soutu', label: 'Soutu' },
  { key: 'purjehdus', label: 'Purjehdus' },
  { key: 'purjelautailu', label: 'Purjelautailu' },
  { key: 'kajakki', label: 'Kajakki' },
  { key: 'sähly', label: 'Sähly' },
  { key: 'frisbeegolf', label: 'Frisbeegolf' },
  { key: 'pilates', label: 'Pilates' },
  { key: 'muu', label: 'Muu' },
]

class ActivitiesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      activityModalOpen: false,
      images: [],
      activityDetailsWizardOpen: false,
      activityDetailsOpen: false,
      selectedActivity: null,
      newrecurrent: false,
      showPast: false,
      filtered: [],
      focusTab: 0,
      deleteLoading: {},
    }
  }

  componentDidMount() {
    this.updateData()
    this.props.firebase.listenMedia().onSnapshot((snapshot) => {
      const images = []
      snapshot.forEach((doc) => {
        if (doc.data().mimetype.indexOf('image/') > -1) {
          images.push({ id: doc.id, ...doc.data() })
        }
      })
      this.setState({ images })
    })
    this.props.firebase.getSports().onSnapshot((doc) => {
      const sports = [...DEFAULT_SPORTS]
      if (doc.exists) {
        sports.push(...doc.data().list)
      }
      // unique array of sports
      const uniqueSports = [...new Map(sports.map((item) => [item['key'], item])).values()]
      uniqueSports.sort((a, b) => a.label.localeCompare(b.label))
      this.setState({ sports: uniqueSports })
    })
    this.props.firebase.getActivitytypes().onSnapshot((doc) => {
      const activitytypes = [...DEFAULT_TYPES]
      if (doc.exists) {
        activitytypes.push(...doc.data().list)
      }
      const uniqueActivitytypes = [...new Map(activitytypes.map((item) => [item['key'], item])).values()]
      uniqueActivitytypes.sort((a, b) => a.label.localeCompare(b.label))
      this.setState({ activitytypes: uniqueActivitytypes })
    })
  }

  updateData = async () => {
    const _activities = await this.props.firebase.getActivities()
    let _d = _activities.docs.map((sh) => {
      return { ...sh.data(), id: sh.id }
    })
    this.setState(
      {
        activities: _d,
      },
      this.filterData
    )
  }

  filterData = () => {
    const _data = this.state.activities
      .map((r) => {
        r.displayName = r.name.fi
        r.desc = r.description.fi
        r.ingr = r.ingress.fi
        r.participantscount = r.participants ? r.participants.length : 0
        r.checkedincount = r.participantscount > 0 ? r.participants.filter((p) => p.checkin).length : 0
        r.queuecount = r.queue ? r.queue.length : 0
        // r.actions = this.getActions(r.id)
        r.state = r.state ? r.state : 'published'
        // r.statelabel = STATES.find((s) => s.value === r.state).label
        // if (r.resourceconfigid) {
        //   let _rc = this.state.resourceconfigurations.find((rc) => rc.id === r.resourceconfigid)
        //   if (_rc) {
        //     r.resourceconfigname = _rc.displayName
        //   } else {
        //     r.resourceconfigname = 'default'
        //   }
        // } else {
        //   r.resourceconfigname = 'default'
        // }
        // if (r.multiuse && r.multiuse.length > 0) {
        //   r.multiusecourt = 'Kyllä'
        // } else {
        //   r.multiusecourt = 'Ei'
        // }
        return {
          ...r,
        }
      })
      .filter((r) => {
        if (this.state.showPast) {
          return true
        } else {
          return moment.utc(r.end, 'YYYY-MM-DDTHH:mm').isAfter(moment.utc())
        }
      })

    let updatedSelectedActivity = null
    if (this.state.selectedActivity) {
      updatedSelectedActivity = _data.find((r) => r.id === this.state.selectedActivity.id)
    }
    if (updatedSelectedActivity) {
      this.setState({ filtered: _data, selectedActivity: updatedSelectedActivity, loading: false })
    } else {
      this.setState({ filtered: _data, loading: false })
    }
  }

  handleRowEdit = (e) => {
    const activity = this.state.activities.find((r) => r.id === e)
    console.log('handleRowEdit', activity)
    this.setState({ selectedActivity: activity }, this.toggleActivityDetailsWizard)
  }

  handleRowSelect = (e) => {
    const activity = this.state.activities.find((r) => r.id === e)
    this.setState({ selectedActivity: activity }, this.toggleActivityDetails) //this.toggleActivityModal)
  }

  handleRowParticipants = (e) => {
    const activity = this.state.activities.find((r) => r.id === e)
    this.setState({ selectedActivity: activity, focusTab: 1 }, this.toggleActivityDetailsWizard) //this.toggleActivityModal)
  }


  addNewActivity = () => {
    this.setState({ selectedActivity: null, newrecurrent: false }, this.toggleActivityDetailsWizard)
    // this.setState({ selectedActivity: {} }, this.toggleActivityModal)
  }

  addNewActivityRecurrent = () => {
    this.setState({ selectedActivity: null, newrecurrent: true }, this.toggleActivityDetailsWizard)
    // this.setState({ selectedActivity: {} }, this.toggleActivityModal)
  }

  toggleActivityModal = () => {
    this.setState({
      activityModalOpen: !this.state.activityModalOpen,
    })
  }

  toggleActivityDetails = () => {
    this.setState({
      activityDetailsOpen: !this.state.activityDetailsOpen,
    })
  }

  toggleActivityDetailsWizard = () => {
    this.setState({
      activityDetailsWizardOpen: !this.state.activityDetailsWizardOpen,
    })
  }

  handleDeleteActivity = async (id) => {
    this.setState({ deleteLoading: { ...this.state.deleteLoading, [id]: true } })
    await this.props.firebase.deleteActivity(id)
    this.setState({ deleteLoading: { ...this.state.deleteLoading, [id]: false } }, this.updateData)
  }

  // getCategoryDeps = () => {
  //   if (this.state.resourcecategories) {
  //     const keys = Object.keys(this.state.resourcecategories)
  //     return keys.map((key) => {
  //       const enumNames = this.state.resourcecategories[key]
  //       const enums = enumNames.map((e) => e.toLowerCase())
  //       const dep = {
  //         properties: {
  //           type: {
  //             enum: [key],
  //           },
  //           category: {
  //             type: 'string',
  //             title: 'Kategoria',
  //             enum: enums,
  //             enumNames: enumNames,
  //           },
  //         },
  //       }
  //       return dep
  //     })
  //   }
  // }

  getDataSchemaWithImagesList = () => {
    const dataSchema = getActivitySchema()
    dataSchema.properties.image = {
      type: 'string',
      title: 'Kuva',
      enum: this.state.images.map((x) => x.id),
      enumNames: this.state.images.map((x) => x.originalName),
    }
    return dataSchema
  }

  createActivity = async (data) => {
    const _data = { ...data }
    if (!_data.description) _data.description = { fi: '', en: '', sv: '' }
    if (!_data.ingress) _data.ingress = { fi: '', en: '', sv: '' }
    if (!_data.name) _data.name = { fi: '', en: '', sv: '' }
    _data.ingress.fi = _data.ingr
    _data.name.fi = _data.displayName
    _data.description.fi = _data.desc
    delete _data.displayName
    delete _data.desc
    delete _data.ingr

    await this.props.firebase.createActivity(_data)
  }

  createActivityModal = () => {
    const schema = this.getDataSchemaWithImagesList()
    // schema.dependencies.type.oneOf = this.getCategoryDeps()

    // const items = { enum: [], enumNames: [] }
    // this.state.resources.sort((a, b) => a.displayName.localeCompare(b.displayName)).forEach((res) => {
    //   items.enum.push(res.id)
    //   items.enumNames.push(res.displayName)
    // })
    // schema.properties.multiuse.items = items

    // const rcItems = { enum: [], enumNames: [] }
    // this.state.resourceconfigurations.sort((a, b) => a.displayName.localeCompare(b.displayName)).forEach((res) => {
    //   rcItems.enum.push(res.id)
    //   rcItems.enumNames.push(res.displayName)
    // })
    // schema.properties.resourceconfigid.enum = rcItems.enum
    // schema.properties.resourceconfigid.enumNames = rcItems.enumNames

    return (
      <FalconModal
        onUpdate={this.props.firebase.updateActivity}
        onCreate={this.props.firebase.createActivity}
        toggle={this.toggleActivityModal}
        size='xl'
        modifiedCallback={this.updateData}
        resource={this.state.selectedActivity}
        isOpen={this.state.activityModalOpen}
        uiSchema={getActivityUISchema()}
        dataSchema={schema}
        title={
          this.state.selectedActivity && this.state.selectedActivity.id ? 'Muokkaa aktiviteettia' : 'Uusi aktiviteetti'
        }
      />
    )
  }

  getActions = (id) => {
    return (
      <>
        <div className='actions-right'>
          <EditButton
            onClick={(el) => {
              this.handleRowEdit(id)
            }}
          />
          <Button
            color='info'
            size='sm'
            onClick={(el) => {
              this.handleRowSelect(id)
            }}
          >
            <span className='btn-label'>
              <i className='nc-icon nc-simple-remove' /> Details
            </span>
          </Button>
        </div>
      </>
    )
  }

  getColDefinitions = () => {
    const cols = [
      {
        headerName: ' ',
        field: 'edit',
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <EditButton onClick={() => this.handleRowEdit(params.row.id)} />
        },
        width: 120,
        sortable: false,
        filterable: false,
      },
      {
        headerName: ' ',
        field: 'participants',
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <ParticipantsButton onClick={() => this.handleRowParticipants(params.row.id)}/>
        },
        width: 60,
        sortable: false,
        filterable: false,
      },
      {
        headerName: 'Nimi',
        field: 'displayName',
        disableClickEventBubbling: true,
        // maxWidth: 400,
        minWidth: 300,
      },
      // {
      //   headerName: 'Tyyppi',
      //   field: 'type',
      //   disableClickEventBubbling: true,
      //   minWidth: 140,
      // },
      {
        headerName: 'Aika',
        field: 'start',
        disableClickEventBubbling: true,
        valueFormatter: (params) => {
          return moment.utc(params.value).format('DD.MM.YY HH:mm')
        },
        minWidth: 160,
      },
      {
        headerName: 'Laji',
        field: 'sport',
        minWidth: 120,
      },
      {
        headerName: 'Max',
        field: 'maxp',
        width: 60,
        type: 'number',
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Min',
        field: 'minp',
        width: 60,
        type: 'number',
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Ilmot',
        field: 'participantscount',
        width: 60,
        // type: 'number',
        sortable: false,
        filterable: false,
      },
      {
        headerName: 'In',
        field: 'checkedincount',
        width: 50,
        // type: 'number',
        sortable: false,
        filterable: false
      },
      {
        headerName: 'Jono',
        field: 'queuecount',
        width: 60,
        // type: 'number',
        sortable: false,
        filterable: false,
      },
      {
        headerName: 'Sulkeutuu',
        field: 'entryend',
        valueFormatter: (params) => {
          return new Date(params.value).toLocaleDateString()
        },
        minWidth: 120,
      },
      {
        headerName: 'Sukupuoli',
        field: 'gender',
        minWidth: 140,
        valueFormatter: (params) => {
          const g = GENDERS.find((x) => x.value === params.value)
          return g ? g.label : ''
        },
      },
      {
        headerName: 'Tila',
        field: 'state',
        disableClickEventBubbling: true,
        valueFormatter: (params) => {
          const s = STATES.find((x) => x.value === params.value)
          return s ? s.label : ''
        },
      },
      {
        headerName: ' ',
        field: 'delete',
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <DeleteButton loading={this.state.deleteLoading[params.row.id]} onClick={() => this.handleDeleteActivity(params.row.id)} />
        },
        width: 120,
        sortable: false,
        filterable: false,
      },
    ]
    return cols.map((x) => {
      // return { ...x, flex: 1 / cols.length }
      return { ...x }
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>Loading ...</Col>
            </Row>
          </div>
        </>
      )
    } else if (this.state.activityDetailsOpen) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <ActivityDetails activity={this.state.selectedActivity} />
              </Col>
            </Row>
          </div>
        </>
      )
    } else if (this.state.activityDetailsWizardOpen) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.toggleActivityDetailsWizard}>Palaa listaan</Button>
              </Col>
            </Row>
            <ActivityDetailsWizard
              sports={this.state.sports}
              activitytypes={this.state.activitytypes}
              // firebase={this.props.firebase}
              activity={this.state.selectedActivity}
              // onChangeActivity={(a) => this.setState({ selectedActivity: a })}
              // closeDetail={this.closeDetail}
              images={this.state.images}
              onUpdate={this.updateData}
              recurrent={this.state.newrecurrent}
              initTab={this.state.focusTab}
            />
          </div>
        </>
      )
    } else {
      return (
        <div className='content'>
          <Row>
            <Col>
              <Button onClick={this.addNewActivity}>Lisää tapahtuma/aktiviteetti</Button>
            </Col>
            <Col>
              <Button onClick={this.addNewActivityRecurrent}>Lisää toistuva tapahtuma/aktiviteetti</Button>
            </Col>
            <Col className='mt-2'>
              <Infobox index={'0'} />
            </Col>
            {/* <Col className='text-right'> */}
            {/* <Button size='sm' onClick={() => console.log('open add types')}>
                <SettingsIcon fontSize='small' /> Tapahtumatyypit
              </Button> */}
            {/* </Col> */}
          </Row>
          <Row>
            <Col className='align-bottom align-text-bottom mb-2'>
              <Chip
                label='Näytä menneet'
                clickable
                size='small'
                color='primary'
                variant={this.state.showPast ? 'default' : 'outlined'}
                onClick={() => this.setState({ showPast: !this.state.showPast }, this.filterData)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <div style={{ display: 'flex', minHeight: 'calc(100vh - 200px)', backgroundColor: '#FFFFFF' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={this.state.filtered}
                    columns={this.getColDefinitions()}
                    density='compact'
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'start', sort: 'asc' }],
                      },
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='12'></Col>
          </Row>
          {this.state.alert}
          {this.createActivityModal()}
        </div>
      )
    }
  }
}

export default withFirebase(ActivitiesContainer)
