import React, { Component } from 'react'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {
  FormControlLabel,
  InputAdornment,
  List,
  MenuItem,
  Switch,
  TextField,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  // Grid
} from '@material-ui/core'
// import Autocomplete from '@material-ui/lab/Autocomplete'
import DeleteIcon from '@material-ui/icons/Delete'
import { Col, Row } from 'reactstrap'
import moment from 'moment'
import algoliasearch from 'algoliasearch'
import { withFirebase } from '../Fb'
import PersonSearch from '../Inputs/PersonSearch'
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core'
import ImageSelector from '../Inputs/ImageSelector'

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f')
const algoliaindex = algoliaclient.initIndex('falcon_combined')

const INTERVALS = [
  { label: 'Päivittäin', value: 'daily' },
  { label: 'Viikottain', value: 'weekly' },
  { label: 'Kuukausittain', value: 'monthly' },
]

const STEPS = ['Perustiedot', 'Ilmoittautuminen', 'Kuvaukset', 'Luo aktiviteetti']
const LONGSTEPS = ['Perustiedot', 'Toistuvuus', 'Ilmoittautuminen', 'Kuvaukset', 'Luo aktiviteetti']
const GENDERS = [
  { label: 'Avoin', value: 'open' },
  { label: 'Miehet', value: 'men' },
  { label: 'Naiset', value: 'female' },
]
const STATES = [
  { label: 'Luonnos', value: 'draft' },
  { label: 'Julkaistu', value: 'published' },
  { label: 'Peruttu', value: 'cancelled' },
  { label: 'Poistettu', value: 'deleted' },
]

const WEEKDAYS = [
  { label: 'Maanantai', value: 1 },
  { label: 'Tiistai', value: 2 },
  { label: 'Keskiviikko', value: 3 },
  { label: 'Torstai', value: 4 },
  { label: 'Perjantai', value: 5 },
  { label: 'Lauantai', value: 6 },
  { label: 'Sunnuntai', value: 7 },
]

function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return hours + 'h, '  + minutes + 'mins'
}

const EMPTY_ACTIVITY = {
  name: { fi: '', sv: '', en: '' },
  description: { fi: '', sv: '', en: '' },
  ingress: { fi: '', sv: '', en: '' },
  location: { fi: '', sv: '', en: '' },
  price: 0,
  variations: [],
  poster: null,
  type: '',
  gender: '',
  ageGroup: '',
  skillLevel: '',
  start: '12:00',
  end: '13:00',
  entrystart: moment().add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm'),
  entryend: null,
  isexternal: false,
  externalurl: '',
  maxp: 0,
  minp: 0,
  public: true,
  tags: [],
  categories: [],
  hasqueue: false,
  sport: '',
  billable: false,
  ispublished: false,
  organiser: null,
  state: 'draft',
  vat: 10,
  accounting: '',
  productCode: '',
}

const EMPTY_RECURRENT_END = {
  days: 1,
  hours: 0,
  minutes: 0,
}

const EMPTY_RECURRENT_START = {
  days: 10,
  hours: 0,
  minutes: 0,
}

class ActivityCreator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      activity: { ...EMPTY_ACTIVITY },
      recurrentEntryEnd: { ...EMPTY_RECURRENT_END },
      recurrentEntryStart: { ...EMPTY_RECURRENT_START },

      activitytypes: this.props.activitytypes,
      sports: this.props.sports,
      steps: this.props.recurrent ? LONGSTEPS : STEPS,
      recurrencyStartWeekday: 1, // 1-7 isoWeekday
      posterEditOpen: false,
      priceInputs: { price: '0.00' },
    }
    if (this.props.recurrent) {
      this.state.activity.recurrency = {
        interval: 'weekly',
        startdate: moment().format('YYYY-MM-DD'),
        enddate: null,
      }
      this.state.activity.entrystart = 10 * 24 * 60
      this.state.activity.entryend = 24 * 60
    }
  }

  handleSave = () => {
    const { activity, priceInputs } = this.state
    if (priceInputs.price) {
      // clear price 
      let _price = priceInputs.price.replace(',', '.').replace(/[^0-9.]/g, '')
      activity.price = parseFloat(_price)
    } else {
      activity.price = 0
    }
    this.props.onCreate(activity)
  }

  handleNext = () => {
    if (this.state.activeStep + 1 === this.state.steps.length) {
      this.handleSave()
    }
    this.setState({ activeStep: this.state.activeStep + 1 })
  }

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  }

  handleReset = () => {
    this.setState({ activeStep: 0, activity: { ...EMPTY_ACTIVITY }, 
      posterEditOpen: false, 
      recurrencyStartWeekday: 1, 
      recurrentEntryEnd: { ...EMPTY_RECURRENT_END }, 
      recurrentEntryStart: { ...EMPTY_RECURRENT_START } 
    })
  }

  handlePosterChange = (e) => {
    const { activity } = this.state
    const _image = this.props.images.find((image) => image.id === e.target.value)
    activity.poster = _image.url
    activity.media = _image
    this.setState({ activity })
  }

  updateRecurrency = (activity) => {
    const { recurrencyStartWeekday } = this.state
    if (activity.recurrency) {
      const { startdate, enddate, interval } = activity.recurrency
      if (startdate && enddate && interval && recurrencyStartWeekday) {
        const d = { title: activity.name.fi }
        const start = moment(startdate)
        if (start.isoWeekday() !== recurrencyStartWeekday) {
          // find next date where weekday is correct
          start.add(1, 'days')
          while (start.isoWeekday() !== recurrencyStartWeekday) {
            start.add(1, 'days')
          }
        }
        const end = moment(enddate)
        const recurrencies = []
        if (interval === 'weekly') {
          for (let m = moment(start); m.isBefore(end); m.add(1, 'weeks')) {
            const dd = { ...d }
            dd.starttime = m.format('YYYY-MM-DD') + 'T' + activity.start
            dd.endtime = m.format('YYYY-MM-DD') + 'T' + activity.end
            recurrencies.push(dd)
          }
        } else if (interval === 'monthly') {
          for (let m = moment(start); m.isBefore(end); m.add(1, 'months')) {
            const dd = { ...d }
            dd.starttime = m.format('YYYY-MM-DD') + 'T' + activity.start
            dd.endtime = m.format('YYYY-MM-DD') + 'T' + activity.end
            recurrencies.push(dd)
          }
        } else if (interval === 'daily') {
          for (let m = moment(start); m.isBefore(end); m.add(1, 'days')) {
            const dd = { ...d }
            dd.starttime = m.format('YYYY-MM-DD') + 'T' + activity.start
            dd.endtime = m.format('YYYY-MM-DD') + 'T' + activity.end
            recurrencies.push(dd)
          }
        }
        return recurrencies
      }
    }
    return null
  }

  handleTextChange = (e, property) => {
    const { activity } = this.state
    console.log(activity)
    if (property.indexOf('.') > -1) {
      const [parent, child] = property.split('.')
      activity[parent][child] = e.target.value
    } else {
      activity[property] = e.target.value
    }
    if (property === 'start') {
      if (!activity.end) {
        activity.end = moment(e.target.value).add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm')
      }
    } else if (property === 'entrystart') {
      if (!activity.entryend) {
        activity.entryend = moment(e.target.value).add(5, 'days').startOf('hour').format('YYYY-MM-DDTHH:mm')
      }
    }
    if (this.props.recurrent && property.indexOf('recurrency.') > -1) {
      const recurrencies = this.updateRecurrency(activity)
      if (recurrencies) {
        activity.recurrencies = recurrencies
      }
    }
    this.setState({ activity })
  }


  handlePriceChange = (e, property) => {
    const { priceInputs } = this.state
    priceInputs[property] = e.target.value
    this.setState({ priceInputs })
  }

  handleNumberChange = (e, property) => {
    const { activity } = this.state
    if (typeof e.target.value !== 'number') {
      let value = e.target.value
      if (value.indexOf(',') > -1) {
        value = value.replace(',', '.')
      }

      value = value.replace(/[^0-9.]/g, '')
      if (property.indexOf('.') > -1) {
        const [parent, child] = property.split('.')
        activity[parent][child] = parseFloat(value)
      } else {
        activity[property] = parseFloat(value)
      }
    } else {
      if (property.indexOf('.') > -1) {
        const [parent, child] = property.split('.')
        activity[parent][child] = e.target.value
      } else {
        activity[property] = e.target.value
      }
    }
    this.setState({ activity })
  }

  handleSwitchChange = (e, property) => {
    const { activity } = this.state
    if (property.indexOf('.') > -1) {
      const [parent, child] = property.split('.')
      activity[parent][child] = e.target.checked
    } else {
      activity[property] = e.target.checked
    }
    this.setState({ activity })
  }

  handleRecurrentWeekdayChange = (value) => {
    this.setState({ recurrencyStartWeekday: value })
  }

  handleOptionLabel = (opt) => {
    return opt.firstname && opt.lastname ? opt.firstname + ' ' + opt.lastname : 'Tyhjä'
  }

  clearAlgolia = (x) => {
    x.id = x.objectID
    delete x._highlightResult
    delete x.lastmodified
    delete x.reservationAlert
    delete x.objectID
    return x
  }

  searchParticipants = (input) => {
    algoliaindex.search(input, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const searchresults = hits
        .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
        .map((x) => this.clearAlgolia(x))
      this.setState({ searchresults })
    })
  }

  handleParticipantChange = (event, value) => {
    console.log('handleParticipantChange', value)
    const { activity } = this.state
    if (!activity.organisers) {
      activity.organisers = []
    }
    if (value) {
      activity.organisers = value.map((x) => {
        return { id: x.id, firstname: x.firstname, lastname: x.lastname, email: x.email, phone: x.phone || '' }
      })
      console.log(activity.organisers)
    }
    this.setState({ activity })
  }


  renderBasicInfo = () => {
    return (
      <div>
        <Row>
          <Col xs='12' md='6'>
            <TextField
              fullWidth
              required
              id='name-required'
              label='Nimi'
              size='small'
              onChange={(e) => this.handleTextChange(e, 'name.fi')}
              value={this.state.activity.name.fi}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              id='start-datetime-local'
              label='Alkuaika'
              type='datetime-local'
              value={this.state.activity.start || moment().add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm')}
              // className={classes.textField}
              onChange={(e) => this.handleTextChange(e, 'start')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              id='end-datetime-local'
              label='Loppuaika'
              type='datetime-local'
              value={this.state.activity.end || ''}
              onChange={(e) => this.handleTextChange(e, 'end')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              id='price-required'
              label='Hinta'
              size='small'
              onChange={(e) => this.handlePriceChange(e, 'price')}
              InputProps={{
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
              }}
              value={this.state.priceInputs.price}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              id='standard-select-type'
              select
              required
              label='Tyyppi'
              value={this.state.activity.type}
              helperText='Aktiviteetin tyyppi'
              onChange={(e) => this.handleTextChange(e, 'type')}
            >
              {this.state.activitytypes.map((option) => (
                <MenuItem key={option.key} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              id='standard-select-sport'
              select
              required
              label='Laji'
              value={this.state.activity.sport}
              helperText='Lajin valinta'
              onChange={(e) => this.handleTextChange(e, 'sport')}
            >
              {this.state.sports.map((option) => (
                <MenuItem key={option.key} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              id='standard-select-gender'
              select
              required
              label='Sukupuoli'
              value={this.state.activity.gender}
              helperText='Onko aktiviteetti vain miehille vai naisille vai kaikille'
              onChange={(e) => this.handleTextChange(e, 'gender')}
            >
              {GENDERS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row>
          <Col className='mt-4' xs='12' md='6'>
            <Typography variant='body2'>Sisäinen tapahtumaluokka (käytetään etukoodien kohdentamiseen)</Typography>
            <TextField
              id='providertype'
              label=''
              size='small'
              onChange={(e) => this.handleTextChange(e, 'providertype')}
              value={this.state.activity.providertype}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <PersonSearch onChange={this.handleParticipantChange} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextField
              id='standard-select-vat'
              select
              required
              label='Alv%'
              value={this.state.activity.vat}
              helperText='Alv prosentti'
              onChange={(e) => this.handleNumberChange(e, 'vat')}
            >
              {[0, 10, 14, 24, 25.5].map((option) => (
                <MenuItem key={option} value={option}>
                  {option} %
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextField
              fullWidth
              id='productCode'
              label='Tuotekoodi'
              size='small'
              onChange={(e) => this.handleTextChange(e, 'productCode')}
              value={this.state.activity.productCode}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextField
              fullWidth
              id='accounting'
              label='Kirjanpitotili'
              size='small'
              onChange={(e) => this.handleTextChange(e, 'accounting')}
              value={this.state.activity.accounting}
            />
          </Col>
        </Row>
      </div>
    )
  }

  renderRecurrentBasicInfo = () => {
    return (
      <div>
        <Row>
          <Col xs='12' md='6'>
            <TextField
              fullWidth
              required
              id='name-required'
              label='Nimi'
              size='small'
              onChange={(e) => this.handleTextChange(e, 'name.fi')}
              value={this.state.activity.name.fi}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              id='start-weekday'
              label='Päivä'
              select
              value={this.state.recurrencyStartWeekday || 1}
              onChange={(e) => this.handleRecurrentWeekdayChange(e.target.value)}
            >
              {WEEKDAYS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              id='start-datetime-local'
              label='Alkuaika'
              type='time'
              value={this.state.activity.start || moment().add(1, 'hour').startOf('hour').format('HH:mm')}
              // className={classes.textField}
              onChange={(e) => this.handleTextChange(e, 'start')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              id='end-datetime-local'
              label='Loppuaika'
              type='time'
              value={this.state.activity.end || ''}
              onChange={(e) => this.handleTextChange(e, 'end')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              id='price-required'
              label='Hinta'
              size='small'
              onChange={(e) => this.handlePriceChange(e, 'price')}
              InputProps={{
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
              }}
              value={this.state.priceInputs.price}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              id='standard-select-type'
              select
              required
              label='Tyyppi'
              value={this.state.activity.type}
              helperText='Aktiviteetin tyyppi'
              onChange={(e) => this.handleTextChange(e, 'type')}
            >
              {this.state.activitytypes.map((option) => (
                <MenuItem key={option.key} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              id='standard-select-sport'
              select
              required
              label='Laji'
              value={this.state.activity.sport}
              helperText='Lajin valinta'
              onChange={(e) => this.handleTextChange(e, 'sport')}
            >
              {this.state.sports.map((option) => (
                <MenuItem key={option.key} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              id='standard-select-gender'
              select
              required
              label='Sukupuoli'
              value={this.state.activity.gender}
              helperText='Onko aktiviteetti vain miehille vai naisille vai kaikille'
              onChange={(e) => this.handleTextChange(e, 'gender')}
            >
              {GENDERS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row>
          <Col className='mt-4' xs='12' md='6'>
            <Typography variant='body2'>Sisäinen tapahtumaluokka (käytetään etukoodien kohdentamiseen)</Typography>
            <TextField
              id='providertype'
              label=''
              size='small'
              onChange={(e) => this.handleTextChange(e, 'providertype')}
              value={this.state.activity.providertype}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <PersonSearch onChange={this.handleParticipantChange} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextField
              id='standard-select-vat'
              select
              required
              label='Alv%'
              value={this.state.activity.vat}
              helperText='Alv prosentti'
              onChange={(e) => this.handleNumberChange(e, 'vat')}
            >
              {[0, 10, 14, 24].map((option) => (
                <MenuItem key={option} value={option}>
                  {option} %
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextField
              fullWidth
              id='productCode-required'
              label='Tuotekoodi'
              size='small'
              onChange={(e) => this.handleTextChange(e, 'productCode')}
              value={this.state.activity.productCode}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextField
              fullWidth
              id='accounting-required'
              label='Kirjanpitotili'
              size='small'
              onChange={(e) => this.handleTextChange(e, 'accounting')}
              value={this.state.activity.accounting}
            />
          </Col>
        </Row>
      </div>
    )
  }

  removeRecurrency = (index) => {
    const recurrencies = this.state.activity.recurrencies
    recurrencies.splice(index, 1)
    this.setState({ activity: { ...this.state.activity, recurrencies } })
  }

  renderRecurrencyInfo = () => {
    return (
      <div>
        <Row>
          <Col xs='12' md='4'>
            <Row>
              <Col xs='12' md='6'>
                <Typography variant='subtitle2'>Toistuvuus</Typography>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <TextField
                  id='standard-select-recurrency'
                  select
                  required
                  label='Toistuvuus'
                  value={this.state.activity?.recurrency?.interval || 'weekly'}
                  helperText='Aktiviteetin toistuvuus'
                  onChange={(e) => this.handleTextChange(e, 'recurrency.interval')}
                >
                  {INTERVALS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <TextField
                  required
                  id='start-date'
                  label='Alkupäivä'
                  type='date'
                  value={
                    this.state.activity?.recurrency?.startdate ||
                    moment().add(1, 'hour').startOf('hour').format('YYYY-MM-DD')
                  }
                  // className={classes.textField}
                  onChange={(e) => this.handleTextChange(e, 'recurrency.startdate')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <TextField
                  required
                  id='end-datetime-local'
                  label='Loppupäivä'
                  type='date'
                  value={this.state.activity?.recurrency?.enddate || ''}
                  onChange={(e) => this.handleTextChange(e, 'recurrency.enddate')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs='12' md='3'>
            <List>
              {this.state.activity?.recurrencies
                ? this.state.activity.recurrencies.map((recurrency, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemText primary={moment(recurrency.starttime).format('DD.MM.YYYY')} />
                        <ListItemSecondaryAction>
                          <IconButton edge='end' aria-label='delete' onClick={() => this.removeRecurrency(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })
                : null}
            </List>
          </Col>
        </Row>
      </div>
    )
  }

  handleRecurrentEntryEndChange = (e, unit) => {
    const { activity } = this.state
    let value = parseInt(e.target.value.replace(/[^0-9]/g, '').replace('.', '')) || 0
    if (unit === 'days') {
      const entryend = this.state.recurrentEntryEnd.minutes + this.state.recurrentEntryEnd.hours * 60 + value * 24 * 60
      this.setState({ recurrentEntryEnd: { ...this.state.recurrentEntryEnd, days: value }, activity: { ...activity, entryend } })
    } else if (unit === 'hours') {
      const entryend = this.state.recurrentEntryEnd.minutes + value * 60 + this.state.recurrentEntryEnd.days * 24 * 60
      this.setState({ recurrentEntryEnd: { ...this.state.recurrentEntryEnd, hours: value }, activity: { ...activity, entryend } })
    } else if (unit === 'minutes') {
      const entryend = value + this.state.recurrentEntryEnd.hours * 60 + this.state.recurrentEntryEnd.days * 24 * 60
      this.setState({ recurrentEntryEnd: { ...this.state.recurrentEntryEnd, minutes: value }, activity: { ...activity, entryend } })
    }
  }

  handleRecurrentEntryStartChange = (e, unit) => {
    const { activity } = this.state
    let value = parseInt(e.target.value.replace(/[^0-9]/g, '').replace('.', '')) || 0
    if (unit === 'days') {
      const entrystart = this.state.recurrentEntryStart.minutes + this.state.recurrentEntryStart.hours * 60 + value * 24 * 60
      this.setState({ recurrentEntryStart: { ...this.state.recurrentEntryStart, days: value }, activity: { ...activity, entrystart } })
    } else if (unit === 'hours') {
      const entrystart = this.state.recurrentEntryStart.minutes + value * 60 + this.state.recurrentEntryStart.days * 24 * 60
      this.setState({ recurrentEntryStart: { ...this.state.recurrentEntryStart, hours: value }, activity: { ...activity, entrystart } })
    } else if (unit === 'minutes') {
      const entrystart = value + this.state.recurrentEntryStart.hours * 60 + this.state.recurrentEntryStart.days * 24 * 60
      this.setState({ recurrentEntryStart: { ...this.state.recurrentEntryStart, minutes: value }, activity: { ...activity, entrystart } })
    }
  }


  renderEntryInfo = () => {
    return (
      <div>
        <Row>
          <Col xs='12' md='6'>
            <Typography variant='subtitle2'>Ilmoittautumisen ajat</Typography>
          </Col>
        </Row>
        {this.props.recurrent ? (
          <>
                        <Row className='mt-4'>
              <Col xs='12' md='6'>
                <Typography variant='subtitle2'>Ilmoittautumisen alkaminen</Typography>
                <Typography variant='caption'>Ilmoittautuminen alkaa kun aktiviteetin alkuun</Typography>
                <div>
                <TextField
                  style={{ width: 40 }}
                  id='entrystart-number-days'
                  label='päivää'
                  type='number'
                  value={this.state.recurrentEntryStart.days || 0}
                  onChange={(e) => this.handleRecurrentEntryStartChange(e, 'days')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />{' '}
                <TextField
                  style={{ width: 40 }}
                  id='entrystart-number-hours'
                  label='tuntia'
                  type='number'
                  value={this.state.recurrentEntryStart.hours || 0}
                  onChange={(e) => this.handleRecurrentEntryStartChange(e, 'hours')}
                />{' '}
                <TextField
                  style={{ width: 40 }}
                  id='entrystart-number-minutes'
                  label='minuuttia'
                  type='number'
                  value={this.state.recurrentEntryStart.minutes || 0}
                  onChange={(e) => this.handleRecurrentEntryStartChange(e, 'minutes')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <Typography variant='caption'>{toHoursAndMinutes(this.state.activity.entrystart)} ennen tapahtumaa</Typography>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <Typography variant='subtitle2'>Ilmoittautumisen päättyminen</Typography>
                <Typography variant='caption'>Ilmoittautuminen päättyy kun aktiviteetin alkuun</Typography>
                <div>
                <TextField
                  style={{ width: 40 }}
                  id='entryend-number'
                  label='päivää'
                  type='number'
                  value={this.state.recurrentEntryEnd.days || 0}
                  onChange={(e) => this.handleRecurrentEntryEndChange(e, 'days')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />{' '}
                <TextField
                  style={{ width: 40 }}
                  id='entryend-number'
                  label='tuntia'
                  type='number'
                  value={this.state.recurrentEntryEnd.hours || 0}
                  onChange={(e) => this.handleRecurrentEntryEndChange(e, 'hours')}
                />{' '}
                <TextField
                  style={{ width: 40 }}
                  id='entryend-number'
                  label='minuuttia'
                  type='number'
                  value={this.state.recurrentEntryEnd.minutes || 0}
                  onChange={(e) => this.handleRecurrentEntryEndChange(e, 'minutes')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <Typography variant='caption'>{toHoursAndMinutes(this.state.activity.entryend)} ennen tapahtumaa</Typography>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col xs='12' md='6'>
                <TextField
                  required
                  id='entrystart-datetime-local'
                  label='Alkuaika'
                  type='datetime-local'
                  value={
                    this.state.activity.entrystart || moment().add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm')
                  }
                  onChange={(e) => this.handleTextChange(e, 'entrystart')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <TextField
                  required
                  id='entryend-datetime-local'
                  label='Loppuaika'
                  type='datetime-local'
                  value={this.state.activity.entryend || ''}
                  onChange={(e) => this.handleTextChange(e, 'entryend')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className='mt-5'>
          <Col xs='12' md='6'>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.activity.isexternal}
                  onChange={(e) => this.handleSwitchChange(e, 'isexternal')}
                />
              }
              label='Ulkoinen ilmoittautuminen'
            />
          </Col>
        </Row>
        {this.state.activity.isexternal ? (
          <Row className='mt-4'>
            <Col xs='12' md='6'>
              <TextField
                required
                id='externalurl'
                label='Ilmoittautumisen ulkoisen linkin osoite'
                size='small'
                value={this.state.activity.externalurl}
                onChange={(e) => this.handleTextChange(e, 'externalurl')}
              />
            </Col>
          </Row>
        ) : (
          <React.Fragment>
            <Row>
              <Col xs='12' md='6'>
                <Typography variant='subtitle2'>Osallistujamäärät</Typography>
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <TextField
                  required
                  id='minp-required'
                  label='Minimimäärä'
                  size='small'
                  value={this.state.activity.minp}
                  onChangeCapture={(e) => this.handleNumberChange(e, 'minp')}
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <TextField
                  required
                  id='maxp-required'
                  label='Maksimimäärä'
                  size='small'
                  value={this.state.activity.maxp}
                  onChangeCapture={(e) => this.handleNumberChange(e, 'maxp')}
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs='12' md='6'>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.activity.hasqueue}
                      onChange={(e) => this.handleSwitchChange(e, 'hasqueue')}
                    />
                  }
                  label='Jono käytössä'
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    )
  }

  togglePosterEdit = () => {
    this.setState({ posterEditOpen: !this.state.posterEditOpen })
  }

  renderDescriptions = () => {
    return (
      <div>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              multiline={true}
              fullWidth
              minRows={2}
              rows={2}
              id='ingress-fi'
              label='Lyhyt kuvaus'
              size='small'
              value={this.state.activity.ingress.fi}
              variant='outlined'
              onChange={(e) => this.handleTextChange(e, 'ingress.fi')}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              required
              multiline={true}
              fullWidth
              minRows={5}
              rows={10}
              id='description-fi'
              label='Pitkä kuvaus'
              size='small'
              value={this.state.activity.description.fi}
              variant='outlined'
              onChange={(e) => this.handleTextChange(e, 'description.fi')}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <Button variant='contained' color='primary' onClick={this.togglePosterEdit}>
              Valitse kuvituskuva
            </Button>
            {this.state.activity.media?.id ? (
              <div>
                <img src={this.state.activity.media.url} alt={this.state.activity.media.originalName} width='100px' />
              </div>
            ) : null}
            {/* <TextField
              id='standard-select-poster'
              select
              required
              label='Poster'
              value={this.state.activity.media?.id}
              helperText='Valitse kuvituskuva'
              onChange={(e) => this.handlePosterChange(e)}
            >
              {this.props.images.map((image) => (
                <MenuItem key={image.id} value={image.id}>
                  <img src={image.url} alt={image.originalName} width='20px' height='20px' />
                  {image.originalName}
                </MenuItem>
              ))}
            </TextField> */}
          </Col>
        </Row>
      </div>
    )
  }

  renderPublish = () => {
    return (
      <div>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            {/* <FormControlLabel
              control={
                <Switch
                  checked={this.state.activity.ispublished}
                  onChange={(e) => this.handleSwitchChange(e, 'ispublished')}
                />
              }
              label='Julkaise'
            /> */}
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs='12' md='6'>
            <TextField
              id='standard-select-state'
              select
              required
              label='Tila'
              value={this.state.activity.state}
              helperText='Aktiviteetin tila'
              onChange={(e) => this.handleTextChange(e, 'state')}
            >
              {STATES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
      </div>
    )
  }

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return this.props.recurrent ? this.renderRecurrentBasicInfo() : this.renderBasicInfo()
      case 1:
        return this.props.recurrent ? this.renderRecurrencyInfo() : this.renderEntryInfo()
      case 2:
        return this.props.recurrent ? this.renderEntryInfo() : this.renderDescriptions()
      case 3:
        return this.props.recurrent ? this.renderDescriptions() : this.renderPublish()
      case 4:
        return this.renderPublish()
      default:
        return 'Unknown step'
    }
  }

  render() {
    const { activeStep } = this.state

    return (
      <div style={{ padding: 20, backgroundColor: 'white' }}>
        <Stepper activeStep={activeStep}>
          {this.state.steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === this.state.steps.length ? (
            <Row className='mt-5'>
              <Col xs='12' md='12'>
                <div>
                  <Typography>Aktiviteetti luotu</Typography>
                  <Button onClick={this.handleReset}>Tee uusi</Button>
                </div>
              </Col>
            </Row>
          ) : (
            <div>
              {this.getStepContent(activeStep)}
              <Row className='mt-5'>
                <Col xs='12' md='12'>
                  <div sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <div sx={{ flex: '1 1 auto' }} />
                    <Button disabled={activeStep === 0} onClick={this.handleBack}>
                      Back
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={this.handleNext}
                      disabled={!this.state.activity.gender || this.state.activity.gender === ''}
                    >
                      {activeStep === this.state.steps.length - 1 ? 'Tallenna aktiviteetti' : 'Next'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
          {/* <div>
            <pre>{JSON.stringify(this.state.activity, null, 2)}</pre>
          </div> */}
        
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.posterEditOpen}
          onClose={this.togglePosterEdit}
          aria-labelledby='max-width-dialog-title'
          style={{ zIndex: '1000' }}
        >
          <DialogTitle id='max-width-dialog-title'>Valitse kuva</DialogTitle>
          <DialogContent>
            <ImageSelector images={this.props.images} onSelect={this.handlePosterChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.togglePosterEdit} color='primary'>
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withFirebase(ActivityCreator)
