import React from 'react'
import { withFirebase } from '../Fb'
import { DataGrid } from '@mui/x-data-grid'
import EditButton from '../Buttons/EditButton'
import DeleteButton from '../Buttons/DeleteButton'
// reactstrap components
import { Row, Col } from 'reactstrap'
import {
  Button,
  Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  // Select,
  // MenuItem,
  // Typography,
  // Divider,
} from '@material-ui/core'
import { Button as BootstrapButton } from 'reactstrap'
import FalconModal from '../Inputs/FalconModal'
import {
  getMaintenanceTicketSchema,
  getMaintenanceTicketUISchema,
} from '../Inputs/dataschemas'
import moment from 'moment'

const statusses = {
  new: 'Uusi',
  inprogress: 'Käsittelyssä',
  completed: 'Valmis',
  closed: 'Suljettu',
}

class MaintenanceTickets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tickets: [],
      targets: [],
      classes: [],
      loading: true,
      openDeleteConfirmation: false,
      openTicketEdit: false,
      edits: { status: null, notes: null, class: null },
      classes: ['kuluminen', 'vika', 'muu'],
    }
  }

  changeTicketStatus = (id, status) => {
    this.setState({ loading: true })
    const ticket = this.state.tickets.find((ticket) => ticket.id === id)
    if (!ticket) {
      alert('Ticket not found')
      return
    }
    ticket.status = status
    ticket[status + 'Date'] = new Date()
    this.props.firebase.updateMaintenanceTicket(ticket).then(() => {
      this.updateList()
    })
  }

  getColumnDefs = () => {
    return [
      { field: 'email', headerName: 'Raportoinut', width: 150, disableClickEventBubbling: true },
      { field: 'message', headerName: 'Viesti', width: 300 },
      {
        field: 'image', headerName: 'Kuva', width: 50,
        renderCell: (params) => {
          if(params.row.image) {
            return <img src={params.row.image} alt='Kuva' style={{ width: 30, height: 30 }} />
          }
          return null
        },
      },
      {
        field: 'status',
        headerName: 'Tila',
        width: 150,
        renderCell: (params) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor:
                    params.row.status === 'new'
                      ? 'red'
                      : params.row.status === 'inprogress'
                      ? 'yellow'
                      : 'green',
                  marginRight: 5,
                }}
              />
              {statusses[params.row.status]} <br />
              {params.row.createdLocal}
            </div>
          )
        },
      },
      { field: 'target', headerName: 'Kohde', width: 150 },
      {
        field: 'actions', headerName: '',
        sortable: false,
        width: 800,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <>
              {params.row.status === 'new' && (
                <BootstrapButton
                disabled={this.state.loading}
                color='warning'
                  size='sm'
                  onClick={(event) => {
                    event.stopPropagation()
                    this.changeTicketStatus(params.row.id, 'inprogress')
                  }}
                >
                  Ota käsittelyyn
                </BootstrapButton>
              )}
              {params.row.status === 'inprogress' && (
                <BootstrapButton
                  disabled={this.state.loading}
                  color='success'
                  size='sm'
                  onClick={(event) => {
                    event.stopPropagation()
                    this.changeTicketStatus(params.row.id, 'completed')
                  }}
                >
                  Merkkaa valmiiksi
                </BootstrapButton>
              )}
              {params.row.status !== 'completed' &&
                params.row.status !== 'closed' && (
                  <BootstrapButton
                  disabled={this.state.loading}
                    size='sm'
                    color='danger'
                    onClick={(event) => {
                      event.stopPropagation()
                      this.changeTicketStatus(params.row.id, 'closed')
                    }}
                  >
                    Sulje (ei toimenpiteitä)
                  </BootstrapButton>
                )}
            </>
          )
        },
       },
    ]
  }
  filterTickets = (tickets) => {
    return tickets.map((ticket) => {
      return {
        ...ticket,
        createdDate: ticket.created
          ? moment.utc(ticket.created.toDate()).format('YYYY-MM-DD')
          : '',
        createdLocal: ticket.created
          ? ticket.created.toDate().toLocaleDateString()
          : '',
        updatedDate: ticket.updated
          ? moment.utc(ticket.updated.toDate()).format('YYYY-MM-DD')
          : '',
      }
    })
  }

  updateList = () => {
    this.setState({ loading: true })
    this.props.firebase.getMaintenanceTickets().then((data) => {
      this.setState({ tickets: this.filterTickets(data), loading: false })
    })
  }


  componentDidMount() {
    this.updateList()
    this.props.firebase.getMaintenanceTicketTargetsOnce().then((data) => {
      this.setState({ targets: data })
    })
    this.props.firebase.getMaintenanceTicketClassesOnce().then((data) => {
      this.setState({ classes: data })
    })
  }

  openDeleteConfirmation = (id) => {
    this.setState({ openDeleteConfirmation: true, deleteId: id })
  }

  onDelete = (id) => {
    this.setState({ loading: true, openDeleteConfirmation: false })
    this.props.firebase.deleteMaintenanceTicket(id).then(() => {
      this.props.firebase.getMaintenanceTickets().then((data) => {
        this.setState({ tickets: this.filterTickets(data), loading: false })
      })
    })
  }

  openTicketDialog = (id) => {
    const ticket = this.state.tickets.find((ticket) => ticket.id === id)
    this.setState({ openTicketEdit: true, ticketId: id, ticket })
  }

  toggleTicketEditModal = () => {
    this.setState({ openTicketEdit: !this.state.openTicketEdit }) // eslint-disable-line
  }

  onSave = async (ticket) => {
    await this.props.firebase.updateMaintenanceTicket(ticket)
    this.setState({ ticketId: null, ticket: null })
    this.updateList()
  }

  echancedDataSchema = () => {
    let _a = getMaintenanceTicketSchema()
    _a.properties.target.enum = this.state.targets.map((r) => r.name)
    _a.properties.target.enumNames = this.state.targets.map((r) => r.name)

    _a.properties.class.enum = this.state.classes.map((r) => r.name)
    _a.properties.class.enumNames = this.state.classes.map((r) => r.name)
    return _a
  }

  render() {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='12'>
              {/* <div>
                <pre>{JSON.stringify(this.state.tickets, null, 2)}</pre>
              </div>
 */}
              <div
                style={{
                  display: 'flex',
                  minHeight: 'calc(100vh - 200px)',
                  backgroundColor: '#FFFFFF',
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    componentsProps={{ panel: { disablePortal: true } }}
                    rows={this.state.tickets}
                    columns={this.getColumnDefs()}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    checkboxSelection={false}
                    disableSelectionOnClick
                    onRowClick={(params) => {
                      this.openTicketDialog(params.row.id)
                    }}
                    loading={this.state.loading}
                    pagination
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <FalconModal
            size='lg'
            onCreate={this.props.firebase.createMaintenanceTicket}
            onUpdate={this.onSave}
            toggle={this.toggleTicketEditModal}
            cancel={this.toggleTicketEditModal}
            resource={this.state.ticket}
            isOpen={this.state.openTicketEdit}
            uiSchema={getMaintenanceTicketUISchema()}
            dataSchema={this.echancedDataSchema()}
            title={
              this.state.ticket && this.state.ticket.id
                ? 'Muokkaa tikettiä'
                : 'Uusi tiketti'
            }
            onButtonCallback={(button) => {
              console.log('Button', button)
            }}
          />
        </div>
        <div>
          <Dialog
            open={this.state.openDeleteConfirmation}
            onClose={() => {
              this.setState({ openDeleteConfirmation: false })
            }}
          >
            <div>
              <h3 style={{ padding: 20 }}>Haluatko varmasti poistaa tämän?</h3>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: 20,
                }}
              >
                <Button
                  onClick={() => {
                    this.onDelete(this.state.deleteId)
                  }}
                >
                  Kyllä
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ openDeleteConfirmation: false })
                  }}
                >
                  Peruuta
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </>
    )
  }
}

export default withFirebase(MaintenanceTickets)
