// import React from 'react'

export const _contractClass = {
  displayName: { type: 'string', title: 'Nimi' },
  user: { type: 'string', title: 'Asiakas' },
  type: {
    type: 'string',
    title: 'Sopimustyyppi',
    enum: ['standardshift-fixedterm', 'standardshift-untilfurthernotice'],
    enumNames: ['Vakiovuoro määräaikainen', 'Vakiovuoro toistaiseksi voimassa'],
  },
  signingtype: { type: 'string', title: 'Allekirjoitus tapa', enum: ['digital', 'manual'], enumNames:['Digitaalinen', 'Manuaalinen'] },
  status: { type: 'string', title: 'Tila', enum: ['created', 'pendingsignature', 'signed', 'cancelled'], enumNames:['Luotu', 'Odottaa allekirjoitusta', 'Allekirjoitettu', 'Peruttu'] },
  validuntil: {
    type: 'string',
    format: 'date',
    title: 'Voimassa',
  }
}

export const _contractClassDeps = {}

export const _contractUISchema = {}
