import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button, Label } from 'reactstrap'
// import FalconModal from '../../components/Inputs/FalconModal'
// import { getContractSchema, getContractUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
// import { filterCaseInsensitive } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import ReservationModal from '../../components/Inputs/ReservationModal'
import Switch from 'react-bootstrap-switch'
import { DataGrid } from '@material-ui/data-grid'

const isInRequestedDates = (occurency, startDate, endDate) => {
  return moment
  .utc(occurency)
  .isBetween(startDate, endDate, 'day', '[]')
}

const isAtVenue = (selectedVenue, venue) => {
  if (selectedVenue) {
    return venue.toLowerCase() === selectedVenue.toLowerCase()
  }
  return true
}

const isSeasonBillingList = (showseasonbilling, rowbilling) => {
  if (showseasonbilling) {
    return rowbilling ? true : false
  } else {
    return rowbilling ? false : true
  }
}

const toCurrency = (value) => {
  return (Math.round(value * 100) / 100).toFixed(2).replace('.', ',') + ' €'
}

// core components

class UnpaidContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      invoicingDialogOpen: false,
      invoicings: null,
      startDate: moment().utc(), //moment().subtract(1, 'years'),
      endDate: moment().utc(), //moment().add(2, 'years'),
      externalConfirmationOpen: false,
      externalBatchConfirmationOpen: false,
      finvoiceConfirmationOpen: false,
      emailInvoiceConfirmationOpen: false,
      onlineConfirmationOpen: false,
      venues: [],
      selectedVenue: null,
      reservationModalOpen: false,
      showseasonals: false,
      billingDate: null,
      actionWait: false,
      selections: [],
    }
  }

  componentDidMount() {
    // this.updateData()
    this.listenData()
    this.getVenues()
  }

  componentWillUnmount() {
    if (this.invoicesRef) {
      // this.unsubscribeInvoices()
      this.invoicesRef.off('value', this.unsubscribeInvoices)
    }
  }

  toggleReservationModal = () => {
    this.toggleInvoicingModal()
    this.setState({ reservationModalOpen: !this.state.reservationModalOpen })
  }

  handleSelectReservation = async (id, occurency) => {
    const reservation = await this.props.firebase.getReservation(id)
    const start = moment.utc(reservation.data().resourcegroups[0].start)
    const end = moment.utc(reservation.data().resourcegroups[0].end)
    const selectedReservation = {
      id: reservation.id,
      start: moment.utc(occurency).hour(start.hour()).minutes(start.minutes()).format(),
      end: moment.utc(occurency).hour(end.hour()).minutes(end.minutes()).format(),
    }
    selectedReservation.data = { ...reservation.data(), id: reservation.id }
    // console.log('selectedReservation', selectedReservation)
    this.setState({ selectedReservation }, this.toggleReservationModal)
  }

  showSeasonBilled = (event) => {
    this.setState({ showseasonals: event.state.value }, this.filterData)
  }

  getVenues = async () => {
    const _v = await this.props.firebase.getVenues()
    if (!_v.empty) {
      const venues = _v.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      this.setState({ venues })
    }
  }

  listenData = () => {
    if (this.invoicesRef) {
      // this.unsubscribeInvoices()
      this.invoicesRef.off('value', this.unsubscribeInvoices)
    }
    // console.log('getting invoices')
    this.invoicesRef = this.props.firebase.listenInvoicingsRt()
    this.unsubscribeInvoices = this.invoicesRef.on('value', (snapshot) => {
      // console.log('get snapshot')
      const data = []
      snapshot.forEach((user) => {
        // console.log(user.key)
        // console.log(Object.keys(user.val().rows).length)
        // console.log(JSON.stringify(user.val()).length)
        data.push({ ...user.val(), id: user.key })
      })
      // eslint-disable-next-line
      // Object.keys(snapshot.val()).map((key) => {
      //   if (Object.keys(snapshot.val()[key].rows).length < 100) {
      //     data.push({ ...snapshot.val()[key], id: key })
      //   }
      // })
      this.setState({ raw: data }, this.filterData)
    })
    // this.invoicesRef = this.props.firebase.listenInvoicings()
    // this.unsubscribeInvoices = this.invoicesRef.onSnapshot((snap) => {
    //   const data = []
    //   snap.forEach((doc) => {
    //     if (doc.data().state !== 'deleted') {
    //       data.push({ ...doc.data(), id: doc.id })
    //     }
    //   })
    //   this.setState({ raw: data }, this.filterData)
    // })
  }

  updateData = () => {
    this.props.firebase.getInvoices().then((snapshot) => {
      const data = []
      // eslint-disable-next-line
      snapshot.docs.map(async (doc) => {
        if (doc.data().state !== 'deleted') {
          data.push({ ...doc.data(), id: doc.id })
        }
      })
      this.setState({ raw: data }, this.filterData)
    })
  }

  setBillingDate = (e) => {
    this.setState({ billingDate: e.target.value })
  }

  setStart = (e) => {
    this.setState({ billingDate: null, startDate: moment.utc(e.target.value) })
  }

  setEnd = (e) => {
    this.setState({ billingDate: null, endDate: moment.utc(e.target.value) /*.hour(23).minute(59).seconds(59)*/ })
  }

  handleRowSelect = (e) => {
    const _result = this.state.invoicings.find((r) => r.id === e)
    if (_result.rows > 2000) {
      alert('Valitse lyhyempi ajanjakso. Yli 2000 riviä hidastaa selaimen.')
    } else {
      this.setState({ selectedResource: _result }, this.toggleInvoicingModal)
    }
  }

  setVenue = (e) => {
    const selectedVenue = e.target.value
    this.setState({ selectedVenue })
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      if (!this.state.raw[i]?.rows) continue
      const keys = Object.keys(this.state.raw[i].rows)
      const sums = keys.reduce(
        (acc, cur) => {
          if (
            isInRequestedDates(this.state.raw[i].rows[cur].occurency, this.state.startDate, this.state.endDate) &&
            this.state.raw[i].rows[cur].unpaid &&
            isAtVenue(this.state.selectedVenue, this.state.raw[i].rows[cur].venue.toLowerCase()) &&
            isSeasonBillingList(this.state.showseasonals, this.state.raw[i].rows[cur].seasonbilling)
          ) {
            acc.cost += Math.round(this.state.raw[i].rows[cur].cost)
            acc.unpaid += Math.round(this.state.raw[i].rows[cur].unpaid)
            acc.rows++
            acc.keys.push(cur)
          }
          return acc
        },
        { cost: 0, unpaid: 0, rows: 0, keys: [] }
      )
      if (sums.keys.length > 0) {
        const filteredData = {}
        for (let h = 0; h < sums.keys.length; h++) {
          filteredData[sums.keys[h]] = this.state.raw[i].rows[sums.keys[h]]
        }
        let contactDisplayName = ''
        let businessId = null
        if (this.state.raw[i].contact) {
          if (this.state.raw[i].contact.businessId) {
            contactDisplayName = this.state.raw[i].contact.name
            businessId = this.state.raw[i].contact.businessId
          } else {
            contactDisplayName = [this.state.raw[i].contact.firstname, this.state.raw[i].contact.lastname].join(' ')
          }
        } else if (this.state.raw[i].user) {
          contactDisplayName = 'user:' + [this.state.raw[i].user.firstname, this.state.raw[i].user.lastname].join(' ')
        }
        data.push({
          ...sums,
          id: this.state.raw[i].id,
          user: contactDisplayName,
          businessId,
          datas: filteredData,
          actions: this.getActions(this.state.raw[i].id),
        })
      }
    }
    this.setState({ loading: false, invoicings: data })
  }

  getActions = (invoicingid) => {
    return (
      <>
        <div className='actions-right'>
          <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.handleRowSelect(invoicingid)
            }}
          >
            Näytä rivit
          </Button>
        </div>
      </>
    )
  }

  toggleSelectRow = (id) => {
    const index = this.state.selections.findIndex((cur) => cur === id)
    const newSelections = this.state.selections
    if (index === -1) {
      newSelections.push(id)
    } else {
      newSelections.splice(index, 1)
    }
    this.setState({ selections: newSelections })
  }

  toggleInvoicingModal = () => {
    this.setState({
      invoicingDialogOpen: !this.state.invoicingDialogOpen,
    })
  }


  closeRowDetails = () => {
    // clear State
    this.setState({ selectedResource: null }, this.toggleInvoicingModal)
  }

  renderInvoiceExtras = () => {
    return (
      <>
        {' '}
        <TextField
          id='recurstart'
          label='Laskun päivä'
          type='date'
          value={this.state.billingDate ? this.state.billingDate : ''}
          onChange={this.setBillingDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </>
    )
  }

  renderRows = () => {
    let unpaidsum = 0
    const showVenue = this.state.venues && this.state.venues.length > 1
    const sorted = Object.keys(this.state.selectedResource.datas)
      .map((key) => {
        return this.state.selectedResource.datas[key]
      })
      .filter((x) => x)
      .sort((a, b) => {
        if (a.occurency > b.occurency) return 1
        else if (a.occurency < b.occurency) return -1
        else return 0
      })
    const rows = sorted
      .map((_r) => {
        unpaidsum += _r.unpaid
        return (
          <Row key={Math.random()} className='border-bottom'>
            <Col md='4' className='pt-1'>
              {_r.row.replace('default', '').replace('tali', '')}
            </Col>
            <Col md='1' className='pt-1'>
              {_r.unpaid} €
            </Col>
            <Col md='1' className='pt-1'>
              {_r.cost} €
            </Col>
            <Col md='1' className='pt-1'>
              {_r.vat} %
            </Col>
            {showVenue ?
              <Col md='1' className='pt-1'>
                {_r.venue === 'tali' || _r.venue === 'default' ? '' : _r.venue}
              </Col> : null}
            <Col md='1' className='pt-1'>
              {_r.seasonbilling ? 'Kyllä' : ''}
            </Col>
            <Col md='2' className='pt-1'>
              {_r.usernotes}
            </Col>
            <Col md='1'>
              {!_r.collection ?
                (<Button
                  className='mt-0 mb-0'
                  size='sm'
                  color='link'
                  onClick={() => this.handleSelectReservation(_r.id, _r.occurency)}
                >
                  Avaa varaus
                </Button>) : null
              }
            </Col>
          </Row>
        )
      })
      .filter((x) => x)
    return (
      <>
        <Row>
          <Col md='4'>
            <b>Rivi</b>
          </Col>
          <Col md='1'>
            <b>Maksamatta</b>
          </Col>
          <Col md='1'>
            <b>Summa</b>
          </Col>
          <Col md='1'>
            <b>VAT</b>
          </Col>
          {showVenue ?
            <Col md='1'>
              <b>Venue</b>
            </Col> : null }
          <Col md='1'>
            <b>Kausilaskutus</b>
          </Col>
          <Col md='2'>
            <b>Notes</b>
          </Col>
          <Col md='1'></Col>
        </Row>
        {rows}
        <Row>
          <Col md='6'>{''}</Col>
          <Col md='1'>{unpaidsum} €</Col>
          <Col md='1'>{''}</Col>
          <Col md='1'>{''}</Col>
          <Col md='1'>{''}</Col>
          <Col md='1'>{''}</Col>
          <Col md='1'>{''}</Col>
        </Row>
      </>
    )
  }


  getColumns = () => {
    const cols = 4
    return [
      {
        headerName: 'Asiakas',
        field: 'user',
        width: 300,
        flex: 1 / cols,
      },
      {
        headerName: 'Summa',
        field: 'cost',
        filterable: false,
        maxWidth: 200,
        minWidth: 100,
        width: 100,
        flex: 1 / cols,
        valueFormatter: (params) => {
          return toCurrency(params.value)
        },

        // Cell: (props) => <div style={{ textAlign: 'right' }}>{toCurrency(props.value)}</div>,
      },
      {
        headerName: 'Maksamatta',
        field: 'unpaid',
        filterable: false,
        maxWidth: 200,
        minWidth: 100,
        width: 100,
        flex: 1 / cols,
        valueFormatter: (params) => {
          return toCurrency(params.value)
        },
        // Cell: (props) => <div style={{ textAlign: 'right' }}>{toCurrency(props.value)}</div>,
      },
      {
        headerName: 'Rivejä',
        field: 'rows',
        filterable: false,
        maxWidth: 200,
        minWidth: 100,
        width: 100,
        flex: 1 / cols,
        renderCell: (params) => {
          return (
            <div className='actions-right'>
              {params.value}
              {' kpl '}
              <Button
                color='success'
                size='sm'
                onClick={(el) => {
                  this.handleRowSelect(params.row.id)
                }}
              >
                Näytä rivit
              </Button>
            </div>
          )
        },
      }
    ]
  }

  render() {
    if (this.state.loading) {
      return <FalconLoading />
    } else {
      return (
        <>
          <div className='content'>
            {/* <Row>
            </Row> */}
            <Row>
              <Col>
                <TextField
                  id='recurstart'
                  label='Alkupäivä'
                  type='date'
                  // defaultValue={this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : ''}
                  value={
                    this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD')
                  }
                  onChange={this.setStart}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id='recurstart'
                  label='Loppupäivä'
                  type='date'
                  value={
                    this.state.endDate ? this.state.endDate.format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD')
                  }
                  onChange={this.setEnd}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
              {this.state.venues && this.state.venues.length > 1 ? (
                <Col>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id='venue'>Paikka</InputLabel>
                    <Select
                      fullWidth
                      labelId='venue-label'
                      id='venue'
                      value={this.state.selectedVenue || ''}
                      onChange={this.setVenue}
                    >
                      {this.state.venues.map((v, i) => {
                        return (
                          <MenuItem key={v.name.toLowerCase()} value={v.name.toLowerCase()}>
                            {v.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Col>
              ) : null}
              <Col>
                <Button onClick={() => this.setState({ selections: [] }, this.filterData)}>Päivitä</Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    this.setState(
                      { startDate: moment.utc().startOf('day'), endDate: moment.utc().endOf('day'),selections: [] },
                      this.filterData
                    )
                  }
                >
                  Tämä päivä
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        startDate: moment.utc().subtract(1, 'day').startOf('day'),
                        endDate: moment.utc().subtract(1, 'day').endOf('day'),
                        selections: []
                      },
                      this.filterData
                    )
                  }
                >
                  Eilinen
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    this.setState(
                      { startDate: moment.utc().startOf('month'), endDate: moment.utc().endOf('month'),selections: [] },
                      this.filterData
                    )
                  }
                >
                  Tämä kuukausi
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        startDate: moment.utc().subtract(1, 'month').startOf('month'),
                        endDate: moment.utc().subtract(1, 'month').endOf('month'),
                        selections: []
                      },
                      this.filterData
                    )
                  }
                >
                  Edellinen kuukausi
                </Button>
              </Col>
              <Col>
                <Label for='showseasonals'>Kausilaskutettavat</Label>{' '}
                <Switch
                  defaultValue={this.state.showseasonals}
                  onColor='primary'
                  offColor='primary'
                  id='showseasonals'
                  name='showseasonals'
                  onChange={this.showSeasonBilled}
                />{' '}
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                {/* <pre>{JSON.stringify(this.state.invoicings, null, 2)}</pre> */}
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 200px)', backgroundColor: '#FFFFFF' }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      rows={this.state.invoicings || []}
                      columns={this.getColumns()}
                      defaultPageSize={50}
                      pageSizeOptions={[50, 100, 200]}
                      filterable
                      onSelectionModelChange={(newSelectionModel) => {
                        console.log('newSelectionModel', newSelectionModel)
                        this.setState({ selectionModel: newSelectionModel })//setSelectionModel(newSelectionModel);
                      }}
                      selectionModel={this.state.selectionModel}
                      // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                    />
                  </div>
                </div>
                {/* <ReactTable
                  data={this.state.invoicings}
                  filterable
                  noDataText='Ei löytynyt yhtään laskutettavaa'
                  defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                  columns={[
                    {
                      Header: 'Asiakas',
                      accessor: 'user',
                      // width: 300,
                    },
                    {
                      Header: 'Summa',
                      accessor: 'cost',
                      filterable: false,
                      // maxWidth: 200,
                      // minWidth: 100,
                      // width: 100,
                    },
                    {
                      Header: 'Maksamatta',
                      accessor: 'unpaid',
                      filterable: false,
                      // maxWidth: 300,
                      // minWidth: 100,
                      // width: 200,
                    },
                    {
                      Header: 'Rivejä',
                      accessor: 'rows',
                      filterable: false,
                      // maxWidth: 300,
                      // minWidth: 100,
                      // width: 300,
                    },
                    {
                      Header: '',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={100}
                  showPaginationTop
                  showPaginationBottom={false}
                  // getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect)}
                  className='-striped -highlight primary-pagination'
                /> */}
              </Col>
            </Row>
            {this.state.alert}
            <Dialog
              fullWidth={true}
              maxWidth={'xl'}
              open={this.state.invoicingDialogOpen}
              onClose={this.closeRowDetails}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1100' }}
            >
              <DialogTitle id='max-width-dialog-title'>
                {this.state?.selectedResource?.user ? this.state.selectedResource.user : null}{' '}
                {this.state.startDate.format('D.M.YYYY') + ' - ' + this.state.endDate.format('D.M.YYYY')}{' '}
              </DialogTitle>
              <DialogContent>{this.state.selectedResource ? this.renderRows() : null}</DialogContent>
              <DialogActions>
                <Button onClick={this.closeRowDetails} color='secondary'>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog>

            <ReservationModal
              isOpen={this.state.reservationModalOpen}
              toggle={this.toggleReservationModal}
              resource={this.state.selectedReservation}
              tags={null}
              {...this.props}
            />
          </div>
        </>
      )
    }
  }
}

export default withFirebase(UnpaidContainer)
