export const _activityClass = {
  desc: {  type: 'string', title: 'Kuvaus' },
  ingr: {  type: 'string', title: 'Lyhyt kuvaus' },
  displayName: { type: 'string', title: 'Nimi' },
  gender: {
    type: 'string',
    title: 'Sukupuoli',
    enum: ['men', 'female', 'open'],
    enumNames: ['Miehet', 'Naiset', 'Avoin'],
  },
  hasqueue: { type: 'boolean', title: 'Jono' },
  maxp: { type: 'number', title: 'Maksimi osallistujamäärä' },
  minp: { type: 'number', title: 'Minimi osallistujamäärä' },
  start: { type: 'string',format: 'datetime', title: 'Alkuaika' },
  end: { type: 'string', format: 'datetime', title: 'Loppuaika' },
  sport: { type: 'string', title: 'Laji' },
  type: {
    type: 'string',
    title: 'Tapahtuman tyyppi',
    enum: ['Valmennus', 'Kilpailu'],
    enumNames: ['Valmennus', 'Kilpailu'],
  },
  isexternal: { type: 'boolean', title: 'Ulkoinen ilmoittautuminen' },
  externalurl: { type: 'string', title: 'Ulkoinen ilmioittautumisosoite' },
  // memberships: {
  //   type: 'array',
  //   items: _activityMembershipClass,
  // },
}

export const _activityUISchema = {
  phone: {
    'ui:placeholder': '+358...',
  },
}
