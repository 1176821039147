import React from 'react'

import { AuthUserContext, UserProfileContext, ModulesContext } from '../Session'
import { Redirect } from 'react-router-dom'
import UserLayout from '../../layouts/UserLayout'
import AuthLayout from '../../layouts/AuthLayout'
import AdminLayout from '../../layouts/AdminLayout'
import CashierLayout from '../../layouts/CashierLayout'
import { CustomerdataContext } from '../Session/context'

const WhereToGo = (props) => {
  return (
    <div>
      <AuthUserContext.Consumer>
        {(authUser) => (
          <CustomerdataContext.Consumer>
            {(customerdata) => (
              <ModulesContext.Consumer>
                {(modules) => (
                  <UserProfileContext.Consumer>
                    {(profile) => {
                      if (authUser) {
                        if (profile.role === 'ADMIN') {
                          return (
                            <AdminLayout
                              {...props}
                              user={authUser}
                              profile={profile}
                              modules={modules}
                              customerdata={customerdata}
                              customerId={
                                profile.customerIds
                                  ? profile.customerIds[0]
                                  : null
                              }
                            />
                          )
                        } else if (profile.role === 'CASHIER') {
                          return (
                            <CashierLayout
                              {...props}
                              user={authUser}
                              profile={profile}
                              modules={modules}
                              customerdata={customerdata}
                            />
                          )
                        } else if (profile.role === 'USER') {
                          return (
                            <UserLayout
                              {...props}
                              user={authUser}
                              profile={profile}
                            />
                          )
                        } else if (profile.role === 'PARTNER') {
                          return (
                            <CashierLayout
                              {...props}
                              user={authUser}
                              profile={profile}
                              modules={modules}
                              customerdata={customerdata}
                            />
                          )
                        } else if (profile.role === 'COOPERATIVE') {
                          return (
                            <CashierLayout
                              {...props}
                              user={authUser}
                              profile={profile}
                              modules={modules}
                              customerdata={customerdata}
                            />
                          )
                        }
                      } else if (
                        props.location &&
                        props.location.pathname === '/auth/login'
                      ) {
                        return <AuthLayout {...props} user={authUser} />
                      } else if (
                        props.location &&
                        props.location.pathname === '/auth/forget'
                      ) {
                        return (
                          <AuthLayout
                            {...props}
                            user={authUser}
                            forget={true}
                          />
                        )
                      } else {
                        return <Redirect push to='/auth/login' />
                      }
                    }}
                  </UserProfileContext.Consumer>
                )}
              </ModulesContext.Consumer>
            )}
          </CustomerdataContext.Consumer>
        )}
      </AuthUserContext.Consumer>
    </div>
  )
}

export { WhereToGo }
