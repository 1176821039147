import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import Plot from 'react-plotly.js'

const WEEKDAYS = { Ma: 1, Ti: 2, Ke: 3, To: 4, Pe: 5, La: 6, Su: 7 }

const sortByDay = (a, b) => {
  return WEEKDAYS[a] - WEEKDAYS[b]
}

const byOneDimension = (data, dimension) => {
  return data.reduce((acc, cur) => {
    const value = cur[dimension]
    if (!acc[value]) acc[value] = { cost: 0, occupancy: 0, windowLength: 0 }
    acc[value].cost += parseFloat(cur.cost)
    acc[value].occupancy += parseFloat(cur.occupancy)
    acc[value].windowLength += cur.windowLength
    return acc
  }, {})
}

const byTwoDimensions = (data, dimensions) => {
  return data.reduce((acc, cur) => {
    const value1 = cur[dimensions[0]]
    const value2 = cur[dimensions[1]]
    if (!acc[value1]) acc[value1] = {}
    if (!acc[value1][value2])
      acc[value1][value2] = { cost: 0, occupancy: 0, windowLength: 0 }
    acc[value1][value2].cost += parseFloat(cur.cost)
    acc[value1][value2].occupancy += parseFloat(cur.occupancy)
    acc[value1][value2].windowLength += cur.windowLength
    return acc
  }, {})
}

var tableToExcel = (function () {
  var uri = 'data:application/vnd.ms-excel;base64,',
    template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)))
    },
    format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p]
      })
    }
  return function (table, name) {
    if (!table.nodeType) table = document.getElementsByClassName(table)[0]
    var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
    window.location.href = uri + base64(format(template, ctx))
  }
})()

var tableToCleanExcel = (function () {
  const duri = 'data:application/vnd.ms-excel;base64,'
  const excelTemplate =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
  const base64 = function (s) {
    return window.btoa(unescape(encodeURIComponent(s)))
  }
  const format = function (excTmpl, contx) {
    return excTmpl.replace(/{(\w+)}/g, function (m, p) {
      return contx[p]
    })
  }
  return function (table, name) {
    if (!table.nodeType) {
      console.log('table', table)
      table = document.getElementsByClassName(table)[0]
      console.log('table', table)
    }
    const innerHTML = table.innerHTML
    console.log('innerHTML', innerHTML)
    const cleanTable = innerHTML
      .replace(/color: blue;/g, "mso-number-format:'0.0'")
      .replace(/color: red;/g, "mso-number-format:'0.00'")
    console.log('cleanTable', cleanTable)
    const ctx = { worksheet: name || 'Worksheet', table: cleanTable }
    const uri = duri + base64(format(excelTemplate, ctx))
    window.location.href = uri
  }
})()

const ChangeIcon = ({ change }) => {
  if (change > 0) {
    return <ArrowDropUpIcon style={{ color: 'green' }} />
  } else if (change < 0) {
    return <ArrowDropDownIcon style={{ color: 'red' }} />
  } else {
    return <></>
  }
}

// const CHART_CONFIGS = {
//   cost: {
//     // mode: 'lines+markers',
//     type: 'bar',
//     name: 'Myynti €',
//   },
//   occupancy: {
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Varausten määrä',
//   },
//   windowLength: {
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Varausten maksimi',
//   },
//   percent: {
//     mode: 'lines+markers',
//     type: 'scatter',
//     name: 'Varausaste',
//     line: {shape: 'hvh'},
//   },
//   avrPrice: {
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Keskihinta/h',
//   },
// }

// const formatTimeSerieToPlot = (ts) => {
//   const dataSeries = []
//   const x = ts.map((t) => t.a)
//   const ys = Object.keys(ts[0])
//     .filter((k) => k !== 'a')
//   for (let y of ys) {
//     const _y = ts.map((t) => t[y])
//     const series = { x, y: _y }
//     if (CHART_CONFIGS[y].mode) series.mode = CHART_CONFIGS[y].mode
//     if (CHART_CONFIGS[y].type) series.type = CHART_CONFIGS[y].type
//     if (CHART_CONFIGS[y].name) series.name = CHART_CONFIGS[y].name
//     if (CHART_CONFIGS[y].line) series.name = CHART_CONFIGS[y].line
//     dataSeries.push(series)
//   }
//   return dataSeries
// }

class BusySimple extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      report: this.props.report,
      data: [],
      tableRows: null,
      aggregatename: null,
      cleanExcelExportOpen: false,
      timeseries: null,
    }
  }

  // componentWillMount() {
  //   this.setState({
  //     report: this.props.report,
  //     data: [],
  //     // tableRows: [],
  //     aggregatename: null,
  //   })
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        data: this.props.rows,
        tableRows: [],
        aggregatename: null,
      })
    }
    if (prevProps.comparison !== this.props.comparison) {
      this.setState({
        comparison: this.props.comparison,
      })
    }
    if (prevProps.comparisonrows !== this.props.comparisonrows) {
      this.setState({
        comparisonrows: this.props.comparisonrows,
      })
    }
  }

  byOne = (value, head, name) => {
    const createTimeSeries = value === 'dayIndex'
    console.log('createTimeSeries', createTimeSeries, value)
    const tSerie = []
    const aggregate = byOneDimension(this.state.data, value)
    let comparisonAggregate = null
    // let comparisonKeys = null
    if (this.state.comparison) {
      comparisonAggregate = byOneDimension(this.state.comparisonrows, value)
      // comparisonKeys = Object.keys(comparisonAggregate).sort()
      // if (createTimeSeries) comparisonKeys = comparisonKeys.sort(sortByDay)
    }
    let keys = Object.keys(aggregate).sort()
    if (value === 'day') keys = keys.sort(sortByDay)
    const tableRows = keys.map((a, i) => {
      const { cost, occupancy, windowLength } = aggregate[a]
      const percent = ((occupancy / windowLength) * 100).toFixed(1)
      const avrPrice = (cost / occupancy).toFixed(2)
      if (comparisonAggregate) {
        const comparison = comparisonAggregate[a]
        const comparisonCost = comparison ? comparison.cost : 0
        const comparisonOccupancy = comparison ? comparison.occupancy : 0
        const comparisonWindowLength = comparison ? comparison.windowLength : 0
        const comparisonPercent = (
          (comparisonOccupancy / comparisonWindowLength) *
          100
        ).toFixed(1)
        const comparisonAvrPrice = (
          comparisonCost / comparisonOccupancy
        ).toFixed(2)
        const comparisonCostDiff = cost - comparisonCost
        console.log(
          'comparisonCostDiff',
          comparisonCostDiff,
          cost,
          comparisonCost
        )
        const comparisonOccupancyDiff = occupancy - comparisonOccupancy
        // const comparisonWindowLengthDiff = (windowLength - comparisonWindowLength).toFixed(2)
        const comparisonPercentDiff =
          (occupancy / windowLength -
            comparisonOccupancy / comparisonWindowLength) *
          100
        const comparisonAvrPriceDiff =
          cost / occupancy - comparisonCost / comparisonOccupancy
        if (createTimeSeries)
          tSerie.push({
            a,
            cost,
            percent,
            avrPrice,
            comparisonCost,
            comparisonPercent,
            comparisonAvrPrice,
          })
        return (
          <TableRow key={'tr' - i}>
            <TableCell key={'ind' - i}>{a}</TableCell>
            <TableCell key={'aggcost' + i}>
              <ChangeIcon change={comparisonCostDiff} />
              {cost.toFixed(2)} €{' '}
              <span style={{ fontSize: 'smaller' }}>
                ({comparisonCost.toFixed(2)} € {comparisonCostDiff.toFixed(2)})
              </span>
            </TableCell>
            <TableCell key={'agghours' + i}>
              <ChangeIcon change={comparisonOccupancyDiff} />
              {occupancy} tuntia, max.{windowLength}{' '}
              <span style={{ fontSize: 'smaller' }}>
                ({comparisonOccupancy} tuntia, max.{comparisonWindowLength}{' '}
                {comparisonOccupancyDiff})
              </span>
            </TableCell>
            <TableCell key={'aggpercent' + i}>
              <ChangeIcon change={comparisonPercentDiff} />
              {percent} %{' '}
              <span style={{ fontSize: 'smaller' }}>
                ({comparisonPercent} % {comparisonPercentDiff.toFixed(1)})
              </span>
            </TableCell>
            <TableCell key={'aggavrpr' + i}>
              <ChangeIcon change={comparisonAvrPriceDiff} />
              {avrPrice} €{' '}
              <span style={{ fontSize: 'smaller' }}>
                ({comparisonAvrPrice} € {comparisonAvrPriceDiff.toFixed(2)})
              </span>
            </TableCell>
          </TableRow>
        )
      } else {
        if (createTimeSeries) tSerie.push({ a, cost, percent, avrPrice })
        return (
          <TableRow key={'tr' - i}>
            <TableCell key={'ind' - i}>{a}</TableCell>
            <TableCell key={'aggcost' + i}>{cost.toFixed(2)} € </TableCell>
            <TableCell key={'agghours' + i}>
              {occupancy} tuntia, max.{windowLength}
            </TableCell>
            <TableCell key={'aggpercent' + i}>{percent} %</TableCell>
            <TableCell key={'aggavrpr' + i}>{avrPrice} €</TableCell>
          </TableRow>
        )
      }
    })
    const cleanRows = keys.map((a, i) => {
      const { cost, occupancy, windowLength } = aggregate[a]
      const percent = ((occupancy / windowLength) * 100).toFixed(1)
      const avrPrice = (cost / occupancy).toFixed(2)
      return (
        <tr key={'cleantr' - i}>
          <td key={'ind' - i}>{a}</td>
          <td
            style={{ color: 'red', msoNumberFormat: '0.00' }}
            key={'aggcost' + i}
          >
            {cost.toFixed(2)}
          </td>
          <td
            style={{ color: 'blue', msoNumberFormat: '0.0' }}
            key={'agghours' + i}
          >
            {occupancy}
          </td>
          <td
            style={{ color: 'blue', msoNumberFormat: '0.0' }}
            key={'aggmax' + i}
          >
            {windowLength}
          </td>
          <td
            style={{ color: 'red', msoNumberFormat: '0.00' }}
            key={'aggpercent' + i}
          >
            {percent}
          </td>
          <td
            style={{ color: 'red', msoNumberFormat: '0.00' }}
            key={'aggavrpr' + i}
          >
            {avrPrice}
          </td>
        </tr>
      )
    })
    this.setState({
      head: [
        head,
        'Summa €',
        'Varausten summa',
        'Varausaste %',
        'Keskihinta/h',
      ],
      // aggregate,
      aggregatename: name,
      tableRows,
      cleanRows,
      cleanHead: [
        head,
        'Summa €',
        'Varausten summa',
        'Varausten max',
        'Varausaste %',
        'Keskihinta/h',
      ],
      timeseries: tSerie,
    })
  }

  byTwo = (dimensions, name) => {
    const aggregate = byTwoDimensions(this.state.data, dimensions.values)

    let comparisonAggregate = null
    // let comparisonKeys = null
    if (this.state.comparison) {
      comparisonAggregate = byTwoDimensions(
        this.state.comparisonrows,
        dimensions.values
      )
      // comparisonKeys = Object.keys(comparisonAggregate).sort()
      // if (dimensions.values[0] === 'day') comparisonKeys = comparisonKeys.sort(sortByDay)
    }
    if (aggregate) {
      let keys = Object.keys(aggregate).sort()
      if (dimensions.values[0] === 'day') keys = keys.sort(sortByDay)
      const tableRows = keys.map((dayIndex, di) => {
        const categories = Object.keys(aggregate[dayIndex]).sort()
        const rows = categories.map((category, i) => {
          const { cost, occupancy, windowLength } =
            aggregate[dayIndex][category]
          const percent = ((occupancy / windowLength) * 100).toFixed(1)
          const avrPrice = (cost / occupancy).toFixed(2)
          if (comparisonAggregate) {
            const comparison = comparisonAggregate[dayIndex]
              ? comparisonAggregate[dayIndex][category]
              : null
            const comparisonCost = comparison ? comparison.cost : 0
            const comparisonOccupancy = comparison ? comparison.occupancy : 0
            const comparisonWindowLength = comparison
              ? comparison.windowLength
              : 0
            const comparisonPercent = (
              (comparisonOccupancy / comparisonWindowLength) *
              100
            ).toFixed(1)
            const comparisonAvrPrice = (
              comparisonCost / comparisonOccupancy
            ).toFixed(2)
            const comparisonCostDiff = cost - comparisonCost
            const comparisonOccupancyDiff = occupancy - comparisonOccupancy
            // const comparisonWindowLengthDiff = (windowLength - comparisonWindowLength).toFixed(2)
            const comparisonPercentDiff =
              (occupancy / windowLength -
                comparisonOccupancy / comparisonWindowLength) *
              100
            const comparisonAvrPriceDiff =
              cost / occupancy - comparisonCost / comparisonOccupancy
            return (
              <TableRow key={'tr' - i}>
                <TableCell key={di + '-ind-' + i}>
                  {i === 0 ? dayIndex : ''}
                </TableCell>
                <TableCell key={di + 'aggcat-' + i}>{category}</TableCell>
                <TableCell key={di + 'aggcost-' + i}>
                  <ChangeIcon change={comparisonCostDiff} />
                  {cost.toFixed(2)} €{' '}
                  <span style={{ fontSize: 'smaller' }}>
                    ({comparisonCost.toFixed(2)} €{' '}
                    {comparisonCostDiff.toFixed(2)})
                  </span>
                </TableCell>
                <TableCell key={di + 'agghours-' + i}>
                  <ChangeIcon change={comparisonOccupancyDiff} />
                  {occupancy} tuntia, max.{windowLength}{' '}
                  <span style={{ fontSize: 'smaller' }}>
                    ({comparisonOccupancy} tuntia, max.{comparisonWindowLength}{' '}
                    {comparisonOccupancyDiff})
                  </span>
                </TableCell>
                <TableCell key={di + 'aggpercent-' + i}>
                  <ChangeIcon change={comparisonPercentDiff} />
                  {percent} %{' '}
                  <span style={{ fontSize: 'smaller' }}>
                    ({comparisonPercent} % {comparisonPercentDiff.toFixed(1)})
                  </span>
                </TableCell>
                <TableCell key={di + 'aggavrpr-' + i}>
                  <ChangeIcon change={comparisonAvrPriceDiff} />
                  {avrPrice} €{' '}
                  <span style={{ fontSize: 'smaller' }}>
                    ({comparisonAvrPrice} € {comparisonAvrPriceDiff.toFixed(2)})
                  </span>
                </TableCell>
              </TableRow>
            )
          } else {
            return (
              <TableRow key={'tr' - i}>
                <TableCell key={di + '-ind-' + i}>
                  {i === 0 ? dayIndex : ''}
                </TableCell>
                <TableCell key={di + 'aggcat-' + i}>{category}</TableCell>
                <TableCell key={di + 'aggcost-' + i}>
                  {cost.toFixed(2)} €
                </TableCell>
                <TableCell key={di + 'agghours-' + i}>
                  {occupancy} tuntia, max.{windowLength}
                </TableCell>
                <TableCell key={di + 'aggpercent-' + i}>{percent} %</TableCell>
                <TableCell key={di + 'aggavrpr-' + i}>{avrPrice} €</TableCell>
              </TableRow>
            )
          }
        })
        return rows.flat()
      })

      const cleanRows = keys.map((dayIndex, di) => {
        const categories = Object.keys(aggregate[dayIndex]).sort()
        const rows = categories.map((category, i) => {
          const { cost, occupancy, windowLength } =
            aggregate[dayIndex][category]
          const percent = ((occupancy / windowLength) * 100).toFixed(1)
          const avrPrice = (cost / occupancy).toFixed(2)
          return (
            <tr key={'cleantr' - i}>
              <td key={di + '-ind-' + i}>{i === 0 ? dayIndex : ''}</td>
              <td
                style={{ color: 'red', msoNumberFormat: '0.00' }}
                key={'aggcost' + i}
              >
                {cost.toFixed(2)}
              </td>
              <td
                style={{ color: 'blue', msoNumberFormat: '0.0' }}
                key={'agghours' + i}
              >
                {occupancy}
              </td>
              <td
                style={{ color: 'blue', msoNumberFormat: '0.0' }}
                key={'aggmax' + i}
              >
                {windowLength}
              </td>
              <td
                style={{ color: 'red', msoNumberFormat: '0.00' }}
                key={'aggpercent' + i}
              >
                {percent}
              </td>
              <td
                style={{ color: 'red', msoNumberFormat: '0.00' }}
                key={'aggavrpr' + i}
              >
                {avrPrice}
              </td>
            </tr>
          )
        })
        return rows.flat()
      })

      this.setState({
        head: [
          dimensions.head[0],
          dimensions.head[1],
          'Summa €',
          'Varausten summa',
          'Varausaste %',
          'Keskihinta/h',
        ],
        // aggregate,
        aggregatename: name,
        tableRows,
        cleanRows,
        cleanHead: [
          dimensions.head[0],
          dimensions.head[1],
          'Summa €',
          'Varausten summa',
          'Varausten max',
          'Varausaste %',
          'Keskihinta/h',
        ],
        inputchange: false,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.tableRows && (
          <React.Fragment>
            <Row>
              <Col>
                <Button
                  // disabled={!this.state.aggregatename}
                  disabled={this.state.comparison}
                  size='sm'
                  onClick={() => this.byOne('dayIndex', 'Päivä', 'Day')}
                >
                  Päivittäin
                </Button>{' '}
                <Button
                  // disabled={!this.state.aggregatename}
                  disabled={this.state.comparison}
                  size='sm'
                  onClick={() =>
                    this.byTwo(
                      {
                        head: ['Päivä', 'Kategoria'],
                        values: ['dayIndex', 'category'],
                      },
                      'DayAndCategory'
                    )
                  }
                >
                  Päivittäin ja kategorioittain
                </Button>{' '}
                <Button
                  // disabled={!this.state.aggregatename}
                  disabled={this.state.comparison}
                  size='sm'
                  onClick={() =>
                    this.byTwo(
                      {
                        head: ['Päivä', 'Kenttä'],
                        values: ['dayIndex', 'court'],
                      },
                      'DayAndCourt'
                    )
                  }
                >
                  Päivittäin ja kentittäin
                </Button>{' '}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() => this.byOne('day', 'Viikonpäivä', 'Weekday')}
                >
                  Viikonpäivittäin
                </Button>{' '}
                <Button
                  disabled={this.state.comparison}
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() =>
                    this.byTwo(
                      {
                        head: ['Viikonpäivä', 'Kategoria'],
                        values: ['day', 'category'],
                      },
                      'WeekdayAndCategory'
                    )
                  }
                >
                  Viikonpäivittäin ja kategorioittain
                </Button>{' '}
                <Button
                  disabled={this.state.comparison}
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() =>
                    this.byTwo(
                      {
                        head: ['Viikonpäivä', 'Kenttä'],
                        values: ['day', 'court'],
                      },
                      'WeekdayAndCourt'
                    )
                  }
                >
                  Viikonpäivittäin ja kentittäin
                </Button>{' '}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() =>
                    this.byOne('category', 'Kategoria', 'byCategory')
                  }
                >
                  Kategorioittain
                </Button>{' '}
              </Col>
            </Row>
          </React.Fragment>
        )}
        {/* <Row>
          <Col>
            {this.state.timeseries && this.state.timeseries.length > 0 && (
              formatTimeSerieToPlot(this.state.timeseries).map((ts, i) => (
              <Plot
                  data={[ts]}
                  layout={{
                    width: '100%', height: 340,
                    legend: {
                      y: 0.5,
                      traceorder: 'reversed',
                      font: { size: 16 },
                      yref: 'paper'
                    }
                  }}
                />
              ))
            )}
          </Col>
        </Row> */}
        <Row>
          <Col>
            {this.state.aggregatename && (
              <>
                <Button size='sm' onClick={() => tableToExcel('MuiTable-root')}>
                  Export to Excel
                </Button>{' '}
                <Button
                  size='sm'
                  onClick={() => tableToCleanExcel('cleanexport')}
                >
                  Numbers only Export to Excel
                </Button>
              </>
            )}{' '}
            <Table>
              <TableHead>
                <TableRow>
                  {this.state.head &&
                    this.state.head.map((h, i) => (
                      <TableCell key={i}>{h}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableRows ? (
                  this.state.tableRows
                ) : (
                  <TableRow>
                    <TableCell>
                      {this.state.tableRows ? 'Valitse aggregointi' : ''}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Col>
        </Row>
        <div>
          <table className='cleanexport' style={{ display: 'none' }}>
            <thead>
              <tr>
                {this.state.cleanHead &&
                  this.state.cleanHead.map((h, i) => <th key={i}>{h}</th>)}
              </tr>
            </thead>
            <tbody>{this.state.cleanRows}</tbody>
          </table>
        </div>
        {/* <div id='debug'>
          <pre>{JSON.stringify(this.state.timeseries, null, 2)}</pre>
        </div> */}
      </React.Fragment>
    )
  }
}
export default BusySimple
