
export const _timingSlotClass = {
  displayName: { type: 'string', title: 'Osan julkaisunimi' },
  days: {
    type: "array",
    title: "Päivät",
    items: {
      type: "string",
      "enum": [
        1,
        2,
        3,
        4,
        5,
        6,
        7
      ],
      'enumNames': [
        'Maanantai',
        'Tiistai',
        'Keskiviikko',
        'Torstai',
        'Perjantai',
        'Lauantai',
        'Sunnuntai',
      ]
    },
    "uniqueItems": true
  },
  starttime: {
    type: 'string',
    title: 'Alkuaika'
  },
  endtime: {
    type: 'string',
    title: 'Loppuaika'
  },
}

export const _timingSlotClassDeps = {
}

export const _timingSlotUISchema = {
  days: {
    'ui:widget':'checkboxes'
  },
  starttime: {
    'ui:widget':'timeWidget'
  },
  endtime: {
    'ui:widget':'timeWidget'
  }
}

export const _timingClass = {
  name: { type: 'string', title: 'Aikataulun nimi' },
  active: { type: 'boolean', title: 'Active' },
  validstart: { type: 'string', format: 'date', title: 'Alkupäivä' },
  validend: { type: 'string', format: 'date', title: 'Loppupäivä' },
}


export const _timingUISchema = {
}

