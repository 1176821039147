import React, { useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {
  CircularProgress,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core'
import Reservation from '../Classes/Reservation'
import { withFirebase } from '../Fb'
import PersonSearch from '../Inputs/PersonSearch'
import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import moment from 'moment'

const GreenButton = withStyles({
  root: {
    // color: green[400],
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    // paddingTop: 2,
    // paddingBottom: 2,
    // marginRight: 5,
  },
})((props) => <Button {...props} />);

function StoragepaymentDialog({ onClose, onSave, open, reservation, occurency, firebase }) {
  const [person, setPerson] = React.useState('')
  const [amount, setAmount] = React.useState(0)
  const [wallets, setWallets] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [venue, setVenue] = React.useState(null)
  const [unpaid, setUnpaid] = React.useState(0)
  const [isValidPayment, setIsValidPayment] = React.useState(false)
  const [selectedSaldoSum, setSelectedSaldoSum] = React.useState(0)
  const [selectedWallet, setSelectedWallet] = React.useState(null)
  const [saving, setSaving] = React.useState(false)

  const getWallets = (userid, walletvenueid) => {
    console.log('getwallets', userid, walletvenueid, 'amount', amount)
    setLoading(true)
    Promise.all([firebase.getPersonSaldo(userid), firebase.getPersonStorage(userid)]).then((results) => {
      // const saldos = results[0]?.data || []
      const storages = results[1]?.data || []

      const wallets = []

      for (let i = 0; i < storages.length; i++) {
        const walletTypes = Object.keys(storages[i]).filter((x) => x !== 'id')
        for (let k = 0; k < walletTypes.length; k++) {
          const sum = storages[i][walletTypes[k]].reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
          const walletTitle =
            walletTypes[k] === 'cash' ? 'Pankki/Varasto' : walletTypes[k] === 'minutes' ? 'Aikapankki' : walletTypes[k]
          const walletVenue =
          storages[i].id !== 'default' ? storages[i].id.charAt(0).toUpperCase() + storages[i].id.slice(1) : ''
          console.log(storages[i].id, '===', walletvenueid)
          if (storages[i].id === walletvenueid && walletTypes[k] === 'cash') {
            wallets.push({
              id: storages[i].id,
              title: walletTitle,
              walletVenue,
              sum,
              type: walletTypes[k],
            })
          }
        }
      }
      console.log(wallets)
      if (wallets && wallets.length >= 1) {
        setSelectedSaldoSum(wallets[0].sum)
        setSelectedWallet(wallets[0])
        setWallets(wallets)
      } else {
        setSelectedSaldoSum(0)
        setSelectedWallet(null)
        setWallets([])
      }
      validateAmount(amount, wallets[0]?.sum || 0)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (reservation && open) {
      console.log('occurency', occurency)
      const _reservation = new Reservation(reservation)
      const paymentStatus = _reservation.getPaymentStatus(occurency)
      console.log('paymentStatus', paymentStatus)
      const w = _reservation.getWallet(firebase.getCustomerId())
      setAmount(0)
      setUnpaid(paymentStatus.unpaid)
      setVenue(w)
      setPerson(_reservation.getFirstPayerSimple())
      getWallets(_reservation.getFirstPayerSimple().id, w)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation, occurency, open])

  const handleClose = () => {
    setAmount(0)
    onClose()
  }

  const validateAmount = (_amount, _selectedSaldoSum) => {
    if (typeof _amount === 'string') {
      _amount = parseFloat(_amount.replace(',', '.'))
    }
    const parsedAmount = Math.floor(_amount * 100)
    console.log(_amount, _selectedSaldoSum, parsedAmount)
    console.log(
      parsedAmount,
      '> 0 && ',
      parsedAmount,
      ' <= ',
      _selectedSaldoSum,
      ' && ',
      parsedAmount,
      ' <= ',
      unpaid * 100
    )
    // const parsedAmount = Math.floor(parseFloat(_vAmount) * 100)
    if (parsedAmount > 0 && parsedAmount <= _selectedSaldoSum && parsedAmount <= unpaid * 100) {
      setIsValidPayment(true)
    } else {
      setIsValidPayment(false)
    }
    // console.log(parsedAmount, '> 0 && ', parsedAmount, ' <= ', selectedSaldoSum, ' && ', parsedAmount, ' <= ', unpaid * 100)
  }

  const handleAmountChange = (e) => {
    const _amount = e.target.value
    validateAmount(_amount, selectedSaldoSum)
    setAmount(_amount)
  }

  const handlePersonChange = (e, value) => {
    const users = value.map((x) => {
      return {
        id: x.id,
        firstname: x.firstname || '',
        lastname: x.lastname || '',
        email: x.email,
        phone: x.phone || '',
      }
    })
    if (users.length > 0) {
      setPerson(users[0])
      getWallets(users[0].id, venue)
    } else {
      setPerson(null)
      setSelectedSaldoSum(0)
      setSelectedWallet(null)
      setWallets([])
      validateAmount(amount, 0)
    }
  }

  const addPayment = () => {
    if (isValidPayment) {
      setSaving(true)
      let _amount = amount
      if (typeof _amount === 'string') {
        _amount = parseFloat(_amount.replace(',', '.'))
      }
      let _occurency = occurency
      if (moment.isMoment(occurency)) {
        _occurency = occurency.format('YYYY-MM-DD')
      }
      const parsedAmount = Math.floor(_amount * 100)
      const payment = {
        amount: parsedAmount,
        id: reservation.id,
        collection: 'reservations',
        wallet: selectedWallet,
        person: person.id,
        occurency: _occurency,
      }
      const _reservation = new Reservation(reservation)
      if (_reservation.getFirstPayerSimple().id !== person.id) {
        payment.note = ['Maksettu', person.firstname, person.lastname, '(' + person.email + ')', 'pankista'].join(' ')
        const reservationPerson = _reservation.getFirstPayerSimple()
        let rName = reservationPerson.name
        if (reservationPerson.firstname && reservationPerson.lastname) {
          rName = reservationPerson.firstname + ' ' + reservationPerson.lastname
        }
        payment.saldonote = [rName, '(' + reservationPerson.email + ')'].join(' ')
      }
      firebase.addStoragePayment(payment).then((result) => {
        console.log(result)
        setAmount(0)
        setSaving(false)
        onSave()
      })
    } else {
      alert('Tarkista summa')
    }
  }
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby='saldo-dialog-slide-title'
      aria-describedby='saldo-dialog-slide-description'
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle id='saldo-dialog-slide-title'>Maksa pankista</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={12}>
            <Typography variant='subtitle2' gutterBottom>
              Auki oleva summa: {unpaid} €
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <CircularProgress size={20} />
            ) : wallets && wallets.length > 0 ? (
              wallets.map((wallet) => {
                return (
                  <div key={wallet.id}>
                    <Typography>
                      {wallet.title} {wallet.walletVenue} {wallet.sum / 100} €
                    </Typography>
                  </div>
                )
              })
            ) : (
              <Typography>Ei saldoa</Typography>
            )}
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
          </Grid>
          <Grid item xs={6}>
            <TextField id='amount' value={amount} onChange={handleAmountChange} label={'Summa'} />
          </Grid>
          <Grid item xs={6}>
            <PersonSearch
              onChange={handlePersonChange}
              values={person ? [person] : []}
              label='Kenen pankista maksetaan'
              placeholder=' '
              onlyone={true}
              fullWidth={true}
              margin='none'
            />
          </Grid>
          <Grid item xs={12}>
            <GreenButton disabled={!isValidPayment || saving} onClick={addPayment} variant='contained' color='success'>
              Suorita maksu {saving && <CircularProgress size={20} />}
            </GreenButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={'default'} onClick={handleClose}>
          Sulje
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withFirebase(StoragepaymentDialog)
