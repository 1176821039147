import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import { ButtonGroup, Button, Row } from 'reactstrap'
import { Typography } from '@material-ui/core'

const localeText={
  toolbarDensity: 'Riviväli',
  toolbarDensityLabel: 'Riviväli',
  toolbarDensityCompact: 'Tiheä',
  toolbarDensityStandard: 'Normaali',
  toolbarDensityComfortable: 'Väljä',
  toolbarExport: 'Lataa / Tulosta',
  toolbarExportLabel: 'Lataa / Tulosta',
  toolbarExportCSV: 'Lataa CSV',
  toolbarExportPDF: 'Lataa PDF',
  toolbarExportPrint: 'Tulosta',
  toolbarFilters: 'Suodata',
  toolbarFiltersLabel: 'Suodata',
  toolbarColumns: 'Sarakkeet',
  toolbarColumnsLabel: 'Sarakkeet',
}

const toCurrency = (value) => {
  return (Math.round(value * 100) / 100).toFixed(2).replace('.', ',') + ' €'
}

function TotalToolbarContainer(props) {
  if(!props.unpaidsum) return null
  return (
    <Typography variant='body1' component='span' style={{ marginRight: 10 }}>
      Maksamatta: {(Math.round(props.unpaidsum * 100) / 100).toFixed(2).replace('.', ',')} € / Summa: {(Math.round(props.costsum * 100) / 100).toFixed(2).replace('.', ',')} €
    </Typography>
  )
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
        <div style={{ flex: 1 }}>
      <GridToolbarColumnsButton title={'Näytä/piilota sarakkeet'} />
      {/* <GridToolbarFilterButton /> */}
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName:  'laskutettavaa-' + props.username.replace(' ', '_'),
          delimiter: ';',
          utf8WithBom: true,
        }}
          />
        </div>
        <div style={{ justifyContent: 'flex-end', textAlign: 'end' }}>
        <TotalToolbarContainer unpaidsum={props.unpaidsum} costsum={props.costsum} />
          </div>  
    </GridToolbarContainer>
  )
}

function TagRow({ tags, tagFilter, setTagFilter }) {
  if (!tags) return null
  if (tags.length < 2) return null
  return (
    <ButtonGroup size='sm'>
      {tags.map((key) => {
        return (
          <Button
            color={tagFilter === key ? 'primary' : 'action'}
            key={key}
            onClick={() => setTagFilter(key)}
          >
            {key}
          </Button>
        )
      })}
      <Button
        color={tagFilter === 'all' ? 'primary' : 'action'}
        key={'all'}
        onClick={() => setTagFilter('all')}
      >
        Kaikki
      </Button>
    </ButtonGroup>
  )
}

function InvoicingDraft({ handleSelectReservation, selectedResource, invoicetags }) {
  const [tagFilter, setTagFilter] = useState('all')
  // const [invoicetags, setInvoicetags] = useState([])
  const [tags, setTags] = useState([])
  const [sorted, setSorted] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [costsum, setCostsum] = useState(0)
  const [unpaidsum, setUnpaidsum] = useState(0)
  const [pageSize, setPageSize] = useState(20)

  // const showVenue = venues && venues.length > 1

  useEffect(() => {
    if (selectedResource) {
      const tags = []
      console.log('selectedResource', selectedResource)
      const sorted = Object.keys(selectedResource.datas)
        .map((key) => {
          const r = selectedResource.datas[key]
          r.id = key
          if (r.tags && r.tags.length > 0) {
            const tag = r.tags[0]
            if (invoicetags) {
              const isInvoiceTag = invoicetags.find(
                (t) => t.category.toLowerCase() === tag.toLowerCase()
              )
              if (!tags.includes(tag) && isInvoiceTag) {
                tags.push(tag)
              }
            }
          }
          return r
        })
        .filter((x) => x)
        .sort((a, b) => {
          if (a.occurency > b.occurency) return 1
          else if (a.occurency < b.occurency) return -1
          else return 0
        })
      setTags(tags)
      setSorted(sorted)
    }
  }, [selectedResource, invoicetags])

  useEffect(() => {
    let costsum = 0
    let unpaidsum = 0
    const rows = sorted
      .map((_r) => {
        if (tagFilter) {
          if (_r.tags && _r.tags.length > 0) {
            const tag = _r.tags[0]
            if (tagFilter === 'all') {
              // all ok
            } else {
              if (tag !== tagFilter) {
                return null
              }
            }
          } else {
            if (tagFilter !== 'all') {
              return null
            }
          }
        }
        costsum += _r.cost
        unpaidsum += _r.unpaid
        return _r
        // return (
        //   <Row key={Math.random()} className='border-bottom'>
        //     <Col md='3' className='pt-1'>
        //       {_r.row.replace('default', '').replace('tali', '')}
        //     </Col>
        //     <Col md='1' className='pt-1 text-right'>
        //       {(Math.round(_r.unpaid * 100) / 100).toFixed(2).replace('.', ',')}{' '}
        //       €
        //     </Col>
        //     <Col md='1' className='pt-1 text-right'>
        //       {(Math.round(_r.cost * 100) / 100).toFixed(2).replace('.', ',')} €
        //     </Col>
        //     <Col md='1' className='pt-1'>
        //       {_r.vat} %
        //     </Col>
        //     {showVenue ? (
        //       <Col md='1' className='pt-1'>
        //         {_r.venue === 'tali' || _r.venue === 'default' ? '' : _r.venue}
        //       </Col>
        //     ) : null}
        //     <Col md='1' className='pt-1'>
        //       {_r.seasonbilling ? 'Kyllä' : ''}
        //     </Col>
        //     <Col md='2' className='pt-1'>
        //       {JSON.stringify(_r.usernotes)}
        //     </Col>
        //     <Col md='1' className='pt-1'>
        //       {_r.tags && _r.tags.length > 0 ? _r.tags[0] : ''}
        //     </Col>
        //     <Col md='1'>
        //       {!_r.collection ? (
        //         <Button
        //           className='mt-0 mb-0'
        //           size='sm'
        //           color='link'
        //           onClick={() => handleSelectReservation(_r.id, _r.occurency)}
        //         >
        //           Avaa varaus
        //         </Button>
        //       ) : null}
        //     </Col>
        //   </Row>
        // )
      })
      .filter((x) => x)
    setFilteredList(rows)
    setCostsum(costsum)
    setUnpaidsum(unpaidsum)
  }, [tagFilter, sorted])

  console.log('filteredList', filteredList)

  return (
    <>
      <TagRow tags={tags} tagFilter={tagFilter} setTagFilter={setTagFilter}/>
      <Row>
        <DataGrid
          rows={filteredList}
          autoHeight
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: { costsum, unpaidsum, username: selectedResource && selectedResource.user ? selectedResource.user : '' },
          }}
          localeText={localeText}
          columns={[
            {
              field: 'row', headerName: 'Rivi',
              flex: 3
            },
            {
              field: 'unpaid',
              headerName: 'Maksamatta',
              flex: 1,
              type: 'number',
              valueFormatter: (params) => {
                return toCurrency(params.value)
              },
            },
            {
              field: 'cost',
              headerName: 'Summa',
              flex: 1,
              type: 'number',
              valueFormatter: (params) => {
                return toCurrency(params.value)
              },
            },
            {
              field: 'vat',
              headerName: 'Alv %',
              flex: 1,
              valueFormatter: (params) => {
                return params.value + ' %'
              },
            },
            {
              field: 'venue',
              headerName: 'Venue',
              flex: 1,
              valueFormatter: (params) => {
                return params.value === 'tali' || params.value === 'default'
                  ? ''
                  : params.value
              },
            },
            {
              field: 'seasonbilling',
              headerName: 'Kausilaskutus',
              flex: 1,
              valueFormatter: (params) => {
                return params.value ? 'Kyllä' : 'Ei'
              },
            },
            {
              field: 'usernotes', headerName: 'Notes',
              flex: 1
            },
            { field: 'tags', headerName: 'Tags', flex: 1 },
            {
              field: 'id',
              sortable: false,
              filterable: false,
              headerName: '',
              flex: 1,
              renderCell: (params) => {
                const id = params.row.id.split('---')[0]
                return (
                  <Button
                    className='mt-0 mb-0'
                    size='sm'
                    color='link'
                    onClick={() =>
                      handleSelectReservation(id, params.row.occurency)
                    }
                  >
                    Avaa varaus
                  </Button>
                )
              },
            },
          ]}
          onPageSizeChange={(params) => {
            setPageSize(params.pageSize)
          }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 100]}
          density='compact'
        />
      </Row>
      {/* <Row>
        <Col md='11' className='text-right'>
          <b>
            Maksamatta{' '}
            {(Math.round(unpaidsum * 100) / 100).toFixed(2).replace('.', ',')} €
          </b>
        </Col>
        <Col md='1' className='text-right'>
          Summa {(Math.round(costsum * 100) / 100).toFixed(2).replace('.', ',')}{' '}
          €
        </Col>
      </Row> */}
    </>
  )
}

export default InvoicingDraft
