import React from 'react'
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from 'reactstrap'
import { TextField } from '@material-ui/core'

class Pulishing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      times: null,
      loading: true,
      saving: false,
      publicdates: 14,
      viewabilityError: false,
      changessaved: true,
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.getConfig('publishing').onSnapshot((doc) => {
      if (doc.exists) {
        this.setState({
          config: doc.data(),
          loading: false,
          publicdates: doc.data().publicdates,
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onCustomerviewChange = async (event) => {
    const value = parseInt(event.target.value)
    if (!isNaN(value) && value > 0 && value < 62) {
      this.setState({ viewabilityError: false, changessaved: false,  publicdates: value })
    } else {
      this.setState({ viewabilityError: true, publicdates: value })
    }
  }

  onSave = async () => {
    if (this.state.viewabilityError) {
      return
    }
    this.setState({ saving: true })
    await this.props.firebase.addConfig('publishing', { publicdates: this.state.publicdates })
    this.setState({ saving: false, changessaved: true })
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' md='5' sm='5'>
                  <CardTitle tag='h5'>Kalenterin näkyvyys</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
              {this.state.saving ? (
                <p>Tallennetaan...</p>
              ) : (
                    <TextField
                      error={this.state.viewabilityError}
                      helperText={this.state.viewabilityError ? 'Arvon tulee olla väliltä 1-61' : ''}
                    id='outlined-number'
                    label='Montako päivää eteenpäin kalenteri näkyy asiakkaille'
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                      }}
                    
                    variant='outlined'
                    value={this.state.publicdates}
                    onChange={this.onCustomerviewChange}
                  />
              )}
              </Col>
              </Row>
              <Row><Col>
              <Button 
                color='primary'
                onClick={this.onSave}
                disabled={this.state.saving || this.state.viewabilityError || this.state.changessaved}
                >Tallenna</Button>
                </Col></Row>
            </CardBody>
          </Card>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default Pulishing
