import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button } from 'reactstrap'

// core components
import { filterCaseInsensitive } from '../../components/FalconTable'
import FalconModal from '../../components/Inputs/FalconModal'
import { getPersonSchema, getPersonUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from '../../components/Lists/ReactTableWrapper'
import { CircularProgress } from '@material-ui/core'

const MAINTENANCEROLES = ['madmin', 'msubadmin', 'msubuser']
const ROLES = ['admin', 'cashier', 'partner', 'cooperative']

class CustomerUserContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, saving: false, userModalOpen: false, selected: null, messaging: { isOpen: false } }
  }

  async componentDidMount() {
    this.customerUserRef = this.props.firebase.listenCustomerUsers()
    this.unsubscribeUserRef = this.customerUserRef.onSnapshot((snapshot) => {
      const data = []
      // eslint-disable-next-line
      // for (let i = 0; i < snapshot.docs.length; i++) {
      //   let doc = snapshot.docs[i]
      //   if (doc.data().user) {
      //     let user = await doc.data().user.get()
      //     data.push({ ...user.data() })
      //   }
      // }
      let index = snapshot.docs.length
      // console.log('index', index)
      if (index > 0) {
        // eslint-disable-next-line
        snapshot.docs.map(async (doc) => {
          // console.log('doc.data()', doc.data())
          if (doc.data().user) {
            // console.log('doc.data().user', doc.data().user)
            let user = await doc.data().user.get()
            // console.log('user', user)
            if (user.data().role && user.data().role.toUpperCase() !== 'USER') {
              data.push({ userdata: { ...user.data() }, customerdata: { ...doc.data() }, id: user.id })
            }
          }
          index = index - 1
          // console.log('dec index', index)
          if (index === 0) {
            // console.log('all users done')
            this.allUsersDone(data)
          }
          // if (doc.data().user) {
          //   data.push({ ...doc.data() })
          // }
        })
      } else {
        this.allUsersDone([])
      }

      // snapshot.docs.map((doc) => {
      //   if (doc.data().user) {
      //     let user = await doc.data().user.get()
      //     data.push({ ...user.data() })
      //   }
      // })

      // this.setState({ raw: data }, this.filterData)
    })
    this.props.firebase.listenOrganisations().onSnapshot((snapshot) => {
      const data = []
      let index = snapshot.docs.length
      snapshot.docs.map(async (doc) => {
        if (doc.data().organisation) {
          let organisation = await doc.data().organisation.get()
          data.push({ ...organisation.data(), ...doc.data(), id: organisation.id })
        }
        index = index - 1
        if (index === 0) {
          this.allOrganisationsDone(data)
        }
      })
    })
  }

  allUsersDone = (data) => {
    this.setState({ raw: data }, this.filterData)
  }

  allOrganisationsDone = (data) => {
    this.setState({ organisations: data }, this.initOrganisationSearch)
  }

  initOrganisationSearch = () => {
    const data = []
    for (let i = 0; i < this.state.organisations.length; i++) {
      const _organisation = this.state.organisations[i]
      data.push({ ..._organisation, id: _organisation.id })
    }
    this.props.firebase.updateOrganisationsCache(data)
  }

  componentWillUnmount() {
    if (this.unsubscribeUserRef) {
      this.unsubscribeUserRef()
    }
  }

  handleRowSelect = (e) => {
    // console.log(e)
    const person = this.state.persons.find((r) => r.id === e)
    // console.log(this.state.persons)
    // delete person.adminedit
    this.setState({ selectedResource: person }, this.toggleUserModal)
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      // let user = await this.state.raw[i].user.get()
      // let _user = user.data()
      let _user = this.state.raw[i]
      if (_user.name) {
        _user.lastname = _user.name
      }
      if (!_user.hasOwnProperty('admincreated')) {
        if (_user.userdata) {
          _user.admincreated = false
        } else {
          _user.admincreated = true
        }
      }
      const isEditable = _user.hasOwnProperty('admincreated') ? _user.admincreated : false
      if (_user.userdata) {
        delete _user.customerdata.user
        delete _user.userdata.user
        data.push({
          ..._user.customerdata,
          ..._user.userdata,
          editable: isEditable,
          adminedit: isEditable ? 'Kyllä' : 'Ei',
          id: _user.id,
          actions: this.getActions(_user, _user.id, _user.userdata.role),
        })
        // } else {
        //   delete _user.customerdata.user
        //   data.push({ ..._user.customerdata, editable: true, id: _user.id, adminedit: (isEditable ? 'Kyllä' : 'Ei'), actions: this.getActions(_user, _user.id, ) })
      }
      // let _name = _user.name
      // if (!_name) {
      //   _name = _user.firstname + ' ' + _user.lastname
      //   if (!_user.firstname) {
      //     _name = _user.email
      //   }
      // }
      // data.push({ ..._user, id: _user.id, actions: this.getActions(_user, _user.id) })
    }
    this.props.firebase.updatePersonCache(data)
    this.setState({ loading: false, persons: data })
  }

  addNewUser = () => {
    this.setState({ selectedResource: {} }, this.toggleUserModal)
  }

  toggleUserModal = () => {
    if (!this.state.messaging.isOpen) {
      this.setState({
        userModalOpen: !this.state.userModalOpen,
      })
    }
  }

  validateInputs = (data) => {
    if (data.phone && data.phone.length > 1) {
      if (!data.phone.startsWith('+')) {
        return Promise.reject({ error: 'Puhelin pitää antaa +358 muodossa' })
      } else {
        const _search = this.props.firebase.searchPerson(data.phone).map((p) => p.item)
        const match = _search.find((x) => x.phone === data.phone)
        if (match) {
          return Promise.reject({ error: 'Käyttäjä tällä puhelinnumerolla on jo olemassa', person: match })
        }
      }
    }
    if (data.email) {
      const _search = this.props.firebase.searchPerson(data.email).map((p) => p.item)
      const match = _search.find((x) => x.email === data.email)
      if (match) {
        return Promise.reject({ error: 'Käyttäjä tällä email osoitteella on jo olemassa', person: match })
      }
    }
    return Promise.resolve('OK')
  }

  createPersonModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.updatePerson}
        onCreate={this.props.firebase.registerPerson}
        toggle={this.toggleUserModal}
        resource={this.state.selectedResource}
        isOpen={this.state.userModalOpen}
        uiSchema={getPersonUiSchema()}
        dataSchema={getPersonSchema()}
        validate={this.validateInputs}
        title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa käyttäjää' : 'Uusi käyttäjä'}
      />
    )
  }

  // closeMessageModal = () => {
  //   let _m = this.state.messaging
  //   _m.isOpen = false
  //   this.setState({ messaging: _m })
  // }

  // openMessageModal = (type, id) => {
  //   let _m = this.state.messaging
  //   _m.isOpen = !_m.isOpen
  //   _m.type = type
  //   this.setState({ messaging: _m, userModalOpen: false })
  // }

  // setMessagebody = (event) => {
  //   let _m = this.state.messaging
  //   _m.body = event.target.value
  //   this.setState({ messaging: _m })
  // }

  // setMessageSubject = (event) => {
  //   let _m = this.state.messaging
  //   _m.subject = event.target.value
  //   if (_m.type === 'notification') {
  //     _m.toolong = event.target.value.length > 80
  //   }
  //   this.setState({ messaging: _m })
  // }

  // sendMessage = () => {
  //   let _m = this.state.messaging
  //   _m.to = this.state.selectedResource.id
  //   this.props.firebase.createMessage(_m).then((message) => {
  //     this.closeMessageModal()
  //   })
  // }

  // createMessageModal = () => {
  //   let user = this.state.selectedResource
  //   if (this.state.messaging.type === 'call') {
  //     return (
  //       <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
  //         <ModalHeader toggle={this.closeMessageModal}>Soita</ModalHeader>
  //         <ModalBody>
  //           <a href={'tel://' + user.phone}>{user.phone}</a>
  //         </ModalBody>
  //         <ModalFooter>
  //           <Button color='secondary' onClick={this.closeMessageModal}>
  //             Sulje
  //           </Button>
  //         </ModalFooter>
  //       </Modal>
  //     )
  //   } else if (this.state.messaging.type === 'notification') {
  //     return (
  //       <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
  //         <ModalHeader toggle={this.closeMessageModal}>Lähetä notifikaatio appsiin</ModalHeader>
  //         <ModalBody>
  //           <Form>
  //             <FormGroup>
  //               <Label for='notificationmessagebody'>Notifikaatio osa (80 merkkiä)</Label>
  //               <Input
  //                 invalid={this.state.messaging.toolong}
  //                 type='textarea'
  //                 name='notificationmessagebody'
  //                 id='notificationmessagebody'
  //                 onChange={this.setMessageSubject}
  //               />
  //             </FormGroup>
  //             <FormGroup>
  //               <Label for='notificationmessagebody'>Viesti (näkyy käyttäjälle appsissa)</Label>
  //               <Input
  //                 type='textarea'
  //                 name='notificationmessagebody'
  //                 id='notificationmessagebody'
  //                 onChange={this.setMessagebody}
  //               />
  //             </FormGroup>
  //           </Form>
  //         </ModalBody>
  //         <ModalFooter>
  //           <Button color='primary' onClick={this.sendMessage}>
  //             Lähetä
  //           </Button>{' '}
  //           <Button color='secondary' onClick={this.closeMessageModal}>
  //             Cancel
  //           </Button>
  //         </ModalFooter>
  //       </Modal>
  //     )
  //   } else if (this.state.messaging.type === 'email') {
  //     return (
  //       <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
  //         <ModalHeader toggle={this.closeMessageModal}>Lähetä sähköposti</ModalHeader>
  //         <ModalBody>
  //           <Form>
  //             <FormGroup>
  //               <Label for='emailsubject'>Otsikko</Label>
  //               <Input type='text' name='emailsubject' id='emailsubject' onChange={this.setMessageSubject} />
  //             </FormGroup>
  //             <FormGroup>
  //               <Label for='emailbody'>Viesti</Label>
  //               <Input type='textarea' name='emailbody' id='emailbody' onChange={this.setMessagebody} />
  //             </FormGroup>
  //           </Form>
  //         </ModalBody>
  //         <ModalFooter>
  //           <Button color='primary' onClick={this.closeSendMessage}>
  //             Lähetä
  //           </Button>{' '}
  //           <Button color='secondary' onClick={this.closeMessageModal}>
  //             Cancel
  //           </Button>
  //         </ModalFooter>
  //       </Modal>
  //     )
  //   }
  // }

  updateUserRole = async (role, userid) => {
    // console.log(role, userid)
    this.setState({ saving: true })
    this.props.firebase.updateUserRole(userid, role).then(() => {
      this.setState({ saving: false })
    }).catch((error) => {
      console.log(error)
      this.setState({ saving: false })
    })
  }

  getActions = (row, id, role) => {
    if (role) role = role.toLowerCase()
    return (
      <div className='actions-right'>
        {ROLES.map((rol) => {
          return (
            <Button
              color={role === rol ? 'success' : 'secondary'}
              size='sm'
              onClick={(el) => {
                this.updateUserRole(rol, id)
              }}
              disabled={this.state.saving}
            >
              {this.state.saving ? <CircularProgress size={10} /> : rol}
            </Button>
          )
        })}
        <Button
          disabled={this.state.saving}
          color='danger'
          size='sm'
          onClick={(el) => {
            this.updateUserRole('user', id)
          }}
        >
          <span className='btn-label'>
            <i className='nc-icon nc-simple-remove' />
          </span>
        </Button>
      </div>
    )
  }

  // getActionsO = (row, id) => {
  //   // console.log(id)
  //   // console.log(row)
  //   return (
  //     <>
  //       <div className='actions-right'>
  //         {row.phone ? (
  //           <Button
  //             color='secondary'
  //             size='sm'
  //             onClick={(el) => {
  //               this.openMessageModal('call', id)
  //             }}
  //           >
  //             <span className='btn-label'>
  //               <i className='nc-icon nc-bell-55' />
  //             </span>
  //           </Button>
  //         ) : (
  //           <></>
  //         )}
  //         {row.pushtoken ? (
  //           <Button
  //             color='primary'
  //             size='sm'
  //             onClick={(el) => {
  //               this.openMessageModal('notification', id)
  //             }}
  //           >
  //             <span className='btn-label'>
  //               <i className='nc-icon nc-send' />
  //             </span>
  //           </Button>
  //         ) : (
  //           <></>
  //         )}
  //         {row.email ? (
  //           <Button
  //             color='warning'
  //             size='sm'
  //             onClick={(el) => {
  //               this.openMessageModal('email', id)
  //             }}
  //           >
  //             <span className='btn-label'>
  //               <i className='nc-icon nc-email-85' />
  //             </span>
  //           </Button>
  //         ) : (
  //           <></>
  //         )}
  //       </div>
  //     </>
  //   )
  // }

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>Loading ...</div>
        </>
      )
    } else {
      // console.log(JSON.stringify(this.state.persons))
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.addNewUser}>Lisää käyttäjä</Button>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.persons}
                  filterable
                  noSumRow={true}
                  noDataText='Ei löytynyt yhtään käyttäjää'
                  defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                  columns={[
                    {
                      Header: 'Etunimi',
                      accessor: 'firstname',
                    },
                    {
                      Header: 'Sukunimi',
                      accessor: 'lastname',
                    },
                    {
                      Header: 'Email',
                      accessor: 'email',
                    },
                    {
                      Header: 'Puhelin',
                      accessor: 'phone',
                    },
                    // {
                    //   Header: 'Voiko editoida',
                    //   accessor: 'adminedit',
                    // },
                    // {
                    //   Header: 'Rooli',
                    //   accessor: 'role',
                    // },
                    {
                      Header: 'Muuta roolia / poista',
                      accessor: 'role',
                      filterable: false,
                      sortable: false,
                      width: 450,
                      renderCell: (params) => {
                        let role = params.row.role.toLowerCase()
                        return (
                          <div className='actions-right'>
                            {ROLES.map((rol) => {
                              return (
                                <Button
                                  disabled={this.state.saving}
                                  color={role === rol ? 'success' : 'secondary'}
                                  size='sm'
                                  onClick={(el) => {
                                    this.updateUserRole(rol, params.row.id)
                                  }}
                                >
                                  {this.state.saving ? <CircularProgress size={10} /> : rol}
                                </Button>
                              )
                            })}
                            <Button
                              disabled={this.state.saving}
                              color='danger'
                              size='sm'
                              onClick={(el) => {
                                this.updateUserRole('user', params.row.id)
                              }}
                            >
                              <span className='btn-label'>
                                <i className='nc-icon nc-simple-remove' />
                              </span>
                            </Button>
                          </div>
                        )
                      },
                    },
                  ]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  // getTrProps={(state, rowInfo) => {
                  //   if (rowInfo && rowInfo.row) {
                  //     // console.log(rowInfo.row)
                  //     return {
                  //       onClick: (e) => {
                  //         this.handleRowSelect(rowInfo.row._original.id)
                  //       },
                  //     }
                  //   } else {
                  //     return {}
                  //   }
                  // }}
                  className='-striped -highlight primary-pagination'
                />
              </Col>
            </Row>
            <Row>
              <Col md='12'></Col>
            </Row>
            {this.createPersonModal()}
            {/* {this.createMessageModal()} */}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(CustomerUserContainer)
