import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'
// import ConfirmationMessageTemplates from '../../components/Config/ConfirmationMessageTemplates'
import Terms from '../../components/Config/Terms'

// core components

class ConfigTerms extends React.Component {
  render () {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='12'>
              <Terms { ...this.props } />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(ConfigTerms)
