import { Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles"
import React from 'react';

const DisabledTypography = withStyles({
  root: {
    color: "#bdbdbd"
  }
})(Typography)

function QuickDurationSelection({ onChange, options, value, disabled }) {

  function handleChange(v) {
    if (disabled) return;
    if (onChange) {
      onChange(v)
    }
  }
  
  return (
    <div className="quick-duration-selection">
      {options.map((option) => {
        if (option.value === value) {
          if (disabled) {
            return (
              <DisabledTypography key={option.value} variant='caption' component={'span'} style={{ marginLeft: 3, marginRight: 3, fontWeight: 'bold' }} >
                {option.label}
              </DisabledTypography>
            )
          } else {
            return (
              <Typography key={option.value} variant='caption' component={'span'} style={{ marginLeft: 3, marginRight: 3, fontWeight: 'bold' }} >
                {option.label}
              </Typography>
            )
          }
        } else {
          if (disabled) {
            return (
              <DisabledTypography key={option.value} variant='caption' component={'span'} style={{ marginLeft: 3, marginRight: 3, cursor: 'default', textDecoration: 'underline' }} >
                {option.label}
              </DisabledTypography>
            )
          } else {
            return (
              <Typography key={option.value} variant='caption' component={'span'} style={{ marginLeft: 3, marginRight: 3, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleChange(option.value)}>
                {option.label}
              </Typography>
            )
          }
        }
      })}
    </div>
  );
}

export default QuickDurationSelection;
  
  