import React from "react";
import { withFirebase } from '../components/Fb'

class Logout extends React.Component {
  componentDidMount() {
    this.props.firebase.doSignOut()
  }

  render() {
    return (<></>)
  }
}

export default withFirebase(Logout);
