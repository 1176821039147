import React from 'react'
import { CircularProgress } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

function PlusMinusButton(props) {
  const { onPlus, onMinus, loading } = props

  if (loading) return <CircularProgress size={10} />
  return (
    <>
      <AddCircleOutlineIcon fontSize='small' onClick={onPlus} />{' / '}<RemoveCircleOutlineIcon fontSize='small' onClick={onMinus} />
      </>
  )
}

export default PlusMinusButton

