import React, { Component } from 'react'
import { withFirebase } from '../../components/Fb'
import { Row, Col, Button } from 'reactstrap'
import { DataGrid } from '@material-ui/data-grid'
import FalconModal from '../../components/Inputs/FalconModal'
import { getBannerSchema, getBannerUISchema } from '../../components/Inputs/dataschemas'
import EditButton from '../../components/Buttons/EditButton'
import DeleteButton from '../../components/Buttons/DeleteButton'
import CalendarHeatmap from 'react-calendar-heatmap'
import moment from 'moment'
import 'react-calendar-heatmap/dist/styles.css'

const enumerateDates = (startDate, endDate) => {
  const dates = []
  const currDate = moment(startDate).startOf('day')
  const lastDate = moment(endDate).startOf('day')

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone())
  }

  return dates
}

class BannersContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      loading: true,
      bannerModalOpen: false,
      selectedResource: {},
      images: [],
      heatdates: [],
    }
  }

  componentDidMount() {
    this.props.firebase.listenBanners().on('value', (snapshot) => {
      if (snapshot && snapshot.exists()) {
        const rows = Object.keys(snapshot.val())
          .map((x) => {
            return { ...snapshot.val()[x], id: x }
          })
          .filter((x) => x.state !== 'deleted')
        const heatdates = rows
          .map((x) => {
            const dates = enumerateDates(x.start, x.end)
            return dates.map((d) => {
              return { date: d.format('YYYY-MM-DD'), count: 100, id: x.id }
            })
          })
          .flat()
        this.setState({ rows, heatdates })
      }
    })
    this.props.firebase.listenMedia().onSnapshot((snapshot) => {
      const images = []
      snapshot.forEach((doc) => {
        if (doc.data().mimetype.indexOf('image/') > -1) {
          images.push({ id: doc.id, ...doc.data() })
        }
      })
      this.setState({ images })
    })
  }

  getColDefinitions = () => {
    const cols = [
      {
        headerName: 'Nimi',
        field: 'name',
        disableClickEventBubbling: true,
      },
      {
        headerName: 'URL',
        field: 'clickurl',
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Ensimmäinen päivä näkyvissä',
        field: 'start',
        disableClickEventBubbling: true,
        valueFormatter: (params) => {
          return new Date(params.value).toLocaleDateString()
        },
      },
      {
        headerName: 'Viimeinen päivä näkyvissä',
        field: 'end',
        disableClickEventBubbling: true,
        valueFormatter: (params) => {
          return new Date(params.value).toLocaleDateString()
        },
      },
      {
        headerName: 'Tila',
        field: 'state',
        disableClickEventBubbling: true,
        valueFormatter: (params) => {
          return params.value === 'active' ? 'Aktiivinen' : 'Pysäytetty'
        },
      },
      {
        headerName: 'Muokkaa',
        field: 'edit',
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <EditButton onClick={() => this.handleRowSelect(params.row.id)} />
        },
      },
      {
        headerName: 'Poista',
        field: 'delete',
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <DeleteButton onClick={() => this.deleteBanner(params.row.id)} />
        },
      },
    ]
    return cols.map((x) => {
      return { ...x, flex: 1 / cols.length }
    })
  }

  // state: {
  //   type: 'string',
  //   title: 'Tila',
  //   enum: ['active', 'paused'],
  //   enumNames: ['Aktiivinen', 'Pysäytetty'],
  // },

  getDataSchemaWithBannerList = () => {
    const dataSchema = getBannerSchema()
    dataSchema.properties.image = {
      type: 'string',
      title: 'Kuva',
      enum: this.state.images.map((x) => x.id),
      enumNames: this.state.images.map((x) => x.originalName),
    }
    return dataSchema
  }

  updateBanner = async (data) => {
    if (data.image) {
      const image = this.state.images.find((x) => x.id === data.image)
      if (image) {
        data.creativeurl = image.url
      }
    }
    await this.props.firebase.updateBanner(data)
  }

  createBanner = async (data) => {
    if (data.image) {
      const image = this.state.images.find((x) => x.id === data.image)
      if (image) {
        data.creativeurl = image.url
      }
    }
    await this.props.firebase.createBanner(data)
  }

  createBannerModal = () => {
    return (
      <FalconModal
        onUpdate={this.updateBanner}
        onCreate={this.createBanner}
        modifiedCallback={this.updateData}
        toggle={this.toggleBannerModal}
        resource={this.state.selectedResource}
        isOpen={this.state.bannerModalOpen}
        uiSchema={getBannerUISchema()}
        dataSchema={this.getDataSchemaWithBannerList()}
        title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa banneria' : 'Uusi banneri'}
      />
    )
  }

  addNewBanner = () => {
    this.setState({ selectedResource: {} }, this.toggleBannerModal)
  }

  deleteBanner = async (id) => {
    await this.props.firebase.deleteBanner(id)
  }

  handleRowSelect = (e) => {
    const _result = this.state.rows.filter((r) => r.id === e)
    this.setState({ selectedResource: _result[0] }, this.toggleBannerModal)
  }

  updateData = () => {}

  toggleBannerModal = () => {
    this.setState({
      bannerModalOpen: !this.state.bannerModalOpen,
    })
  }

  render() {
    return (
      <>
        <div className='content'>
          <Row>
            <Col xs={12} md={3}>
              <Button onClick={this.addNewBanner}>Lisää uusi banneri</Button>
            </Col>
            <Col xs={12} md={3} className='pb-4'>
              <CalendarHeatmap
                startDate={moment().startOf('month').toDate()}
                endDate={moment().add(6, 'months').toDate()}
                onClick={(value) => {
                  this.handleRowSelect(value.id)
                }}
                values={this.state.heatdates}
                monthLabels={['Tam', 'Hel', 'Maa', 'Huh', 'Tou', 'Kes', 'Hei', 'Elo', 'Syy', 'Lok', 'Mar', 'Jou']}
              />
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <div style={{ display: 'flex', minHeight: 'calc(100vh - 200px)', backgroundColor: '#FFFFFF' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid rows={this.state.rows} columns={this.getColDefinitions()} density='compact' />
                </div>
              </div>
            </Col>
          </Row>
          {this.createBannerModal()}
        </div>
      </>
    )
  }
}
export default withFirebase(BannersContainer)
