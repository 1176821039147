import { Typography } from "@material-ui/core";
import { Card, Col, Row } from "reactstrap";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";

const MATCH_FORMATS = {
  'singles_match': 'Kaksinpeli',
  'doubles_match': 'Nelinpeli',
}

const MATCH_TYPES = {
  'practice': 'Harjoitusottelu',
  'tournament': 'Turnausottelu',
  'league': 'Sarjaottelu',
  'cup': 'Cup-ottelu',
  'friendly': 'Ystävyysottelu'
};

const MATCH_VISIBILITIES = {
  'public': 'Julkinen',
  'private': 'Yksityinen',
  'unlisted': 'Listaton'
};

function MatchDetails({ match, enabled, isAttached }) {

  const toggleAddNote = () => {
    console.log('toggleAddNote');
  }

  if (!enabled) {
    return null;
  }
  if (!isAttached) {
    return (
      <>
        <Col xs='1'>
          <Avatar onClick={toggleAddNote} style={{ cursor: 'pointer', height: 20, width: 20 }}>
            <AddIcon />
          </Avatar>
        </Col>
        <Col>
          <div display='inline' style={{ marginTop: 0 }}>Lisää ottelu</div>
        </Col>
      </>
    );
  } else {
    return (
      <div className='border rounded' style={{ padding: 5, marginBottom: 10, backgroundColor: '#f4f3ef' }}>
        <Row>
          <Col><Typography variant='body2' component='div' display='inline' align="left"><b>Ottelu</b></Typography></Col>
          <Col xs={'auto'} ><Typography variant='body2' component='div' display='inline' gutterBottom align="right">{MATCH_FORMATS[match.type]} / {MATCH_VISIBILITIES[match.visibility]}</Typography></Col>
          {match.description && <Col xs={12}><Typography variant='body2' component='div' align="left">{match.description}</Typography></Col>}
          <Col xs={12}><Typography variant='subtitle2' component="div"><b>Pelaajat</b></Typography></Col>
          {match.participants.map((p) => <Col><Typography variant="caption" display="inline">{p.lastname}</Typography></Col>)}
        </Row>
      </div>
    );
  }
}

export default MatchDetails;