import React from 'react'
import { withFirebase } from '../Fb/index.js'

// reactstrap components
import { Row, Col, Button, Card, CardText, CardBody } from 'reactstrap'

import ProshopNotifications from './ProshopNotifications.jsx'

// core components

const service = 'proshop'

class ProshopSettingsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      enabled: false,
    }
  }

  componentDidMount() {
    if (
      this.props.modules &&
      this.props.modules[service] &&
      this.props.modules[service].enabled
    ) {
      this.setState({ enabled: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.modules && this.props.modules[service]) {
      if (
        this.props.modules[service].enabled &&
        !prevProps.modules[service]?.enabled
      ) {
        this.setState({ enabled: true })
      }
      if (
        !this.props.modules[service].enabled &&
        prevProps.modules[service]?.enabled
      ) {
        this.setState({ enabled: false })
      }
    } else {
      if (this.state.enabled) {
        this.setState({ enabled: false })
      }
    }
  }

  disableModule = async () => {
    this.setState({ saving: true })
    await this.props.firebase.disableModule(service)
  }

  render() {
    return (
      <>
        <div className='content'>
        <Row style={{ marginTop: 90 }}>
            <Col md='12'>
            {this.state.enabled ? (
                <Button color='danger' onClick={this.disableModule}>
                  Peruuta palvelu
                </Button>
              ) : (
                  <Card>
                    <CardBody>
                    <CardText>
                    Palvelu ei ole aktivoitu. Voit aktivoida sen Proshop -> Tuotteet -sivulta.
                      </CardText>
                    </CardBody>
                </Card>
              )}
              
              </Col>
            </Row>
          <Row >
            <Col md='12'>
              <ProshopNotifications />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(ProshopSettingsContainer)
