import React from 'react'
import { Button } from 'reactstrap'
import EditIcon from '@material-ui/icons/Edit'
import { CircularProgress } from '@material-ui/core'

function EditButton(props) {
  const { onClick, loading } = props
  return (
    <div className='actions-right'>
      <Button color='success' size='sm' disabled={loading} onClick={onClick}>
        {loading ?
          <CircularProgress size={10} color={'inherit'} /> :
        <EditIcon fontSize={'inherit'} />
        }
    {' Muokkaa'}</Button></div>
  )
}

export default EditButton