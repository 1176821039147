export const _valueCardUISchema = {
}
export const _valueCardClass = {
  name: { type: 'string', title: 'Arvo/lahjakortin nimi' },
  price: { type: 'number', title: 'Hinta' },
  extra: { type: 'number', title: 'Asiakkaalle annettava lisäarvo (€)', default: 0},
  description: { type: 'string', title: 'Kuvaus' },
  validity: {
    type: 'string',
    title: 'Voimassaoloaika',
    enum: ['rolling', 'next', 'fixed', 'permanent'],
    enumNames: ['Rullaava ajanjakso', 'Seuraavaan ajanjaksoon', 'Kiinteä ajanjakso', 'Pysyvä'],
  },
  type: {
    type: 'string',
    title: 'Arvokortin tyyppi',
    description: 'Voiko arvokortilla ostaa vain yhden alv kannan tuotteita vai kaikkia tuotteita.',
    enum: ['single', 'multi'],
    enumNames: ['Yksiarvo', 'Moniarvo'],
    default: 'single',
  },
  availableinshop: {
    type: 'string',
    title: 'Näytetäänkö verkkokaupassa (Ominaisuus ei vielä käytössä)',
    enum: ['true', 'false'],
    enumNames: ['Kyllä', 'Ei'],
    default: 'false',
  },
  canpurchasewithsportmoney: {
    type: 'boolean',
    title: 'Voiko ostaa liikuntarahalla',
    default: false,
  },
    
}
export const _valueCardClassDeps = {
  type: {
    oneOf: [
      {
        properties: {
          type: {
            enum: ['single']
          },
          vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 24, 25.5], enumNames: ['0%', '10%', '14%', '24%','25,5%'] },
          accounting: { type: 'string', title: 'Kirjanpidontili' },
          productCode: { type: 'string', title: 'Tuotekoodi' },
        },
      },
      {
        properties: {
          type: {
            enum: ['multi']
          },
          typedecission: {
            type: 'boolean',
            title: 'Päätetäänkö arvokortin tyyppi myynnin yhteydessä',
          },
        },
      },
    ]
  },
  validity: {
    oneOf: [
      {
        properties: {
          validity: {
            enum: ['rolling'],
          },
          unit: {
            type: 'string',
            title: 'Rullaava ajanjakso',
            enum: ['day', 'week', 'month', 'year'],
            enumNames: ['Päivä', 'Viikko', 'Kuukausi', 'Vuosi'],
          },
          amount: {
            type: 'number',
            title: 'Montako ajanjaksoa ostohetkestä',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['next'],
          },
          endof: {
            type: 'string',
            title: 'Päättyy',
            enum: ['month', 'year'],
            enumNames: ['Kuukauden lopussa', 'Vuoden lopussa'],
          },
          daysahead: {
            type: 'number',
            title: 'Montako päivää ennen aletaan myydä seuraavaa ajanjaksoa. 0 = tästä hetkestä kuluvan ajanjakson loppuun asti.',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['fixed'],
          },
          firstvalid: {
            type: 'string',
            title: 'Ensimmäinen voimassaolopäivä',
            format: 'date',
          },
          lastvalid: {
            type: 'string',
            title: 'Viimeinen voimassaolopäivä',
            format: 'date',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['permanent'],
          },
          firstvalid: {
            type: 'string',
            title: 'Ensimmäinen voimassaolopäivä',
            format: 'date',
          },
        },
      },
    ],
  },
}
