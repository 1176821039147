// const logClass = {
//   timestamp: '',
//   type: '',
//   userid: '', // this.props.firebase.auth.currentUser.uid,
//   diff: '',
// }

const baseClass = {
  id: '',
  displayName: '',
  state: '',
}

const accountingClass = {
  taxid: '',
  email: '',
}

const partnerClass = {
  accounting: accountingClass,
}

// const personClass = {
//   firstname: '',
//   lastname: '',
//   memberships: [],
//   role: '',
//   partner: partnerClass,
// }

// const payerClass = {
//   person: personClass,
//   share: 0,
// }

const resourceCalendarClass = {
  defaultWeek: {
    0: {
      start: '00:00',
      end: '23:59',
    },
  },
  dates: {
    '2020-04-06': {
      start: '',
      end: '',
    },
  },
}

const resourceGroupClass = {
  type: '', // court,
  category: '', // tennis, badminton, squash,
  calendarId: '',
  config: {
    type: 'minutebased', // future proof
    defaultSlot: 60, // mins
    slotExtend: 30,
    allowEmptySlots: false, // can resource reserved so that there is shorter than defaultSlot between two reservations
  },
}

const resourceClass = {
  owner: partnerClass,
  // seller: partnerClass,
  displayName: '',
  type: '', // court,
  category: '', // tennis, badminton, squash
  calendarId: '',
  // config: {
  //   type: 'minutebased', // future proof
  //   defaultSlot: 60, // mins
  //   slotExtend: 30,
  //   allowEmptySlots: false, // can resource reserved so that there is shorter than defaultSlot between two reservations
  // },
}

const productClass = {
  owner: partnerClass,
  resources: [
    {
      divident: 0,
      resource: resourceClass,
    },
  ],
}

const reservationClass = {
  product: productClass,
  organizer: partnerClass,
  resourcegroups: [{
    start: '',
    end: '',
    cost: 0,
    resources: [resourceClass]
  }],
  items: [],
  persons: {
    payers: [],
    participants: [],
  },
  cost: 0, // cents
  isrecurring: false,
  recurrencyDates: [],
  recurrencyInterval: 'weekly',
  recurrencyEndDate: ''
}

const getResource = () => {
  return Object.assign({}, baseClass, resourceClass)
}

const getReservation = () => {
  return JSON.parse(JSON.stringify(Object.assign({}, baseClass, reservationClass)))
}
const getResourceGroup = () => {
  return Object.assign({}, baseClass, resourceGroupClass)
}

const getResourceCalendar = () => {
  return Object.assign({}, baseClass, resourceCalendarClass)
}
export { getResource, getReservation, getResourceGroup, getResourceCalendar }
