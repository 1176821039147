import React from 'react'
import { withFirebase } from '../components/Fb'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  //  Label,
  //  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap'

const INITIAL_STATE = {
  email: '',
  password: '',
  domain: '',
  error: null,
}

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    const { email, password } = this.state
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
      })
      .catch((error) => {
        alert(error.message)
        this.setState({ error })
      })
    event.preventDefault()
  }

  setEmail = (value) => {
    this.setState({ email: value })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSubmit(event)
    }
  }

  setPassword = (value) => {
    this.setState({ password: value })
  }

  componentDidMount() {
    document.body.classList.toggle('login-page')
  }
  componentWillUnmount() {
    document.body.classList.toggle('login-page')
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.user) {
      if (nextProps.profile) {
        if (nextProps.profile.role === 'ADMIN') {
          this.props.history.push('/admin/calendar')
        } else if (nextProps.profile.role === 'CASHIER') {
          this.props.history.push('/cashier/calendar')
        } else if (nextProps.profile.role === 'PARTNER') {
          this.props.history.push('/partner/calendar')
        } else if (nextProps.profile.role === 'COOPERATIVE') {
          this.props.history.push('/cooperative/calendar')
        }
      }
      return true
    }
    return false
  }

  OnForgetPassword = () => {
    console.log('OnForgetPassword')
    // this.props.history.push('/auth/forget')
    window.location.href = '/auth/forget'
  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto' lg='4' md='6'>
              <Form action='' className='form' method=''>
                <Card className='card-login'>
                  <CardHeader>
                    <CardHeader>
                      <h3 className='header text-center'>Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='nc-icon nc-single-02' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Email'
                        type='text'
                        // value={email}
                        onChange={(e) => this.setEmail(e.target.value)}
                        onKeyPress={this.handleKeyPress}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='nc-icon nc-key-25' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Password'
                        type='password'
                        autoComplete='off'
                        onChange={(e) => this.setPassword(e.target.value)}
                        onKeyPress={this.handleKeyPress}
                      />
                    </InputGroup>
                    <br />
                    <Button
                      block
                      className='btn-round mb-3'
                      color='warning'
                      onClick={this.onSubmit}
                    >
                      Login
                    </Button>
                    <br/>
                    <Button
                      block
                      className='btn-round mb-3'
                      color='link'
                      size='sm'
                      onClick={this.OnForgetPassword}
                    >
                      Salasana unohtunut
                    </Button>
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withFirebase(Login)
