import React from 'react'
import { withFirebase } from '../Fb'

// reactstrap components
import { Row, Col, Button, Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import FalconModal from '../Inputs/FalconModal'
import { getExtraSchema, getExtraUISchema } from '../Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from '../Lists/ReactTableWrapper'
// core components
// import moment from 'moment'
// import 'moment/locale/fi'
import { filterCaseInsensitive } from '../FalconTable'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DeleteButton from '../Buttons/DeleteButton'
import EditButton from '../Buttons/EditButton'

const transformToDB = (data) => {
  const _data = { ...data }
  if (_data.type === 'rentalitem') {
    _data.price = {}
    _data.price.g = _data.pricegeneral * 100
  }  
  delete _data.pricegeneral
  return _data
}

const transformFromDB = (data) => {
  const _data = { ...data }
  if (_data.type === 'rentalitem') {
    if (_data.price && _data.price.g) {
      _data.pricegeneral = _data.price.g / 100
    } else {
      _data.pricegeneral = 0
    }
  } else {
    _data.pricegeneral = 'hinta määritellään resurssin hinnastossa'
  }
  delete _data.price
  return _data
}

class ReservationExtras extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, reservationExtraModalOpen: false, selected: null }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    this.setState({ loading: true })
    Promise.all([this.props.firebase.getResourses(), this.props.firebase.getReservationExtras()]).then((values) => {
      let _rs = values[0].docs.map((sh) => {
        return { ...sh.data(), id: sh.id }
      })
      if (!values[1].empty) {
        let _d = values[1].docs
          .map((sh) => {
            return { ...sh.data(), id: sh.id }
          })
          .map(transformFromDB)
        this.setState(
          {
            extras: _d,
            resources: _rs,
            loading: false,
          },
          this.filterData
        )
      } else {
        this.setState(
          {
            resources: _rs,
            extras: [],
            loading: false,
          },
          this.filterData
        )
      }
    })
  }

  deleteProduct = (id) => {
    return this.props.firebase.deleteReservationExtra(id).then(() => {
      this.updateData()
    })
  }

  getActions = (id) => {
    return (
      <>
        <div className='actions-right'>
          <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.handleRowSelect(id)
            }}
          >
            <EditIcon fontSize={'inherit'} />
            {' Muokkaa'}
          </Button>
          <Button
            color='danger'
            size='sm'
            onClick={(el) => {
              this.deleteProduct(id)
            }}
          >
            <DeleteIcon fontSize={'inherit'} />
            {' Poista'}
          </Button>
        </div>
      </>
    )
  }

  filterData = () => {
    console.log('filterData')
    if (this.state.extras) {
      const data = this.state.extras.map((r) => {
        const _r = { ...r }
        _r.actions = this.getActions(r.id)
        return _r
      })
      this.setState({ loading: false, extras: data })
    }
  }

  getExtras = () => {
    const schema = getExtraSchema()
    const items = { enum: [], enumNames: [] }
    // eslint-disable-next-line array-callback-return
    this.state.resources
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .forEach((res) => {
        items.enum.push(res.id)
        items.enumNames.push(res.displayName)
      })
    schema.properties.resources.items = items
    schema.dependencies.type.oneOf[1].properties.resource.enum = items.enum
    schema.dependencies.type.oneOf[1].properties.resource.enumNames = items.enumNames
    return schema
  }

  handleRowSelect = (e) => {
    // console.log(e)
    const resource = this.state.extras.find((r) => r.id === e)
    this.setState({ selectedResource: resource }, this.toggleReservationExtraModal)
  }

  addNewReservationExtra = () => {
    this.setState({ selectedResource: {} }, this.toggleReservationExtraModal)
  }

  toggleReservationExtraModal = () => {
    this.setState({
      reservationExtraModalOpen: !this.state.reservationExtraModalOpen,
    })
  }

  updateExtra = async (e) => {
    console.log('updateExtra', e)
    return this.props.firebase.updateReservationExtra(transformToDB(e)).then(() => {
      this.updateData()
      this.toggleReservationExtraModal()
    })
  }

  createExtra = async (e) => {
    console.log('createExtra', e)
    return this.props.firebase.createReservationExtra(transformToDB(e)).then(() => {
      this.updateData()
      this.toggleReservationExtraModal()
    })
  }

  createReservationExtraModal = () => {
    return (
      <FalconModal
        onUpdate={this.updateExtra}
        onCreate={this.createExtra}
        toggle={this.toggleReservationExtraModal}
        resource={this.state.selectedResource}
        isOpen={this.state.reservationExtraModalOpen}
        uiSchema={getExtraUISchema()}
        dataSchema={this.getExtras()}
        title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa tuotetta' : 'Uusi tuote'}
      />
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>Loading ...</div>
        </>
      )
    } else {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' md='5' sm='5'>
                  <CardTitle tag='h5'>Varauksien lisätuotteet</CardTitle>
                  <CardBody className='text-muted'>
                    Voit luoda tuotteet jotka asiakas voi lisätä varauksen yhteydessä varaukseen mukaan.
                  </CardBody>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Button onClick={this.addNewReservationExtra}>Lisää varauksen lisätuote</Button>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <ReactTable
                    data={this.state.extras}
                    filterable
                    noDataText='Ei löytynyt yhtään tuotetta'
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                    columns={[
                      {
                        Header: 'Nimi',
                        accessor: 'displayName',
                      },
                      {
                        Header: 'Kuvaus',
                        accessor: 'desc',
                      },
                      {
                        Header: 'Hinta',
                        accessor: 'pricegeneral',
                      },
                      {
                        Header: ' ',
                        accessor: 'edit',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <EditButton
                              onClick={(el) => {
                                this.handleRowSelect(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                      {
                        Header: ' ',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <DeleteButton
                              onClick={(el) => {
                                this.deleteProduct(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    // getTrProps={(state, rowInfo) => {
                    //   if (rowInfo && rowInfo.row) {
                    //     // console.log(rowInfo.row)
                    //     return {
                    //       onClick: (e) => {
                    //         this.handleRowSelect(rowInfo.row._original.id)
                    //       },
                    //     }
                    //   } else {
                    //     return {}
                    //   }
                    // }}
                    className='-striped -highlight primary-pagination'
                  />
                </Col>
              </Row>
              <Row>
                <Col md='12'></Col>
              </Row>
            </CardBody>
          </Card>
          {this.state.alert}
          {this.createReservationExtraModal()}
          {/* </div> */}
        </>
      )
    }
  }
}

export default withFirebase(ReservationExtras)
