import React from 'react'
import YouTube from 'react-youtube'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'

// core components

const opts = {
  height: '540',
  width: '960',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
}

class CashierGuidesContainer extends React.Component {
  
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  render () {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='12'>
              <h5>Kassan opasvideo</h5>
              <YouTube videoId="yc22ZCxUkuA" opts={opts} onReady={this._onReady} />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default CashierGuidesContainer
