import React, { Component, createRef } from 'react'
import { withFirebase } from '../Fb'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Spinner } from 'reactstrap'
import { Editor } from '@tinymce/tinymce-react'
import Alert from '@material-ui/lab/Alert'

class MessageDialog extends Component {
  constructor(props) {
    super(props)
    this.editorRef = createRef(null)
    this.state = {
      open: false,
      body: '',
      subject: '',
    }
  }

  componentDidMount() {
    this.setState({ open: this.props.open })
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open })
    }
  }

  onClose = () => {
    this.setState({ body: '', subject: '' }, this.props.onClose)
  }

  onCancel = () => {
    this.setState({ body: '', subject: '' }, this.props.onClose)
  }

  onSubmit = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit(
        this.state.subject,
        this.editorRef.current.getContent({ format: 'html' }),
        this.editorRef.current.getContent({ format: 'text' })
      )
      this.setState({ body: '', subject: '' }, this.props.onClose)
    } else if (this.props.recipientids) {
      this.setState({ sending: true })
      this.props.firebase
        .sendMessages(
          this.props.recipientids,
          this.state.subject,
          this.editorRef.current.getContent({ format: 'text' }),
          this.editorRef.current.getContent({ format: 'html' }),
          ['admin', 'activities']
        )
        .then(() => {
          this.setState({ body: '', subject: '', sending: false }, this.props.onClose)
        })
    } else {
      this.setState({ body: '', subject: '' }, this.props.onClose)
    }
  }

  handleMessageBodyChange = (body) => {
    this.setState({ body })
  }

  handleMessageSubjectChange = (e) => {
    this.setState({ subject: e.target.value })
  }

  render() {
    return (
      <Modal isOpen={this.state.open} toggle={this.onClose} size={this.props.size || 'lg'}>
        <ModalHeader toggle={this.onClose}>Viesti</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for='messageSubject'>Otsikko</Label>
            <Input
              id='messageSubject'
              value={this.state.subject}
              name='messageSubject'
              onChange={this.handleMessageSubjectChange}
            />
          </FormGroup>
          <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => (this.editorRef.current = editor)}
            initialValue={this.state.body || ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'preview',
                'help',
                'wordcount',
              ],
              toolbar:
                'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
          <br/>
          <Alert severity='info'>
            Jos haluat, että henkilöt voivat vastata viestiin muista määritellä sähköposti viestiasetuksista.
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.onCancel} disabled={this.state.sending}>
            {'Peruuta'}
          </Button>
          <Button color='primary' onClick={this.onSubmit} disabled={this.state.sending}>
            {this.state.sending ? <Spinner size={'sm'} /> : this.props.submitText || 'Lähetä'}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default withFirebase(MessageDialog)
