import React from 'react'
import { withFirebase } from '../Fb'

// reactstrap components
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap'
import FalconModal from '../Inputs/FalconModal'
import {
  getProshopProductSchema,
  getProshopProductUISchema,
  getProshopProductCategorySchema,
  getProshopProductCategoryUISchema,
  getProshopBrandSchema,
  getProshopBrandUISchema,
} from '../Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from './ReactTableWrapper'
// core components
// import moment from 'moment'
// import 'moment/locale/fi'
import { filterCaseInsensitive } from '../FalconTable'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DeleteButton from '../Buttons/DeleteButton'
import EditButton from '../Buttons/EditButton'

const transformToDB = (data) => {
  const _data = { ...data }
  _data.price = {}
  _data.price.g = _data.pricegeneral * 100
  delete _data.pricegeneral
  return _data
}

const transformFromDB = (data) => {
  const _data = { ...data }
  _data.pricegeneral = _data.price.g / 100
  delete _data.price
  return _data
}

class ProshopBrands extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, brands: [], images: [], shopBrandModalOpen: false }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    this.setState({ loading: true })
    Promise.all([
      this.props.firebase.getProductBrands(),
      this.props.firebase.getImages(),
    ]).then((values) => {
      let brands = []
      let images = []
      if (!values[0].empty) {
        // brands
        const _b = values[0].docs.map((sh) => {
          return { ...sh.data(), id: sh.id }
        })
        brands = _b
      }
      if (values[1]) {
        // images
        images = values[1]
      }
      this.setState(
        { brands, images, loading: false },
        this.filterData
      )
    })
  }

  deleteProduct = (id) => {
    return this.props.firebase.deleteProshopProduct(id).then(() => {
      this.updateData()
    })
  }

  getActions = (id) => {
    return (
      <>
        <div className='actions-right'>
          <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.handleRowSelect(id)
            }}
          >
            <EditIcon fontSize={'inherit'} />
            {' Muokkaa'}
          </Button>
          <Button
            color='danger'
            size='sm'
            onClick={(el) => {
              this.deleteShopProduct(id)
            }}
          >
            <DeleteIcon fontSize={'inherit'} />
            {' Poista'}
          </Button>
        </div>
      </>
    )
  }

  filterData = () => {
    console.log('filterData', this.state.brands)
    if (this.state.brands) {
      const data = this.state.brands.map((r) => {
        const _r = { ...r }
        _r.actions = this.getActions(r.id)
        return _r
      })
      console.log('done filtering', data)
      this.setState({ loading: false, brands: data })
    }
  }

  handleRowSelect = (e) => {
    // console.log(e)
    const brand = this.state.brands.find((r) => r.id === e)
    this.setState({ selectedBrand: brand }, this.toggleShopBrandModal)
  }

  addNewShopBrand = () => {
    this.setState({ selectedBrand: {} }, this.toggleShopBrandModal)
  }
  
  toggleShopBrandModal = () => {
    this.setState({
      shopBrandModalOpen: !this.state.shopBrandModalOpen,
    })
  }

  updateShopBrand = async (e) => {
    console.log('updateShopBrand', e)
    return this.props.firebase
      .updateProductBrand(e)
      .then(() => {
        this.updateData() 
        this.toggleShopBrandModal()
      })
  }

  createShopBrand = async (e) => {
    console.log('createExtra', e)
    return this.props.firebase
      .createProductBrand(e)
      .then(() => {
        this.updateData()
        this.toggleShopBrandModal()
      })
  }

  getEnhancedBrandSchema = () => {
    const _schema = getProshopBrandSchema()
    if (this.state.images) {
      _schema.properties.image.items.images = this.state.images
    }
    return _schema
  }

  createShopBrandModal = () => {
    return (
      <FalconModal
        onUpdate={this.updateShopBrand}
        onCreate={this.createShopBrand}
        toggle={this.toggleShopBrandModal}
        resource={this.state.selectedBrand}
        isOpen={this.state.shopBrandModalOpen}
        uiSchema={getProshopBrandUISchema()}
        dataSchema={this.getEnhancedBrandSchema()}
        title={
          this.state.selectedBrand && this.state.selectedBrand.id
            ? 'Muokkaa valmistajaa'
            : 'Uusi valmistaja'
        }
      />
    )
  }




  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>Loading ...</div>
        </>
      )
    } else {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' md='5' sm='5'>
                  <CardTitle tag='h5'>Valmistajat</CardTitle>
                  <CardBody className='text-muted'>
                  </CardBody>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Button onClick={this.addNewShopBrand}>Lisää valmistaja</Button>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <ReactTable
                    data={this.state.brands}
                    height='calc(50vh - 200px)'
                    filterable
                    noDataText='Ei löytynyt yhtään valmistajaa'
                    defaultFilterMethod={(filter, row) =>
                      filterCaseInsensitive(filter, row)
                    }
                    columns={[
                      {
                        Header: 'Nimi',
                        accessor: 'displayName',
                      },
                      {
                        Header: ' ',
                        accessor: 'edit',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <EditButton
                              onClick={(el) => {
                                this.handleRowSelect(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                      {
                        Header: ' ',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <DeleteButton
                              onClick={(el) => { console.log('delete', params.row.id)
                                // this.deleteShopProduct(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className='-striped -highlight primary-pagination'
                  />
                </Col>
              </Row>
              <Row>
                <Col md='12'></Col>
              </Row>
            </CardBody>
          </Card>
          {this.state.alert}
          {this.createShopBrandModal()}
        </>
      )
    }
  }
}

export default withFirebase(ProshopBrands)
