import React, { Component } from 'react'
import { DataGrid } from '@material-ui/data-grid'

export function CustomFooterStatusComponent(props) {
  return (
    <div>Footer</div>
  );
}

class ReactTableWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: this.props.columns,
      rows: this.props.data,
      disableSelectionOnClick: false,
      pageSize: 20,
      totalSum: 0,
    }
  }

  componentDidMount() {
    const actions = this.props.columns.find((col) => col.accessor === 'actions')
    if (actions) {
      this.setState({ disableSelectionOnClick: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ rows: this.props.data })
    }
  }

  getColDefinitions() {
    return this.state.columns.map((col) => {

      const d = {
        ...col,
        field: col.accessor,
        headerName: col.Header,
        width: col.width,
        flex: (1 / this.state.columns.length || 1),
      }
      if (d.width) {
        delete d.flex
      }
      if (col.accessor instanceof Function) {
        // console.log('accessor is function', col.accessor)
        d.valueGetter = (params) => {
          return col.accessor(params.row)
        }
      }

      return d
    })
  }

  onPageSizeChange(newPageSize) {
    this.setState({ pageSize: newPageSize })
  }

  countTotal = (state) => {
    // console.log(state)
    let total = 0
    if (state.filter.items && state.filter.items.length > 0) {
      const lookup = state.visibleRows.visibleRowsLookup
      if (lookup && Object.keys(lookup).length > 0) {
        const visibleItems = Object.keys(lookup).filter((key) => lookup[key] === true)
        const res = this.state.rows.filter((item) => visibleItems.includes(item.id))
        total = res
          .map((item) => item.sum)
          .reduce((a, b) => a + b, 0);
      } else {
        return
      }
    } else {
      total = this.state.rows.map((item) => item.sum).reduce((a, b) => a + b, 0)
    }
    if (this.state.totalSum === total) return
    this.setState({ totalSum: total })
  }

  render() {
    return (
      <React.Fragment>
      <div style={{ display: 'flex', minHeight: this.props.height ? this.props.height : 'calc(100vh - 200px)', backgroundColor: '#FFFFFF' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={this.state.rows}
            columns={this.getColDefinitions()}
            density='compact'
            pageSize={this.state.pageSize}
            disableSelectionOnClick={this.state.disableSelectionOnClick}
            onPageSizeChange={(newPageSize) => this.onPageSizeChange(newPageSize)}
            getRowClassName={this.props.getRowClassName}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            onRowClick={this.props.onRowClick}
            onStateChange={({ state }) => {
              if (this.props.countTotal) {
                this.countTotal(state)
              }
            }}
          />
        </div>
        </div>
        {this.props.noSumRow ? null :
          <div style={{ width: '100%', padding: 20, textAlign: 'right', backgroundColor: '#FFFFFF', fontWeight: 'bold' }}>
            Summa {this.state.totalSum.toFixed(2)} €
          </div>}
      </React.Fragment>
  )
  }
}

export default ReactTableWrapper
