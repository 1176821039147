import React, { Component } from 'react';
import { withFirebase } from '../Fb';
import { Typography } from '@material-ui/core';
import { Row, Col } from 'reactstrap';

class ActivityDetails2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activity: this.props.activity,
    };
  }

  componentDidMount() {
    if (this.state.activity) {
      console.log('this.state.activity is not null')
      return
    }
    this.setState({ activity: this.props.activity, loading: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activity !== this.props.activity) {
      this.setState({ activity: this.props.activity, loading: false });
    }
    // if (!prevProps.activity && this.props.activity) {
    //   console.log('prevProps.activity is null')
    //   this.setState({ activity: this.props.activity, loading: false })
    // } else if (this.props.activity && prevProps.activity && this.props.activity.id !== prevProps.activity.id) {
    //   console.log('prevProps.activity.id is not equal to this.props.activity.id')
    //   this.setState({ activity: this.props.activity, loading: false });
    // }
  }

  componentWillUnmount() {
    this.setState({ activity: null })
  }


  render() {
    const a = this.state.activity
    return (
      <div style={{ padding: 20, backgroundColor: 'white' }}>
        <Row>
          <Col>
            <Typography variant='h4' gutterBottom>
              {a.name.fi}
            </Typography>
          </Col>
        </Row>
      </div>
    )
  }
}

export default withFirebase(ActivityDetails2)
