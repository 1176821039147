import React, { Component } from 'react'
import { withFirebase } from '../Fb'
import moment from 'moment'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const yesterday = {
  start: moment().subtract(1, 'day'),
  end: moment().subtract(1, 'day'),
}
const yesterdayLastWeek = {
  start: moment().subtract(1, 'week').subtract(1, 'day'),
  end: moment().subtract(1, 'week').subtract(1, 'day'),
}
const last7days = {
  start: moment().subtract(7, 'day'),
  end: moment().subtract(1, 'days'),
}
const last14days = {
  start: moment().subtract(14, 'day'),
  end: moment().subtract(8, 'days'),
}
const lastWeek = {
  start: moment().subtract(1, 'week').startOf('isoWeek'),
  end: moment().subtract(1, 'week').endOf('isoWeek'),
}
const lastWeekLastYear = {
  start: moment().subtract(1, 'year').subtract(1, 'week').startOf('isoWeek'),
  end: moment().subtract(1, 'year').subtract(1, 'week').endOf('isoWeek'),
}
const lastMonth = {
  start: moment().subtract(1, 'month').startOf('month'),
  end: moment().subtract(1, 'month').endOf('month'),
}
const lastMonthLastYear = {
  start: moment().subtract(1, 'year').subtract(1, 'month').startOf('month'),
  end: moment().subtract(1, 'year').subtract(1, 'month').endOf('month'),
}
const monthBeforeLastMonth = {
  start: moment().subtract(2, 'month').startOf('month'),
  end: moment().subtract(2, 'month').endOf('month'),
}
const thisYear = {
  start: moment().startOf('year'),
  end: moment().subtract(1, 'day'),
}
const lastYear = {
  start: moment().subtract(1, 'year').startOf('year'),
  end: moment().subtract(1, 'year').subtract(1, 'day'),
}

const periods = [
  { dates: yesterday, name: 'yesterday' },
  { dates: yesterdayLastWeek, name: 'yesterdayLastWeek' },
  { dates: last7days, name: 'last7days' },
  { dates: last14days, name: 'last14days' },
  { dates: lastWeek, name: 'lastWeek' },
  { dates: lastWeekLastYear, name: 'lastWeekLastYear' },
  { dates: lastMonth, name: 'lastMonth' },
  { dates: lastMonthLastYear, name: 'lastMonthLastYear' },
  { dates: lastMonth, name: 'lastMonth' },
  { dates: monthBeforeLastMonth, name: 'monthBeforeLastMonth' },
  { dates: thisYear, name: 'thisYear' },
  { dates: lastYear, name: 'lastYear' },
]

const reportperiods = [
  // { dates: yesterday, name: 'yesterday' },
  // { dates: yesterdayLastWeek, name: 'yesterdayLastWeek' },
  // { dates: last7days, name: 'last7days' },
  // { dates: last14days, name: 'last14days' },
  // { dates: lastWeek, name: 'lastWeek' },
  // { dates: lastWeekLastYear, name: 'lastWeekLastYear' },
  // { dates: lastMonth, name: 'lastMonth' },
  // { dates: lastMonthLastYear, name: 'lastMonthLastYear' },
  // { dates: monthBeforeLastMonth, name: 'monthBeforeLastMonth' },
  { dates: thisYear, name: 'thisYear' },
  { dates: lastYear, name: 'lastYear' },
]

const sum = (data, name) => {
  const sum = data.reduce(
    (acc, row) => {
      acc.cost += parseFloat(row.cost)
      acc.occupancy += parseFloat(row.occupancy)
      acc.windowLength += row.windowLength
      return acc
    },
    { cost: 0, occupancy: 0, windowLength: 0, name, costcurrency: '€' }
  )
  sum.costraw = sum.cost
  if (sum.cost > 1000000) {
    sum.cost = (sum.cost / 1000000).toFixed(1)
    sum.costcurrency = 'M€'
  } else if (sum.cost > 1000) {
    sum.cost = (sum.cost / 1000).toFixed(1)
    sum.costcurrency = 'k€'
  } else {
    sum.cost = sum.cost.toFixed(0)
  }
  sum.occupancy = sum.occupancy.toFixed(1)
  return sum
}

const PERIONDTITLES = {
  yesterday: { title: 'Eilinen', comparisontitle: 'Eilinen viikko sitten' },
  last7days: {
    title: 'Viimeiset 7 päivää',
    comparisontitle: 'Sitä edelliset 7 päivää',
  },
  lastWeek: {
    title: 'Viime viikko',
    comparisontitle: 'Viime vuoden sama viikko',
  },
  lastMonth: {
    title: 'Viime kuukausi',
    comparisontitle: 'Viime vuoden sama kuukausi',
  },
  lastMonth2: {
    title: 'Viime kuukausi',
    comparisontitle: 'Edeltävä kuukausi',
  },
  thisYear: {
    title: 'Tämä vuosi eiliseen asti',
    comparisontitle: 'Viime vuosi sama ajanjakso',
  },
}

const byOneDimension = (data, dimension) => {
  const agg = data.reduce((acc, cur) => {
    const value = cur[dimension]
    if (!acc[value]) acc[value] = { cost: 0, occupancy: 0, windowLength: 0, costcurrency: '€', costraw: 0 }
    acc[value].cost += parseFloat(cur.cost)
    acc[value].occupancy += parseFloat(cur.occupancy)
    acc[value].windowLength += cur.windowLength
    acc[value].costraw += parseFloat(cur.cost)
    return acc
  }, {})
  for (const key in agg) {
    const sum = agg[key]
    if (sum.cost > 1000000) {
      sum.cost = (sum.cost / 1000000).toFixed(1)
      sum.costcurrency = 'M€'
    } else if (sum.cost > 1000) {
      sum.cost = (sum.cost / 1000).toFixed(1)
      sum.costcurrency = 'k€'
    } else {
      sum.cost = sum.cost.toFixed(0)
    }
    sum.occupancy = sum.occupancy.toFixed(1)
  }
  return agg
}

const splitToSums = (data) => {
  const sums = []
  for (let i = 0; i < periods.length; i += 2) {
    const period = periods[i]
    const rows = data.filter((row) => {
      if (period.dates.start.isSame(period.dates.end)) {
        console.log('this is yesterday', row.dayIndex, period.dates.start.format('YYYY-MM-DD'))
        return row.dayIndex === period.dates.start.format('YYYY-MM-DD')
      } else {
        return moment(row.dayIndex).isBetween(
          period.dates.start,
          period.dates.end,
          null,
          '[]'
        )
      }
    }
    )
    const periodsum = byOneDimension(rows, 'category')
    console.log('periodsum', rows.length, period.name)
    const comparisonperiod = periods[i + 1]
    const comparisonrows = data.filter((row) => 
    {
      if (comparisonperiod.dates.start.isSame(comparisonperiod.dates.end)) {
        return row.dayIndex === comparisonperiod.dates.start.format('YYYY-MM-DD')
      } else {
        return moment(row.dayIndex).isBetween(
        comparisonperiod.dates.start,
        comparisonperiod.dates.end,
        null,
        '[]'
        )
      }
    }
    )
    const comparisonsum = byOneDimension(comparisonrows, 'category')

    console.log('comparisonrows', comparisonrows.length, comparisonperiod.name)
    sums.push({
      period: { sum: periodsum, start: period.dates.start, end: period.dates.end, name: period.name },
      comparisonperiod: { sum: comparisonsum, start: comparisonperiod.dates.start, end: comparisonperiod.dates.end, name: comparisonperiod.name },
    })
  }
  return sums
}

const ChangeIcon = ({ change }) => {
  if (change > 0) {
    return (
      <ArrowDropUpIcon
        style={{ color: 'green', marginBottom: 20 }}
        fontSize='large'
      />
    )
  } else if (change < 0) {
    return (
      <ArrowDropDownIcon
        style={{ color: 'red', marginBottom: 20 }}
        fontSize='large'
      />
    )
  } else {
    return <></>
  }
}

function PeriodCard({ period, comparisonperiod }) {
  console.log('PeriodCard', period, comparisonperiod)
  const categories = Object.keys(period.sum).sort().map((category) => {
    const r = { category, period: period.sum[category], comparisonperiod: comparisonperiod.sum[category] || { cost: 0, costcurrency: '€' } }
    const pcostraw = period.sum[category].costraw
    const ccostraw = comparisonperiod?.sum?.[category]?.costraw || 0
    const costChange = pcostraw - ccostraw
    r.costChange = costChange
    return r
  })

  console.log('categories', categories)
      

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <Card>
        <CardContent>
          <Table>
            <TableBody style={{ borderBottom: 'none' }}>
              <TableRow style={{ borderBottom: 'none' }}>
                <TableCell style={{ borderBottom: 'none' }}>
                  <Typography variant='body1' component='span'>
                    {/* {PERIONDTITLES[period.sum.name].title} */}
                    {period.start.format('D.M.YY')} - {period.end.format('D.M.YY')}
                  </Typography>
                  <Typography variant='body2' component='span'>
                    {/* {comparisonperiod &&
                      ' (' + PERIONDTITLES[period.sum.name].comparisontitle + ')'} */}
                    {comparisonperiod && ' (' + comparisonperiod.start.format('D.M.YY')} - {comparisonperiod && comparisonperiod.end.format('D.M.YY') + ')'}
                  </Typography>
                </TableCell>
              </TableRow>
              {categories.map((category) => (
                <>
                  <TableRow key={category.category + '-title'} style={{ borderBottom: 'none' }}>
                    <TableCell style={{ borderBottom: 'none' }}>
                      <Typography variant='body2'>{category.category}</Typography>
                    </TableCell>
                  </TableRow>
                <TableRow key={category.category} style={{ borderBottom: 'none' }}>
                <TableCell align='right' style={{ borderBottom: 'none' }}>
                  <ChangeIcon change={category.costChange} />{' '}
                  <Typography variant='h3' component='span'>
                    {category.period.cost} {category.period.costcurrency}
                  </Typography>
                </TableCell>
                <TableCell align='right' style={{ borderBottom: 'none' }}>
                  {comparisonperiod && (
                    <Typography variant='h5'>
                      {category.comparisonperiod.cost} {category.comparisonperiod.costcurrency}
                    </Typography>
                  )}
                </TableCell>
                  </TableRow>
                  </>
              ))}
{/* 
              <TableRow style={{ borderBottom: 'none' }}>
                <TableCell align='right' style={{ borderBottom: 'none' }}>
                  <Typography variant='h3'>{period.sum.occupancy} h</Typography>
                </TableCell>
                <TableCell align='right' style={{ borderBottom: 'none' }}> */}
                  {/* {comparisonperiod && (
                    <Typography variant='h5'>
                      {comparisonperiod.sum.occupancy} h
                    </Typography>
                  )} */}
                {/* </TableCell>
              </TableRow> */}


            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  )
}

class ReportDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, reports: [] }
  }

  // yersterday and yesterday last week
  // last 7 days and last 14  - 7 days
  // last week and last week last year
  // last month and last month last year
  // last month and month before last month
  // this year until yesterday  and last year until yesterday - 1 year

  updateDashboard = () => {
    Promise.all(
      reportperiods.map((period) => {
        return this.props.firebase.getReport(
          period.dates.start.format('YYYY-MM-DD'),
          period.dates.end.format('YYYY-MM-DD'),
          'busyraw',
          '00:00',
          '23:59'
        )
      })
    )
      .then((response) => {
        const allrows = response.map((report) => report.data.rows).flat()

        const reports = splitToSums(allrows)

        // const reports = response.map((report, i) =>
        //   sum(report.data.rows, reportperiods[i].name)
        // )
        // // create copy of index 6  amd add it index 8
        // if(reports.length > 6) {
        //   const lastMonth2 = { ...reports[6] }
        //   reports.splice(8, 0, lastMonth2)
        //   reports[8].name = 'lastMonth2'
        // }
        this.setState({ loading: false, reports })
      })
      .catch((error) => {
        console.error('Error getting reports', error)
        this.setState({ loading: false })
      })
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.updateDashboard()
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          {/* <h1>Report Dashboard</h1> */}
          <div>Loading ...</div>
        </div>
      )
    } else {
      return (
        <div>
          {/* <h1>Report Dashboard</h1> */}
          <Grid container spacing={3}>
            {this.state.reports.map((report, i) =>
                <PeriodCard
                  key={i}
                  period={report.period}
                comparisonperiod={report.comparisonperiod}
                
                />
            )}
          </Grid>
          {/* <pre>{JSON.stringify(this.state.reports, null, 2)}</pre> */}
        </div>
      )
    }
  }
}

export default withFirebase(ReportDashboard)
