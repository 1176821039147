import React from 'react'
import { Button } from 'reactstrap'
import DeleteIcon from '@material-ui/icons/Delete'
import { CircularProgress } from '@material-ui/core'

function DeleteButton(props) {
  const { onClick, loading } = props

  return (
    <div className='actions-right'>
      <Button disabled={loading} color='danger' size='sm' onClick={onClick}>
        <DeleteIcon fontSize={'inherit'} />
        {loading ? <CircularProgress size={10} /> : ' Poista'}
      </Button>
    </div>
  )
}

export default DeleteButton