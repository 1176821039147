import React from 'react'
import AppDataContext from './context'
import { withFirebase } from '../Fb'
import { Container, Alert } from 'reactstrap'

const CURRENT_VERSION = '1.4.1'

const withAppdata = (WrappedComponent) => {
  class WithAppdata extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        appdata: null,
        uiversionAlert: null,
      }
    }

    componentDidMount() {
      console.log('start appdata listener', this.props)
      this.listener = this.props.firebase.listenUiVersionVenue('prod').on('value', (snapshot) => {
        if (snapshot && snapshot.exists()) {
          if (snapshot.val().version !== CURRENT_VERSION) {
            this.setState({
              uiversionAlert: (
                <Container fluid={this.props.fluid ? true : false}>
                  <Alert color='danger' style={{ marginTop: 80 }}>
                    Uusi versio {CURRENT_VERSION} julkaistu. Päivitä sulkemalla sivu ja avaamalle se uudelleen. Jos sulkeminen ei auta,
                    niin paina Vaihto-näppäin pohjassa sivun uudelleenlatausta.
                  </Alert>
                </Container>
              ),
            })
          }
          const appdata = { news: [], videohelp: [], infoboxes: [] }
          if (snapshot.val().news) {
            const news = Object.keys(snapshot.val().news).map((x) => snapshot.val().news[x])
            news.sort((a, b) => b.created - a.created)
            appdata.news = news
          }
          if (snapshot.val().videohelp) {
            appdata.videohelp = snapshot.val().videohelp
          }
          if (snapshot.val().infoboxes) {
            appdata.infoboxes = snapshot.val().infoboxes
          }
          this.setState({ appdata: appdata })
        }
      })
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AppDataContext.Provider value={this.state.appdata}>
          <WrappedComponent {...this.props} appdata={this.state.appdata} />
        </AppDataContext.Provider>
      )
    }
  }
  return withFirebase(WithAppdata)
}

export default withAppdata
