import React from 'react'
import { Col, Row, Button, Card, CardBody } from 'reactstrap'
import { withFirebase } from '../../components/Fb'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import 'moment/locale/fi'
import FalconModal from '../../components/Inputs/FalconModal'
import {
  // getResourcePricingSchema,
  getReservationBenefitCardTimingSchema,
  getReservationBenefitCardTimingUISchema,
  getReservationBenefitCardTimingSlotSchema,
  getReservationBenefitCardTimingSlotUISchema,
} from '../../components/Inputs/dataschemas'
import Select from 'react-select'

const localizer = momentLocalizer(moment)
// const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

const weekdaysAsResources = [
  { resourceId: 1, resourceTitle: 'Maanantai' },
  { resourceId: 2, resourceTitle: 'Tiistai' },
  { resourceId: 3, resourceTitle: 'Keskiviikko' },
  { resourceId: 4, resourceTitle: 'Torstai' },
  { resourceId: 5, resourceTitle: 'Perjantai' },
  { resourceId: 6, resourceTitle: 'Lauantai' },
  { resourceId: 7, resourceTitle: 'Sunnuntai' },
]

const PricingToolBar = () => {
  return <></>
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

class ReservationBenefitCardCalendarContainer extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      loading: true,
      timings: [],
      timingModalOpen: false,
      timingSlotModalOpen: false,
      timingOptions: [],
      slots: [],
    }
  }

  setResource = (event) => {
    this.setState({ input: JSON.parse(event.target.value) })
  }

  addNew = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  componentDidMount() {
    this.updateSlotData()
  }

  updateSlotData = async () => {
    this.props.firebase.getReservationBenefitCardTimings().then(
      (values) => {
        let _rs = values.docs.map((sh) => {
          return { ...sh.data(), id: sh.id }
        })
          this.setState(
            {
              timings: _rs,
            },
            this.filterData
          )
      }
    )
  }

  filterData = () => {
    if (this.state.timings) {
      let _timingsOptions = this.state.timings
        .map((p) => {
          return {
            value: p.id,
            label: p.name,
            active: p.active,
          }
        })
        .reduce(
          (acc, current) => {
            if (current.active) {
              acc[0].options.push(current)
            } else {
              acc[1].options.push(current)
            }
            return acc
          },
          [
            { label: 'Active', options: [] },
            { label: 'Inactive', options: [] },
          ]
        )
      this.setState({ timingOptions: _timingsOptions, loading: false })
    } else {
      this.setState({ loading: false })
    }
    if (this.state.selectedTimingId) {
      this.updateViewableTiming(this.state.selectedTimingId)
    }
  }

  // getResourcePricing = () => {
  //   const schema = getResourcePricingSchema()
  //   const items = { enum: [], enumNames: [] }
  //   // eslint-disable-next-line array-callback-return
  //   this.state.resources.sort((a, b) => a.displayName.localeCompare(b.displayName)).forEach((res) => {
  //     items.enum.push(res.id)
  //     items.enumNames.push(res.displayName)
  //   })
  //   schema.properties.resources.items = items
  //   return schema
  // }

  handleSelectEvent = (event) => {
    // console.log(event.data)
    this.setState({ selectedResource: JSON.parse(JSON.stringify(event.data)) }, this.toggleTimingSlotModal())
  }

  handleSelectSlot = (slotInfo) => {
    // console.log('handleSelectSlot')
    // console.log(slotInfo)
    let s = moment(slotInfo.start)
    let e = moment(slotInfo.end)
    if (e.diff(s, 'minutes') < 30) {
      e = moment(s).add(30, 'minutes')
    }
    this.setState({ selectedResource: null })
    let _ePlaceholder = {
      id: 'placeholder',
      start: slotInfo.start,
      end: e.toDate(),
      resourceId: slotInfo.resourceId,
    }
    let newslots = [...this.state.slots]
    newslots.push(_ePlaceholder)
    this.setState({ slots: newslots })
    let _selectedResource = {
      configid: this.state.selectedTimingId,
      days: [slotInfo.resourceId],
      pricingunit: 'h',
      starttime: s.format('HH:mm'),
      endtime: e.format('HH:mm'),
    }
    this.setState({ selectedResource: _selectedResource }, this.toggleTimingSlotModal)
  }

  updateFilter = (filter, status) => {
    let fss = {}
    if (filter === 'category') {
      fss = { categoryFilter: status }
    }
    this.setState(fss, this.filterData)
  }

  // getCategoryFilters = () => {
  //   if (this.state.rawResources) {
  //     let _cs = this.state.rawResources.map((e) => e.category)
  //     let _dc = [...new Set(_cs)]
  //     const _c = _dc.map((e) => {
  //       return (
  //         <Button
  //           key={e}
  //           className='btn-round'
  //           color='info'
  //           outline
  //           type='button'
  //           onClick={() => this.updateFilter('category', e)}
  //           active={this.state.categoryFilter === e}
  //         >
  //           {e}
  //         </Button>
  //       )
  //     })
  //     _c.push(
  //       <Button
  //         className='btn-round'
  //         key='typeall'
  //         color='info'
  //         outline
  //         type='button'
  //         onClick={() => this.updateFilter('category', 'all')}
  //         active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
  //       >
  //         Kaikki
  //       </Button>
  //     )
  //     return _c
  //   } else {
  //     return [
  //       <Button
  //         key='typeall'
  //         className='btn-round'
  //         color='info'
  //         outline
  //         type='button'
  //         onClick={() => this.updateFilter('category', 'all')}
  //         active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
  //       >
  //         Kaikki
  //       </Button>,
  //     ]
  //   }
  // }

  toggleTimingModal = (state) => {
    console.log('state', state)
    console.log('this.state.selectedTimingId', this.state.selectedTimingId)
    if (state === 'new') {
      this.setState({
        timingModalOpen: !this.state.timingModalOpen,
      })
    } else if (this.state.selectedTimingId) {
      const _selectedTiming = this.state.timings.filter((p) => p.id === this.state.selectedTimingId)
      if (_selectedTiming[0]) {
        this.setState({
          selectedTiming: _selectedTiming[0],
          timingModalOpen: !this.state.timingModalOpen,
        })
      }
    } else {
      this.setState({
        timingModalOpen: !this.state.timingModalOpen,
      })
    }
  }

  toggleTimingSlotModal = () => {
    this.setState({
      timingSlotModalOpen: !this.state.timingSlotModalOpen,
    })
  }

  toggleTimingSlotModalCancel = () => {
    let _e = this.state.slots.filter((e) => e.id !== 'placeholder')
    this.setState({ slots: _e }, this.toggleTimingSlotModal)
  }

  createTimingSlotModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.addReservationBenefitCardTimingSlot}
        onCreate={this.props.firebase.addReservationBenefitCardTimingSlot}
        toggle={this.toggleTimingSlotModal}
        cancel={this.toggleTimingSlotModalCancel}
        modifiedCallback={this.updateSlotData}
        resource={this.state.selectedResource}
        isOpen={this.state.timingSlotModalOpen}
        uiSchema={getReservationBenefitCardTimingSlotUISchema()}
        dataSchema={getReservationBenefitCardTimingSlotSchema()}
        title={
          this.state.selectedResource && this.state.selectedResource.id
            ? 'Muokkaa kortin aikataulun osaa'
            : 'Uusi kortin aikataulu osa'
        }
      />
    )
  }

  // getResourcePricings = () => {
  //   const schema = getResourcePricingSchema()
  //   const items = { enum: [], enumNames: [] }
  //   // eslint-disable-next-line array-callback-return
  //   this.state.resources.sort((a, b) => a.displayName.localeCompare(b.displayName)).forEach((res) => {
  //     items.enum.push(res.id)
  //     items.enumNames.push(res.displayName)
  //   })
  //   schema.properties.resources.items = items
  //   return schema
  // }

  // pricing modal
  createTimingModal = () => {
    return (
      <FalconModal
        onCreate={this.props.firebase.createReservationBenefitCardTiming}
        onUpdate={this.props.firebase.updateReservationBenefitCardTiming}
        modifiedCallback={this.updateSlotData}
        toggle={this.toggleTimingModal}
        cancel={this.toggleTimingModal}
        resource={this.state.selectedTiming}
        isOpen={this.state.timingModalOpen}
        uiSchema={getReservationBenefitCardTimingUISchema()}
        dataSchema={getReservationBenefitCardTimingSchema()}
        title={
          this.state.selectedTiming && this.state.selectedTiming.id
            ? 'Muokkaa kortin aikataulua'
            : 'Uusi kortin aikataulu'
        }
      />
    )
  }

  selectTiming = (event) => {
    this.updateViewableTiming(event.value)
  }

  updateViewableTiming = (id) => {
    let _selectedTiming = this.state.timings.filter((p) => p.id === id)
    // console.log(_selectedPricing)
    if (_selectedTiming[0]) {
      let _slots = []
      if (_selectedTiming[0].slots) {
        _slots = Object.keys(_selectedTiming[0].slots)
          .map((slotKey) => {
            let slot = _selectedTiming[0].slots[slotKey]
            let _start = moment(slot.starttime, 'HH:mm').year(1970).month(0).date(1).toDate()
            let _end = moment(slot.endtime, 'HH:mm').year(1970).month(0).date(1).toDate()
            let _title = (slot.displayName || '')
            let _ev = slot.days.map((d) => {
              return { resourceId: d, data: { ...slot }, title: _title, start: _start, end: _end }
            })
            return _ev
          })
          .flat()
        // console.log(_slots)
      }
      this.setState({ selectedTimingId: _selectedTiming[0].id, slots: _slots })
    }
  }

  getSelectedTimingDetails = () => {
    if (this.state.selectedTimingId) {
      let _selectedTiming = this.state.timings.filter((p) => p.id === this.state.selectedTimingId)
      if (_selectedTiming[0]) {
        return (
          <Card>
            <CardBody onClick={this.toggleTimingModal}>
              <strong>{_selectedTiming[0].active ? 'Aktiivinen' : 'Ei Aktiivinen'}</strong>
              <br />
              <strong>Aikaväli</strong>
              {': ' +
                moment(_selectedTiming[0].validstart).format('DD.MM.YYYY') +
                ' - ' +
                moment(_selectedTiming[0].validend).format('DD.MM.YYYY')}
            </CardBody>
          </Card>
        )
      }
    }
    return <></>
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content' style={{ height: '75vh' }}>
            <Row>
              <Col md='4'>
                <Card>
                  <CardBody>
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      closeMenuOnSelect={true}
                      name='resourcepricings'
                      options={this.state.timingOptions}
                      formatGroupLabel={formatGroupLabel}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      placeholder='Aikataulut ...'
                      onChange={this.selectTiming}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Button onClick={(e) => this.toggleTimingModal('new')}>Lisää aikataulu</Button>
              </Col>
            </Row>
            <Row>
              <Col>{this.getSelectedTimingDetails()}</Col>
            </Row>
            <Row>
              <Col md='12'>
                <Card>
                  <CardBody>
                    <Calendar
                      style={{ height: '85vh' }}
                      ref={this.myRef}
                      selectable
                      localizer={localizer}
                      events={this.state.slots ? this.state.slots : []}
                      views={['day']}
                      step={30}
                      min={new Date(1970, 0, 1, 0, 0, 0)}
                      defaultView={Views.DAY}
                      scrollToTime={new Date(1970, 0, 1, 12)}
                      defaultDate={new Date(1970, 0, 1, 12)}
                      onSelectEvent={(event) => this.handleSelectEvent(event)}
                      onSelectSlot={this.handleSelectSlot}
                      // onView={this.updateView}
                      components={{ toolbar: PricingToolBar }}
                      // eventPropGetter={this.eventStyleGetter}
                      resources={weekdaysAsResources}
                      resourceIdAccessor='resourceId'
                      resourceTitleAccessor='resourceTitle'
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.createTimingSlotModal()}
            {this.createTimingModal()}
          </div>
        </>
      )
    } else {
      return (
        <>
          <Row>
            <Col>Loading ...</Col>
          </Row>
        </>
      )
    }
  }
}

export default withFirebase(ReservationBenefitCardCalendarContainer)
