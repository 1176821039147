/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, Collapse } from 'reactstrap'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'
import { withFirebase } from '../Fb'
import MuiBadge from '@material-ui/core/Badge'

import logo from '../../assets/img/logos/cintoia.png'
// import BetaIcon from '../BetaIcon'
import { isMobile } from 'react-device-detect'
var ps

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.sidebar = React.createRef()
    this.state = this.getCollapseStates(props.routes)
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {}
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        }
      }
      return null
    })
    return initialState
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes, submenu) => {
    if (isMobile && routes[routes.length - 1].path !== '/noop') {
      let i = 0
      while (i < 2) {
        routes.push(
          {
            path: '/noop',
            name: '',
            icon: '',
            layout: '/admin',
          }
        )
        i++
      }
    }
    return routes.filter((x) => { if (x.customers && !x.customers.includes(this.props.firebase.customerid)) { return false } else { return true } }).map((prop, key) => {
      if (prop.redirect) {
        return null
      }
      let Component = null
      let ServiceComp = null
      const isActive = this.activeRoute(prop.layout + prop.path) === 'active'
      const fontSize = submenu ? 17 : 24
      const color = isActive && submenu ? '#ef8157' : '#9A9A9A'

      if (prop.iconcomponent) {
        Component = prop.iconcomponent
      }
      if (prop.serviceIcon) {
        ServiceComp = prop.serviceIcon
      }
      if (prop.collapse) {
        var st = {}
        st[prop['state']] = !this.state[prop.state]
        return (
          <li className={this.getCollapseInitialState(prop.views) ? 'active' : ''} key={key}>
            <a
              href='#noop'
              data-toggle='collapse'
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault()
                this.setState(st)
              }}
            >
              {Component ?
                <><Component style={{ marginRight: 20, marginLeft: 4, fontSize, color, float: 'left' }} /><p style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{prop.name} <b className='caret' /></p></> :
                prop.icon !== undefined && Component === null ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name} {prop.serviceIcon ? <ServiceComp fontSize={'small'} /> : null}
                    <b className='caret' />
                  </p>
                </>
              ) : (
                <>
                  <span className='sidebar-mini-icon'>{prop.mini}</span>
                  <span className='sidebar-normal'>
                    {prop.name}
                    <b className='caret' />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className='nav'>{this.createLinks(prop.views, true)}</ul>
            </Collapse>
          </li>
        )
      }

      // main level
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName=''>
            {Component ? <><Component style={{ marginRight: 20, marginLeft: 4, fontSize, color }} /><span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{prop.name} {prop.serviceIcon ? <ServiceComp fontSize={'small'} /> : null}</span></> : prop.icon !== undefined && Component === null ? (
              <>
                {(prop.beta ? <MuiBadge style={{ display: 'inline' }} badgeContent={'Beta'} color="primary"><i className={prop.icon} /></MuiBadge> : <i className={prop.icon} />)}
                <p>{prop.name} {prop.serviceIcon ? <ServiceComp fontSize={'small'} /> : null}</p>
              </>
            ) : (
              <>
                  <span className='sidebar-mini-icon'>{prop.mini}</span>
                  <span className='sidebar-normal'>{prop.name} {'6'}</span>
              </>
            )}
            
          </NavLink>
        </li>
      )
    })
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }
  componentDidMount() {
    // console.log('setting stage')
    this.setState({ customer: null, clubdata: null })
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
    }
  }

  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
    }
  }
  render() {
    // console.log('this.props', this.props)
    // let www = (this.props.profile.customer ? this.props.profile.customer.www : '#noop')
    // let displayName = (this.props.profile.customer ? this.props.profile.customer.identity : '')
    // let clogo = (this.props.profile.customer && this.props.profile.customer.name ? require('../../assets/img/logos/' + this.props.profile.customer.name.toLowerCase() + '.png') : '')
    return (
      <div className='sidebar' data-color={this.props.bgColor} data-active-color={this.props.activeColor}>
        <div className='logo'>
          <a href='https://www.cintoia.com' className='simple-text logo-mini'>
            <div className='logo-img'>
              <img src={logo} alt='cintoia-logo' />
            </div>
          </a>
          <a href='https://www.cintoia.com' className='simple-text logo-normal'>
            CINTOIA
          </a>
        </div>

        <div className='logocustomer'>
          <div className='users'>
            <div className='info'>
              <span className='user-text'>{this.props.displayName}</span>
              <span className='user-text'>{this.props.user.email}</span>
            </div>
          </div>
        </div>

        <div className='sidebar-wrapper' ref={this.sidebar}>
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
        {isMobile ? (<div style={{ height: 60 }}></div>) : null}
      </div>
    )
  }
}

export default withFirebase(Sidebar)
