import React, { Component } from 'react'
import { withFirebase } from '../../components/Fb'
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Badge,
  Drawer,
  Divider,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'
import TarmoLogo from './TarmoLogo'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ClearIcon from '@material-ui/icons/Clear'

const demoproducts = [
  {
    id: 1,
    name: 'Product 1',
    price: 100,
    description: 'This is a product description',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 2,
    name: 'Product 2',
    price: 200,
    description: 'This is a product description',
    image: 'https://via.placeholder.com/150',
  },
]

function CardDrawer({ cart, addToCart, removeFromCart }) {
  const itemCount = cart.length
  const itemQuantity = cart.reduce((a, c) => a + c.quantity, 0)
  return (
    <Paper style={{ padding: 10 }} elevation={0}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h5'>Ostoskori</Typography>
          <Typography variant='body1'>
            {itemCount} Tuotetta ({itemQuantity} kpl)
          </Typography>
          <Divider />
        </Grid>
        {cart.map((product) => (
          <React.Fragment>
            <Grid item xs={2}>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <IconButton onClick={() => removeFromCart(product)}>
                    <RemoveCircleOutline color='primary' />
                  </IconButton>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='subtitle2'>
                    {product.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <IconButton onClick={() => addToCart(product)}>
                    <AddCircleOutline color='primary' />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} style={{ margin: 'auto' }}>
              <Typography variant='subtitle2'>{product.name}</Typography>
            </Grid>
            <Grid item xs={2} style={{ margin: 'auto' }}>
              <IconButton onClick={() => removeFromCart(product, true)}>
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <Divider />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  )
}

function Product({ product, addToCart, removeFromCart, quantityInCart }) {
  return (
    <Paper style={{ padding: 10 }}>
      <Grid container spacing={3} style={{ minHeight: 180 }}>
        <Grid item xs={8}>
          <Typography variant='h6'>{product.name}</Typography>
          <Typography variant='body1'>{product.description}</Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <img src={product.image} alt={product.name} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          {quantityInCart > 0 && (
            <React.Fragment>
              <IconButton onClick={() => removeFromCart(product)}>
                <RemoveCircleOutline color='primary' />
              </IconButton>
              <span>{quantityInCart}</span>
            </React.Fragment>
          )}
          <IconButton onClick={() => addToCart(product)}>
            <AddCircleOutline color='primary' />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

function CartIcon({ cart, onClick }) {
  const badgeContent =
    cart && cart.length > 0
      ? cart.reduce((a, c) => a + c.quantity, 0) + ''
      : null
  return (
    <IconButton onClick={onClick}>
      <Badge badgeContent={badgeContent} color='primary'>
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  )
}

class TarmoShop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      products: [],
      cart: [],
      cartOpen: false,
    }
  }

  storeOrder = () => {
    // const { firebase } = this.props
    // const { cart } = this.state
    // const order = {
    //   products: cart,
    //   status: 'pending',
    // }
    // firebase.db.ref('orders').push(order)
    this.setState({ cart: [], cartOpen: false, thankYouOpen: true })
  }

  addToCart = (product) => {
    const cart = this.state.cart
    const isAlreadyInCart = cart.find((p) => p.id === product.id)
    if (isAlreadyInCart) {
      cart.forEach((p) => {
        if (p.id === product.id) {
          p.quantity++
        }
      })
      this.setState({ cart })
      return
    } else {
      product.quantity = 1
    }
    cart.push(product)
    this.setState({ cart })
  }

  removeFromCart = (product, removeall) => {
    const cart = this.state.cart
    const isAlreadyInCart = cart.find((p) => p.id === product.id)
    if (isAlreadyInCart) {
      if (removeall) {
        this.setState({ cart: cart.filter((p) => p.id !== product.id) })
      } else {
        cart.forEach((p) => {
          if (p.id === product.id) {
            p.quantity--
          }
        })
      }
      this.setState({ cart })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography
              variant='h5'
              component={'span'}
              style={{ marginRight: 30 }}
            >
              Tilaustuotteet
            </Typography>
            <CartIcon
              cart={this.state.cart}
              onClick={() => this.setState({ cartOpen: !this.state.cartOpen })}
            />
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <TarmoLogo />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {demoproducts.map((product) => (
                <Grid item xs={12} sm={3}>
                  <Product
                    key={product.id}
                    product={product}
                    addToCart={this.addToCart}
                    removeFromCart={this.removeFromCart}
                    quantityInCart={
                      this.state.cart.find((p) => p.id === product.id)
                        ? this.state.cart.find((p) => p.id === product.id)
                            .quantity
                        : 0
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Drawer
          anchor='right'
          open={this.state.cartOpen}
          onClose={() => this.setState({ cartOpen: false })}
          PaperProps={{ style: { width: '25%', padding: 10 } }}
        >
          <CardDrawer
            cart={this.state.cart}
            addToCart={this.addToCart}
            removeFromCart={this.removeFromCart}
          />
          <TextField
            fullWidth
            label='Lisätiedot'
            margin='normal'
            variant='outlined'
            rows={4}
            multiline
          />
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={this.storeOrder}
          >
            Tilaa
          </Button>
        </Drawer>
        <Dialog
          open={this.state.thankYouOpen}
          onClose={() => this.setState({ thankYouOpen: false })}
        >
          <DialogTitle>Kiitos tilauksestasi!</DialogTitle>
          <DialogActions>
            <Button
              color='primary'
              onClick={() => this.setState({ thankYouOpen: false })}
            >
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withFirebase(TarmoShop)
