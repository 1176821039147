import React from 'react'
import { withFirebase } from '../Fb'

// reactstrap components
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap'
import FalconModal from '../Inputs/FalconModal'
import {
  getProshopProductSchema,
  getProshopProductUISchema,
  getProshopProductCategorySchema,
  getProshopProductCategoryUISchema,
  getProshopBrandSchema,
  getProshopBrandUISchema,
} from '../Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from './ReactTableWrapper'
// core components
// import moment from 'moment'
// import 'moment/locale/fi'
import { filterCaseInsensitive } from '../FalconTable'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DeleteButton from '../Buttons/DeleteButton'
import EditButton from '../Buttons/EditButton'

const transformToDB = (data) => {
  const _data = { ...data }
  // _data.price = {}
  // _data.price.g = _data.pricegeneral * 100
  // delete _data.pricegeneral
  return _data
}

const transformFromDB = (data) => {
  const _data = { ...data }
  // _data.pricegeneral = _data.price.g / 100
  // delete _data.price
  return _data
}

class ProshopProducts extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, shopProcuctModalOpen: false, selected: null, products: [], categories: [], brands: [], images: [], shopCategoryModalOpen: false, shopBrandModalOpen: false }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    this.setState({ loading: true })
    Promise.all([
      this.props.firebase.getProshopProducts(),
      this.props.firebase.getProductCategories(),
      this.props.firebase.getProductBrands(),
      this.props.firebase.getImages(),
    ]).then((values) => {
      let products = []
      let categories = []
      let brands = []
      let images = []
      if (!values[0].empty) {
        // products
        const _d = values[0].docs
          .map((sh) => {
            return { ...sh.data(), id: sh.id }
          })
          .map(transformFromDB)
        products = _d
      }
      if (!values[1].empty) {
        // categories
        const _c = values[1].docs.map((sh) => {
          return { ...sh.data(), id: sh.id }
        })
        categories = _c
      }
      if (!values[2].empty) {
        // brands
        const _b = values[2].docs.map((sh) => {
          return { ...sh.data(), id: sh.id }
        })
        brands = _b
      }
      if (values[3]) {
        // images
        images = values[3]
      }
      this.setState(
        { products, categories, brands, images, loading: false },
        this.filterData
      )
    })
  }

  deleteProduct = (id) => {
    return this.props.firebase.deleteProshopProduct(id).then(() => {
      this.updateData()
    })
  }

  getActions = (id) => {
    return (
      <>
        <div className='actions-right'>
          <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.handleRowSelect(id)
            }}
          >
            <EditIcon fontSize={'inherit'} />
            {' Muokkaa'}
          </Button>
          <Button
            color='danger'
            size='sm'
            onClick={(el) => {
              this.deleteShopProduct(id)
            }}
          >
            <DeleteIcon fontSize={'inherit'} />
            {' Poista'}
          </Button>
        </div>
      </>
    )
  }

  filterData = () => {
    console.log('filterData', this.state.products)
    if (this.state.products) {
      const data = this.state.products.map((r) => {
        const _r = { ...r }
        _r.actions = this.getActions(r.id)
        return _r
      })
      console.log('done filtering', data)
      this.setState({ loading: false, products: data })
    }
  }

  handleRowSelect = (e) => {
    // console.log(e)
    const product = this.state.products.find((r) => r.id === e)
    this.setState({ selectedProduct: product }, this.toggleShopProductModal)
  }

  addNewShopProduct = () => {
    this.setState({ selectedProduct: {} }, this.toggleShopProductModal)
  }

  addNewShopCategory = () => {
    this.setState({ selectedCategory: {} }, this.toggleShopCategoryModal)
  }

  addNewShopBrand = () => {
    this.setState({ selectedBrand: {} }, this.toggleShopBrandModal)
  }
  
  toggleShopProductModal = () => {
    this.setState({
      shopProcuctModalOpen: !this.state.shopProcuctModalOpen,
    })
  }

  toggleShopCategoryModal = () => {
    this.setState({
      shopCategoryModalOpen: !this.state.shopCategoryModalOpen,
    })
  }

  toggleShopBrandModal = () => {
    this.setState({
      shopBrandModalOpen: !this.state.shopBrandModalOpen,
    })
  }

  updateShopProduct = async (e) => {
    console.log('updateShopProduct', e)
    return this.props.firebase
      .updateProshopProduct(transformToDB(e))
      .then(() => {
        this.updateData()
        this.toggleShopProductModal()
      })
  }

  createShopProduct = async (e) => {
    console.log('createExtra', e)
    return this.props.firebase
      .createProshopProduct(transformToDB(e))
      .then(() => {
        this.updateData()
        this.toggleShopProductModal()
      })
  }

  updateShopCategory = async (e) => {
    console.log('updateShopCategory', e)
    return this.props.firebase
      .updateProductCategory(e)
      .then(() => {
        this.updateData()
        this.toggleShopCategoryModal()
      })
  }

  createShopCategory = async (e) => {
    console.log('createExtra', e)
    return this.props.firebase
      .createProductCategory(e)
      .then(() => {
        this.updateData()
        this.toggleShopCategoryModal()
      })
  }

  updateShopBrand = async (e) => {
    console.log('updateShopBrand', e)
    return this.props.firebase
      .updateProductBrand(e)
      .then(() => {
        this.updateData() 
        this.toggleShopBrandModal()
      })
  }

  createShopBrand = async (e) => {
    console.log('createExtra', e)
    return this.props.firebase
      .createProductBrand(e)
      .then(() => {
        this.updateData()
        this.toggleShopBrandModal()
      })
  }

  getProductEnhancedSchema = () => {
    const schema = getProshopProductSchema()
    console.log('getProductEnhancedSchema', schema)
    if (this.state.images) {
      schema.dependencies.availableinshop.oneOf[0].properties.image.items.images =
        this.state.images
    }
    if (this.state.categories) {
      schema.properties.productcategory.enum = this.state.categories.map(
        (c) => c.id
      )
      schema.properties.productcategory.enumNames = this.state.categories.map(
        (c) => c.displayName
      )
    }
    if (this.state.brands) {
      schema.properties.brand.enum = this.state.brands.map(
        (c) => c.id
      )
      schema.properties.brand.enumNames = this.state.brands.map(
        (c) => c.displayName
      )
    }
    return schema
  }

  createShopProductModal = () => {
    return (
      <FalconModal
        onUpdate={this.updateShopProduct}
        onCreate={this.createShopProduct}
        toggle={this.toggleShopProductModal}
        resource={this.state.selectedProduct}
        isOpen={this.state.shopProcuctModalOpen}
        uiSchema={getProshopProductUISchema()}
        dataSchema={this.getProductEnhancedSchema()}
        title={
          this.state.selectedProduct && this.state.selectedProduct.id
            ? 'Muokkaa tuotetta'
            : 'Uusi tuote'
        }
      />
    )
  }

  createShopCategoryModal = () => {
    return (
      <FalconModal
        onUpdate={this.updateShopCategory}
        onCreate={this.createShopCategory}
        toggle={this.toggleShopCategoryModal}
        resource={this.state.selectedCategory}
        isOpen={this.state.shopCategoryModalOpen}
        uiSchema={getProshopProductCategoryUISchema()}
        dataSchema={getProshopProductCategorySchema()}
        title={
          this.state.selectedCategory && this.state.selectedCategory.id
            ? 'Muokkaa kategoriaa'
            : 'Uusi kaategoria'
        }
      />
    )
  }


  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>Loading ...</div>
        </>
      )
    } else {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' md='5' sm='5'>
                  <CardTitle tag='h5'>Tuotteet</CardTitle>
                  <CardBody className='text-muted'>
                    Voit luoda tuotteet jotka näkyvät asiakkaille verkossa.
                    Asiakkaat eivät voi maksaa tuotteita verkossa, mutta voivat
                    lähettää tiedustelun / varauksen niistä.
                  </CardBody>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Button onClick={this.addNewShopProduct}>Lisää tuote</Button>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <ReactTable
                    height='calc(100vh - 365px)'
                    data={this.state.products}
                    filterable
                    noDataText='Ei löytynyt yhtään tuotetta'
                    defaultFilterMethod={(filter, row) =>
                      filterCaseInsensitive(filter, row)
                    }
                    columns={[
                      {
                        Header: 'Nimi',
                        accessor: 'displayName',
                      },
                      {
                        Header: 'Kategoria',
                        accessor: 'productcategory',
                        renderCell: (params) => {
                          const category = this.state.categories.find(
                            (c) => c.id === params.row.productcategory
                          )
                          return category ? category.displayName : ''
                        },
                      },
                      {
                        Header: ' ',
                        accessor: 'edit',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <EditButton
                              onClick={(el) => {
                                this.handleRowSelect(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                      {
                        Header: ' ',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <DeleteButton
                              onClick={(el) => {
                                this.deleteShopProduct(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className='-striped -highlight primary-pagination'
                  />
                </Col>
              </Row>
              <Row>
                <Col md='12'></Col>
              </Row>
            </CardBody>
          </Card>
          {this.state.alert}
          {this.createShopProductModal()}
          {this.createShopCategoryModal()}
        </>
      )
    }
  }
}

export default withFirebase(ProshopProducts)
