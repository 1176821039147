import Fuse from 'fuse.js'

const personOptions = {
  // isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  minMatchCharLength: 3,
  location: 0,
  threshold: 0.4,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    "firstname",
    "lastname",
    "email",
    "phone"
  ]
}

const organizationsOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  // threshold: 0.6,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    "name",
    "displayName"
  ]
}

const combinedOptions = {
  // isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  minMatchCharLength: 3,
  location: 0,
  threshold: 0.4,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    "firstname",
    "lastname",
    "name",
    "displayName",
    "email",
    "phone"
  ]
}

class FuseCache {
  constructor() {
    this.persons = []
    this.organizations = []

    this.personsfuse = new Fuse(this.persons, personOptions)
    this.organizationsfuse = new Fuse(this.organizations, organizationsOptions)
    this.combinedfuse = new Fuse(this.organizations.concat(this.persons), combinedOptions)
    this.lastupdate = 0
  }

  updatePersons = (persons) => {
    this.persons = persons
    this.personsfuse = new Fuse(this.persons, personOptions)
  }

  updateOrganisations = (organizations) => {
    this.lastupdate = Date.now()
    this.organizations = organizations
    this.organizationsfuse = new Fuse(this.organizations, organizationsOptions)
  }

  updateCombined = (organizations, persons) => {
    this.lastupdate = Date.now()
    if (organizations) this.organizations = organizations
    if (persons) this.persons = persons
    this.combinedfuse = new Fuse(this.organizations.concat(this.persons), combinedOptions)
  }

  searchPersons = (pattern) => {
    return this.personsfuse.search(pattern)
  }

  searchOrganisations = (pattern) => {
    return this.organizationsfuse.search(pattern)
  }

  searchCombined = (pattern) => {
    return this.combinedfuse.search(pattern)
  }
  
  isValid = () => {
    return (Date.now() - this.lastupdate) < 600
  }
  

}
export default FuseCache