import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button } from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import { getMembershipSchema, getMembershipUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from '../../components/Lists/ReactTableWrapper'
import { filterCaseInsensitive, getTdProps } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
import EditButton from '../../components/Buttons/EditButton'
import DeleteButton from '../../components/Buttons/DeleteButton'
import Infobox from '../../components/Infos/Infobox'

// core components

class MembershipsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, membershipModalOpen: false, memberships: null, images: [], providers: [] }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    Promise.all([this.props.firebase.getMemberships(), this.props.firebase.getMembershipResourcePricings(), this.props.firebase.getProviders()]).then(
      (values) => {
        const memberships = []
        if (!values[0].empty) {
          // eslint-disable-next-line
          values[0].docs.map((doc) => {
            if (doc.data().state !== 'deleted') {
              memberships.push({ ...doc.data(), id: doc.id })
            }
          })
        }
        if (!values[1].empty) {
          let _d = values[1].docs
            .map((sh) => {
              return { ...sh.data(), id: sh.id }
            })
            .sort((a, b) => {
              return (b.priority || 0) - (a.priority || 0)
            })
          this.setState(
            {
              pricings: _d,
              raw: memberships,
            },
            this.filterData
          )
        } else {
          this.setState({ raw: memberships }, this.filterData)
        }
        if(!values[2].empty) {
          const providers = []
          // eslint-disable-next-line
          values[2].docs.map((doc) => {
            if (doc.data().state !== 'deleted') {
              providers.push({ ...doc.data(), id: doc.id })
            }
          })
          this.setState({ providers })
        }
      }
    )
    this.props.firebase.getImages().then((images) => {
      this.setState({ images })
    })
  }

  handleRowSelect = (e) => {
    const _result = this.state.memberships.filter((r) => r.id === e)
    this.setState({ selectedResource: _result[0] }, this.toggleMembershipModal)
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      data.push({
        ...this.state.raw[i],
        actions: this.getActions(this.state.raw[i]),
      })
    }
    this.setState({ loading: false, memberships: data })
  }

  deleteMembership = async (id) => {
    await this.props.firebase.deleteMembership(id)
    this.setState({ loading: true }, this.updateData)
  }

  getActions = (row) => {
    return (
      <>
        <div className='actions-right'>
          {}
          <Button
            color='danger'
            size='sm'
            onClick={(el) => {
              this.deleteMembership(row.id)
            }}
          >
            <span className='btn-label'>
              <i className='nc-icon nc-simple-remove' />
            </span>
          </Button>
        </div>
      </>
    )
  }

  addNewMembership = () => {
    this.setState({ selectedResource: {} }, this.toggleMembershipModal)
  }

  toggleMembershipModal = () => {
    this.setState({
      membershipModalOpen: !this.state.membershipModalOpen,
    })
  }

  getEnhancedMembershipSchema = () => {
    const schema = getMembershipSchema()
    console.log(schema)
    const pricing = { ...schema.properties.benefits.items.dependencies.benefittype.oneOf[2].properties.pricing }
    if (this.state.pricings) {
      for (let i = 0; i < this.state.pricings.length; i++) {
        pricing.enum.push(this.state.pricings[i].id)
        pricing.enumNames.push(this.state.pricings[i].name)
      }
    }
    schema.properties.benefits.items.dependencies.benefittype.oneOf[2].properties.pricing = pricing
    const provider = { ...schema.dependencies.provideritem.oneOf[0].properties.provider }
    if (this.state.providers) {
      for (let i = 0; i < this.state.providers.length; i++) {
        provider.enum.push(this.state.providers[i].code)
        provider.enumNames.push(this.state.providers[i].name)
      }
      schema.dependencies.provideritem.oneOf[0].properties.provider = provider
    }
    if (this.state.images) {
      schema.dependencies.availableinshop.oneOf[0].properties.image.items.images = this.state.images
    }
    return schema
  }

  createMembershipModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.updateMembership}
        onCreate={this.props.firebase.createMembership}
        modifiedCallback={this.updateData}
        toggle={this.toggleMembershipModal}
        resource={this.state.selectedResource}
        isOpen={this.state.membershipModalOpen}
        uiSchema={getMembershipUiSchema()}
        dataSchema={this.getEnhancedMembershipSchema()}
        title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa jäsenyyttä' : 'Uusi jäsenyys'}
        size='lg'
      />
    )
  }

  render() {
    if (this.state.loading) {
      return <FalconLoading />
    } else {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.addNewMembership}>Lisää jäsenyys</Button>
              </Col>
              <Col>
                <Infobox index={'0'}/>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.memberships}
                  filterable
                  noSumRow={true}
                  noDataText='Ei löytynyt yhtään jäsenyyttä'
                  defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                  columns={[
                    {
                      Header: 'Nimi',
                      accessor: 'name',
                    },
                    {
                      Header: 'Ostettavissa verkosta',
                      accessor: 'availableinshop',
                      valueFormatter: (data) => {
                        return data.value === 'true' ? 'Kyllä' : 'Ei'
                      }
                    },
                    {
                      Header: 'Kumppanituote',
                      accessor: 'provideritem',
                      valueFormatter: (data) => {
                        return data.value? 'Kyllä' : 'Ei'
                      }
                    },
                    {
                      Header: 'Muokkaa',
                      accessor: 'edit',
                      filterable: false,
                      sortable: false,
                      width: 100,
                      renderCell: (params) => {
                        return (
                          <EditButton
                            onClick={(el) => {
                              this.handleRowSelect(params.row.id)
                            }}
                          />
                        )
                      },
                    },
                    {
                      Header: 'Poista',
                      accessor: 'actions',
                      filterable: false,
                      sortable: false,
                      width: 100,
                      renderCell: (params) => {
                        return (
                          <DeleteButton
                            onClick={(el) => {
                              this.deleteMembership(params.row.id)
                            }}
                          />
                        )
                      },
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect)}
                  className='-striped -highlight primary-pagination'
                />
              </Col>
            </Row>
            {this.state.alert}
            {this.createMembershipModal()}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(MembershipsContainer)
