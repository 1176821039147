import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { withFirebase } from '../Fb'

import Form from '@rjsf/material-ui'

const schema = {
  type: "object",
  required: ["title"],
  properties: {
    title: {type: "string", title: "Title", default: "A new task"},
    done: {type: "boolean", title: "Done?", default: false}
  }
}


const uiSchema =  {
  done: {
    "ui:widget": "radio" // could also be "select"
  }
}

class ResourceModal extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: props.isOpen }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
        this.setState({ isOpen: this.props.isOpen })
    } else {
      // console.log('do nothing')
    }
  }

  onFormChange = (event) => {
    // console.log(event.formData)
  }

  render() { 
    return (
      <div>
      <Modal isOpen={this.state.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Resurssi</ModalHeader>
        <ModalBody>
            <Form schema={schema} uiSchema={uiSchema} onChange={this.onFormChange}>
            <Button color='success' onClick={this.onReservationSave}>
                    Tallenna
                  </Button>

            </Form>
            </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    )
  }
}
 
export default withFirebase(ResourceModal)