import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import algoliasearch from 'algoliasearch'
import { withFirebase } from '../Fb'

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f');
const algoliaindex = algoliaclient.initIndex('falcon_combined');


const isValidBusiness = (option) => {
  if (option.businessId) {
    if (!option.contacts) {
      return true
    }
  }
  return false
}

class CustomerSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: null,
      customers: [],
      loading: true,
    }
  }

  componentDidMount() {
  }

  clearAlgolia = (x) => { x.id = x.objectID; delete x._highlightResult; delete x.lastmodified; delete x.reservationAlert; delete x.objectID; return x }

  searchCombinedCustomers = (input) => {
    algoliaindex.search(input, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const persons = hits
        .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
        .map((x) => this.clearAlgolia(x) )
      const organisations = this.props.firebase.searchCombined(input).map((p) => p.item)
      const searchresult = persons.concat(organisations)
      this.setState({ customers: searchresult })
    })
  }

  // getCustomers = async () => {
  //   const customers = await getCustomers()
  //   this.setState({ customers, loading: false })
  // }

  handleOptionLabel = (option) => {
    if (option.businessId) {
      return option.name
    } else {
      return option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
    }
  }

  handleCustomerChange = (event, value) => {
    console.log('handleCustomerChange', value)
    this.setState({ customer: value }, this.props.onChange(value))
  }


  render() {
    return (
    <Autocomplete
      options={this.state.customers ? this.state.customers : []}
      getOptionLabel={this.handleOptionLabel} // {option => option.name}
      onChange={this.handleCustomerChange}
      getOptionDisabled={isValidBusiness}
      getOptionSelected={(option, val) => {
        return option.id === val.id
      }}
      value={
        this.state.customer
      }
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length > 2) {
          this.searchCombinedCustomers(newInputValue)
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant='standard'
            label='Valitse asiakas'
            placeholder='Asiakas'
            margin='normal'
            fullWidth
          />
        )
      }}
      renderOption={(option) => {
        let title = ''
        if (option.businessId) {
          title = option.name
        } else {
          title =
            option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
        }
        return (
          <Grid container alignItems='center'>
            <Grid item xs>
              <span>{title}</span>
              <Typography variant='body2' color='textSecondary'>
                {option.email}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
    )
  }


}

export default withFirebase(CustomerSelector)