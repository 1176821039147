import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import Infobox from '../Infos/Infobox'
import { withFirebase } from '../Fb'
import DeleteButton from '../Buttons/DeleteButton'

class ProshopNotifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      classes: [],
      loading: true,
      saved: true,
    }
  }

  componentDidMount() {
    this.props.firebase.getConfig('proshop').onSnapshot((doc) => {
      if (doc.exists) {
        this.setState({
          notificationemails: doc.data().notificationemails || [],
          loading: false,
        })
      } else {
        this.setState({
          notificationemails: [],
          loading: false,
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  setEmail = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.notificationemails
    _a[index] = event.target.value
    this.setState({ notificationemails: _a, saved: false })
  }

  addNotificationEmailRow = () => {
    let j = this.state.notificationemails
    j.push('')
    this.setState({ notificationemails: j })
  }

  deleteNotificationEmailRow = (index) => {
    let j = this.state.notificationemails
    j.splice(index, 1)
    this.setState({ notificationemails: j, saved: false })
  }

  onSave = async (event) => {
    event.preventDefault()
    const _rows = this.state.notificationemails.filter((r) => r && r !== '')
    this.props.firebase
      .addConfig('proshop', { notificationemails: _rows })
      .then(() => {
        this.setState({ saved: true })
      })
  }

  notificationEmailRows = () => {
    if (this.state.notificationemails) {
      return this.state.notificationemails.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col md='2' sm='2' xs='2'>
              <FormGroup>
                <Label for={'lbl.' + i}>Sähköpostiosoite</Label>
                <Input
                  type='text'
                  name={'email.' + i}
                  id={'email.' + i}
                  onChange={this.setEmail}
                  value={
                    this.state.notificationemails[i]
                      ? this.state.notificationemails[i]
                      : ''
                  }
                />
              </FormGroup>
            </Col>
            {this.state.notificationemails ? (
              <>
                <Col xs={1} style={{ paddingTop: 20, marginLeft: 40 }}>
                  <DeleteButton
                    onClick={() => this.deleteNotificationEmailRow(i)}
                  />
                </Col>
              </>
            ) : null}
          </Row>
        )
      })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>
                          Uusien tilauksien ilmoitussähköpostit
                        </CardTitle>
                      </Col>
                      <Col>
                        <Infobox index={0} />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.notificationEmailRows()}
                    <Row>
                      <Col style={{ marginTop: '20px' }}>
                        <Button
                          className='btn-round btn-icon'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.addNotificationEmailRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                        </Button>{' '}
                        Lisää osoite
                      </Col>
                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='6'>
                        <Button
                          disabled={this.state.saved}
                          color='primary'
                          onClick={this.onSave}
                        >
                          Tallenna osoitteet
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default withFirebase(ProshopNotifications)
