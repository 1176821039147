import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
// import './index.css';
import App from './App'
// import * as serviceWorker from './serviceWorker';
import { unregister } from './serviceWorker'
import Firebase, { FirebaseContext } from './components/Fb'
import { CookiesProvider } from 'react-cookie'

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}
}

const rootNode = document.getElementById('root')

ReactDOM.render(
  <StrictMode>
    <CookiesProvider>
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    </CookiesProvider>
  </StrictMode>,
  rootNode
)
unregister()
