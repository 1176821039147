// const _organisationMembershipClass = {
//   type: 'object',
//   properties: {
//     type: {
//       type: 'string',
//       title: 'Jäsenyys',
//       anyOf: [
//         {
//           type: 'string',
//           title: 'Varusmies',
//           enum: ['soldier'],
//         },
//         {
//           type: 'string',
//           title: 'Opiskelija',
//           enum: ['student'],
//         },
//       ],
//     },
//     memberid: {
//       type: 'string',
//       title: 'Jäsentunnus',
//     },
//     validuntil: {
//       type: 'string',
//       format: 'date',
//       title: 'Voimassa',
//     },
//   },
// }
const EINVOICE_OPERATORS = [
  {
    title: 'Ei operaattoria',
    value: null,
  },
  {
    title: '4US Oy (UTMOST)',
    value: 'UTMOST',
  },
  {
    title: 'Apix Messaging Oy (003723327487)',
    value: '003723327487',
  },
  {
    title: 'Basware Oyj (BAWCFI22)',
    value: 'BAWCFI22',
  },
  {
    title: 'CGI Suomi Oy (003703575029)',
    value: '003703575029',
  },
  {
    title: 'Comarch (5909000716438)',
    value: '5909000716438',
  },
  {
    title: 'Crediflow AB (CREDIFLOW)',
    value: 'CREDIFLOW',
  },
  {
    title: 'Dynatos (ROUTTY)',
    value: 'ROUTTY',
  },
  {
    title: 'HighJump AS (885790000000418)',
    value: '885790000000418',
  },
  {
    title: 'InExchange Factorum AB (INEXCHANGE)',
    value: 'INEXCHANGE',
  },
  {
    title: 'Lexmark Expert Systems AB (EXPSYS)',
    value: 'EXPSYS',
  },
  {
    title: 'Maventa (003721291126 tai MAVENTA)',
    value: '003721291126',
  },
  {
    title: 'Netbox Finland Oy (003726044706)',
    value: '003726044706',
  },
  {
    title: 'Opentext Oy (003708599126)',
    value: '003708599126',
  },
  {
    title: 'OpusCapita Solutions Oy (E204503)',
    value: 'E204503',
  },
  {
    title: 'Pagero Oy (003723609900 tai PAGERO)',
    value: '003723609900',
  },
  {
    title: 'Posti Messaging Oy (FI28768767)',
    value: 'FI28768767',
  },
  {
    title: 'PostNord Strålfors Oy (003701150617)',
    value: '003701150617',
  },
  {
    title: 'Ropo Capital Oy (003714377140)',
    value: '003714377140',
  },
  {
    title: 'Telia Finland Oyj (003703575029)',
    value: '003703575029',
  },
  {
    title: 'TietoEvry Oyj (003701011385)',
    value: '003701011385',
  },
  {
    title: 'Tradeshift Ab (885060259470028)',
    value: '885060259470028',
  },
  {
    title: 'Ålands Post Ab (003722207029)',
    value: '003722207029',
  },
  { value: 'HELSFIHH', title: 'Aktia (HELSFIHH)' },
  { value: 'DABAFIHH', title: 'Danske Bank (DABAFIHH)' },
  { value: 'DNBAFIHX', title: 'DNB (DNBAFIHX)' },
  { value: 'HANDFIHH', title: 'Handelsbanken (HANDFIHH)' },
  { value: 'NDEAFIHH', title: 'Nordea Pankki (NDEAFIHH)' },
  { value: 'ITELFIHH', title: 'Oma Säästöpankki Oyj (ITELFIHH)' },
  { value: 'OKOYFIHH', title: 'Osuuspankit ja Pohjola Pankki (OKOYFIHH)' },
  { value: 'POPFFI22', title: 'POP Pankki (POPFFI22)' },
  { value: 'SBANFIHH', title: 'S-Pankki (SBANFIHH)' },
  { value: 'TAPIFI22', title: 'S-Pankki (ent. LähiTapiola Pankin) (TAPIFI22)' },
  { value: 'ITELFIHH', title: 'Säästöpankit (ITELFIHH)' },
  { value: 'AABAFI22', title: 'Ålandsbanken (AABAFI22)' },
]
/*
UTMOST	4US Oy	
003723327487	Apix Messaging Oy	Apix Messaging ohjeet
BAWCFI22	Basware Oyj	Basware ohjeet
003703575029	CGI Suomi Oy	CGI Suomi ohjeet
5909000716438	Comarch	
CREDIFLOW	Crediflow AB	Crediflow ohjeet
ROUTTY	Dynatos	
885790000000418	HighJump AS	
INEXCHANGE	InExchange Factorum AB	InExchange Factorum ohjeet
EXPSYS	Lexmark Expert Systems AB	
003721291126 tai MAVENTA	Maventa	Maventa ohjeet
003726044706	Netbox Finland Oy	Netbox ohjeet
003708599126	Opentext Oy	Opentext ohjeet
E204503	OpusCapita Solutions Oy	OpusCapita Solutions ohjeet
003723609900 tai PAGERO	Pagero Oy	Pagero ohjeet
FI28768767	Posti Messaging Oy	Posti Messaging ohjeet
003701150617	PostNord Strålfors Oy	PostNord Strålfors ohjeet
003714377140	Ropo Capital Oy	Ropo Capital ohjeet
003703575029	Telia Finland Oyj	Telia Finland ohjeet
003701011385	TietoEvry Oyj	TietoEvry ohjeet
885060259470028	Tradeshift Ab	
003722207029	Ålands Post Ab	Ålands Post ohjeet
*/

export const _organisationClass = {
  name: { type: 'string', title: 'Nimi' },
  businessId: { type: 'string', title: 'Y-tunnus' },
  address: { type: 'string', title: 'Osoite' },
  city: { type: 'string', title: 'Kaupunki' },
  zipcode: { type: 'string', title: 'Postinumero' },
  phone: { type: 'string', title: 'Puhelin' },
  email: { type: 'string', title: 'Tiedotus email' },
  billingOvt: { type: 'string', title: 'OVT-tunnus' },
  billingOperator: {
    type:  ['string', 'null'],
    title: 'Operaattoritunnus',
    enum: EINVOICE_OPERATORS.map((i) => i.value),
    enumNames: EINVOICE_OPERATORS.map((i) => i.title),
  },
  billingEmail: { type: 'string', title: 'Lasku email' },
  billingReference: { type: 'string', title: 'Tilaajan viite' },
  // memberships: {
  //   type: 'array',
  //   items: _organisationMembershipClass,
  // },
}

export const _organisationUISchema = {
  phone: {
    'ui:placeholder': '+358...',
  },
}
