import React, { Component, createRef } from 'react'
import { withFirebase } from '../../components/Fb'
import algoliasearch from 'algoliasearch'
import { createNullCache } from '@algolia/cache-common'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { Paper, Popper, MenuItem, MenuList, ClickAwayListener } from '@material-ui/core'

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f', {
  responsesCache: createNullCache()
})
const algoliaindex = algoliaclient.initIndex('falcon_combined')

class PersonSearch extends Component {
  constructor(props) {
    super(props)
    this.state = { values: [], hits: null, menuopen: false }
    this.anchorEl = createRef()
  }

  updateAdornment = () => {
    this.setState({ startAdornment: this.getStartAdornment() })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.values !== this.props.values) {
      this.setState({ values: this.props.values }, this.updateAdornment)
    }
  }

  componentDidMount = () => {
    if (!this.props.values) return
    if (this.props.onlyone) {
      this.setState({ values: [this.props.values[0]] }, this.updateAdornment)
    } else {
      this.setState({ values: this.props.values }, this.updateAdornment)
    }
  }

  clearAlgolia = (x) => {
    x.id = x.objectID
    delete x._highlightResult
    delete x.lastmodified
    delete x.reservationAlert
    delete x.objectID
    return x
  }

  handleChange = (e) => {
    const search = e.target.value
    if (search.length > 2) {
      algoliaindex.search(search, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
        const searchresults = hits
          .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
          .map((x) => this.clearAlgolia(x))
        if (searchresults.length > 0) {
          this.setState({ search, hits: searchresults, menuopen: true })
        } else {
          this.setState({ search, hits: null, menuopen: false })
        }
      })
    } else {
      this.setState({ search, hits: null, menuopen: false })
    }
  }

  add = (option) => {
    const { values } = this.state
    const _values = values.filter((x) => x) 
    console.log('add', option, _values)
    if (_values.indexOf(option) > -1) return
    console.log('add1.5', _values)
    if (this.props.onlyone && _values.length > 1) return
    console.log('add2', _values)
    _values.push(option)
    console.log('add3', _values)
    this.setState({ values: _values, hits: null, search: '' } , (() => this.setState({ startAdornment: this.getStartAdornment() })))
    if (this.props.onChange) this.props.onChange(this.props.name, _values)
  }

  remove = (option) => {
    const { values } = this.state
    const index = values.indexOf(option)
    values.splice(index, 1)
    this.setState({ values, startAdornment: this.getStartAdornment() })
    if (this.props.onChange) this.props.onChange(this.props.name, values)
  }

  renderHit = (option) => {
    const title = option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
    return (<MenuItem key={option.objectID}  onClick={() => this.add(option)}>
        <Grid container alignItems='center'>
          <Grid item xs style={{ cursor: 'pointer' }}>
            <span>{title}</span>
            <Typography variant='body2' color='textSecondary'>
              {option.email}
            </Typography>
          </Grid>
        </Grid>
    </MenuItem>)
  }

  handleClose = () => {
    this.setState({ menuopen: false })
  }

  getStartAdornment = () => {
    if (!this.state.values) return null
    return (
      <div
        style={{
          display: 'flex',
          listStyle: 'none',
          margin: 0,
          flexDirection: this.props.variant === 'list' ? 'column' : 'row',
        }}
      >
        {this.state.values.map((data) => {
          if(!data) return null
          return (
            <li key={data.id}>
              <Chip
                label={data.firstname + ' ' + data.lastname}
                onDelete={() => this.remove(data)}
                style={{ margin: 2 }}
              />
            </li>
          )
        })}
      </div>
    )
  }

  render = () => {
    let inputProps = { startAdornment: this.state.startAdornment }
    if (this.props.variant === 'list') inputProps = {}
    return (
      <div>
        <div ref={this.anchorEl}>
          <TextField
            id='search'
            type='search'
            margin={this.props.margin || 'normal'}
            variant='standard'
            label={this.props.label || 'Lisää ohjaaja/ohjaajia'}
            placeholder={this.props.placeholder || 'Etsi'}
            value={this.state.search}
            onChange={this.handleChange}
            InputProps={inputProps}
            fullWidth={this.props.fullWidth || false}
          />
        </div>
        {this.props.variant === 'list' ? (<div style={{ marginTop: 10 }}>{this.state.startAdornment}</div>) : null}
        <Popper open={this.state.menuopen} anchorEl={this.anchorEl.current} role={undefined} placement={'bottom-start'} style={{ maxHeight: 300, zIndex: 9999 }}>
          <Paper style={{ maxHeight: 300 }}>
          <ClickAwayListener onClickAway={this.handleClose}>
            <MenuList id='menu-list-grow' style={{ maxHeight: 300, overflow: 'auto' }}>
              {this.state.hits && this.state.hits.map((hit) => this.renderHit(hit))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    )
  }
}

export default withFirebase(PersonSearch)
