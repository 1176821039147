import React from 'react'
// import DemoAlert from 'views/components/DemoAlert'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import Select from 'react-select'

const RESOURCE_TYPES = ['court', 'equipment', 'person', 'room']

class ResourceCategories extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      resourcecategories: [],
      loading: true,
      saved: true,
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase
      .getResourceCategories()
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.setState({
            resourcecategories: doc.data().categories,
            loading: false,
          })
        } else {
          this.setState({
            loading: false,
          })
        }
      })
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onChange = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.resourcecategories
    _a[index].enabled = event.state.value
    this.setState({ resourcecategories: _a, saved: false })
  }

  setCategory = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.resourcecategories
    _a[index].category = event.target.value
    this.setState({ resourcecategories: _a, saved: false })
  }

  setOrder = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.resourcecategories
    _a[index].order = parseInt(event.target.value)
    this.setState({ resourcecategories: _a, saved: false })
  }

  setType = (selection, action) => {
    let index = Number.parseInt(action.name.split('.')[1])
    let _a = this.state.resourcecategories
    let _s = selection.value
    _a[index].type = _s
    this.setState({ resourcecategories: _a, saved: false })
  }

  addResourceCategoryRow = () => {
    let j = this.state.resourcecategories
    j.push({ empty: true, enabled: true })
    this.setState({ resourcecategories: j })
  }

  onSave = (event) => {
    event.preventDefault()
    let _rows = this.state.resourcecategories.map((r) => {
      delete r.empty
      return r
    })
    this.props.firebase
      .addResourceCategories({
        categories: _rows,
      })
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  resourceCategoryRows = () => {
    if (this.state.resourcecategories) {
      return this.state.resourcecategories.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col sm='2' md='2' xs='2'>
              <FormGroup>
                <Label for={'label.' + i}>Tyyppi</Label>
                <Select
                  className='react-select primary'
                  classNamePrefix='react-select'
                  name={'label.' + i}
                  id={'label.' + i}
                  value={
                    (this.state.resourcecategories[i].type
                      ? { value: this.state.resourcecategories[i].type, label: this.state.resourcecategories[i].type }
                      : '')
                  }
                  defaultValue={RESOURCE_TYPES[0]}
                  onChange={this.setType}
                  options={RESOURCE_TYPES.map((rc) => ({
                    value: rc,
                    label: rc,
                  }))}
                />
              </FormGroup>
            </Col>
            <Col md='3' sm='3' xs='3'>
              <FormGroup>
                <Label for={'nbr.' + i}>Kategoria (esim. kentän urheilulaji)</Label>
                <Input
                  type='text'
                  name={'nbr.' + i}
                  id={'nbr.' + i}
                  onChange={this.setCategory}
                  placeholder=''
                  value={
                    this.state.resourcecategories[i].category
                      ? this.state.resourcecategories[i].category
                      : ''
                  }
                  invalid={!this.state.resourcecategories[i].category || this.state.resourcecategories[i].category.length < 3}
                  disabled={
                    this.state.resourcecategories[i].category &&
                    !this.state.resourcecategories[i].empty
                  }
                />
              </FormGroup>
            </Col>
            <Col md='2' sm='3' xs='3'>
              <FormGroup>
                <Label for={'order.' + i}>Järjestys ylläpidon käyttöliittymässä</Label>
                <Input
                  type='number'
                  name={'order.' + i}
                  id={'order.' + i}
                  onChange={this.setOrder}
                  placeholder=''
                  value={
                    this.state.resourcecategories[i].order
                      ? this.state.resourcecategories[i].order
                      : ''
                  }
                />
              </FormGroup>
            </Col>
            <Col>
              <Label for={'enabled.' + i}>Käytössä</Label>{' '}
              <div name={'enabled.' + i}>
                <Switch
                  defaultValue={this.state.resourcecategories[i].enabled}
                  onColor='primary'
                  offColor='primary'
                  id={'enabled.' + i}
                  name={'enabled.' + i}
                  onChange={this.onChange}
                />
              </div>
            </Col>
          </Row>
        )
      })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Resurssikategoriat</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.resourceCategoryRows()}
                    <Row>
                      <Col style={{ marginTop: '20px' }}>
                        <Button
                          className='btn-round btn-icon'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.addResourceCategoryRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                        </Button>{' '}
                        Lisää resurssikategoria
                      </Col>
                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button
                          disabled={this.state.saved || this.state.resourcecategories.some((r) => !r.category || r.category.length < 3)}
                          color='primary'
                          onClick={this.onSave}
                        >
                          Tallenna resurssikategoriat
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default ResourceCategories
