import React from 'react'
import { Button } from 'reactstrap'
import SyncAltIcon from '@material-ui/icons/SyncAlt'

function MoveButton(props) {
  const { onClick } = props
  return (
    <div className='actions-right'>
      <Button color='warning' size='sm' onClick={onClick}>
        <SyncAltIcon fontSize={'inherit'} />
        {' Siirrä'}
      </Button>
    </div>
  )
}

export default MoveButton