// import React from 'react'

export const _providerClass = {
  name: { type: 'string', title: 'Nimi' },
  description: { type: 'string', title: 'Kuvaus' },
  businessId: { type: 'string', title: 'Y-tunnus' },
  contactname: { type: 'string', title: 'Kontaktin nimi', readOnly: false },
  contactemail: { type: 'string', title: 'Kontaktin email', readOnly: false },
  contactphone: { type: 'string', title: 'Kontaktin puhelin', readOnly: false },
  logo: { type: 'string', title: 'Logo, neliön mallinen', items: { type: 'object', images: [] }, minaspectratio: 1 },
  code: { type: 'string', title: 'tunnus', readOnly: true },
  paytrail: { type: 'string', title: 'Paytrail MerchantID', readOnly: true },
  address: { type: 'string', title: 'Osoite', readOnly: false },
  zip: { type: 'string', title: 'Postinumero', readOnly: false },
  city: { type: 'string', title: 'Kaupunki', readOnly: false },
}

export const _providerClassDeps = {}

export const _providerUISchema = {
  logo: {
    'ui:widget': 'imageWidget',
  },
}
