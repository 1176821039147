import React from 'react'
import infoimage from '../../assets/img/Tiketit-info.png'
import { Paper, Typography, Button, CircularProgress } from '@material-ui/core'

const PRICE = 5

function ProshopInfo({ onSubmit, saving }) {
  return (
    <div>
      <Typography variant='h4'>Web Proshop</Typography>
      <Paper style={{ margin: 20, padding: 20 }}>
        <Typography
          variant='body1'
          style={{ marginBottom: 25 }}
          component={'p'}
        >
          Proshopin ensimmäinen vaihe on valmis. Voit ottaa sen käyttöön tästä.
          Proshop toimii tässä vaiheessa tuotekatalogina, jossa voit lisätä ja muokata tuotteita.
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: 25 }}
          component={'p'}
        >
          Asiakkaat eivät voi maksaa tuotteita netissä. He vouivat kuitenkin varata tuotteita ja noutaa ne myymälästä. He voivat myös tehdä tuotteista tiedustelun.
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: 25 }}
          component={'p'}
        >
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: 30 }}
          component={'p'}
        >
          Voit ottaa ominaisuuden käyttöön tästä. Ensimmäinen kaksi viikkoa on
          ilmainen testijakso. Tämän jälkeen ominainuus maksaa {PRICE}€/kk. Voit
          lopettaa palvelun milloin tahansa.
        </Typography>
        <Button
          disabled={saving}
          variant='contained'
          color='primary'
          onClick={() => onSubmit(PRICE)}
        >
          {saving ? <CircularProgress size={25} color='info' /> : 'Ota käyttöön'}
        </Button>
      </Paper>
      {/* <img src={infoimage} alt='Proshop-info' /> */}
    </div>
  )
}

export default ProshopInfo
