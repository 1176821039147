import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input, Spinner } from 'reactstrap'

// core components
// import { filterCaseInsensitive } from '../../components/FalconTable'
import FalconModal from '../../components/Inputs/FalconModal'
import { getPersonSchema, getPersonUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import PersonDetails from '../../components/Details/PersonDetails2'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIcon from '@material-ui/icons/Phone'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import SearchIcon from '@material-ui/icons/Search'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
// import { DataGrid } from '@material-ui/data-grid'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
// import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// import TablePagination from '@material-ui/core/TablePagination'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
// import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
// import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
// import Alert from '@material-ui/lab/Alert'
import moment from 'moment-timezone'

import algoliasearch from 'algoliasearch'
import { createNullCache } from '@algolia/cache-common'
import ReservationModal from '../../components/Inputs/ReservationModal'
import Infobox from '../../components/Infos/Infobox'

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f',
{
  responsesCache: createNullCache()
})
const algoliaindex = algoliaclient.initIndex('falcon_combined')

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CustomerPersonBoxContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userModalOpen: false,
      selected: null,
      messaging: { isOpen: false },
      personSelected: false,
      passwordResetModalOpen: false,
      passwordResetLink: 'Ladataan ...',
      searchInput: '',
      showSearchIcon: true,
      pressedEnter: false,
      searchpersons: [],
      rowsPerPage: 10,
      page: 0,
      newemail: null,
      sending: false,
      searchDone: false,
      reservationModalOpen: false,
      selectedPersonIndex: 0
    }
  }

  // componentDidMount() {
  //   this.searchPersons()
  // }

  // handleRowSelect = (e) => {
  handleRowSelect = (id) => {
    // console.log('row', id)
    const person = this.state.searchpersons.find((r) => r.id === id)
    // this.setState({ personSelected: !this.state.personSelected, selectedResource: person }, this.toggleUserModal)
    this.setState({ personSelected: true, selectedResource: person })
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      // let user = await this.state.raw[i].user.get()
      // let _user = user.data()
      let _user = this.state.raw[i]
      if (_user.name) {
        _user.lastname = _user.name
      }
      if (!_user.customerdata) {
        _user.customerdata = {}
      }
      if (!_user.customerdata.hasOwnProperty('admincreated')) {
        if (_user.userdata) {
          _user.customerdata.admincreated = false
        } else {
          _user.customerdata.admincreated = true
        }
      }
      const isEditable = _user.customerdata.hasOwnProperty('admincreated') ? _user.customerdata.admincreated : false
      if (_user.userdata) {
        delete _user.customerdata.user
        data.push({
          ..._user.customerdata,
          ..._user.userdata,
          editable: isEditable,
          adminedit: isEditable ? 'Kyllä' : 'Ei',
          id: _user.id,
          actions: this.getActions(_user.userdata, _user.id),
        })
      } else {
        delete _user.customerdata.user
        data.push({
          ..._user.customerdata,
          editable: true,
          id: _user.id,
          adminedit: isEditable ? 'Kyllä' : 'Ei',
          actions: this.getActions(_user.customerdata, _user.id),
        })
      }
    }
    this.props.firebase.updatePersonCache(data)
    this.setState({ loading: false, persons: data })
  }

  addNewUser = () => {
    this.setState({ selectedResource: {} }, this.toggleUserModal)
  }

  toggleUserModal = () => {
    if (!this.state.messaging.isOpen) {
      this.setState({
        userModalOpen: !this.state.userModalOpen,
      })
    }
  }

  validateInputs = (data) => {
    if (data.phone && data.phone.length > 1) {
      if (!data.phone.startsWith('+')) {
        return Promise.reject({ error: 'Puhelin pitää antaa +358 muodossa' })
      } else {
        const _search = this.props.firebase.searchPerson(data.phone).map((p) => p.item)
        const match = _search.find((x) => x.phone === data.phone)
        if (match) {
          return Promise.reject({ error: 'Käyttäjä tällä puhelinnumerolla on jo olemassa', person: match })
        }
      }
    }
    if (data.email) {
      const _search = this.props.firebase.searchPerson(data.email).map((p) => p.item)
      const match = _search.find((x) => x.email === data.email)
      if (match) {
        return Promise.reject({ error: 'Käyttäjä tällä email osoitteella on jo olemassa', person: match })
      }
    }
    return Promise.resolve('OK')
  }

  createPersonEditModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.updatePerson}
        onCreate={this.props.firebase.registerPerson}
        toggle={this.toggleUserModal}
        resource={this.state.selectedResource}
        isOpen={this.state.userModalOpen}
        uiSchema={getPersonUiSchema()}
        dataSchema={getPersonSchema('USER', [])}
        validate={this.validateInputs}
        title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa henkilöä' : 'Uusi henkilö'}
      />
    )
  }

  closePersonDetails = () => {
    this.setState({ selectedResource: null, personSelected: false })
  }

  closeMessageModal = () => {
    let _m = this.state.messaging
    _m.isOpen = false
    this.setState({ messaging: _m, passwordResetLink: 'Ladataan...', sending: false })
  }

  openMessageModal = (type, id) => {
    let _m = this.state.messaging
    _m.isOpen = !_m.isOpen
    _m.type = type
    if (type === 'passwordreset') {
      this.generatePasswordReset(id)
    }
    const person = this.state.searchpersons.find((r) => r.id === id)
    this.setState({ messaging: _m, userModalOpen: false, selectedResource: person })
  }

  generatePasswordReset = async (email) => {
    // console.log(email, ' to reset')
    let response = await this.props.firebase.getPasswordResetLink(email)
    // console.log(response)
    if (response.data.error) {
      this.setState({ passwordResetLink: response.data.error })
    } else {
      this.setState({ passwordResetLink: response.data.link })
    }
  }

  setNewEmail = (event) => {
    const newemail = event.target.value
    this.setState({ newemail })
  }

  setMessagebody = (event) => {
    let _m = this.state.messaging
    _m.body = event.target.value
    this.setState({ messaging: _m })
  }

  setMessageSubject = (event) => {
    let _m = this.state.messaging
    _m.subject = event.target.value
    if (_m.type === 'notification') {
      _m.toolong = event.target.value.length > 80
    }
    this.setState({ messaging: _m })
  }

  saveMessage = () => {
    let _m = this.state.messaging
    _m.to = this.state.selectedResource.id
    this.props.firebase.createMessage(_m).then((message) => {
      this.closeMessageModal()
    })
  }

  sendMessage = () => {
    this.setState({ sending: true })
    this.props.firebase.sendMessage(this.state.messaging.body, this.state.messaging.subject, this.state.selectedResource.email, this.state.selectedResource.id, ['usersearch']).then((response) => {
      // console.log(response)
      this.closeMessageModal()
    })
  }

  sendConfirmation = () => {
    this.setState({ sending: true })
    this.props.firebase.sendConfirmation(this.state.newemail, this.state.selectedResource.id, 'fi').then((response) => {
      // console.log(response)
      this.closeMessageModal()
    })
  }

  createMessageModal = () => {
    let user = this.state.selectedResource
    let phone = user && user.phone ? user.phone : ''
    if (this.state.messaging.type === 'call') {
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Soita</ModalHeader>
          <ModalBody>
            <a href={'tel://' + phone}>{phone}</a>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.closeMessageModal}>
              Sulje
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else if (this.state.messaging.type === 'notification') {
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Lähetä notifikaatio appsiin</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for='notificationmessagebody'>Notifikaatio osa (80 merkkiä)</Label>
                <Input
                  invalid={this.state.messaging.toolong}
                  type='textarea'
                  name='notificationmessagebody'
                  id='notificationmessagebody'
                  onChange={this.setMessageSubject}
                />
              </FormGroup>
              <FormGroup>
                <Label for='notificationmessagebody'>Viesti (näkyy käyttäjälle appsissa)</Label>
                <Input
                  type='textarea'
                  name='notificationmessagebody'
                  id='notificationmessagebody'
                  onChange={this.setMessagebody}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.sendMessage}>
              {this.state.sending ? <Spinner size='sm' /> : 'Lähetä'}
            </Button>{' '}
            <Button color='secondary' onClick={this.closeMessageModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else if (this.state.messaging.type === 'email') {
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Lähetä sähköposti</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for='emailsubject'>Otsikko</Label>
                <Input type='text' name='emailsubject' id='emailsubject' onChange={this.setMessageSubject} />
              </FormGroup>
              <FormGroup>
                <Label for='emailbody'>Viesti</Label>
                <Input type='textarea' name='emailbody' id='emailbody' onChange={this.setMessagebody} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.sendMessage}>
            {this.state.sending ? <Spinner size='sm' /> : 'Lähetä'}
            </Button>{' '}
            <Button color='secondary' onClick={this.closeMessageModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else if (this.state.messaging.type === 'passwordreset') {
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Salasanan nollaus</ModalHeader>
          <ModalBody>
            <code>{this.state.passwordResetLink}</code>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.closeMessageModal}>
              Sulje
            </Button>
          </ModalFooter>
        </Modal>
      )
  } else if (this.state.messaging.type === 'emailchange') {
    return (
      <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
        <ModalHeader toggle={this.closeMessageModal}>Sähköpostin vaihto</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for='newemail'>Uusi email</Label>
              <Input type='text' name='newemail' id='newemail' onChange={this.setNewEmail} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalBody>
          Käyttäjälle lähtee uuteen sähköpostiin viesti jossa on vahvistuskoodi jolla hän voi vahvistaa uuden sähköpostiosoitteen.<br/><br/>
          Vahvistaminen<br/><br/>
          * Käyttäjä kirjautuu vanhalla osoitteella varausjärjestelmään<br/>
          * Omat tiedot kohdassa on nappi vahvistuskoodi josta painamalla pääsee syöttämään vahvistuksen<br/>
          * Käyttäjän sähköposti ei muutu mikäli vahvistuskoodia ei syötetä<br/>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={this.sendConfirmation}>
            {this.state.sending ? <Spinner size='sm' /> : 'Lähetä vahvistusviesti'}
          </Button>{' '}
          <Button color='secondary' onClick={this.closeMessageModal}>
            Peru
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  }

  getActions = (row, id) => {
    if (!id) id = row.id
    // console.log(id)
    // console.log(row)
    return (
      <>
        <div className='actions-right'>
          {row.admincreated ? (
            <></>
          ) : (
              <>
            <Button
              color='info'
              size='sm'
              onClick={(el) => {
                this.openMessageModal('emailchange', id)
              }}
            >
              <span className='btn-label'>
                <AlternateEmailIcon fontSize='small' />
              </span>
            </Button>
            <Button
              color='success'
              size='sm'
              onClick={(el) => {
                this.openMessageModal('passwordreset', row.email)
              }}
            >
              <span className='btn-label'>
                <VpnKeyIcon fontSize='small' />
              </span>
                </Button>
                </>
          )}
          {row.phone ? (
            <Button
              color='secondary'
              size='sm'
              onClick={(el) => {
                this.openMessageModal('call', id)
              }}
            >
              <span className='btn-label'>
                <PhoneIcon fontSize='small' />
              </span>
            </Button>
          ) : (
            <></>
          )}
          {row.pushtoken ? (
            <Button
              color='primary'
              size='sm'
              onClick={(el) => {
                this.openMessageModal('notification', id)
              }}
            >
              <span className='btn-label'>
                <AnnouncementIcon fontSize='small' />
              </span>
            </Button>
          ) : (
            <></>
          )}
          {row.email ? (
            <Button
              color='warning'
              size='sm'
              onClick={(el) => {
                this.openMessageModal('email', id)
              }}
            >
              <span className='btn-label'>
               <MailOutlineIcon fontSize='small' />
              </span>
            </Button>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  }

  handleSearchChange = (event) => {
    this.setState({ searchInput: event.target.value, pressedEnter: false })
  }

  clearAlgolia = (x) => {
    x.id = x.objectID
    delete x._highlightResult
    delete x.lastmodified
    delete x.reservationAlert
    delete x.objectID
    return x
  }

  searchPersons = () => {
    algoliaindex.search(this.state.searchInput, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const persons = hits
        .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
        .map((x) => this.clearAlgolia(x))
        .map((x) => {
          return { ...x, actions: this.getActions(x) }
        })
      this.setState({ searchpersons: persons, page: 0, pressedEnter: true, searchDone: true })
    })
  }

  keyPress = (e) => {
    if (e.keyCode === 13 && !this.state.showSearchIcon) {
      this.searchPersons()
    }
  }

  handleOnFocusSearch = () => {
    this.setState({ showSearchIcon: !this.state.showSearchIcon })
  }

  handleChangePage = (event, newPage) => {
    // console.log(event.target, newPage)
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    // console.log(event.target)
    this.setState({ rowsPerPage: +event.target.value, page: 0 })
  }

  renderRow = (row) => {
    return (
      <React.Fragment>
        <ListItem button alignItems="flex-start" style={{ width: '100%', backgroundColor: 'white' }}
          onClick={() => this.handleRowSelect(row.id)}
          selected={this.state.selectedPersonIndex === row.id}
        >
        <ListItemText
          primary={row.firstname + ' ' + row.lastname}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {row.email}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider />
      </React.Fragment>
    )
  }

  renderRowTable = (row) => {
    return (
      <React.Fragment key={'actions-' + row.id}>
        <TableRow style={{ maxHeight: 30 }}>
          <TableCell>{row.firstname + ' ' + row.lastname}</TableCell>
          <TableCell style={{ wordWrap: 'break-word' }} align='right'>
            {row.email}
          </TableCell>
          {/* <TableCell align='right'>{row.phone}</TableCell> */}
          {/* <TableCell align='right'>{row.actions}</TableCell> */}
          {/* <TableCell align='right'>
            <IconButton aria-label='expand row' size='medium' onClick={() => this.handleRowSelect(row.id)}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </TableCell> */}
        </TableRow>
      </React.Fragment>
    )
  }

  openReservationModal = async (id, occurency) => {
    const reservation = await this.props.firebase.getReservation(id)
    const start = moment.utc(reservation.data().resourcegroups[0].start)
    const end = moment.utc(reservation.data().resourcegroups[0].end)
    const selectedReservation = {
      id: reservation.id,
      start: moment.utc(occurency.date).hour(start.hour()).minutes(start.minutes()).format(),
      end: moment.utc(occurency.date).hour(end.hour()).minutes(end.minutes()).format(),
    }
    selectedReservation.data = { ...reservation.data(), id: reservation.id }
    // console.log('selectedReservation', selectedReservation)
    this.setState({ selectedReservationResource: selectedReservation }, this.toggleReservationModal)
    // this.setState({ reservationModalOpen: true, selectedReservationResource: resource })
  }

  toggleReservationModal = () => {
    this.setState({ reservationModalOpen: !this.state.reservationModalOpen })
  }

  render() {
    return (
      <React.Fragment>
        <div className='content'>
          <Row className='border-bottom pb-3 pt-2'>
            <Col xl='4' md='6' sm='8'> {/* Search input */}
            <FormControl fullWidth style={{ backgroundColor: 'white' }}>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={this.state.searchInput}
                  onChange={this.handleSearchChange}
                  startAdornment={
                    this.state.showSearch || this.state.searchInput.length === 0 ? (
                      <InputAdornment position='start'>
                        <SearchIcon color='disabled' />
                      </InputAdornment>
                    ) : null
                  }
                  labelWidth={0}
                  onFocus={this.handleOnFocusSearch}
                  onBlur={this.handleOnFocusSearch}
                  onKeyDown={this.keyPress}
                  endAdornment={
                    !this.state.pressedEnter && this.state.searchInput.length > 2 ? (
                      <InputAdornment onClick={() => this.searchPersons()} position='end'>
                        <KeyboardReturnIcon color='disabled' />
                      </InputAdornment>
                    ) : null
                  }
                />
              </FormControl>
            </Col>
            <Col xl='6' md='4' sm='2' >
              <Infobox index={'0'} />
            </Col>
            <Col xl='2' md='2' sm='2' className='text-right'> 
              <Button onClick={this.addNewUser}>Lisää henkilö</Button>
            </Col>
          </Row>
          <Row className='no-gutters'>
            <Col md='3' className='border-right no-gutters' style={{ minHeight: '100vh' }}> 
            <List component="nav" style={{ padding: 0, maxHeight: '80vh', position: 'relative', overflow: 'auto' }}>
              {this.state.searchpersons.length > 0 ? 
                  this.state.searchpersons.map((row) => this.renderRow(row)) : (this.state.searchDone ? 'Ei löytynyt asiakasta' : 'Hae asiakas nimellä tai sähköpostilla')}
                </List>
            </Col>
            <Col md='9'>
            {this.state.selectedResource ? <PersonDetails person={this.state.selectedResource} {...this.props} onReservationSelect={this.openReservationModal} /> : ''}
            </Col>
          </Row>


          {this.createPersonEditModal()}
          {this.createMessageModal()}

          <Dialog
            fullWidth={true}
            // fullScreen
            maxWidth={'lg'}
            // open={this.state.personSelected}
            onClose={this.closePersonDetails}
            aria-labelledby='max-width-dialog-title'
            style={{ zIndex: '1050' }}
            TransitionComponent={Transition}
            hidden={this.state.reservationModalOpen}
          >
            <DialogTitle id='max-width-dialog-title'>{this.state.selectedResource ? this.state.selectedResource.firstname + ' ' + this.state.selectedResource.lastname + ' ' + this.state.selectedResource.email : ''}</DialogTitle>
            <DialogContent>
              <PersonDetails person={this.state.selectedResource} {...this.props} onReservationSelect={this.openReservationModal} />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closePersonDetails} color='primary'>
                Sulje
              </Button>
            </DialogActions>
          </Dialog>
          <ReservationModal
            isOpen={this.state.reservationModalOpen}
            toggle={this.toggleReservationModal}
            resource={this.state.selectedReservationResource}
            tags={null}
            {...this.props}
          />       
        </div>
      </React.Fragment>
    )
  }
}

export default withFirebase(CustomerPersonBoxContainer)
