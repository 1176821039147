import React, { Component } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  FormGroup,
  Col,
  Spinner,
  Row,
} from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';

import algoliasearch from 'algoliasearch'

import { withFirebase } from '../Fb'

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f');
const algoliaindex = algoliaclient.initIndex('falcon_combined');

class OrganisationModal extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: props.isOpen, searchResult: '', businessId: null, resource: props.resource }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    } else if (prevProps.resource !== this.props.resource) {
      if (this.props.resource) {
        if(!this.props.resource.contacts) this.props.resource.contacts = []
      }
      this.setState({ resource: this.props.resource })
      
    }
  }

  handleBusinessIdChange = (event) => {
    this.setState({ businessId: event.target.value })
  }
  handleBusinessNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  clearAlgolia = (x) => { x.id = x.objectID; delete x._highlightResult; delete x.lastmodified; delete x.reservationAlert; delete x.objectID; return x }
  searchPerson = (input) => {
    algoliaindex.search(input, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const persons = hits.map((x) => this.clearAlgolia(x))
      const _p = []
      _p.push(...persons)
      this.setState({ searchpersons: _p })
    })
  }


  businessIdInput = () => {
    return (
      <Form>
        <FormGroup row>
          <Label for='businessId' sm={2}>
            Y-Tunnus
          </Label>
          <Col sm={10}>
            <Input
              type='text'
              name='businessId'
              id='businessId'
              placeholder='XXXXXXXXXX-Y'
              onChange={this.handleBusinessIdChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='businessname' sm={2}>Nimi
          </Label>
          <Col sm={10}>
            <Input
              type='text'
              name='businessname'
              id='businessname'
              placeholder='nimen alkuosa'
              onChange={this.handleBusinessNameChange}
            />
          </Col>
        </FormGroup>
      </Form>
    )
  }

  handleContact = (event, value) => {
    // console.log(event, value)
    let _r = { ...this.state.resource }
    if (_r && value) {
      _r.contacts = value
      this.setState({ resource: _r })
    }
  }

  handleOptionLabel = (opt) => {
    return opt.firstname && opt.lastname ? opt.firstname + ' ' + opt.lastname : ''
  }

  renderContact = () => {
    // console.log(this.state.searchpersons)
    return (
      <Autocomplete
        multiple
        options={this.state.searchpersons ? this.state.searchpersons : []}
        getOptionLabel={this.handleOptionLabel}
        onChange={this.handleContact}
        getOptionSelected={(option, val) => {
          return option.id === val.id
        }}
        value={this.state?.resource?.contacts ? this.state.resource.contacts : []}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.length > 2) {
            this.searchPerson(newInputValue)
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant='standard'
              label='Lisää yhteyshenkilö'
              placeholder='Yhteyshenkilöt'
              margin='normal'
              fullWidth
            />
          )
        }
        }
        renderOption={(option) => {
          let title = option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
          return (
            <Grid container alignItems='center'>
              <Grid item xs>
                <span>{title}</span>
                <Typography variant='body2' color='textSecondary'>
                  {option.email}
                </Typography>
              </Grid>
            </Grid>
          )
        }}
      />
    )
  }

  findBusiness = async () => {
    this.setState({ searchResult: <Spinner /> })
    const business = await this.props.firebase.findOrganisationFromPrh(this.state.businessId, this.state.name)
    // console.log(business.data)
    if (!business?.data?.empty) {
      const result = business.data.map((business, index) => {
        return (<Row key={index}>
          <Col>{business.name}</Col>
          <Col>
            <Button color='primary' onClick={() => this.storeBusiness(business.businessId)}>
              Tallenna
            </Button>
          </Col>
        </Row>)
      })

      this.setState({ searchResult: result, search: business.data, valid: true })
    } else {
      const result = <div>{'Ei tuloksia'}</div>
      this.setState({ searchResult: result, valid: false })
    }
  }

  storeBusiness = async (businessId) => {
    const business = await this.props.firebase.getOrganisationFromPrhById(businessId)
    if (business) {
      await this.props.firebase.registerOrganisation(business.data)
      this.setState({ searchResult: <div>Tallennettu</div>, valid: false, businessId: '', name: '' })
    } else {
      this.setState({ searchResult: <div>Tallentaminen ei onnistu</div>, valid: false, businessId: '', name: '' })
    }
  }

  storeContacts = async () => {
    await this.props.firebase.storeContacts({ contacts: this.state.resource.contacts }, this.state.resource.id)
    this.setState({ searchResult: <></>, valid: false, businessId: '' }, this.props.toggle)
  }


  render() {
    return (
      <div>
        <Modal isOpen={this.state.isOpen} toggle={this.props.toggle}>
          <ModalHeader toggle={this.props.toggle}>Lisää organisaatio / yritys</ModalHeader>
          <ModalBody>
            {!this.props?.resource?.id ? this.businessIdInput() : this.renderContact()}
            {this.state.searchResult}
          </ModalBody>
          <ModalFooter>
          {this.props?.resource?.id ?
            (<Button color='primary' onClick={this.storeContacts}>Tallenna</Button>)
            :
            (<Button color='primary' onClick={this.findBusiness}>Etsi</Button>)
          }
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default withFirebase(OrganisationModal)
