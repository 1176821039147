import React from 'react'
import infoimage from '../../assets/img/Tiketit-info.png'
import { Paper, Typography, Button, CircularProgress } from '@material-ui/core'

const PRICE = 7

function MaintenanceInfo({ onSubmit, saving }) {
  return (
    <div>
      <Typography variant='h4'>Kiinteistöhuollon tiketit</Typography>
      <Paper style={{ margin: 20, padding: 20 }}>
        <Typography
          variant='body1'
          style={{ marginBottom: 25 }}
          component={'p'}
        >
          Kiinteistöhuollon tiketeillä pysyt ajan tasalla kiinteistösi
          huoltotoimenpiteistä. Asiakkaat voivat, niin halutessasi jättää
          tiketin, joka ohjautuu suoraan huoltohenkilöstölle. Voit myös käyttää
          tikettejä vain henkilökunnan tekemiin tiketteihin.
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: 25 }}
          component={'p'}
        >
          Tiketit voidaan luokitella ja priorisoida, jolloin kiireellisimmät
          tiketit saadaan hoidettua ensin. Asiakkaat voivat laittaa paikan missä
          vika on ja lisätä viestin sekä kuvan vikapaikasta. Huoltohenkilöstö
          voi lisätä kommentteja sekä merkitä tiketin tilan käsittelyyn,
          valmiiksi tai sulkea sen. Tiketit voidaan myös luokitella eri luokkiin
          tilastointia varten.
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: 25 }}
          component={'p'}
        >
          Asiakkaan käyttöliittymä on helppokäyttöinen ja selkeä.
          Varausjärjestelmän valikkoon voi halutessaan julkaista linkin josta
          pääsee suoraan ilmoituslomakkeelle. Palvelusta saa QR-koodin, jonka
          voi tulosta "Kerro ongelmasta" -ilmoitukseen. Jolloin asiakkaat voivat
          sen puhelimella skannaamalla päästä suoraan ilmoituslomakkeelle.
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: 30 }}
          component={'p'}
        >
          Voit ottaa ominaisuuden käyttöön tästä. Ensimmäinen kaksi viikkoa on
          ilmainen testijakso. Tämän jälkeen ominainuus maksaa {PRICE}€/kk. Voit
          lopettaa palvelun milloin tahansa.
        </Typography>
        <Button
          disabled={saving}
          variant='contained'
          color='primary'
          onClick={() => onSubmit(PRICE)}
        >
          {saving ? <CircularProgress size={25} color='info' /> : 'Ota käyttöön'}
        </Button>
      </Paper>
      <img src={infoimage} alt='Tiketit-info' />
    </div>
  )
}

export default MaintenanceInfo
