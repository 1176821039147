import React from 'react'
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap'
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core'

class Memberships extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      times: null,
      loading: true,
      saving: false,
      config: {
        availableforcustomers: false,
      },
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.getConfig('membership').onSnapshot((doc) => {
      if (doc.exists) {
        this.setState({
          config: doc.data(),
          loading: false,
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onCustomerviewChange = async (event) => {
    this.setState({ saving: true })
    await this.props.firebase.addConfig('membership', { availableforcustomers: event.target.checked })
    this.setState({ saving: false })
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' md='5' sm='5'>
                  <CardTitle tag='h5'>Myytävät jäsenyydet</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.saving ? (
                <p>Tallennetaan...</p>
              ) : (
                  <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.config.availableforcustomers}
                      onChange={this.onCustomerviewChange}
                      name='0'
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox controlled' }}
                    />
                  }
                  label='Näkyvissä asiakkaille'
                  />
                  </FormGroup>
              )}
            </CardBody>
          </Card>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default Memberships
