import * as ACData from 'adaptivecards-templating'
import * as AdaptiveCards from 'adaptivecards'
import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'reactstrap'

const ACTION_OPEN_URL = 'Action.OpenUrl'
const ACTION_SHOW_CARD = 'Action.ShowCard'
const ACTION_SUBMIT = 'Action.Submit'

/*eslint no-template-curly-in-string: "off"*/
const reservation = {
  "type": "AdaptiveCard",
  "body": [
      {
          "type": "TextBlock",
          "size": "Medium",
          "weight": "Bolder",
          "text": "${title}",
          "wrap": true
      },
      {
          "type": "ColumnSet",
          "columns": [
              {
                  "type": "Column",
                  "items": [
                      {
                          "type": "Image",
                          "style": "Person",
                          "url": "${creator.profileImage}",
                          "size": "Small"
                      }
                  ],
                  "width": "auto"
              },
              {
                  "type": "Column",
                  "items": [
                      {
                          "type": "TextBlock",
                          "weight": "Bolder",
                          "text": "${creator.name}",
                          "wrap": true
                      },
                      {
                          "type": "TextBlock",
                          "spacing": "None",
                          "text": "{{DATE(${string(createdUtc)}, SHORT)}}",
                          "isSubtle": true,
                          "wrap": true
                      }
                  ],
                  "width": "stretch"
              }
          ]
      },
      {
          "type": "TextBlock",
          "text": "${description}",
          "wrap": true
      },
      {
          "type": "FactSet",
          "facts": [
              {
                  "$data": "${properties}",
                  "title": "${key}:",
                  "value": "${value}"
              }
          ]
      }
  ],
  "actions": [
      {
          "type": "Action.Submit",
          "title": "Näytä",
          "url": "${viewUrl}"
      }
  ],
  "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
  "version": "1.0"
}



const compare = (a, b) => {
  if (a === b) return true
  // if both a and b are null or undefined and exactly the same

  if (!(a instanceof Object) || !(b instanceof Object)) return false
  // if they are not strictly equal, they both need to be Objects

  if (a.constructor !== b.constructor) return false
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in a) {
    if (!a.hasOwnProperty(p)) continue
    // other properties were tested using a.constructor === b.constructor

    if (!b.hasOwnProperty(p)) return false
    // allows to compare a[ p ] and b[ p ] when set to undefined

    if (a[ p ] === b[ p ]) continue
    // if they have the same strict value or identity then they are equal

    if (typeof (a[ p ]) !== 'object') return false
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!compare(a[ p ], b[ p ])) return false
    // Objects and Arrays must be tested recursively
  }

  for (p in b) {
    if (b.hasOwnProperty(p) && !a.hasOwnProperty(p)) return false
    // allows a[ p ] to be set to undefined
  }
  return true
}


class ActionCard extends React.Component {
  static propTypes = {
    /** The hostConfig object that is passed along to the native AdaptiveCards. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/hostconfig) */
    hostConfig: PropTypes.object,
    /** The card schema.  It must comply with the card schema. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/create/cardschema) */
    carddata: PropTypes.object.isRequired,
    /** Method that will be invoked anytime a card action is executed. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/implementingrenderer#actions) */
    onExecuteAction: PropTypes.func,
    /** Method that will be invoked when a Submit action is executed. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/implementingrenderer#actionsubmit) */
    onActionSubmit: PropTypes.func,
    /** Method that will be invoked when an Open Url action is executed. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/implementingrenderer#actionopenurl) */
    onActionOpenUrl: PropTypes.func,
    /** Method that will be invoked when a Show Card action is executed. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/implementingrenderer#actionshowcard) */
    onActionShowCard: PropTypes.func,
    /** Method that will be invoked if an error is thrown while trying to render a card. */
    onError: PropTypes.func,
    /** JSX styles that will be applied to the card conatiner */
    style: PropTypes.object
  }
  constructor (props) {
    super(props)
    // Create this in the constructor so we don't create it every render
    this.adaptiveCard = new AdaptiveCards.AdaptiveCard()
  }

  componentWillUnmount () {
    // Remove all references
    delete this.adaptiveCard
  }

  shouldComponentUpdate(nextProps) {
    console.log('checking if should update')
    if (compare(nextProps.hostConfig, this.props.hostConfig)) {
      console.log('yes host')
      return true
    }
    if (compare(nextProps.carddata, this.props.carddata)) {
      console.log('yes data')
      return true
    }
    if (compare(nextProps.onExecuteAction, this.props.onExecuteAction)) {
      console.log('yes onexecute')
      return true
    }
    console.log('no')
    return false
  }

  executeAction (a) {
    const type = a.getJsonTypeName()
    console.log(a)
    switch (type) {
      case ACTION_OPEN_URL: {
        if (this.props.onActionOpenUrl) {
          this.props.onActionOpenUrl(a)
        } else {
          this.defaultOpenUrlHandler(a)
        }
        break
      }
      case ACTION_SHOW_CARD: {
        if (this.props.onActionShowCard) {
          this.props.onActionShowCard(a)
        }
        break
      }
      case ACTION_SUBMIT: {
        if (this.props.onActionSubmit) {
          this.props.onActionSubmit(a)
        }
        break
      }
      default: {
        // noop        
      }
    }
    if (this.props.onExecuteAction) {
      this.props.onExecuteAction(a)
    }
  }

  defaultOpenUrlHandler(action) {
    window.open(action.url, action.title || '_blank')
  }

  render() {
    if (this.props.hostConfig) {
      this.adaptiveCard.hostConfig = new AdaptiveCards.HostConfig(this.props.hostConfig)
    }
    this.adaptiveCard.onExecuteAction = this.executeAction.bind(this)
    // if (this.props.type === 'reservation') {
      const template = new ACData.Template(reservation)
    // }
    let _c = template.expand({ $root: this.props.carddata })
    try {
      // this.adaptiveCard.parse(this.props.payload)
      this.adaptiveCard.parse(_c)
      const result = this.adaptiveCard.render()
      return <Card><div style={this.props.style} ref={(n) => { n && n.replaceWith(result) }} /></Card>
    } catch (err) {
      console.error(err)
      if (this.props.onError) {
        return this.props.onError(err)
      } else {
        return <Card><div style={{ color: 'red' }}>{err.message}</div></Card>
      }
    }
  }
}

export default ActionCard