import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'
import { withFirebase } from '../Fb'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'

function CurrencyField({ value, onChange, currency }) {

  const [currencyValue, setCurrencyValue] = useState({ integer: 0, decimal: 0 })

  useEffect(() => {
    setCurrencyValue({ integer: Math.floor(value), decimal: (value / 100).toFixed(2).replace('.', ',') })
  }, [value])

  const _onChange = (e) => {
    const cleaned = e.target.value.trim().replaceAll(' ', '').replace(',', '.')
    const integer = Math.floor(parseFloat(cleaned) * 100)
    setCurrencyValue({ integer, decimal: e.target.value })
    if (onChange) {
      onChange({ target: { value: integer } })
    }
  }


  return (
    <TextField
      value={currencyValue.decimal}
      onChange={_onChange}
      InputProps={{
        endAdornment: <InputAdornment position='end'>{currency}</InputAdornment>,
      }}
    />
  )
}


function ProductLine({ product, index, handleRemoveFromList, updateProduct }) {
  const [quantity, setQuantity] = useState(1)
  const [price, setPrice] = useState(product.price)
  const [name, setName] = useState(product.name)

  const handleChange = (event, property) => {
    if (property === 'price') {
      setPrice(event.target.value)
      updateProduct(index, { price: event.target.value })
    } else if (property === 'quantity') {
      setQuantity(event.target.value)
      updateProduct(index, { quantity: event.target.value })
    } else if (property === 'name') {
      setName(event.target.value)
      updateProduct(index, { name: event.target.value })
    }
  }

  return (
    <>
      <Grid item xs={2}>
        {product.productCode}
      </Grid>
      <Grid item xs={4}>
        <TextField fullWidth value={name} onChange={(e) => handleChange(e, 'name')} />
      </Grid>
      <Grid item xs={2}>
        <CurrencyField value={price} onChange={(e) => handleChange(e, 'price')} currency='€' />
      </Grid>
      <Grid item xs={1}>
        <TextField value={quantity} onChange={(e) => handleChange(e, 'quantity')} type='number' />
      </Grid>
      <Grid item xs={1}>
        {product.vat} %
      </Grid>
      <Grid item xs={1}>
        {((price / 100) * quantity).toFixed(2).replace('.',',')} €
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={() => handleRemoveFromList(index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </>
  )
}

function ShopProductDialog({ open, onClose, title, firebase, payer, onUpdate }) {
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState(null)
  const [loading, setLoading] = useState(true)
  const [productList, setProductList] = useState([])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (open) {
      firebase.getShopProducts().then((results) => {
        if (!results.empty) {
          const products = []
          results.forEach((doc) => {
            const product = doc.data()
            product.id = doc.id
            product.price = product.price.g
            products.push(product)
          })
          setProducts(products)
          setLoading(false)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleChange = (event) => {
    const product = products.find((x) => x.id === event.target.value)
    setProduct(product)
  }

  const handleAddToList = () => {
    setProductList([...productList, product])
    setProduct(null)
  }

  const handleRemoveFromList = (index) => {
    setProductList(productList.filter((x, i) => i !== index))
  }

  const handleClose = () => {
    setProductList([])
    setProduct(null)
    if (onClose) onClose()
  }

  const updateList = (index, product) => {
    const newList = [...productList]
    newList[index] = { ...newList[index], ...product }
    console.log(newList)
    setProductList(newList)
  }

  const handleSave = () => {
    setSaving(true)
    const promises = []
    productList.forEach((product) => {
      promises.push(firebase.addProductOrder(product, payer))
    })
    Promise.all(promises).then(() => {
      setSaving(false)
      setProductList([])
      setProduct(null)
      if (onUpdate) onUpdate()
      if (onClose) onClose()
    })
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {loading && <DialogContentText>Ladataan...</DialogContentText>}
        <FormControl style={{ minWidth: 160 }}>
          <InputLabel id='product-select-label'>Tuote</InputLabel>
          <Select
            labelId='product-select-label'
            id='product-select'
            value={product ? product.id : ''}
            onChange={handleChange}
          >
            <MenuItem value={''} disabled>
              Valitse
            </MenuItem>
            {products.map((product) => (
              <MenuItem value={product.id}>{product.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton aria-label='add' onClick={handleAddToList} disabled={!product}>
          <AddCircleIcon />
        </IconButton>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={1} direction='row' justifyContent='center' alignItems='center'>
          {productList.map((product, index) => {
            if (product.quantity === undefined) product.quantity = 1
            return (
              <ProductLine
                product={product}
                index={index}
                handleRemoveFromList={handleRemoveFromList}
                updateProduct={updateList}
              />
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={saving}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={saving}>
          Save {saving && <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withFirebase(ShopProductDialog)
