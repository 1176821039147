import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import Infobox from '../Infos/Infobox'
import { withFirebase } from '../Fb'
import DeleteButton from '../Buttons/DeleteButton'

class MaintenanceTicketClasses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      classes: [],
      loading: true,
      saved: true,
    }
  }


  componentDidMount() {
    this.unsubscribe = this.props.firebase
      .getMaintenanceTicketClasses()
      .onSnapshot((doc) => {
        if (doc.exists && doc.data().classes) {
          let _c = doc.data().classes.map((r) => {
            if (r.visibility === undefined) r.visibility = true
            return r
          })
          this.setState({
            classes: _c,
            loading: false,
          })
        } else {
          this.setState({
            loading: false,
          })
        }
      })
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onChange = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.classes
    _a[index].enabled = event.target.value
    this.setState({ classes: _a, saved: false })
  }

  onChangeVisibility = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.classes
    _a[index].visibility = event.state.value
    this.setState({ classes: _a, saved: false })
  }

  setName = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.classes
    _a[index].name = event.target.value
    this.setState({ targets: _a, saved: false })
  }

  addClassRow = () => {
    let j = this.state.classes
    j.push({ empty: true, enabled: true, name: '' })
    this.setState({ classes: j })
  }

  deleteClassRow = (index) => {
    let j = this.state.classes
    j.splice(index, 1)
    this.setState({ classes: j, saved: false })
  }

  onSave = (event) => {
    event.preventDefault()
    let _rows = this.state.classes.map((r) => {
      delete r.empty
      return r
    })
    this.props.firebase
      .updateMaintenanceTicketClasses({
        classes: _rows,
      })
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  classesRows = () => {
    if (this.state.classes) {
      return this.state.classes.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col md='2' sm='2' xs='2'>
              <FormGroup>
                <Label for={'lbl.' + i}>Kohde</Label>
                <Input
                  type='text'
                  name={'name.' + i}
                  id={'name.' + i}
                  onChange={this.setName}
                  value={
                    this.state.classes[i].name ? this.state.classes[i].name : ''
                  }
                />
              </FormGroup>
            </Col>
            {this.state.classes ? (
              <>
                <Col xs={1}>
                  <Label for={'visibility.' + i}>Näkyvyys</Label>{' '}
                  <div name={'visibility.' + i}>
                    <Switch
                      defaultValue={
                        this.state.classes[i].visibility ? true : false
                      }
                      onColor='primary'
                      offColor='primary'
                      id={'visibility.' + i}
                      name={'visibility.' + i}
                      onChange={this.onChangeVisibility}
                    />
                  </div>
                </Col>
                {/* <Col xs={1}>
                  <Button onClick={() => this.setState({ qrOpen: true })}>
                    Kohteeen suora QR koodi
                  </Button>
                </Col> */}
                <Col xs={1} style={{ paddingTop: 20, marginLeft: 40 }}>
                  <DeleteButton onClick={() => this.deleteClassRow(i)} />
                </Col>
              </>
            ) : null}
          </Row>
        )
      })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>
                          Tikettien luokitukset
                        </CardTitle>
                      </Col>
                      <Col>
                        <Infobox index={0} />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.classesRows()}
                    <Row>
                      <Col style={{ marginTop: '20px' }}>
                        <Button
                          className='btn-round btn-icon'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.addClassRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                        </Button>{' '}
                        Lisää luokitus
                      </Col>
                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='6'>
                        <Button
                          disabled={this.state.saved}
                          color='primary'
                          onClick={this.onSave}
                        >
                          Tallenna luokitukset
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default withFirebase(MaintenanceTicketClasses)
