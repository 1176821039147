import React from 'react'
import { withFirebase } from '../Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'

import MaintenanceTickets from './MaintenanceTickets.jsx'
import MaintenanceInfo from './MaintenanceInfo.js'

const service = 'maintenancetickets'
// core components

class BuildingMaintenanceTicketsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      enabled: false,
    }
  }


  componentDidMount() {
    if (this.props.modules && this.props.modules[service] && this.props.modules[service].enabled) {
      this.setState({ enabled: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.modules && this.props.modules[service]) {
      if (this.props.modules[service].enabled && !prevProps.modules[service]?.enabled) {
        this.setState({ enabled: true })
      } 
      if (!this.props.modules[service].enabled && prevProps.modules[service]?.enabled) {
        this.setState({ enabled: false })
      }
    } else {
      if(this.state.enabled) {
        this.setState({ enabled: false })
      }
    }
  }

  enableModule = async (price) => {
    this.setState({ saving: true })
    await this.props.firebase.enableModule(service, price)
  }

  render() {
    if (!this.state.enabled) {
      return (
        <>
          <div className='content'>
            <Row style={{ marginTop: 90 }}>
              <Col md='12'>
                <MaintenanceInfo onSubmit={this.enableModule} saving={this.state.saving} />
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='content'>
            <Row style={{ marginTop: 90 }}>
              <Col md='12'>
                <MaintenanceTickets />
              </Col>
            </Row>
          </div>
        </>
      )
    }
  }
    
}

export default withFirebase(BuildingMaintenanceTicketsContainer)
