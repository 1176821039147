import React, { Component } from 'react';
import { Row, Col, Spinner } from 'reactstrap';

class FalconLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return (
      <>
        <div className='content'>
          <Row style={{ marginTop: 80 }}>
            <Col>
              <Spinner size='sm' color='primary'/> Loading
            </Col>
          </Row>
        </div>
      </>
    )
}
}
 
export default FalconLoading