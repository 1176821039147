import React from 'react'
import { Row, Col, Button } from 'reactstrap'
// import { Tab, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
// import { filterCaseInsensitive } from '../FalconTable'
// import ReactTable from 'react-table'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'


const WEEKDAYS = { Ma: 1, Ti: 2, Ke: 3, To: 4, Pe: 5, La: 6, Su: 7 }

// eslint-disable-next-line no-unused-vars
const sortByDay = (a, b) => {
  return WEEKDAYS[a] - WEEKDAYS[b]
}

// eslint-disable-next-line no-unused-vars
const byOneDimension = (data, dimension) => {
  return data.reduce((acc, cur) => {
    const value = cur[dimension]
    if (!acc[value]) acc[value] = { cost: 0, occupancy: 0, windowLength: 0 }
    acc[value].cost += parseFloat(cur.cost)
    acc[value].occupancy += parseFloat(cur.occupancy)
    acc[value].windowLength += cur.windowLength
    return acc
  }, {})
}

// eslint-disable-next-line no-unused-vars
const byTwoDimensions = (data, dimensions) => {
  return data.reduce((acc, cur) => {
    const value1 = cur[dimensions[0]]
    const value2 = cur[dimensions[1]]
    if (!acc[value1]) acc[value1] = {}
    if (!acc[value1][value2]) acc[value1][value2] = { cost: 0, occupancy: 0, windowLength: 0 }
    acc[value1][value2].cost += parseFloat(cur.cost)
    acc[value1][value2].occupancy += parseFloat(cur.occupancy)
    acc[value1][value2].windowLength += cur.windowLength
    return acc
  }, {})
}

// eslint-disable-next-line no-unused-vars
var tableToExcel = (function() {
  var uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
  return function(table, name) {
    if (!table.nodeType) table = document.getElementsByClassName(table)[0]
    var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
    window.location.href = uri + base64(format(template, ctx))
  }
})()

class Persons extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentWillMount() {
    this.setState({
      report: this.props.report,
      data: [],
      tableRows: [],
      filter: '',
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      console.log('Persons: rows updated')
      this.setState({ data: this.props.rows, filter: '' }, this.filterRows)
    }
  }

  filterRows = () => {
    const { data } = this.state
    const { filter } = this.state
    const filteredRows = data.filter(row => {
      if (filter === '') return true
      if (filter === 'pastpayment') return row.ispastpayment
      if (filter === 'recurring') return row.isrecurring
      if (filter === 'blocked') return row.isblocked
      return false
    })
    this.setState({ tableRows: filteredRows })
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
          <Button color='primary' onClick={() => this.setState({ filter: 'recurring' }, this.filterRows)}>Vakiovuoroasiakkaat</Button>
          <Button color='primary' onClick={() => this.setState({ filter: 'pastpayment' }, this.filterRows)}>Laskutusasiakkaat</Button>
            <Button color='primary' onClick={() => this.setState({ filter: 'blocked' }, this.filterRows)}>Varauskiellossa</Button>
            <Button color='primary' onClick={() => this.setState({ filter: '' }, this.filterRows)}>Kaikki</Button>
            {/* <Button color='primary' onClick={() => tableToExcel('table-to-xls', 'persons')}>Lataa Exceliin</Button> */}
          </Col>
        </Row>
        <Row>
          <Col>
          <div style={{ display: 'flex', minHeight: 'calc(100vh - 200px)', backgroundColor: '#FFFFFF' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  components={{ Toolbar: GridToolbar }}
                  // componentsProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                  csvOptions={{
                    fileName: 'customerDataBase',
                    delimiter: ';',
                    utf8WithBom: false,
                  }}
              rows={this.state.tableRows}
              columns={[
                { field: 'firstname', headerName: 'Etunimi', flex: (1/11) },
                { field: 'lastname', headerName: 'Sukunimi', flex: (1/11) },
                { field: 'email', headerName: 'Sähköposti', flex: (1/11) },
                { field: 'phone', headerName: 'Puhelin', flex: (1/11) },
                { field: 'streetaddress', headerName: 'Katuosoite', flex: (1/11) },
                { field: 'zip', headerName: 'Postinumero', flex: (1/11) },
                { field: 'city', headerName: 'Kaupunki', flex: (1/11) },
                { field: 'isrecurring', headerName: 'Vakiovuoroasiakas', flex: (1/11) },
                { field: 'ispastpayment', headerName: 'Laskutusasiakas', flex: (1/11) },
                { field: 'isblocked', headerName: 'Varauskielto', flex: (1/11) },
                { field: 'memberships', headerName: 'Jäsenyydet', flex: (1/11) },
              ]}
              density='compact'
              pageSize={20}
              checkboxSelection
              disableSelectionOnClick
                />
              </div>
            </div>
          {/* <ReactTable
                  data={this.state.data}
                  filterable
                  // noDataText=''
                  defaultFilterMethod={(filter, row) =>
                    filterCaseInsensitive(filter, row)
                  }
                  columns={[
                    {
                      Header: 'Etunimi',
                      accessor: 'firstname',
                    },
                    {
                      Header: 'Sukunimi',
                      accessor: 'lastname',
                    },
                    {
                      Header: 'Sähköposti',
                      accessor: 'email',
                    },
                    {
                      Header: 'Puhelin',
                      accessor: 'phone',
                    },
                    {
                      Header: 'Katuosoite',
                      accessor: 'streetaddress',
                    },
                    {
                      Header: 'Postinumero',
                      accessor: 'zip',
                    },
                    {
                      Header: 'Kaupunki',
                      accessor: 'city',
                    },
                    {
                      Header: 'Laskutusasiakas',
                      accessor: 'isrecurring',
                      Cell: row => (
                        <span>
                          {row.value ? 'Kyllä' : 'Ei'}
                        </span>
                      ),
                      filterable: false,
                    },
                    {
                      Header: 'Vakiovuoroasiakas',
                      accessor: 'ispastpayment',
                      Cell: row => (
                        <span>
                          {row.value ? 'Kyllä' : 'Ei'}
                        </span>
                      ),
                      filterable: false,
                    },
                    {
                      Header: 'Varauskiellossa',
                      accessor: 'isblocked',
                      Cell: row => (
                        <span>
                          {row.value ? 'Kyllä' : 'Ei'}
                        </span>
                      ),
                      filterable: false,
                    },

                  ]}
                  defaultPageSize={100}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      // console.log(rowInfo.row)
                      return {
                        onClick: (e) => {
                          this.handleRowSelect(rowInfo.row._original.id)
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
                  className='-striped -highlight primary-pagination'
                /> */}
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <pre>
              {JSON.stringify(this.state.data, null, 2)}
            </pre>
          </Col>
        </Row> */}
      </React.Fragment>
    )
  }
}
export default Persons
