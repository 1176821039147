import React, { Component } from 'react'
// import { Card, CardBody, CardTitle, CardText, Button } from 'reactstrap'
import ActionCard from './ActionCard';
import { withFirebase } from '../../components/Fb'

// const card = {
//   title: "Varaus peruttu",
//   description: '',
//     creator: {
//         name: "Matt Hidinger",
//         profileImage: "https://pbs.twimg.com/profile_images/3647943215/d7f12830b3c17a5a9e4afcc370e3a37e_400x400.jpeg"
//     },
//     createdUtc: "2017-02-14T06:08:39Z",
//     viewUrl: "https://adaptivecards.io",
//     properties: [
//       { key: "Kenttä", value: "Massa 1" },
//       { key: "Päivä", value: "Torstai 12.04." },
//       { key: "Aika", value: "12:00" },
//         { key: "Kesto", value: "60 min" }
//     ]
// }

class CashierActionBar extends Component {
  constructor(props) {
    super(props)
    this.state = {actionmessages:[]}
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.listenActionMessages().on('value', (snapshot) => {
      console.log('got actionmessages')
      const data = []
      if (snapshot.exists) {
        snapshot.forEach((doc) => {
          if (doc.val().message) {
            data.push({ ...doc.val(), id: doc.key })
          }
        })
        data.reverse()
        this.setState({ actionmessages: data })
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe)
      this.unsubscribe()
  }

  render() {
    return (
      <>
        {this.state.actionmessages.filter((m) => m.message).map((message) => {
          return (<ActionCard carddata={message.message} type={message.type} key={message.id} />)
        } )}
      </>
    )
  }
}

export default withFirebase(CashierActionBar)
