import React, { useRef } from 'react'
import { Button, Col, Row } from 'reactstrap'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import { Input, FormControl, InputAdornment, IconButton, Popper } from '@material-ui/core'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { fi } from 'date-fns/locale'

const datenavidation = { cursor: 'pointer', margin: 10 }

const DateSelector = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = React.useState(moment.utc())
  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isPopperOpen, setIsPopperOpen] = React.useState(false)
  const anchorRef = useRef(null)

  const closePopper = () => {
    setIsPopperOpen(false);
  }

  // const handleDateChange = (event) => {
  //   const date = moment(event.target.value)
  //   if (date.isValid()) {
  //     setSelectedDate(date)
  //     onChange(date)
  //   }
  // }

  const handleDaySelect = (date) => {
    const d = date.getDate()
    const m = date.getMonth()
    const y = date.getFullYear()
    const momentdate = moment.utc(`${y}-${m + 1}-${d}`)
    setSelectedDate(momentdate);
    onChange(momentdate)
    if (date) {
      closePopper();
    }
  }


  const moveDate = (days) => {
    let date
    if (days > 0) {
      date = moment.utc(selectedDate).add(days, 'days')
    } else if (days < 0) {
      date = moment.utc(selectedDate).subtract(-days, 'days')
    } else {
      date = moment.utc()
    }
    if (date.isValid()) {
      setSelectedDate(date)
      onChange(date)
    }
  }

  const handleDateSelectOpen = (event) => {
    console.log('handleDateSelectOpen', event.currentTarget)
    setAnchorEl(event.currentTarget)
    setIsPopperOpen(true);
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <strong style={{ textTransform: 'capitalize' }}>{moment.utc(selectedDate).format('dddd')}</strong><br/>
          <FormControl variant='standard'>
            {/* <InputLabel style={{ color: 'black' }} fontSize='small' htmlFor="standard-adornment">{moment.utc(selectedDate).format('D.M.YYYY')}</InputLabel> */}
            <Input
              size='small'
              id='standard-adornment'
              type='text'
              style={{ width: 120 }}
              value={moment.utc(selectedDate).format('D.M.YYYY')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='dateselect' onClick={handleDateSelectOpen} size='small'>
                    <CalendarTodayIcon style={{ fontSize: 15, color: 'black' }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
            <div ref={anchorRef}></div>
          {/* <TextField
        id='endtime'
        type='date'
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        value={selectedDate.format('YYYY-MM-DD')}
      /> */}

          <>
            <FirstPageIcon onClick={() => moveDate(-7)} style={datenavidation} />
            <KeyboardArrowLeftIcon onClick={() => moveDate(-1)} style={datenavidation} />
            <Button size='sm' outline style={{ margin: 10 }} onClick={() => moveDate(0)}>
              Tänään
            </Button>
            <KeyboardArrowRightIcon onClick={() => moveDate(1)} style={datenavidation} />
            <LastPageIcon onClick={() => moveDate(7)} style={datenavidation} />
          </>
        </Col>
      </Row>
      <Popper open={isPopperOpen} anchorEl={anchorEl} placement="bottom-start">
        <div style={{ zIndex: 1000, backgroundColor: 'white', borderRadius: 10, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
        <DayPicker
          initialFocus={isPopperOpen}
          mode="single"
          defaultMonth={selectedDate.toDate()}
          selected={selectedDate.toDate()}
            onSelect={handleDaySelect}
            showWeekNumber
            weekStartsOn={1}
            locale={fi}
          />
          </div>
      </Popper>
        
    </React.Fragment>
  )
}

export default DateSelector
