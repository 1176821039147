import React from 'react'
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Label, FormGroup, Input } from 'reactstrap'
import { Prompt } from 'react-router-dom'

class MessagingConfig extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      name: null,
      smssender: null,
      loading: true,
      saved: true,
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.getMessageTemplates().onSnapshot((doc) => {
      if (doc.exists && doc.data().replyto) {
        const { email, name } = doc.data().replyto
        this.setState({
          email,
          name,
          loading: false,
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onNameChange = (event) => {
    this.setState({ name: event.target.value, saved: false })
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value, saved: false })
  }

  onSmssenderChange = (event) => {
    this.setState({ smssender: event.target.value, saved: false })
  }

  onSave = (event) => {
    event.preventDefault()
    let mt = {}
    const replyto = {
      email: this.state.email,
      name: this.state.name,
    }
    mt.replyto = replyto
    if (this.state.smssender && this.state.smssender.length > 0) {
      mt.smssender = this.state.smssender
    }

    this.props.firebase
      .addMessageTemplates(mt)
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' md='5' sm='5'>
                  <CardTitle tag='h5'>Viestien lähettäjän tiedot</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className='pr-1' md='3'>
                  <FormGroup>
                    <Label style={{ color: 'black' }} for={'nimi'}>Sähköpostien lähettäjän nimi</Label>
                    <Input
                      type='text'
                      name={'nimi'}
                      id={'nimi'}
                      onChange={this.onNameChange}
                      placeholder=''
                      value={this.state.name ? this.state.name : ''}
                    />
                  </FormGroup>
                </Col>
                <Col className='pl-1' md='3'>
                  <FormGroup>
                    <Label style={{ color: 'black' }} for={'email'}>Sähköpostiosoite johon vastausviestit tulee</Label>
                    <Input
                      type='text'
                      name={'email'}
                      id={'email'}
                      onChange={this.onEmailChange}
                      placeholder=''
                      value={this.state.email ? this.state.email : ''}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className='pr-1' md='3'>
                  <FormGroup>
                    <Label style={{ color: 'black' }} for={'smssender'}>Tekstiviestien lähettäjä</Label>
                    <Input
                      type='text'
                      name={'smssender'}
                      id={'smssender'}
                      onChange={this.onSmssenderChange}
                      placeholder=''
                      value={this.state.smssender ? this.state.smssender : ''}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className='ml-auto mr-auto' sm='12'>
                  <Button disabled={this.state.saved} color='primary' onClick={this.onSave}>
                    Tallenna muutokset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Prompt when={!this.state.saved} message='Muutoksia ei ole tallennettu. Haluatko varmasti poistua?' />
        </>
      )
    } else {
      return <></>
    }
  }
}

export default MessagingConfig
