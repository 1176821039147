import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React from 'react'
import moment from 'moment'

class SportmoneyReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  filterRows = () => {
    const { data } = this.state
    const filtered = data.map((x) => {
      x.name = x.firstname + ' ' + x.lastname
      return x
    })
    this.setState({ tableRows: filtered })
  }

  componentWillMount() {
    this.setState({
      report: this.props.report,
      data: [],
      tableRows: [],
      filter: '',
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      this.setState({ data: this.props.rows, filter: '' }, this.filterRows)
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', minHeight: 'calc(100vh - 200px)', backgroundColor: '#FFFFFF' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: {
                  fileName: 'liikuntarahalataukset',
                  delimiter: ';',
                  utf8WithBom: true,
                },
              },
            }}
            rows={this.state.tableRows}
            columns={[
              {
                field: 'created',
                headerName: 'Pvm',
                type: 'date',
                valueFormatter: ({ value }) => moment.utc(value).format('DD.MM.YYYY HH:mm'),
                width: 200,
              },
              {
                field: 'paidsum',
                headerName: 'Summa',
                type: 'number',
                valueFormatter: ({ value }) => (value / 100).toFixed(2) + ' €',
                width: 150,
              },
              {
                field: 'provider',
                headerName: 'Liikuntaraha',
                valueFormatter: ({ value }) => (value === 'epassi' ? 'Epassi' : 'Smartum'),
                width: 150,
              },
              {
                field: 'status',
                headerName: 'Tila',
                valueFormatter: ({ value }) => (value === 'stored' ? 'Maksettu' : 'Keskeytynyt'),
                width: 150,
              },
              { field: 'name', headerName: 'Käyttäjä', width: 250 },
              { field: 'venue', headerName: 'Paikka', width: 100 },
            ]}
            density='compact'
            pageSize={20}
            // checkboxSelection
            disableSelectionOnClick
          />
          {/* <pre>{JSON.stringify(this.state.data, null, 2)}</pre> */}
        </div>
      </div>
    )
  }
}

export default SportmoneyReport
