import { create } from "filepond"

export const _maintenanceContractClass = {
  name: {
    type: 'string',
    title: 'Nimi',
  },
  description: {
    type: 'string',
    title: 'Kuvaus',
  },
  customerid: {
    type: 'string',
    title: 'Asiakas',
  },
  validuntil: {
    type: 'string',
    format: 'date',
    title: 'Voimassa',
  },
  status: {
    type: 'string',
    title: 'Status',
    enum: ['active', 'inactive'],
    enumNames: ['Aktiivinen', 'Ei aktiivinen'],
  },
}


export const _maintenanceContractClassDeps = {
}

export const _maintenanceContractUISchema = {
  status: {
    'ui:widget': 'radio',
  },
}


export const _maintenanceServicedescriptionClass = {
  name: {
    type: 'string',
    title: 'Nimi',
  },
  description: {
    type: 'string',
    title: 'Kuvaus',
  },
  taskgroups: {
    title: '',
    description: 'taskiryhmät',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        name: { type: 'string', title: 'Nimi' },
        description: { type: 'string', title: 'Kuvaus' },
      },
    },
  },
}

export const _maintenanceServicedescriptionClassDeps = {
}

export const _maintenanceServicedescriptionUISchema = {
  description: {
    'ui:widget': 'editorWidget',
  },
}


export const _maintenanceTaskgroupClass = {
  name: {
    type: 'string',
    title: 'Nimi',
  },
  description: {
    type: 'string',
    title: 'Kuvaus',
  },
  taskids: {
    type: 'array',
    title: 'Tehtävät',
    items: {
      type: 'string',
      enum: [],
      enumNames: [],
    },
    uniqueItems: true,
  },
}

export const _maintenanceTaskgroupClassDeps = {
  taskids: {
    oneOf: [
      {
        properties: {
          taskids: {
            enum: [],
          },
        },
      },
    ],
  },
}

export const _maintenanceTaskgroupUISchema = {
  taskids: {
    'ui:widget': 'checkboxes',
  },
}


export const _maintenanceTicketClass = {
  message: {
    type: 'string',
    title: 'Viesti',
    readOnly: true,
  },
  email: {
    type: 'string',
    title: 'Raportoinut',
    readOnly: true,
  },
  class: {
    type: 'string',
    title: 'Luokka',
    enum: [],
    enumNames: [],
  },
  image: {
    type: 'string',
    title: 'Kuva',
    readOnly: true,
  },
  priority: {
    type: 'string',
    title: 'Prioriteetti',
    enum: ['low', 'normal', 'high'],
    enumNames: ['Matala', 'Normaali', 'Korkea'],
  },
  target: {
    type: 'string',
    title: 'Kohde',
    enum: [],
    enumNames: [],
  },
  notes: {
    title: '',
    description: 'muistiinpanot',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        note: { type: 'string', title: 'Teksti' },
      },
    },
  },
  status: {
    type: 'string',
    title: 'Status',
    enum: ['closed', 'new', 'inprogress', 'completed'],
    enumNames: ['Suljettu (ei toimenpiteitä)', 'Uusi', 'Käsittelyssä', 'Valmis'],
    readOnly: true,
  },
  createdDate: {
    type: 'string',
    format: 'date',
    title: 'Luotu',
    readOnly: true,
  },
  updatedDate: {
    type: 'string',
    format: 'date',
    title: 'Päivitetty',
    readOnly: true,
  },

}

export const _maintenanceTicketClassDeps = {
}

export const _maintenanceTicketUISchema = {
  status: {
    'ui:widget': 'radio',
  },
  message: {
    "ui:widget": "textarea",
    "ui:options": {
      "rows": 10
    }
  },
  image: {
    'ui:widget': 'imageViewerWidget',
  },
}