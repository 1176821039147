import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col,
  Button,
} from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import { getInfotextSchema, getInfotextUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from '../../components/Lists/ReactTableWrapper'
import { filterCaseInsensitive, getTdProps } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
import EditButton from '../../components/Buttons/EditButton'
import DeleteButton from '../../components/Buttons/DeleteButton'
// import moment from 'moment'

// core components

class ConfigInfoTexts extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, infotextModalOpen: false, infotexts: null }
  }

  componentDidMount() {
    this.updateData()
  }

  componentWillUnmount() {
    if (this.infotextRef) {
      this.infotextRef.off('value', this.unsubscribeInfoTexts)
    }
  }

  listenInfoTexts = () => {
    this.infotextRef = this.props.firebase.listenInfoTexts()
    this.unsubscribeInfoTexts = this.infotextRef.on('value', (snapshot) => {
      if (snapshot && snapshot.exists()) {
        const _infotexts = JSON.parse(JSON.stringify(snapshot.val()))
        const infotexts = Object.keys(_infotexts).map((k) => { return { ..._infotexts[k], id: k }})
        this.setState({ raw: infotexts }, this.filterData)
      } else {
        this.setState({ raw: [] }, this.filterData)
      }
    })
  }

  updateData = () => {
    this.listenInfoTexts()
  }

  handleRowSelect = (e) => {
    const _result = this.state.infotexts.filter((r) => r.id === e)
    this.setState({ selectedResource: _result[0] }, this.toggleInfotextModal)
  }

  filterData = async () => {
    const data = this.state.raw.filter((x) => x.state !== 'deleted').map((x) => { const k = { ...x, actions: this.getActions(x.id) }; k.showlist = k.visible.toUpperCase();  return k })
    this.setState({ loading: false, infotexts: data })
  }

  deleteInfotext = async (id) => {
    await this.props.firebase.deleteInfotext(id)
  }

  getActions = (id) => {
    return (
        <>
          <div className="actions-right">
            <Button
              color="info"
              size="sm"
              onClick={(el) => {
                this.updateInfo(id)
              }}
            >
              <span className="btn-label">
                Muokkaa
              </span>
            </Button>
            <Button
              color="danger"
              size="sm"
              onClick={(el) => {
                this.deleteInfotext(id)
              }}
            >
              <span className="btn-label">
                <i className="nc-icon nc-simple-remove" /> Poista
              </span>
            </Button>
          </div>
        </>
    )
  }

  addNewInfotext = () => {
    this.setState({ selectedResource: {} }, this.toggleInfotextModal)
  }

  updateInfo = (e) => {
    const _r = this.state.infotexts.find((r) => r.id === e)
    this.setState({ selectedResource: _r }, this.toggleInfotextModal)
  }

  toggleInfotextModal = () => {
    this.setState({
      infotextModalOpen: !this.state.infotextModalOpen,
    })
  }

  noop = () => {}

  createInfotextModal = () => {
    return (<FalconModal
      onUpdate={this.props.firebase.updateInfotext}
      onCreate={this.props.firebase.createInfotext}
      modifiedCallback={this.noop}
      toggle={this.toggleInfotextModal}
      resource={this.state.selectedResource}
      isOpen={this.state.infotextModalOpen}
      uiSchema={getInfotextUiSchema()}
      dataSchema={getInfotextSchema()}
      title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa tekstiä' : 'Uusi teksti'}
    />)
  }


  render() {
    if (this.state.loading) {
      return(<FalconLoading />)
    } else {
      return (
        <>
          <div className='content'>
            <Row>
              <Col xs={3} md={2}>
                <Button onClick={this.addNewInfotext}>
                  Lisää Viesti
                </Button>
              </Col>
              <Col className='pt-3'>Nämä viestit näkyvät Venuemanagerissa kassoille (CASHIER) ja ylläpidolle (admin) </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.infotexts}
                  filterable
                  noDataText='Ei löytynyt yhtään viestiä'
                  defaultFilterMethod={(filter, row) =>
                    filterCaseInsensitive(filter, row)
                  }
                  columns={[
                    {
                      Header: 'Viesti',
                      accessor: 'text',
                    },
                    {
                      Header: 'Näkyvyys alkaa',
                      accessor: 'start',
                    },
                    {
                      Header: 'Näkyvissä asti',
                      accessor: 'end',
                    },
                    {
                      Header: 'Kenelle näkyy',
                      accessor: 'showlist',
                    },
                    {
                      Header: 'Toiminnot',
                      accessor: 'actions',
                      renderCell: (params) => {
                        return (
                          <>
                            <EditButton
                              onClick={(el) => {
                                this.updateInfo(params.row.id)
                              }}
                            />
                            <DeleteButton
                              onClick={(el) => {
                                this.deleteInfotext(params.row.id)
                              }}
                            />
                          </>
                      )
                      }
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect) }
                  className='-striped -highlight primary-pagination'
                />

              </Col>
            </Row>
            {this.state.alert}
            {this.createInfotextModal()}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(ConfigInfoTexts)
