import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Label,
  // UncontrolledTooltip,
} from 'reactstrap'
// import Switch from 'react-bootstrap-switch'
import ReactDatetime from 'react-datetime'

const DEFAULT_TIMES = [
  { day: 0, dayname: 'Ma', start: '08.00', end: '22.00', allday: false },
  { day: 1, dayname: 'Ti', start: '08.00', end: '22.00', allday: false },
  { day: 2, dayname: 'Ke', start: '08.00', end: '22.00', allday: false },
  { day: 3, dayname: 'To', start: '08.00', end: '22.00', allday: false },
  { day: 4, dayname: 'Pe', start: '08.00', end: '22.00', allday: false },
  { day: 5, dayname: 'La', start: '08.00', end: '22.00', allday: false },
  { day: 6, dayname: 'Su', start: '08.00', end: '22.00', allday: false },
]

class OpeningTimes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      times: null,
      loading: true,
      saved: true,
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase
      .getOpeningTimes()
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.setState({
            times: doc.data(),
            loading: false,
          })
        } else {
          this.setState({
            loading: false,
            times: {
              defaults: DEFAULT_TIMES,
            },
          })
        }
      })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onBooleanChange = (element, state) => {
    let day = Number.parseInt(element.props.name)
    let _a = this.state.times
    _a.defaults[day].allday = state
    this.setState({ times: _a, saved: false })
  }

  onTimeChange = (time, day, prop) => {
    let _a = this.state.times
    _a.defaults[day][prop] = time.format('HH:mm')
    this.setState({ times: _a, saved: false })
  }

  onSave = (event, times) => {
    if (event) event.preventDefault()
    if (!times) times = this.state.times
    this.props.firebase.addOpeningTimes(times).then((dataSnapshot) => {
      this.setState({ saved: true })
    })
  }

  openingTimes = () => {
    if (this.state.times) {
      return this.state.times.defaults.map((day, index) => {
        let key = index.toString()
        return (
          <Row key={key} style={{ border: '1px solid rgba(0, 0, 0, 0.00)' }}>
            <Col sm='6' md='6' xs='12'>
              <Row>
                <Col sm='1' md='1' xs='2'>
                  <Label for={key}>{day.dayname}</Label>{' '}
                </Col>
                <Col sm='2' md='2' xs='2'>
                  <ReactDatetime
                    dateFormat={false}
                    inputProps={{
                      className: 'form-control',
                    }}
                    name={day.day + '-start'}
                    initialValue={day.start}
                    onChange={(value) =>
                      this.onTimeChange(value, day.day, 'start')
                    }
                  />
                </Col>
                <Col sm='2' md='2' xs='2'>
                  <ReactDatetime
                    dateFormat={false}
                    inputProps={{
                      className: 'form-control',
                    }}
                    name={day.day + '-end'}
                    initialValue={day.end}
                    onChange={(value) =>
                      this.onTimeChange(value, day.day, 'end')
                    }
                  />
                </Col>
                {/* <Col>
                  <Switch
                    name={key}
                    defaultValue={day.allday}
                    onColor='primary'
                    offColor='primary'
                    onChange={(el, state) => this.onBooleanChange(el, state)}
                  />
                  {' auki 24h'}
                  <Button
                    className='btn-round btn-icon btn-icon-mini btn-neutral'
                    color='info'
                    id={'tooltip' + key}
                    title=''
                    type='button'
                  >
                    <i className='nc-icon nc-alert-circle-i' />
                  </Button>
                  <UncontrolledTooltip delay={0} target={'tooltip' + key}>
                    {day.description}
                  </UncontrolledTooltip>
                </Col> */}
              </Row>
              
            </Col>
          </Row>
        )
      })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          {/* <div className='content'>
            <Row>
              <Col md='12'> */}
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Aukioloajat</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>{this.openingTimes()}
                  <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button
                          disabled={this.state.saved}
                          color='primary'
                          onClick={this.onSave}
                        >
                          Tallenna aukioloajat
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              {/* </Col>
            </Row>
          </div> */}
        </>
      )
    } else {
      return <></>
    }
  }
}

export default OpeningTimes
