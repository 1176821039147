import React from 'react'

const accountingClass = {
  businessid: { type: 'string', title: 'Y-tunnus'},
}

// eslint-disable-next-line
const partnerClass = {
  accounting: accountingClass,
}

export const _resourceClass = {
  displayName: { type: 'string', title: 'Asiakkaille näkyvä nimi' },
  // owner: { type: 'string', title: 'Omistaja' }, //partnerClass,
  // seller: { type: 'string', title: 'Myyjä' },
  type: {
    type: 'string',
    title: 'Resurssin tyyppi',
    enum: ['court', 'equipment', 'person', 'room'],
    enumNames: ['Kenttä', 'Väline', 'Henkilö', 'Tila'],
  },
  vat: { type: 'number', title: 'resurssin ALV %' },
  product: { type: 'string', title: 'Laskutuksen tuotekoodi' },
  order: { type: 'number', title: 'Kalenterinäkymän järjestys' },
  description: { type: 'string', title: 'Kuvaus' },
  image:{ type: 'string', title: 'Tuotekuva', items: { type: 'object', images: [] }, minaspectratio: 1 },
  customeralert: { type: 'string', title: 'Huomioteksti' },
  sport: { type: 'string', title: 'Laji' },
  sponsor: { type: 'string', title: 'Sponsorikuva, kuvasuhde min. 3,5:1 esim. leveys: 175 korkeus: 50 tai 175x40', items: { type: 'object', images: [] }, minaspectratio: 3.5 },
  accounting: { type: 'string', title: 'Kirjanpidontili' },
  multiuse: {
    type: "array",
    title: "Kun tämä resurssi on varattu, seuraavat resurssit eivät ole käytettävissä",
    items: {
      type: "string",
      "enum": [],
      'enumNames': []
    },
    "uniqueItems": true
  }, 
  resourceconfigid: {
    type: 'string',
    title: 'Resurssin konfiguraatio',
    enum: [],
    enumNames: [],
  },
  modbusid: { type: 'string', title: 'Valo-ohjauksen numero' },
  numberid: { type: 'number', title: 'Kassan resurssi id' },
  resourceCalendarId: { type: 'string' },
}

export const _resourceClassDeps = {
  type: {
    oneOf: [
      {
        properties: {
          type: {
            enum: ['court']
          },
          category: {
            type: 'string',
            title: 'Laji',
            enum: ['tennis', 'badminton', 'squash', 'pingis'],
            enumNames: ['Tennis', 'Sulkapallo', 'Squash', 'Pingis'],
          }
        },
      },
      {
        properties: {
          type: {
            enum: ['equipment']
          },
          category: {
            type: 'string',
            title: 'Millainen väline',
            enum: ['racket', 'ballmachine'],
            enumNames: ['Maila', 'Pallotykki'],
          }
        },
      },
      {
        properties: {
          type: {
            enum: ['person']
          },
          category: {
            type: 'string',
            title: 'Millainen henkilö',
            enum: ['coach', 'medical'],
            enumNames: ['Valmentaja', 'Tohtori'],
          }
        },
      },
      {
        properties: {
          type: {
            enum: ['room']
          },
          category: {
            type: 'string',
            title: 'Tilan tyyppi',
            enum: ['sauna', 'conferenceroom'],
            enumNames: ['Sauna', 'Kokoustila'],
          }
       
        }
      }
    ]
  }
}

export const _resourceUISchema = {
  resourceCalendarId: {
    'ui:widget': (props) => {
      const rand = Math.random()
      return (
        <input
          type='hidden'
          value={props.value ? props.value : rand}
          required={props.required}
          onChange={(event) => props.onChange(event.target.value)}
        />
      )
    }
  },
  multiuse: {
    'ui:widget':'checkboxes'
  },
  sponsor: {
    'ui:widget': 'imageWidget',
  },
  image: {
    'ui:widget': 'imageWidget',
  },

}
