import React, { useState } from 'react'
// import Select from 'react-select'
import Select from '@material-ui/core/Select'
import { FormControl, InputLabel, MenuItem } from '@material-ui/core'

const EINVOICE_OPERATORS = [
  {
    title: 'Ei operaattoria',
    value: null,
  },
  {
    title: '4US Oy (UTMOST)',
    value: 'UTMOST',
  },
  {
    title: 'Apix Messaging Oy (003723327487)',
    value: '003723327487',
  },
  {
    title: 'Basware Oyj (BAWCFI22)',
    value: 'BAWCFI22',
  },
  {
    title: 'CGI Suomi Oy (003703575029)',
    value: '003703575029',
  },
  {
    title: 'Comarch (5909000716438)',
    value: '5909000716438',
  },
  {
    title: 'Crediflow AB (CREDIFLOW)',
    value: 'CREDIFLOW',
  },
  {
    title: 'Dynatos (ROUTTY)',
    value: 'ROUTTY',
  },
  {
    title: 'HighJump AS (885790000000418)',
    value: '885790000000418',
  },
  {
    title: 'InExchange Factorum AB (INEXCHANGE)',
    value: 'INEXCHANGE',
  },
  {
    title: 'Lexmark Expert Systems AB (EXPSYS)',
    value: 'EXPSYS',
  },
  {
    title: 'Maventa (003721291126 tai MAVENTA)',
    value: '003721291126',
  },
  {
    title: 'Netbox Finland Oy (003726044706)',
    value: '003726044706',
  },
  {
    title: 'Opentext Oy (003708599126)',
    value: '003708599126',
  },
  {
    title: 'OpusCapita Solutions Oy (E204503)',
    value: 'E204503',
  },
  {
    title: 'Pagero Oy (003723609900 tai PAGERO)',
    value: '003723609900',
  },
  {
    title: 'Posti Messaging Oy (FI28768767)',
    value: 'FI28768767',
  },
  {
    title: 'PostNord Strålfors Oy (003701150617)',
    value: '003701150617',
  },
  {
    title: 'Ropo Capital Oy (003714377140)',
    value: '003714377140',
  },
  {
    title: 'Telia Finland Oyj (003703575029)',
    value: '003703575029',
  },
  {
    title: 'TietoEvry Oyj (003701011385)',
    value: '003701011385',
  },
  {
    title: 'Tradeshift Ab (885060259470028)',
    value: '885060259470028',
  },
  {
    title: 'Ålands Post Ab (003722207029)',
    value: '003722207029',
  },
  { value: 'HELSFIHH', title: 'Aktia (HELSFIHH)' },
  { value: 'DABAFIHH', title: 'Danske Bank (DABAFIHH)' },
  { value: 'DNBAFIHX', title: 'DNB (DNBAFIHX)' },
  { value: 'HANDFIHH', title: 'Handelsbanken (HANDFIHH)' },
  { value: 'NDEAFIHH', title: 'Nordea Pankki (NDEAFIHH)' },
  { value: 'ITELFIHH', title: 'Oma Säästöpankki Oyj (ITELFIHH)' },
  { value: 'OKOYFIHH', title: 'Osuuspankit ja Pohjola Pankki (OKOYFIHH)' },
  { value: 'POPFFI22', title: 'POP Pankki (POPFFI22)' },
  { value: 'SBANFIHH', title: 'S-Pankki (SBANFIHH)' },
  { value: 'TAPIFI22', title: 'S-Pankki (ent. LähiTapiola Pankin) (TAPIFI22)' },
  { value: 'ITELFIHH', title: 'Säästöpankit (ITELFIHH)' },
  { value: 'AABAFI22', title: 'Ålandsbanken (AABAFI22)' },
]

function BillingOperatorSelect(props) {
  const { onChange, value, label } = props
  const [operator, setOperator] = useState(value)
  const handleChange = (e) => {
    const { value } = e.target
    setOperator(value)
    onChange(value)
  }
  return (
    <FormControl>
      <InputLabel id='select-label'>{label}</InputLabel>
      <Select labelId='select-label' id='simple-select' value={operator} onChange={handleChange}>
        {EINVOICE_OPERATORS.map((x) => (
          <MenuItem key={x.value} value={x.value}>
            {x.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  // return <Select value={operator} onChange={handleChange} options={EINVOICE_OPERATORS} />
}

export default BillingOperatorSelect
