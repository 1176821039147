import React from 'react'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { ButtonGroup, Button } from '@material-ui/core'
import moment from 'moment'

const datenavidation = { cursor: 'pointer', margin: 10 }

const WeekSelector = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = React.useState(moment.utc())
  const [selectedWeek, setSelectedWeek] = React.useState(
    moment.utc().startOf('week')
  )

  const moveWeek = (weeks) => {
    let date
    if (weeks > 0) {
      date = moment.utc(selectedWeek).add(weeks, 'weeks')
    } else if (weeks < 0) {
      date = moment.utc(selectedWeek).subtract(-weeks, 'weeks')
    } else {
      date = moment.utc()
    }
    if (date.isValid()) {
      setSelectedWeek(date)
      changeDate(date)
    }
  }
  
  const changeDate = (date) => {
    setSelectedDate(date)
    if(onChange) onChange(date)
  }

  const today = moment.utc(selectedWeek)
  return (
    <React.Fragment>
    <div>
      <KeyboardArrowLeftIcon
        onClick={() => moveWeek(-1)}
        style={datenavidation}
      />
      <strong style={{ textTransform: 'capitalize' }}>
        Viikko{' '}
        {moment.utc(selectedDate).format('WW (dddd D.M.YYYY)')}
      </strong>
      <KeyboardArrowRightIcon
        onClick={() => moveWeek(1)}
        style={datenavidation}
      />
    </div>
    <div>
        <ButtonGroup variant="outlined" size='small' color='primary'>
          {
    ['maanantai', 'tiistai', 'keskiviikko', 'torstai', 'perjantai', 'lauantai', 'sunnuntai'].map((day, index) => {
        const dayIndex = index + 1
        const move =
          dayIndex - today.isoWeekday() < 0 ? 7 + (dayIndex - today.isoWeekday()) : dayIndex - today.isoWeekday()
        const weekday = moment.utc(selectedWeek).add(move, 'days')
        return (
          <Button
            key={index}
            onClick={() => changeDate(weekday)}
            variant={moment.utc(selectedDate).isoWeekday() === weekday.isoWeekday() ? 'contained' : 'outlined'}
          >
            {day}
          </Button>
          )
        }
    )
      }
        </ButtonGroup>
      </div>
    </React.Fragment>
  )
}

export default WeekSelector
