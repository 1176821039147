import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button } from 'reactstrap'

// core components
// import { filterCaseInsensitive } from '../../components/FalconTable'
import FalconModal from '../../components/Inputs/FalconModal'
import { getOrganisationSchema, getOrganisationUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import OrganisationModal from '../../components/Inputs/OrganisationModal'
// import { DataGrid } from '@mui/x-data-grid'

import { Typography, ListItemText, ListItem, Divider, List, FormControl, OutlinedInput, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import OrganisationDetails from '../../components/Details/OrganisationDetails'
import Infobox from '../../components/Infos/Infobox'
import { Index } from 'flexsearch'

const allowed = [
  'id',
  'name',
  'businessId',
  'address',
  'city',
  'zipcode',
  'phone',
  'email',
  'billingOvt',
  'billingOperator',
  'billingEmail',
  'billingReference',
  'contacts',
]

function buildIndex(data) {
  const index = new Index({
    tokenize: "full"
  });
  data.forEach((row) => {
    index.add(row.id, row.pattern.join(' '));
  });
  return index;
}

function formatData(data) {
  return data.map((row, i) => {
    const contacts = row.contacts ? row.contacts.map((c) => c.firstname + ' ' + c.lastname) : []
    return { id: row.id, pattern: [`${row.name}`, `${row.businessId}`, ...contacts] }
  });
}

class CustomerOrganisationSearchContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      organisationModalOpen: false,
      selected: null,
      manualOrganisationModalOpen: false,
      pageSize: 20,
      searchInput: '',
      searchresults: [],
      searchindex: null,
    }
  }

  async componentDidMount() {
    this.props.firebase.listenOrganisations().onSnapshot((snapshot) => {
      const data = []
      if (!snapshot.empty) {
        let index = snapshot.docs.length
        snapshot.docs.map(async (doc) => {
          if (doc.data().state === 'deleted') {
            index = index - 1
            if (index === 0) {
              this.allOrganisationsDone(data)
            }
            return
          }
          if (doc.data().organisation) {
            let organisation = await doc.data().organisation.get()
            data.push({ ...organisation.data(), ...doc.data(), id: organisation.id })
          } else {
            data.push({ ...doc.data(), id: doc.id })
          }
          index = index - 1
          if (index === 0) {
            this.allOrganisationsDone(data)
          }
        })
      } else {
        this.allOrganisationsDone([])
      }
    })
  }

  onPagesizeChange = (pageSize) => {
    this.setState({ pageSize })
  }

  allOrganisationsDone = (data) => {
    this.setState({ raw: data }, this.filterData)
  }

  getColDefinitions = () => {
    return [
      {
        headerName: 'Nimi',
        field: 'name',
        flex: 1 / 7,
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Y-tunnus',
        field: 'businessId',
        flex: 1 / 7,
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 1 / 7,
        disableClickEventBubbling: true,
      },
      {
        headerName: 'WWW',
        field: 'www',
        flex: 1 / 7,
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Puhelinnumero',
        field: 'phone',
        flex: 1 / 7,
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Yhteyshenkilö',
        field: 'contactNames',
        flex: 1 / 7,
      },
      {
        flex: 1 / 7,
        disableClickEventBubbling: true,
        headerName: 'Actions',
        field: 'actions',
        maxWidth: 240,
        minWidth: 200,
        width: 210,
        renderCell: (params) => {
          return (
            <div className='actions-right'>
              <Button
                color='primary'
                size='sm'
                onClick={(el) => {
                  // console.log(id)
                  this.handleRowSelect(params.row.id)
                }}
              >
                Yhteyshlö
              </Button>
              <Button
                color='success'
                size='sm'
                onClick={(el) => {
                  this.handleManualEdit(params.row.id)
                }}
              >
                Muokkaa
              </Button>
            </div>
          )
        },
      },
    ]
  }

  handleRowSelect = (e) => {
    const _r = this.state.organisations.find((r) => r.id === e)
    this.setState({ selectedResource: _r })
  }

  handleManualEdit = (e) => {
    const _r = this.state.organisations.find((r) => r.id === e)
    // console.log('_r', _r)
    if (_r && (_r.registrationDate || _r.registeredEntries)) {
      if (!_r.address && _r.addresses) {
        let defaultAddress = _r.addresses[_r.addresses.length - 1]
        _r.address = defaultAddress.street
        if (!_r.zipcode) _r.zipcode = defaultAddress.postCode
        if (!_r.city) _r.city = defaultAddress.city
      }
      const filteredOrganization = Object.keys(_r)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = _r[key]
          return obj
        }, {})
      // console.log('filteredOrganization', filteredOrganization)
      this.setState({ selectedResource: filteredOrganization }, this.toggleManualOrganisationModal)
    } else {
      this.setState({ selectedResource: _r }, this.toggleManualOrganisationModal)
    }
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      let _organisation = { ...this.state.raw[i] }
      if (_organisation.businessid) {
        _organisation.businessId = _organisation.businessid
      }
      if (_organisation.contactDetails) {
        let contacts = _organisation.contactDetails
          .map((cd) => {
            if (cd.language === 'FI') {
              if (cd?.type.toLowerCase() === 'kotisivun www-osoite') {
                if (cd.value && cd.value.length > 0) {
                  return { key: 'www', value: cd.value }
                }
              } else if (cd?.type.toLowerCase() === 'puhelin' || cd?.type.toLowerCase() === 'matkapuhelin') {
                if (cd.value && cd.value.length > 0) {
                  return { key: 'phone', value: cd.value }
                }
              }
            }
            return null
          })
          .filter((x) => x)
        if (contacts) {
          contacts = contacts.flat()
        }
        // console.log(_organisation)
        // eslint-disable-next-line
        contacts.map((c) => {
          // console.log(c)
          if (!_organisation[c.key]) _organisation[c.key] = []
          if (_organisation[c.key] instanceof Array) {
            _organisation[c.key].push(c.value)
          }
        })
        if (_organisation.phone && _organisation.phone instanceof Array) {
          _organisation.phone = _organisation.phone.join(',')
        }
        if (_organisation.www && _organisation.www instanceof Array) {
          _organisation.www = _organisation.www.join(',')
        }
        if (_organisation.contacts) {
          const _contactNames = _organisation.contacts.map((c) => c.firstname + ' ' + c.lastname)
          // console.log(_contactNames)
          if (_contactNames) {
            _organisation.contactNames = _contactNames.join(', ')
          }
        }
      } else if (_organisation.contacts) {
        const _contactNames = _organisation.contacts.map((c) => c.firstname + ' ' + c.lastname)
        // console.log(_contactNames)
        if (_contactNames) {
          _organisation.contactNames = _contactNames.join(', ')
        }
      }
      // _organisation.actions = this.getActions(_organisation.id)
      data.push({ ..._organisation, id: _organisation.id })
    }
    const searchindex = buildIndex(formatData(data)) 
    this.setState({ loading: false, organisations: data, searchresults: data, searchindex })
  }

  addNewOrganisation = () => {
    this.setState({ selectedResource: {} }, this.toggleOrganisationModal)
  }

  toggleOrganisationModal = () => {
    this.setState({
      organisationModalOpen: !this.state.organisationModalOpen,
    })
  }

  addNewManualOrganisation = () => {
    this.setState({ selectedResource: {} }, this.toggleManualOrganisationModal)
  }

  toggleManualOrganisationModal = () => {
    this.setState({
      manualOrganisationModalOpen: !this.state.manualOrganisationModalOpen,
    })
  }

  getActions = (id) => {
    return (
      <>
        <div className='actions-right'>
          {}
          <Button
            color='primary'
            size='sm'
            onClick={(el) => {
              // console.log(id)
              this.handleRowSelect(id)
            }}
          >
            Yhteyshlö
          </Button>
          <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.handleManualEdit(id)
            }}
          >
            Muokkaa
          </Button>
        </div>
      </>
    )
  }

  createManualOrganisationModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.updateOrganisation}
        onCreate={this.props.firebase.createCustomerOrganisation}
        toggle={this.toggleManualOrganisationModal}
        resource={this.state.selectedResource}
        isOpen={this.state.manualOrganisationModalOpen}
        uiSchema={getOrganisationUiSchema()}
        dataSchema={getOrganisationSchema('USER')}
        title={
          this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa organisaatiota' : 'Uusi organisaatio'
        }
      />
    )
  }

  createOrganisationModal = () => {
    return (
      <OrganisationModal
        resource={this.state.selectedResource}
        isOpen={this.state.organisationModalOpen}
        toggle={this.toggleOrganisationModal}
      />
    )
  }

  handleSearchChange = (event) => {
    if (event.target.value.length > 2) {
      const searchresults = this.state.searchindex.search(event.target.value)
      console.log(searchresults)
      const filtered = this.state.organisations.filter((o) => searchresults.includes(o.id))
      this.setState({ searchresults: filtered })
    } else {
      this.setState({ searchresults: this.state.organisations })
    }
    this.setState({ searchInput: event.target.value })
  }

  renderRow = (row) => {
    console.log(row)
    const error = !row.contacts || row.contacts.length < 1
    const backgroundColor = error ? '#FF0000' : 'white'
    let contactNames = 'Ei yhteyshenkilöä'
    if (!error) {
      contactNames = row.contacts.map((c) => c.firstname + ' ' + c.lastname).join(' ')
    }
    let phone = row.phone ? row.phone : ''

    return (
      <React.Fragment>
        <ListItem
          button
          alignItems='flex-start'
          style={{ width: '100%', backgroundColor: backgroundColor }}
          onClick={() => this.handleRowSelect(row.id)}
          selected={this.state.selectedPersonIndex === row.id}
        >
          <ListItemText
            primary={row.name}
            secondary={
              <React.Fragment>
                <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.primary'>
                  {row.businessId + ', ' + contactNames + ', ' + phone}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
      </React.Fragment>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>Loading ...</div>
        </>
      )
    } else {
      // console.log(JSON.stringify(this.state.persons))
      return (
        <>
          <div className='content'>
            <Row className='pt-1 pb-2'>
              <Col md='4'>
                <FormControl fullWidth style={{ backgroundColor: 'white' }}>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    value={this.state.searchInput}
                    onChange={this.handleSearchChange}
                    startAdornment={
                      this.state.showSearch || this.state.searchInput.length === 0 ? (
                        <InputAdornment position='start'>
                          <SearchIcon color='disabled' />
                        </InputAdornment>
                      ) : null
                    }
                    labelWidth={0}
                    // onFocus={this.handleOnFocusSearch}
                    // onBlur={this.handleOnFocusSearch}
                    // onKeyDown={this.keyPress}
                    // endAdornment={
                    //   !this.state.pressedEnter && this.state.searchInput.length > 2 ? (
                    //     <InputAdornment onClick={() => this.searchPersons()} position='end'>
                    //       <KeyboardReturnIcon color='disabled' />
                    //     </InputAdornment>
                    //   ) : null
                    // }
                  />
                </FormControl>
              </Col>
              <Col md='5'>
                <Infobox index={'0'} />
              </Col>
              <Col md='3'>
                <Button onClick={this.addNewOrganisation}>Lisää organisaatio</Button>
                <Button size='sm' color='danger' onClick={this.addNewManualOrganisation}>
                  Lisää organisaatio käsin
                </Button>
              </Col>
            </Row>
            <Row className='no-gutters'>
              <Col md='3' className='border-right no-gutters' style={{ minHeight: '100vh' }}>
                <List component='nav' style={{ padding: 0, maxHeight: '80vh', position: 'relative', overflow: 'auto' }}>
                  {this.state.searchresults && this.state.searchresults.length > 0 ? (
                    this.state.searchresults.map((row) => this.renderRow(row))
                  ) : (
                    <div>Ei yhtään organisaatiota</div>
                  )}  
                </List>
              </Col>
              <Col md='9'>
                {this.state.selectedResource ? <OrganisationDetails organisation={this.state.selectedResource} {...this.props} onReservationSelect={this.openReservationModal} /> : ''}
              </Col>
            </Row>

            {/* <Row>
              <Col md='12'>
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 200px)', backgroundColor: '#FFFFFF' }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      rows={this.state.organisations}
                      columns={this.getColDefinitions()}
                      density='compact'
                      pageSize={this.state.pageSize}
                      onPageSizeChange={(newPageSize) => this.onPagesizeChange(newPageSize)}
                      disableSelectionOnClick
                      getRowClassName={(params) => {
                        if (!params.row.contactNames || params.row.contactNames.length < 1) {
                          return 'error-row'
                        } else {
                          return ''
                        }
                      }}
                      rowsPerPageOptions={[20, 50, 100]}
                      pagination
                    /> 
                  </div>
                </div>
              </Col>
            </Row> 
            // <Row>
            //   <Col md='12'></Col>
            // </Row>*/}
            {this.createOrganisationModal()}
            {this.createManualOrganisationModal()}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(CustomerOrganisationSearchContainer)
