import React, { Component } from 'react'
import { withFirebase } from '../../components/Fb'

import { DataGrid } from '@material-ui/data-grid'
import { GridToolbar } from '@material-ui/data-grid'
import MediaUpload from '../Inputs/MediaUpload'
import { Spinner } from 'reactstrap'
import EditButton from '../Buttons/EditButton'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@material-ui/core'
import DeleteButton from '../Buttons/DeleteButton'

class MediaLibrary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      loading: true,
      openEditModal: false,
    }
  }

  componentDidMount() {
    this.props.firebase.listenMedia().onSnapshot((snapshot) => {
      const _rows = []
      snapshot.forEach((doc) => {
        _rows.push({ id: doc.id, ...doc.data() })
      })
      const rows = _rows.map((row) => {
        const ar = row.metadata ? Math.round((row.metadata.width / row.metadata.height) * 10) / 10 : null
        return {
          ...row,
          size: row.metadata ? row.metadata.width + ' x ' + row.metadata.height : '',
          aspectratio: ar ? ar + ':1' : '',
          name: row.name ? row.name : row.originalName,
        }
      })
      this.setState({ rows, loading: false })
    })
  }

  openEditModal = (id) => {
    this.setState({ openEditModal: true, selectedRow: this.state.rows.find((x) => x.id === id) })
  }

  openDeleteConfirmation = (id) => {
    this.setState({ openDeleteConfirmation: true, selectedRow: this.state.rows.find((x) => x.id === id) })
  }

  getColDefinitions = () => {
    return [
      {
        headerName: 'Nimi',
        field: 'name',
        flex: 1 / 5,
        disableClickEventBubbling: true,
      },
      {
        headerName: 'URL',
        field: 'url',
        flex: 1 / 5,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <a href={params.value} target='_blank' rel='noopener noreferrer'>
              {params.value}
            </a>
          )
        },
      },
      {
        headerName: 'Ladattu',
        field: 'created',
        flex: 1 / 10,
        disableClickEventBubbling: true,
        valueFormatter: (params) => {
          return new Date(params.value.seconds * 1000).toLocaleDateString()
        },
      },
      {
        headerName: 'Alkuperäisen koko',
        field: 'size',
        flex: 1 / 10,
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Kuvasuhde',
        field: 'aspectratio',
        flex: 1 / 10,
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Toiminnot',
        field: 'actions',
        flex: 1 / 8,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <>
              <EditButton
                onClick={() => {
                  this.openEditModal(params.row.id)
                }}
              />
              <DeleteButton
                onClick={() => {
                  this.openDeleteConfirmation(params.row.id)
                }}
              />
            </>
          )
        },
      },
    ]
  }

  onUploaded = () => {
    this.setState({ loading: true })
  }

  onMediaUpdated = (id, name) => {
    this.setState({ loading: true, openEditModal: false })
    this.props.firebase.updateMedia(id, name)
  }

  onMediaDelete = (id) => {
    this.setState({ loading: true, openDeleteConfirmation: false })
    this.props.firebase.deleteMedia(id)
  }

  render() {
    return (
      <div className='content'>
        <div>
          <MediaUpload onUploaded={this.onUploaded} />
        </div>
        <div style={{ display: 'flex', minHeight: 'calc(100vh - 200px)', backgroundColor: '#FFFFFF' }}>
          {this.state.loading ? (
            <div style={{ flexGrow: 1, textAlign: 'center', marginTop: 30 }}>
              <Spinner color='secondary' />
            </div>
          ) : (
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={this.state.rows}
                columns={this.getColDefinitions()}
                density='compact'
                pageSize={20}
                disableSelectionOnClick={true}
                rowsPerPageOptions={[20, 50, 100]}
                pagination
                slots={{ toolbar: GridToolbar }}
              />
            </div>
          )}
        </div>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.openEditModal}
          onClose={() => {
            this.setState({ openEditModal: false })
          }}
        >
          <DialogTitle>Muokkaa</DialogTitle>
          <DialogContent>
            <TextField
              id='outlined-basic'
              label='Nimi'
              variant='outlined'
              fullWidth
              value={this.state.selectedRow ? this.state.selectedRow.name : ''}
              onChange={(e) =>
                this.setState({ nameEdited: true, selectedRow: { ...this.state.selectedRow, name: e.target.value } })
              }
              style={{ marginBottom: 10 }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => this.onMediaUpdated(this.state.selectedRow.id, this.state.selectedRow.name)}
              color='primary'
              disabled={!this.state.nameEdited || this.state.loading}
            >
              Tallenna
            </Button>
            <Button
              size={'xs'}
              onClick={() => {
                this.setState({ openEditModal: false, nameEdited: false, updatedname: '' })
              }}
            >
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.openDeleteConfirmation}
          onClose={() => {
            this.setState({ openDeleteConfirmation: false })
          }}
        >
          <DialogTitle>Vahvista poistaminen</DialogTitle>
          <DialogContent>
            <p>Oletko varma, että haluat poistaa tiedoston {this.state.selectedRow ? this.state.selectedRow.name : ''}?</p>
            <p>Mikäli tiedostoa käytetään jossain, esim. kenttäsponsorina yms., niin poistaminen aiheuttaa sen, että sivulle ilmestyy kadonneen tiedoston merkki <img src='/random.jpg' alt='kuva' /></p>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => this.onMediaDelete(this.state.selectedRow.id)}
              color='secondary'
              disabled={this.state.loading}
            >
              Poista
            </Button>
            <Button
              size={'xs'}
              onClick={() => {
                this.setState({ openDeleteConfirmation: false })
              }}
            >
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withFirebase(MediaLibrary)
