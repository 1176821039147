import React from 'react'
import { withFirebase } from '../Fb'

// reactstrap components
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap'
import FalconModal from '../Inputs/FalconModal'
import {
  getProshopProductCategorySchema,
  getProshopProductCategoryUISchema,
} from '../Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from './ReactTableWrapper'
// core components
// import moment from 'moment'
// import 'moment/locale/fi'
import { filterCaseInsensitive } from '../FalconTable'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DeleteButton from '../Buttons/DeleteButton'
import EditButton from '../Buttons/EditButton'

const transformToDB = (data) => {
  const _data = { ...data }
  _data.price = {}
  _data.price.g = _data.pricegeneral * 100
  delete _data.pricegeneral
  return _data
}

const transformFromDB = (data) => {
  const _data = { ...data }
  _data.pricegeneral = _data.price.g / 100
  delete _data.price
  return _data
}

class ProshopProductCategories extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, selected: null, categories: [], shopCategoryModalOpen: false }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    this.setState({ loading: true })
    this.props.firebase.getProductCategories().then((values) => {
      let categories = []
      if (!values.empty) {
        const _c = values.docs.map((sh) => {
          return { ...sh.data(), id: sh.id }
        })
        categories = _c
      }
      this.setState(
        { categories, loading: false },
        this.filterData
      )
    })
  }

  deleteCategory = (id) => {
    return this.props.firebase.deleteProshopProduct(id).then(() => {
      this.updateData()
    })
  }

  getActions = (id) => {
    return (
      <>
        <div className='actions-right'>
          <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.handleRowSelect(id)
            }}
          >
            <EditIcon fontSize={'inherit'} />
            {' Muokkaa'}
          </Button>
          <Button
            color='danger'
            size='sm'
            onClick={(el) => {
              this.deleteShopProduct(id)
            }}
          >
            <DeleteIcon fontSize={'inherit'} />
            {' Poista'}
          </Button>
        </div>
      </>
    )
  }

  filterData = () => {
    console.log('filterData', this.state.categories)
    if (this.state.categories) {
      const data = this.state.categories.map((r) => {
        const _r = { ...r }
        _r.actions = this.getActions(r.id)
        return _r
      })
      console.log('done filtering', data)
      this.setState({ loading: false, categories: data })
    }
  }

  handleRowSelect = (e) => {
    // console.log(e)
    const category = this.state.categories.find((r) => r.id === e)
    this.setState({ selectedProduct: category }, this.toggleShopProductModal)
  }

  addNewShopCategory = () => {
    this.setState({ selectedCategory: {} }, this.toggleShopCategoryModal)
  }

  toggleShopCategoryModal = () => {
    this.setState({
      shopCategoryModalOpen: !this.state.shopCategoryModalOpen,
    })
  }


  updateShopCategory = async (e) => {
    console.log('updateShopCategory', e)
    return this.props.firebase
      .updateProductCategory(e)
      .then(() => {
        this.updateData()
        this.toggleShopCategoryModal()
      })
  }

  createShopCategory = async (e) => {
    console.log('createExtra', e)
    return this.props.firebase
      .createProductCategory(e)
      .then(() => {
        this.updateData()
        this.toggleShopCategoryModal()
      })
  }


  createShopCategoryModal = () => {
    return (
      <FalconModal
        onUpdate={this.updateShopCategory}
        onCreate={this.createShopCategory}
        toggle={this.toggleShopCategoryModal}
        resource={this.state.selectedCategory}
        isOpen={this.state.shopCategoryModalOpen}
        uiSchema={getProshopProductCategoryUISchema()}
        dataSchema={getProshopProductCategorySchema()}
        title={
          this.state.selectedCategory && this.state.selectedCategory.id
            ? 'Muokkaa kategoriaa'
            : 'Uusi kaategoria'
        }
      />
    )
  }


  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>Loading ...</div>
        </>
      )
    } else {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' md='5' sm='5'>
                  <CardTitle tag='h5'>Tuotekategoriat</CardTitle>
                  <CardBody className='text-muted'>
                  </CardBody>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Button onClick={this.addNewShopCategory}>Lisää tuotekategoria</Button>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <ReactTable
                    data={this.state.categories}
                    height='calc(40vh - 200px)'
                    filterable
                    noDataText='Ei löytynyt yhtään tuotetta'
                    defaultFilterMethod={(filter, row) =>
                      filterCaseInsensitive(filter, row)
                    }
                    columns={[
                      {
                        Header: 'Nimi',
                        accessor: 'displayName',
                      },
                      {
                        Header: ' ',
                        accessor: 'edit',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <EditButton
                              onClick={(el) => {
                                this.handleRowSelect(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                      {
                        Header: ' ',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                          return (
                            <DeleteButton
                              onClick={(el) => {
                                this.deleteShopProduct(params.row.id)
                              }}
                            />
                          )
                        },
                      },
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className='-striped -highlight primary-pagination'
                  />
                </Col>
              </Row>
              <Row>
                <Col md='12'></Col>
              </Row>
            </CardBody>
          </Card>
          {this.state.alert}
          {this.createShopCategoryModal()}
        </>
      )
    }
  }
}

export default withFirebase(ProshopProductCategories)
