import React, { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'
import moment from 'moment'

/* rule properties
  freq: 3
  dtstart: 2021-03-01T00:00:00.000Z
  interval: 1
  wkst: 0
  count: 10
  until: undefined
  tzid: undefined
  bysetpos: undefined
  bymonth: undefined
  bymonthday: [ 1 ]
  byyearday: undefined
  byweekno: undefined
  byweekday: [ 2 ]
  byhour: [ 10 ]
  byminute: [ 0 ]
  bysecond: [ 0 ]
*/

const FREQVALUES = [
  // { value: 0, label: 'Yearly' },
  { value: 1, label: 'Monthly' },
  { value: 2, label: 'Weekly' },
  { value: 3, label: 'Daily' },
]

const WEEKDAYS = [
  {
    value: 0,
    label: 'MO',
  },
  {
    value: 1,
    label: 'TU',
  },
  {
    value: 2,
    label: 'WE',
  },
  {
    value: 3,
    label: 'TH',
  },
  {
    value: 4,
    label: 'FR',
  },
  {
    value: 5,
    label: 'SA',
  },
  {
    value: 6,
    label: 'SU',
  },
]

function RepeatEditor(props) {
  const [rule, setRule] = useState({
    freq: 1,
    dtstart: new Date(),
    interval: 1,
    wkst: RRule.MO,
  })
  const [allDebug, setAllDebug] = useState(null)
  const [ruleString, setRuleString] = useState('')

  useEffect(() => {
    const rrule = new RRule(rule)
    if (rule.count || rule.until) {
      const all = rrule.all()
      const readablelist = all.map((d) => moment(d).format('ddd D.M.YY'))
      setAllDebug(readablelist)
    }
    const st = rrule.toString()
    setRuleString(st)
  }, [rule])

  const handleChangeNum = (e) => {
    const { name, value } = e.target
    const num = parseInt(value)
    if (isNaN(num)) return
    setRule({ ...rule, [name]: num })
  }

  const handleFreqChange = (e) => {
    const { value } = e.target
    const num = parseInt(value)
    if (isNaN(num)) return
    if (num === 1) {
      delete rule.byweekday
    }
    setRule({ ...rule, freq: num })
  }


  const handleChangeDate = (e) => {
    const { name, value } = e.target
    let newDate = moment.utc(value)
    if (name === 'dtstart') {
      newDate = newDate.startOf('day')
    } else if (name === 'until') {
      newDate = newDate.endOf('day')
    }

    setRule({ ...rule, [name]: newDate.toDate() })
  }

  const handleChangeWeekdayArray = (e, v) => {
    console.log('handleChangeWeekdayArray', e, v)
    setRule({ ...rule, byweekday: v })
  }

  const handleChangeString = (e) => {
    const { name, value } = e.target
    setRule({ ...rule, [name]: value })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        dtstart
      </Grid>
      <Grid item xs={8}>
        <TextField
          value={moment(rule.dtstart).format('YYYY-MM-DD')}
          name='dtstart'
          type='date'
          onChange={handleChangeDate}
        />
      </Grid>
      <Grid item xs={4}>
        freq
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <InputLabel id='freq-select-label'>freq</InputLabel>
          <Select
            labelId='freq-select-label'
            id='freq'
            name='freq'
            value={rule.freq}
            label='Age'
            onChange={handleFreqChange}
          >
            {FREQVALUES.map((v) => (
              <MenuItem key={v.value} value={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        interval
      </Grid>
      <Grid item xs={8}>
        <TextField
          type='number'
          name='interval'
          value={rule.interval}
          onChange={handleChangeNum}
        />
      </Grid>
      {/* <Grid item xs={4}>
        count
      </Grid>
      <Grid item xs={8}>
        <TextField
          type='number'
          name='count'
          value={rule.count || ''}
          onChange={handleChangeNum}
        />
      </Grid> */}
      <Grid item xs={4}>
        until
      </Grid>
      <Grid item xs={8}>
        <TextField
          value={moment(rule.until).format('YYYY-MM-DD')}
          name='until'
          type='date'
          onChange={handleChangeDate}
        />
      </Grid>
      {(rule.freq === 3 || rule.freq === 2) && (
        <React.Fragment>
          <Grid item xs={4}>
            byweekday
          </Grid>
          <Grid item xs={8}>
            <ToggleButtonGroup
              value={rule.byweekday}
              onChange={handleChangeWeekdayArray}
              aria-label='text alignment'
            >
              {WEEKDAYS.map((v) => (
                <ToggleButton key={v.value} value={v.value}>
                  {v.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={12}>
        <pre>{JSON.stringify(allDebug, null, 2)}</pre>
        <pre>{JSON.stringify(ruleString, null, 2)}</pre>
      </Grid>
      <Grid item xs={12}>
        <pre>{JSON.stringify(rule, null, 2)}</pre>
      </Grid>
    </Grid>
  )
}

export default RepeatEditor
