import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'
import OpeningTimes from '../../components/Config/OpeningTimes'
import Pulishing from '../../components/Config/Publishing'

// core components

class ConfigOpeningTimes extends React.Component {
  render () {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='12'>
              <Pulishing { ...this.props } />
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <OpeningTimes { ...this.props } />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(ConfigOpeningTimes)
