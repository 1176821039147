import React, { Component } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core'
import ImageSelector from './ImageSelector'
import Edit from '@material-ui/icons/Edit'

class ImageSelectDialog extends Component {
  constructor(props) {
    console.log('ImageSelectDialog', props)
    super(props)
    this.state = {
      edits: {
        posterEditOpen: false,
      },
      images: [],
      thumbnail: null,
      thumbnailSize: null,
    }
  }

  componentDidMount() {
    this.updateData()
    this.updateThumbnail()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.images !== this.props.images) {
      this.updateData()
    }
    if (prevProps.value !== this.props.value) {
      this.updateThumbnail()
    }
  }

  updateThumbnail = () => {
    if(this.props.value) {
      const { url, sizes } = this.props.value
      let defaultSize = ''
      let thumbnailSize = 150
      if (sizes) {
        defaultSize = sizes[1] + '_'
        thumbnailSize = sizes[1]
      }
      const thumbnail = url.split('/').splice(0, url.split('/').length - 1).join('/') + '/' + defaultSize + url.split('/').splice(-1)
      this.setState({ thumbnail, thumbnailSize })
    }
  }

  onSelect = (id) => {
    this.props.onSelect({ target: { value: id } })
  }

  updateData = () => {
    this.setState({ images: this.props.images })
  }

  togglePosterEdit = () => {
    this.setState({ edits: { posterEditOpen: !this.state.edits.posterEditOpen } })
  }

  handlePosterChange = (e) => {
    console.log('valittu', e.target.value)
    const image = this.state.images.find((image) => image.id === e.target.value)
    if (this.props.schema.minaspectratio) {
      if (image && image.metadata) {
        const aspectRatio = image.metadata.width / image.metadata.height
        console.log('aspectRatio', aspectRatio)
        if (aspectRatio < this.props.schema.minaspectratio) {
          alert('Kuvan kuvasuhde on liian pieni:' + aspectRatio.toFixed(1) + ':1, pitää olla 3.5:1 tai suurempi')
          return
        }
      } else {
        alert('Kuvan kuvasuhdetta ei voida tarkistaa. Valitse toinen kuva.')
        return
      }
    }
    this.props.onChange(image)
    this.togglePosterEdit()
  }

  render() {
    return (
      <>
        <div>
          <Typography variant='caption' component={'span'} style={{ color: 'gray' }} gutterBottom>
            {this.props.label}
          </Typography>
          <div style={{ cursor: 'pointer' }} onClick={() => this.togglePosterEdit()}>
            {this.state.thumbnail ? <img width={this.state.thumbnailSize} src={this.state.thumbnail} alt='th' /> : null} <Edit style={{ fontSize: '1rem', verticalAlign: 'middle' }} />
          </div>
        </div>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.edits.posterEditOpen}
          onClose={this.togglePosterEdit}
          aria-labelledby='max-width-dialog-title'
          style={{ zIndex: '9000' }}
        >
          <DialogTitle id='max-width-dialog-title'>Valitse kuva</DialogTitle>
          <DialogContent>
            <ImageSelector images={this.state.images} onSelect={this.handlePosterChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.togglePosterEdit} color='primary'>
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default ImageSelectDialog
