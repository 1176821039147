import React from 'react'
// import DemoAlert from 'views/components/DemoAlert'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  // ButtonGroup,
  // Form,
  // ModalFooter,
} from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import Select from 'react-select'
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import TinyEditor from '../Editor/TinyEditor'
import { Typography } from '@material-ui/core'
import { Prompt } from 'react-router-dom'

const REMINDER_TYPES = [
  { value: 'single', label: 'Irtovuorolaisille (ei laskutusoikeudellisille)', tip: 'Lähtee klo 5 maksamattoman vuoron jälkeisenä aamuna' },
  { value: 'recurring', label: 'Vakiovuorolaisille (ei yrityksille)', tip: 'Lähtee kuun 1. päivä klo 5' },
]

const TIMESOPTIONS = [
  { value: 1, label: 'kerran' },
  { value: 2, label: 'kahdesti' }
]

class ReminderMessageTemplates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reminders: {
        single: { body: '', subject: '', enabled: false, times: 1 },
        recurring: { body: '', subject: '', enabled: false },
      },
      loadingTemplates: true,
      saved: true,
      showMessageModal: false,
      selectedType: 'single',
      singleTimes: '1',
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.getMessageTemplates().onSnapshot((doc) => {
      if (doc.exists && doc.data().reminders) {
        this.setState({
          reminders: doc.data().reminders,
          loadingTemplates: false,
        })
      } else {
        this.setState({
          loadingTemplates: false,
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onSave = (event) => {
    event.preventDefault()
    this.props.firebase
      .addMessageTemplates({
        reminders: this.state.reminders,
      })
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  toggleMessage = () => {
    this.setState({ showMessageModal: !this.state.showMessageModal })
  }

  openEditMessageModal = (type) => {
    this.setState({ showMessageModal: true, selectedType: type })
  }

  handleMessageBodyChange = (e) => {
    let j = this.state.reminders
    j[this.state.selectedType].body = e
    this.setState({ reminders: j, saved: false }, this.toggleMessage)
  }

  handleMessageSubjectChange = (e) => {
    let j = this.state.reminders
    j[this.state.selectedType].subject = e.target.value
    this.setState({ reminders: j, saved: false })
  }

  toggleDynamicHelp = () => {
    this.setState({ showDynamicHelp: !this.state.showDynamicHelp })
  }

  onActivityChange = (event) => {
    let _a = this.state.reminders
    _a[event.props.name].enabled = event.state.value
    this.setState({ reminders: _a, saved: false })
  }

  setSingleTimes = (selection, action) => {
    let _a = this.state.reminders
    _a.single.times = selection.value
    this.setState({ reminders: _a, saved: false })
  }

  messageEditModal = () => {
    return (
      <Modal isOpen={this.state.showMessageModal} toggle={this.toggleMessage} size='lg'>
        <ModalHeader toggle={this.toggleMessage}>Viesti</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for='messageSubject'>Otsikko</Label>
            <Input
              id='messageSubject'
              value={
                this.state.reminders[this.state.selectedType]?.subject
                  ? this.state.reminders[this.state.selectedType]?.subject
                  : ''
              }
              name='messageSubject'
              onChange={this.handleMessageSubjectChange}
            />
          </FormGroup>
          <TinyEditor value={this.state.reminders[this.state.selectedType]?.body} onClose={this.handleMessageBodyChange}  />
        </ModalBody>
        {/* <ModalFooter>
          <Button color='primary' onClick={this.toggleMessage}>
            {'Valmis'}
          </Button>
        </ModalFooter> */}
      </Modal>
    )
  }

  messageTemplateRows = () => {
    return REMINDER_TYPES.map((row, index) => {
      let i = index
      return (
        <Row key={i} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
          <Col sm='6' md='3' xs='9' className='mt-1'>
            <Typography variant='body1'>{row.label}</Typography>
            <Typography variant='caption'>{row.tip}</Typography>
          </Col>
            <Col md='2' sm='2' xs='2'>
            {row.value === 'single' && (
               <FormGroup>
               <Label for={'singletimes'}>Muistutuksien määrä</Label>
                <Select
                className='react-select primary'
                classNamePrefix='react-select'
                name={'singletimes'}
                id={'singletimes'}
                value={
                  this.state.reminders.single.times 
                    ? TIMESOPTIONS.find((x) => this.state.reminders.single.times === x.value)
                    : ''
                }
                defaultValue={TIMESOPTIONS[0]}
                onChange={this.setSingleTimes}
                options={TIMESOPTIONS}
              />
              </FormGroup>
)}
            </Col>
          <Col md='1' sm='1' xs='1'>
            <Label for={'enabled.' + i}>Käytössä</Label>{' '}
            <div name={'enabled.' + i}>
              <Switch
                defaultValue={this.state.reminders[row.value].enabled || false}
                onColor='primary'
                offColor='primary'
                id={'enabled.' + i}
                name={row.value}
                onChange={this.onActivityChange}
              />
            </div>
          </Col>
          <Col md='2' sm='2' xs='2'>
            <Button color={'success'} onClick={() => this.openEditMessageModal(row.value)}>
              Muokkaa
            </Button>
          </Col>
        </Row>
      )
    })
  }

  render() {
    if (!this.state.loadingTemplates) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Maksumuistutusviestit</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.messageTemplateRows()}
                    <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button disabled={this.state.saved} color='primary' onClick={this.onSave}>
                          Tallenna muutokset
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.messageEditModal()}
          </div>
          <Prompt when={!this.state.saved} message='Muutoksia ei ole tallennettu. Haluatko varmasti poistua?' />
        </>
      )
    } else {
      return <></>
    }
  }
}

export default ReminderMessageTemplates
