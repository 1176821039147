import React from 'react'
import { Button } from 'reactstrap'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { CircularProgress } from '@material-ui/core'

function CheckInoutButton(props) {
  const { onClick, loading, disabled } = props

  return (
    <div className='actions-right'>
      <Button disabled={loading || disabled} color='success' size='sm' onClick={onClick}>
        <SwapHorizIcon fontSize={'inherit'} />
        {loading ? <CircularProgress size={10} /> : ' Kuittaa'}
      </Button>
    </div>
  )
}

export default CheckInoutButton