import React from 'react'
import withAppdata from '../AppConfig/withAppdata'
import { Button } from 'reactstrap'
import HelpCenterIcon from '@material-ui/icons/Help'

function VideoHelpButton({ appdata }) {
  const openHelp = (url) => {
    window.open(url, '_blank')
  }

  if (appdata && appdata.videohelp && appdata.videohelp.length > 0) {
    const path = window.location.href
    const videohelp = appdata.videohelp.find((v) => {
      return path.indexOf(v.path) !== -1
    })
    if (videohelp) {
      return (
        <Button size='sm' onClick={() => openHelp(videohelp.url)}>
          {' '}
          <HelpCenterIcon fontSize='small' />{' '}
          Ohjeet
        </Button>
      )
    }
  }
  return null
}

export default withAppdata(VideoHelpButton)
