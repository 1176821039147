import React, { Component } from 'react'
import { Router, Route } from 'react-router-dom'

import { withAuthentication } from './components/Session'
import { WhereToGo } from './components/Auth'
import { createBrowserHistory } from 'history'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/demo/demo.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
// import './assets/scss/paper-dashboard.scss?v=1.1.0'
import './assets/scss/paper-dashboard.scss?v=1.3.0'
import 'adaptivecards/dist/adaptivecards.css'
import LogRocket from 'logrocket'
LogRocket.init('f5hyli/falcon')

const hist = createBrowserHistory()

class App extends Component {
  render() {
    return (
      <Router history={hist}>
        <Route render={(props) => <WhereToGo {...props} />} />
      </Router>
    )
  }
}

export default withAuthentication(App)