import React from 'react'
import { Button } from 'reactstrap'
import { CircularProgress } from '@material-ui/core'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'

function ParticipantsButton(props) {
  const { onClick, loading, title } = props
  return (
    <div className='actions-right'>
      <Button color='success' size='sm' disabled={loading} onClick={onClick}>
        {loading ?
          <CircularProgress size={10} color={'inherit'} /> :
        <PeopleAltIcon fontSize={'inherit'} />
        }{title ? title : null}</Button></div>
  )
}

export default ParticipantsButton