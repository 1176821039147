
export const _resourcePricingSlotClass = {
  displayName: { type: 'string', title: 'Hinnoitteluosan julkaisunimi' },
  days: {
    type: "array",
    title: "Päivät",
    items: {
      type: "string",
      "enum": [
        1,
        2,
        3,
        4,
        5,
        6,
        7
      ],
      'enumNames': [
        'Maanantai',
        'Tiistai',
        'Keskiviikko',
        'Torstai',
        'Perjantai',
        'Lauantai',
        'Sunnuntai',
      ]
    },
    "uniqueItems": true
  },
  starttime: {
    type: 'string',
    title: 'Alkuaika'
  },
  endtime: {
    type: 'string',
    title: 'Loppuaika'
  },
  price: {
    type: 'number',
    title: 'Hinta'
  },
  nonlinearpricing: {
    type: 'boolean',
    title: 'Käytä epälineaarista hinnoittelua'
  },
  // prices: {
  //   type: 'array',
  //   title: 'Hinnat',
  //   items: [
  //     {
  //       title: '30 min',
  //       type: 'number',
  //       default: 0
  //     },
  //     {
  //       title: '1 h',
  //       type: 'number',
  //       default: 0
  //     },
  //     {
  //       title: '1,5 h',
  //       type: 'number',
  //       default: 0
  //     },
  //     {
  //       title: '2 h',
  //       type: 'number',
  //       default: 0
  //     },
  //     {
  //       title: '2,5 h',
  //       type: 'number',
  //       default: 0
  //     },
  //     {
  //       title: '3 h',
  //       type: 'number',
  //       default: 0
  //     },
  //   ]
  // },
  pricingunit: {
    type: 'string',
    title: 'Hinnan yksikkö',
    enum: ['h'],
    enumNames: ['tunti']
//    enum:['h', 'm', 'p', 'krt'],
//    enumNames:['tunti', 'minuutti', 'päivä', 'kerta']
  },
}

export const _resourcePricingSlotClassDeps = {
  nonlinearpricing: {
    oneOf: [
      {
        properties: {
          nonlinearpricing: {
            enum: [true],
          },
          prices: {
            type: 'array',
            title: 'Hinnat',
            items: [
              {
                title: '30 min',
                type: 'number',
                default: 0
              },
              {
                title: '1 h',
                type: 'number',
                default: 0
              },
              {
                title: '1,5 h',
                type: 'number',
                default: 0
              },
              {
                title: '2 h',
                type: 'number',
                default: 0
              },
              {
                title: '2,5 h',
                type: 'number',
                default: 0
              },
              {
                title: '3 h',
                type: 'number',
                default: 0
              },
            ]
          },
        },
      }
    ],
  }
}


export const _resourcePricingSlotUISchema = {
  days: {
    'ui:widget':'checkboxes'
  },
  starttime: {
    'ui:widget':'timeWidget'
  },
  endtime: {
    'ui:widget':'timeWidget'
  }
}

export const _resourcePricingClass = {
  name: { type: 'string', title: 'Hinnoittelun nimi' },
  active: { type: 'boolean', title: 'Active' },
  priority: { type: 'number', title: 'Prioriteetti' },
  validstart: { type: 'string', format: 'date', title: 'Alkupäivä' },
  validend: { type: 'string', format: 'date', title: 'Loppupäivä' },
  zeroprice: {
    type: 'boolean',
    title: 'Valitse tämä mikäli haluat hyväksyä nollahintaisia hinnoitteluosioita tähän hinnastoon. Muuten 0€ hintaiset hinnoitteluosiot ohitetaan.',
  },
  resources: {
    type: "array",
    title: "Resurssit",
    items: {
      type: "string",
      "enum": [
        1,
        2,
        3,
        4,
        5,
        6,
        7
      ],
      'enumNames': [
        'Maanantai',
        'Tiistai',
        'Keskiviikko',
        'Torstai',
        'Perjantai',
        'Lauantai',
        'Sunnuntai',
      ]
    },
    "uniqueItems": true
  }, 
}


export const _resourcePricingUISchema = {
  resources: {
    'ui:widget':'checkboxes'
  },
  priority: {
    'ui:help': 'Pienempi numero on korkeampi prioriteetti'
  }
}

