import React from 'react'
import {
  Col,
  Row,
  // FormGroup,
  // Label,
  // Input,
  // Button,
  // Modal,
  // ModalHeader,
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody,
  CardImg,
} from 'reactstrap'
import { withFirebase } from '../../components/Fb'
import CashierActionBar from './CashierActionBar'
// import { generateName } from '../../randomnames'

// const keywords1 = ['face', 'person', 'human']
// const keywords2 = ['male', 'female', 'girls', 'sport', 'tennis', 'boys']

class PersonsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = { loading: true}
  }

  filterData = () => {
    // console.log('setting loading false')
    // console.log(this.state)
    this.setState({ loading: false })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  // getPersonAndInfo = () => {
  //   const c = {}
  //   c.id = Math.random()
  //   c.name = generateName()
  //   const randIndex = Math.floor(Math.random() * this.state.resources.length)
  //   // console.log('rand index:' + randIndex)
  //   c.location = this.state.resources[randIndex].name
  //   c.category = this.state.resources[randIndex].category
  //   return c
  // }

  async componentDidMount() {
    const _resources = await this.props.firebase.getResourses()
    let _d = _resources.docs.map((sh) => {
      return { ...sh.data(), id: sh.id }
    })
    this.props.firebase.listenPersonsRT().on('value', (snapshot) => {
      const data = []

      // eslint-disable-next-line
      Object.keys(snapshot.val()).map((key) => {
        data.push({ ...snapshot.val()[key], id: key })
      })

      this.setState({ persons: data, resources: _d }, this.filterData)
    })

    // this.unsubscribe = this.props.firebase
    //   .listenPersons()
    //   .onSnapshot((snapshot) => {
    //     let _r = snapshot.docs.map((sh) => {
    //       return { ...sh.data(), id: sh.id }
    //     })
    //     console.log('setting persons and resources')
    //     this.setState({ persons: _r, resources: _d}, this.filterData)
    //   })
  }

  getPersonCard = (person) => {
    return (
      <Card key={person.id} style={{ width: '12rem'}}>
        <CardBody>
          <CardTitle tag='h5'>{person.firstname + ' ' + person.lastname}</CardTitle>
          <CardSubtitle tag='h6' className='mb-2 text-muted'>
          {person.location}
          </CardSubtitle>
          <Row>
            <Col md='4'>
              <CardImg
                top
                src={person.image} 
              />
            </Col>
            <Col>
              <CardText>
              {person.upcoming}
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  createIncoming = () => {
    return Array.apply(null, Array(6)).map(() => {
      const person = this.state.persons[Math.floor(Math.random() * this.state.persons.length)]
      person.image = 'https://randomuser.me/api/portraits/thumb/' + person.gender.replace('a','e') + '/' + Math.floor(Math.random() * 100) + '.jpg'
      person.upcoming = 'Tulevat: ' + (Math.random() < 0.5 ? 'Ei varauksia' : '26.12. klo 12:00 1h')
      person.location = this.state.resources[Math.floor(Math.random() * this.state.resources.length)].name
      return this.getPersonCard(person)
    })
  }

  createInHouse = () => {
    return Array.apply(null, Array(6)).map(() => {
      const person = this.state.persons[Math.floor(Math.random() * this.state.persons.length)]
      person.image = 'https://randomuser.me/api/portraits/thumb/' + person.gender.replace('a','e') + '/' + Math.floor(Math.random() * 100) + '.jpg'
      person.upcoming = 'Tulevat: ' + (Math.random() < 0.5 ? 'Ei varauksia' : '26.12. klo 12:00 1h')
      person.location = this.state.resources[Math.floor(Math.random() * this.state.resources.length)].name
      return this.getPersonCard(person)
    })
  }

  createLeaving = () => {
    return Array.apply(null, Array(6)).map(() => {
      const person = this.state.persons[Math.floor(Math.random() * this.state.persons.length)]
      person.image = 'https://randomuser.me/api/portraits/thumb/' + person.gender.replace('a','e') + '/' + Math.floor(Math.random() * 100) + '.jpg'
      person.upcoming = 'Tulevat: ' + (Math.random() < 0.5 ? 'Ei varauksia' : '26.12. klo 12:00 1h')
      return this.getPersonCard(person)
    })
  }

  //                   Tulevat: {(Math.random() < 0.5 ? 'Ei varauksia' : '26.12. klo 12:00 1h')}

  render() {
    if (!this.state.loading) {
      // console.log('render final')
      return (
        <>
          <Row>
            <Col md='10'>
              <Row className="d-flex flex-row">
                <Col>
                  <h4>Tulevat</h4>
                </Col>
              </Row>
              <Row  className="d-flex flex-row">
                  {this.createIncoming()}
              </Row>
              <Row>
                <Col>
                  <h4>Talossa</h4>
                </Col>
              </Row>
              <Row  className="d-flex flex-row">
                  {this.createInHouse()}
              </Row>
              <Row>
                <Col>
                  <h4>Lähtevät</h4>
                </Col>
              </Row>
              <Row className="d-flex flex-row">
                  {this.createLeaving()}
              </Row>
            </Col>
            <Col md='2'>
              <CashierActionBar />
            </Col>
          </Row>
          {this.state.alert}
        </>
      )
    } else {
      // console.log('render loading')
      return (
        <>
          <Row>
            <Col>Loading</Col>
          </Row>
        </>
      )
    }
  }
}

export default withFirebase(PersonsContainer)
