export const _proshopProductClass = {
  availableinshop: {
    type: 'string',
    title: 'Näytetäänkö verkkokaupassa',
    enum: ['true', 'false'],
    enumNames: ['Kyllä', 'Ei'],
  },
  displayName: { type: 'string', title: 'Asiakkaille näkyvä nimi' },
  description: { type: 'string', title: 'Kuvaus' },
  productcategory: { type: 'string', title: 'Tuotekategoria', enum: [], enumNames: [] },
  brand: { type: 'string', title: 'Valmistaja', enum: [], enumNames: [] },
  producttype: { type: 'string', title: 'Tuotetyyppi' },
}

export const _proshopProductClassDeps = {
  availableinshop: {
    oneOf: [
      {
        properties: {
          availableinshop: {
            enum: ['true'],
          },
          vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 25.5], enumNames: ['0%', '10%', '14%', '25,5%'] },
          image: { type: 'string', title: 'Verkkokaupan tuotekuva', items: { type: 'object', images: [] }, minaspectratio: 1 },
          variations: {
            title: '',
            description: 'Hintavaihtoehdot',
            type: 'array',
            items: {
              type: 'object',
              properties: {
                name: { type: 'string', title: 'Nimi' },
                price: { type: 'number', title: 'Hinta' },
                productCode: { type: 'string', title: 'Tuotekoodi' },
                amount: { type: 'number', title: 'Määrä' },
              },
            },
          },
          nextarrivaldate: { type: 'string', format:'date', title: 'Saapumispäivä' },
          accounting: { type: 'string', title: 'Kirjanpidontili' },
        }
      },
      {
        properties: {
          availableinshop: {
            enum: ['false'],
          },
        }
      }
    ]
  },
}

export const _proshopProductUISchema = {
  description: {
    'ui:widget': 'editorWidget',
  },
  image: {
    'ui:widget': 'imageWidget',
  }
}


export const _proshopBrandClass = {
  displayName: { type: 'string', title: 'Nimi' },
  description: { type: 'string', title: 'Kuvaus' },
  image: { type: 'string', title: 'Kuva', items: { type: 'object', images: [] }, minaspectratio: 1 },
}

export const _proshopBrandUISchema = {
  description: {
    'ui:widget': 'editorWidget',
  },
  image: {
    'ui:widget': 'imageWidget',
  }
}

export const _proshopProductCategoryClass = {
  displayName: { type: 'string', title: 'Nimi' },
}

export const _proshopProductCategoryUISchema = {}
