// import React from 'react'

export const _infotextClass = {
  text: { type: 'string', title: 'Teksti' },
  visible: {
    type: 'string',
    title: 'Kenelle näkyvissä',
    enum: ['admin', 'cashier'],
    enumNames: ['Admin', 'Kassa'],
    default: 'admin',
  },
  // status: { type: 'string', title: 'Tila', enum: ['created', 'pendingsignature', 'signed', 'cancelled'], enumNames:['Luotu', 'Odottaa allekirjoitusta', 'Allekirjoitettu', 'Peruttu'] },
  start: {
    type: 'string',
    format: 'date',
    title: 'Alkaa',
  },
  end: {
    type: 'string',
    format: 'date',
    title: 'Päättyy',
  }
}

export const _infotextClassDeps = {}

export const _infotextUISchema = {
    text: {
      "ui:widget": "textarea",
      "ui:options": {
        "rows": 5
      }
    },
}
