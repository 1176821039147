import React from 'react'
import { withFirebase } from '../Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'

import TarmoShop from './TarmoShop'
import TarmoInfo from './TarmoInfo'
const test = true
// core components

class BuildingMaintenanceProductsContainer extends React.Component {
  render() {
    if (test) {
      return (
        <>
          <div className='content'>
            <Row style={{ marginTop: 90 }}>
              <Col md='12'>
                <TarmoInfo />
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='content'>
            <Row style={{ marginTop: 90 }}>
              <Col md='12'>
                <TarmoShop />
              </Col>
            </Row>
            <Row>
              <Col md='12'>
              </Col>
            </Row>
          </div>
        </>
      )
    }
  }
}

export default withFirebase(BuildingMaintenanceProductsContainer)
