import React, { Component } from 'react'
import { withFirebase } from '../../components/Fb'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import Select from 'react-select'
import { Button } from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import {
  getMaintenanceContractSchema,
  getMaintenanceContractUISchema,
  getMaintenanceServicedescriptionSchema,
  getMaintenanceServicedescriptionUISchema,
} from '../Inputs/dataschemas'
import AddCircle from '@material-ui/icons/AddCircle'
import Visibility from '@material-ui/icons/Visibility'
import TaskEditor from './TaskEditor'
import Edit from '@material-ui/icons/Edit'
import TarmoLogo from './TarmoLogo'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}
const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

const clearState = {
  maintenancecontracts: [],
  selectedMaintenanceContract: null,
  maintenanceContractOptions: [],
  maintenancesrvicedescriptions: [],
  selectedMaintenanceServiceDescription: null,
  maintenanceServiceDescriptionOptions: [],
}

const fillSDWithTask = (servicedescription, task) => {
  const tasksummary = { id: task.id, name: task.name_fi }
  const updatedMaintenanceServiceDescription = { ...servicedescription }
  updatedMaintenanceServiceDescription.taskgroups =
    updatedMaintenanceServiceDescription.taskgroups.map((tg) => {
      if (tg.id === task.taskgroupid) {
        // find task
        if (!tg.tasks) {
          tg.tasks = []
        }
        const taskIndex = tg.tasks.findIndex((t) => t.id === task.id)
        if (taskIndex > -1) {
          tg.tasks[taskIndex] = tasksummary
        } else {
          tg.tasks.push(tasksummary)
        }
      }
      return tg
    })
  return updatedMaintenanceServiceDescription
}

class TarmoAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maintenancecontracts: [],
      maintenanceContractModalOpen: false,
      selectedMaintenanceContract: null,
      maintenanceContractOptions: [],

      maintenancesrvicedescriptions: [],
      maintenanceServiceDescriptionModalOpen: false,
      selectedMaintenanceServiceDescription: null,
      maintenanceServiceDescriptionOptions: [],
      tarmoAdmin: false,
    }
  }


  updateMaintenanceContractsData = () => {
    Promise.all([this.props.firebase.getMaintenanceContracts()]).then(
      (values) => {
        console.log('values', JSON.stringify(values[0], null, 2))
        if (values[0] && values[0].length > 0) {
          const options = values[0]
            .map((mc) => {
              return {
                value: mc.id,
                label: mc.name,
                active: mc.status === 'active' ? true : false,
              }
            })
            .reduce(
              (acc, current) => {
                if (current.active) {
                  acc[0].options.push(current)
                } else {
                  acc[1].options.push(current)
                }
                return acc
              },
              [
                { label: 'Aktiivinen', options: [] },
                { label: 'Ei aktiivinen', options: [] },
              ]
            )

          this.setState({
            ...clearState,
            maintenancecontracts: values[0],
            maintenanceContractOptions: options,
          })
        }
      }
    )
  }

  updateMaintenanceServiceDescriptionsData = () => {
    Promise.all([
      this.props.firebase.getMaintenanceServiceDescriptions(
        this.state.selectedMaintenanceContract.id
      ),
    ]).then((values) => {
      console.log('values', JSON.stringify(values[0], null, 2))
      if (values[0] && values[0].length > 0) {
        const options = values[0].map((mc) => {
          return {
            value: mc.id,
            label: mc.name,
            active: mc.status === 'active' ? true : false,
          }
        })
        if (this.state.selectedMaintenanceServiceDescription) {
          const updatedSelectedMaintenanceServiceDescription = values[0].find(
            (p) => p.id === this.state.selectedMaintenanceServiceDescription.id
          )
          this.setState({
            selectedMaintenanceServiceDescription:
              updatedSelectedMaintenanceServiceDescription,
            maintenancesrvicedescriptions: values[0],
            maintenanceServiceDescriptionOptions: options,
          })
        } else {
          this.setState({
            maintenancesrvicedescriptions: values[0],
            maintenanceServiceDescriptionOptions: options,
          })
        }
      }
    })
  }

  componentDidMount() {
    this.setState({ tarmoAdmin: this.props.firebase.getCustomerId() === 'tarmo' })
    this.updateMaintenanceContractsData()
  }

  selectMaintenanceContract = (selectedOption) => {
    console.log('selectedOption', selectedOption)
    const selectedMaintenanceContract = this.state.maintenancecontracts.find(
      (p) => p.id === selectedOption.value
    )
    this.setState(
      { selectedMaintenanceContract },
      this.updateMaintenanceServiceDescriptionsData
    )
  }

  selectMaintenanceServiceDescription = (selectedOption) => {
    console.log('selectedOption', selectedOption)
    const selectedMaintenanceServiceDescription =
      this.state.maintenancesrvicedescriptions.find(
        (p) => p.id === selectedOption.value
      )
    this.setState({ selectedMaintenanceServiceDescription })
  }

  toggleMaintenanceContractModal = (state) => {
    if (state === 'new') {
      this.setState({
        maintenanceContractModalOpen: !this.state.maintenanceContractModalOpen,
      })
    } else if (this.state.selectedMaintenanceContractId) {
      let _selectedMaintenanceContract = this.state.maintenancecontracts.find(
        (p) => p.id === this.state.selectedMaintenanceContractId
      )
      if (_selectedMaintenanceContract) {
        this.setState({
          selectedMaintenanceContract: _selectedMaintenanceContract,
          maintenanceContractModalOpen:
            !this.state.maintenanceContractModalOpen,
        })
      }
    } else {
      this.setState({
        maintenanceContractModalOpen: !this.state.maintenanceContractModalOpen,
      })
    }
  }

  toggleMaintenanceServiceDescriptionModal = (state) => {
    if (state === 'new') {
      this.setState({
        maintenanceServiceDescriptionModalOpen:
          !this.state.maintenanceServiceDescriptionModalOpen,
      })
    } else if (this.state.selectedMaintenanceServiceDescriptionId) {
      let _selectedMaintenanceServiceDescription =
        this.state.maintenancesrvicedescriptions.find(
          (p) => p.id === this.state.selectedMaintenanceServiceDescriptionId
        )
      if (_selectedMaintenanceServiceDescription) {
        this.setState({
          selectedMaintenanceServiceDescription:
            _selectedMaintenanceServiceDescription,
          maintenanceServiceDescriptionModalOpen:
            !this.state.maintenanceServiceDescriptionModalOpen,
        })
      }
    } else {
      this.setState({
        maintenanceServiceDescriptionModalOpen:
          !this.state.maintenanceServiceDescriptionModalOpen,
      })
    }
  }

  toggleTaskEditModal = (taskid, tgid) => {
    console.log('toggleTaskEditModal', taskid, tgid)
    if (taskid) {
      this.props.firebase.getMaintenanceTask(taskid).then((task) => {
        this.setState({
          selectedTask: task,
          taskEditModalOpen: !this.state.taskEditModalOpen,
        })
      }).catch((error) => {
        console.error('Error getting task', error)
      })
    } else if (!taskid && tgid) {
      console.log('only tgid', tgid)
      this.setState({
        selectedTask: { taskgroupid: tgid },
        taskEditModalOpen: !this.state.taskEditModalOpen,
      })
    } else {
      console.log('no taskid or tgid')
      this.setState({
        taskEditModalOpen: !this.state.taskEditModalOpen,
      })
    }
  }

  createMaintenanceServiceDescription = (data) => {
    data.maintenancecontractid = this.state.selectedMaintenanceContract.id
    return this.props.firebase.createMaintenanceServiceDescription(data)
  }

  handleTaskSave = (data) => {
    console.log('handleTaskSave', data)
    data.servicedescriptionid =
      this.state.selectedMaintenanceServiceDescription.id
    if (!data.id) {
      this.props.firebase
        .createMaintenanceTask(data)
        .then((result) => {
          data.id = result.id
          const updatedMaintenanceServiceDescription = fillSDWithTask(
            this.state.selectedMaintenanceServiceDescription,
            data
          )
          return this.props.firebase.updateMaintenanceServiceDescription(
            updatedMaintenanceServiceDescription
          )
        })
        .then(() => {
          this.toggleTaskEditModal()
          this.updateMaintenanceServiceDescriptionsData()
        })
    } else {
      this.props.firebase
        .updateMaintenanceTask(data)
        .then(() => {
          const updatedMaintenanceServiceDescription = fillSDWithTask(
            this.state.selectedMaintenanceServiceDescription,
            data
          )
          return this.props.firebase.updateMaintenanceServiceDescription(
            updatedMaintenanceServiceDescription
          )
        })
        .then(() => {
          this.toggleTaskEditModal()
          this.updateMaintenanceServiceDescriptionsData()
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
            <TarmoLogo />
          </Grid>

          <Grid item xs={6} sm={6}>
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              closeMenuOnSelect={true}
              name='maintenancecontracts'
              options={this.state.maintenanceContractOptions}
              formatGroupLabel={formatGroupLabel}
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder='Sopimukset ...'
              onChange={this.selectMaintenanceContract}
            />
          </Grid>
          {this.state.tarmoAdmin && (
          <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>
            <Button
              color='primary'
              type='button'
              onClick={(e) => this.toggleMaintenanceContractModal('new')}
            >
              Lisää sopimus
            </Button>
            <Button
              color='primary'
              type='button'
              onClick={(e) => this.toggleMaintenanceContractModal()}
            >
              Muokkaa sopimusta
            </Button>
          </Grid>
            )}
          <Grid item xs={6} sm={6}>
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              closeMenuOnSelect={true}
              name='maintenanceservicedescriptions'
              options={this.state.maintenanceServiceDescriptionOptions}
              formatGroupLabel={formatGroupLabel}
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder='Palvelukuvaukset ...'
              onChange={this.selectMaintenanceServiceDescription}
            />
          </Grid>
          { this.state.tarmoAdmin && (
          <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>
            <Button
              color='primary'
              type='button'
              disabled={!this.state.selectedMaintenanceContract}
              onClick={(e) =>
                this.toggleMaintenanceServiceDescriptionModal('new')
              }
            >
              Lisää palvelukuvaus
            </Button>
            <Button
              color='primary'
              type='button'
              disabled={!this.state.selectedMaintenanceContract}
              onClick={(e) => this.toggleMaintenanceServiceDescriptionModal()}
            >
              Muokkaa palvelukuvausta
            </Button>
          </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <h3>Tehtäväryhmät</h3>
            {this.state.selectedMaintenanceServiceDescription &&
            this.state.selectedMaintenanceServiceDescription.taskgroups ? (
              this.state.selectedMaintenanceServiceDescription.taskgroups.map(
                (tg) => (
                  <Paper style={{ padding: 10, margin: 10 }} key={tg.id}>
                  {/* <div key={tg.id}> */}
                    <Typography variant='h6' gutterBottom>{tg.name}</Typography>
                    <Typography variant='body2'gutterBottom>{tg.description}</Typography>
                    <Typography component={'span'} variant='body1'>Tehtävät</Typography>
                    {this.state.tarmoAdmin && (
                    <IconButton
                      onClick={(e) => this.toggleTaskEditModal(null, tg.id)}
                    >
                      <AddCircle />
                    </IconButton>
                    )}
                    <ul>
                      {tg.tasks &&
                        tg.tasks.map((t) => (
                          <li key={t.id}>
                            {t.name}{' '}
                            <IconButton
                              onClick={(e) =>
                                this.toggleTaskEditModal(t.id, tg.id)
                              }
                            >
                              {this.state.tarmoAdmin ?
                                <Edit />
                                : <Visibility />}
                              </IconButton>
                          </li>
                        ))}
                    </ul>
                    </Paper>
                  // </div>
                )
              )
            ) : (
              <p>Ei tehtäväryhmiä</p>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <h3>Debug</h3>
            <pre>{JSON.stringify(this.state, null, 2)}</pre>
          </Grid> */}
        </Grid>

        <FalconModal
          onCreate={this.props.firebase.createMaintenanceContract}
          onUpdate={this.props.firebase.updateMaintenanceContract}
          modifiedCallback={this.updateMaintenanceContractsData}
          toggle={this.toggleMaintenanceContractModal}
          cancel={this.toggleMaintenanceContractModal}
          resource={this.state.selectedMaintenanceContract}
          isOpen={this.state.maintenanceContractModalOpen}
          uiSchema={getMaintenanceContractUISchema()}
          dataSchema={getMaintenanceContractSchema()}
          title={
            this.state.selectedMaintenanceContract &&
            this.state.selectedMaintenanceContract.id
              ? 'Muokkaa sopimusta'
              : 'Uusi sopimus'
          }
        />
        <FalconModal
          size='xl'
          onCreate={this.createMaintenanceServiceDescription}
          onUpdate={this.props.firebase.updateMaintenanceServiceDescription}
          modifiedCallback={this.updateMaintenanceServiceDescriptionsData}
          toggle={this.toggleMaintenanceServiceDescriptionModal}
          cancel={this.toggleMaintenanceServiceDescriptionModal}
          resource={this.state.selectedMaintenanceServiceDescription}
          isOpen={this.state.maintenanceServiceDescriptionModalOpen}
          uiSchema={getMaintenanceServicedescriptionUISchema()}
          dataSchema={getMaintenanceServicedescriptionSchema()}
          title={
            this.state.selectedMaintenanceServiceDescription &&
            this.state.selectedMaintenanceServiceDescription.id
              ? 'Muokkaa palvelukuvausta'
              : 'Uusi palvelukuvaus'
          }
        />

        <Dialog
          open={this.state.taskEditModalOpen}
          onClose={() => this.toggleTaskEditModal()}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle id='alert-dialog-title'>Tehtävä</DialogTitle>
          <DialogContent>
            <TaskEditor
              languages={[
                { title: 'Suomi', code: 'fi' },
                { title: 'English', code: 'en' },
              ]}
              onSave={this.handleTaskSave}
              task={this.state.selectedTask}
            />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withFirebase(TarmoAdmin)
