import moment from 'moment'
import 'moment/locale/fi'

const specialCategories = ['tennis taivallahti', 'aktia', 'martinmäki']
const doesContain = (element, category) => category.indexOf(element) > -1 
const DEFAULT_VAT = 10

const venueArray = [
  { id: 'IBsXT5YwUuPEqOpsogCG', category: 'tennis taivallahti',  name: 'Taivallahti', fullName: 'Taivallahden Tenniskeskus'},
  { id: 'HjoKJ8lX2gSavJZ85nJ3', name: 'Tali', fullName: 'Talin Tenniskeskus'},
  { id: '', category: 'martinmäki', name: 'Martinmäki', fullName: 'Martinmäen Tenniskeskus'},
  { id: '', category: 'aktia', name: 'Aktia', fullName: 'Aktia-halli'},
]

class Reservation {
  constructor(dbObject) {
    if (typeof dbObject.data === 'function') {
      this._reservation = { ...dbObject.data(), id: dbObject.id }
    } else {
      this._reservation = dbObject
    }
  }

  getId() { return this._reservation.id }

  getPayers() {
    if (this._reservation.persons && this._reservation.persons.payers) {
      return this._reservation.persons.payers
    } else {
      return []
    }
  }

  getFirstPayerSimple () {
    if (this._reservation.persons && this._reservation.persons.payers && this._reservation.persons.payers.length > 0) {
      if (this._reservation.persons.payers[0].businessId) {
        const { email, firstname, lastname, phone, id } = this._reservation.persons.payers[0].contacts[0]
        return { email, firstname, lastname, phone, id }
      } else {
        const { email, firstname, lastname, phone, id } = this._reservation.persons.payers[0]
        return { email, firstname, lastname, phone, id }
      }
    } else {
      return null
    }
  }

  getPayerOrganisation () {
    if (this._reservation.persons && this._reservation.persons.payers && this._reservation.persons.payers.length > 0) {
      if (this._reservation.persons.payers[0].businessId) {
        const { id, businessId, name } = this._reservation.persons.payers[0]
        const { email, firstname, lastname, phone, personid } = this._reservation.persons.payers[0].contacts[0]
        return { email, firstname, lastname, phone, personid, id, businessId, name }
      }
    } else {
      return null
    }
  }

  getPaymentStatus (occurencyDate) {
    let sumOpen = 0
    let allReadyPaid = 0
    const firstOccurency = moment(this.getFirstOccurency().date).format('YYYY-MM-DD')
    const thisOccurency = occurencyDate ? occurencyDate : firstOccurency
    const cost = this.getCost()
    let invoicepayment = 0
    const occurency = this.getRecurrencyDates().find((o) => {
      if (o.date) {
        return o.date === occurencyDate
      }
      return false
    })
    if (occurency && occurency.state === 'onsale') {
      if (occurency.refundinvoicing) {
        invoicepayment = ((occurency.refundinvoicing / 100) * cost ).toFixed(2)
      }
    }


    const payments = this.getPayments()
    // const notRecurrent = !this.isRecurrent()
    if (payments) {
      allReadyPaid = payments.reduce((acc, cur) => {
        if (cur.type === 'refund') {
          return acc
        }
        const paymentOccurency = cur.occurency ? cur.occurency : firstOccurency // safe to fix fact that first payments were added without occurency date
        const isPaymentForThisOccurency = thisOccurency === paymentOccurency
        if (isPaymentForThisOccurency) {
          return acc + cur.amount / 100
        } else {
          return acc
        }
      }, 0)
    }
    console.log('allReadyPaid', allReadyPaid, 'invoicepayment', invoicepayment, 'cost', cost, 'payments', payments, 'occurencyDate', occurencyDate, 'thisOccurency', thisOccurency, 'firstOccurency', firstOccurency, 'recurrencyDates', this.getRecurrencyDates())
    sumOpen = cost - allReadyPaid - invoicepayment
    if (payments.length > 0) { // payments marked
      const isRefunded = payments.find((p) => p.type === 'refund' && p.typelabel === 'Saldopalautus' && p.occurency === thisOccurency)
      if (isRefunded) {
        sumOpen = 0
      }
    }

    return { cost, paid: allReadyPaid, unpaid: sumOpen }

  }

  getPaymentStatusWithoutInvoicing (occurencyDate) {
    let sumOpen = 0
    let allReadyPaid = 0
    const firstOccurency = moment(this.getFirstOccurency().date).format('YYYY-MM-DD')
    const thisOccurency = occurencyDate ? occurencyDate : firstOccurency
    const cost = this.getCost()
    let invoicepayment = 0
    const occurency = this.getRecurrencyDates().find((o) => {
      if (o.date) {
        return o.date === occurencyDate
      }
      return false
    })
    if (occurency && occurency.state === 'onsale') {
      if (occurency.refundinvoicing) {
        invoicepayment = ((occurency.refundinvoicing / 100) * cost).toFixed(2)
      }
    }

    const payments = this.getPayments().filter((x) => x.type !== 'invoice')
    if (payments) {
      allReadyPaid = payments.reduce((acc, cur) => {
        const paymentOccurency = cur.occurency ? cur.occurency : firstOccurency // safe to fix fact that first payments were added without occurency date
        const isPaymentForThisOccurency = thisOccurency === paymentOccurency
        if (isPaymentForThisOccurency) {
          return acc + cur.amount / 100
        } else {
          return acc
        }
      }, 0)
    }
    sumOpen = cost - allReadyPaid - invoicepayment
    if (sumOpen === cost && payments.length > 0) { // payments marked
      const isRefunded = payments.find((p) => p.type === 'refund' && p.typelabel === 'Saldopalautus')
      if (isRefunded) {
        sumOpen = 0
      }
    }
    return { cost, paid: allReadyPaid, unpaid: sumOpen }
  }


  getResourceVat (resourceArray) {
    let resource = null
    if (this._reservation.resourcegroups[0] && this._reservation.resourcegroups[0].resources[0]) {
      resource = this._reservation.resourcegroups[0].resources[0]
    }
    if (resourceArray && resource) {
      const _updatedResource = resourceArray.find((r) => r.id === resource.id)
      if (_updatedResource) {
        resource.type = _updatedResource.type
      }
    }
    if (resource) {
      if (resource.type === 'court') {
        return 10
      }
      if (resource.type === 'room') {
        return 24
      }
    }
    else {
      return DEFAULT_VAT
    }
    return DEFAULT_VAT
  }

  getCost () {
    let cost = 0
    if (this._reservation.resourcegroups[0]) {
      if (this._reservation.resourcegroups[0].hasOwnProperty('cost')) {
        cost = parseFloat(this._reservation.resourcegroups[0].cost)
      }
      if (this._reservation.resourcegroups[0].hasOwnProperty('overridecost')) {
        if (typeof this._reservation.resourcegroups[0].overridecost === 'string') {
          if (!isNaN(parseFloat(this._reservation.resourcegroups[0].overridecost))) {
            cost = parseFloat(this._reservation.resourcegroups[0].overridecost)
          }
        } else {
          cost = parseFloat(this._reservation.resourcegroups[0].overridecost)
        }
      }
    }
    return cost
  }

  getDuration (unit) {
    if (!unit) unit = 'hour'
    return this.getEndMoment().diff(this.getStartMoment(), unit, true)
  }

  getFirstOccurency () {
    return this.getRecurrencyDates().sort((a, b) => moment(a.date).unix() - moment(b.date).unix())[0]
  }

  addPayment (payment) {
    if (!this._reservation.payments) {
      this._reservation.payments = []
    }
    this._reservation.payments.push(payment)
  }

  addNote (note) {
    if (!this._reservation.notes) {
      this._reservation.notes = []
    }
    this._reservation.notes.push(note)
  }

  deleteNote (id) {
    if (this._reservation.notes) {
      let _n = this._reservation.notes.filter((x) => x.id !== id)
      this._reservation.notes = _n
    }
  }

  getNote (id) {
    if (this._reservation.notes) {
      return this._reservation.notes.find((x) => x.id === id)
    }
    return null
  }


  updateNote (note) {
    if (this._reservation.notes) {
      let _n = this._reservation.notes.map((x) => { if(x.id === note.id) { return note } else { return x }  })
      this._reservation.notes = _n
    }
  }


  getPayments (occurencyDate) {
    if (this._reservation.payments) {
      const ids = []
      const onlineorderids = []
      const _p = this._reservation.payments.filter((x) => {
        if (x.type === 'online' && x.orderid) {
          if (onlineorderids.includes(x.orderid)) {
            return false
          } else {
            onlineorderids.push(x.orderid)
            return true
          }
        } else if (x.id) {
          if (ids.includes(x.id)) {
            return false
          } else {
            ids.push(x.id)
            return true
          }
        } else {
          return true
        }
      }).filter((x) => {
        if (occurencyDate && x.occurency) {
          return x.occurency === occurencyDate
        } else {
          return true
        }
      })
      return _p
    } else {
      return []
    }
  }

  setPayments (payments) {
    this._reservation.payments = payments
    if (this._reservation.payments) {
      return this._reservation.payments
    } else {
      return []
    }
  }

  getOnlinePayments () {
    return this.getPayments().filter((x) => x.type === 'online')
  }

  getCashierPayments () {
    return this.getPayments().filter((x) => x.type === 'pos')
  }

  getManualPayments () {
    return this.getPayments().filter((x) => x.type === 'cash' || x.type.startsWith('sportmoney-'))
  }

  getInvoicePayments () {
    return this.getPayments().filter((x) => x.type === 'invoice')
  }

  getRefundPayments () {
    return this.getPayments().filter((x) => x.type === 'refund')
  }

  getReservationDate (format) {
    if (!format) format = 'YYYY-MM-DD'
    if (
      this._reservation.resourcegroups &&
      this._reservation.resourcegroups.length > 0
    ) {
      return moment.utc(this._reservation.resourcegroups[0].start).format(format)
    }
    return null
  }

  getStartMoment (occurencyDate) {
    if (
      this._reservation.resourcegroups &&
      this._reservation.resourcegroups.length > 0
    ) {
      if (occurencyDate) {
        let occDay = moment.utc(occurencyDate)
        return moment.utc(this._reservation.resourcegroups[0].start).year(occDay.year()).month(occDay.month()).date(occDay.date())
      } else {
        return moment.utc(this._reservation.resourcegroups[0].start)
      }
    }
    return null
  }

  getEndMoment (occurencyDate) {
    if (
      this._reservation.resourcegroups &&
      this._reservation.resourcegroups.length > 0
    ) {
      if (occurencyDate) {
        let occDay = moment.utc(occurencyDate)
        return moment.utc(this._reservation.resourcegroups[0].end).year(occDay.year()).month(occDay.month()).date(occDay.date())
      } else {
        return moment.utc(this._reservation.resourcegroups[0].end)
      }
    }
    return null
  }

  getResource () {
    if (
      this._reservation.resourcegroups &&
      this._reservation.resourcegroups.length > 0 &&
      this._reservation.resourcegroups[0].resources &&
      this._reservation.resourcegroups[0].resources.length > 0
    ) {
      return this._reservation.resourcegroups[0].resources[0]
    }
    return null
  }

  isRecurrent () {
    return this._reservation.recurrencyDates && this._reservation.recurrencyDates.length > 0 && this._reservation.isrecurring
  }

  isMovedRecurrent() {
    if (this._reservation.parentid) {
      return true
    }
    return false
  }

  setResource (resource) {
    if (!this._reservation.resourcegroups) this._reservation.resourcegroups = [{ resources: [] }]
    if (!this._reservation.resourcegroups[0].resources) this._reservation.resourcegroups = [{ resources: [] }]
    // console.log('replacing', this._reservation.resourcegroups[0].resources[0], resource)
    this._reservation.resourcegroups[0].resources[0] = resource
    return this._reservation.resourcegroups[0].resources[0]
  }

  isRefunded (occurencyDate) {
    if (occurencyDate) {
      const refundPayment = this.getPayments().find((p) => p.occurency === occurencyDate && p.type === 'refund' && p.typelabel === 'Saldopalautus')
      if (refundPayment) {
        return true
      } else {
        return false
      }
    } else {
      const refundPayment =  this.getPayments().find((p) => p.type === 'refund' && p.typelabel === 'Saldopalautus')
      if (refundPayment) {
        return true
      } else {
        return false
      }
    }
  }

  isSeasonBilled () {
    if (this._reservation.hasOwnProperty('seasonbilling')) {
      // console.log('has seasonbilling', this._reservation.seasonbilling)
      return this._reservation.seasonbilling
    } else {
      return false
    }
  }

  // TODO check bw comp
  getRecurrencyDates () {
    if (this.isRecurrent()) {
      if (this._reservation.recurrencyDates) {
        return this._reservation.recurrencyDates
      }
      return []
    } else {
      let startMoment = this.getStartMoment()
      let state = this.getState()
      return [{ date: startMoment.format('YYYY-MM-DD'), state }]
    }
  }

  // getRecurrencyDates() {
  //   if (this._reservation.recurrencyDates) {
  //     return this._reservation.recurrencyDates
  //   } else {
  //     return null
  //   }
  // }

  setRecurrencyDates (array) {
    if (this.isRecurrent()) {
      if (this._reservation.recurrencyDates) {
        this._reservation.recurrencyDates = array
      }
    }
  }

  getRecurrencyDatesTypes () {
    if (this._reservation.recurrencyDates && this._reservation.recurrencyDates.length > 0) {
      return typeof this._reservation.recurrencyDates[0]
    }
    return null
  }

  getState (occurencyDate) {
    if (!occurencyDate) {
      if (this._reservation.state === '') {
        return 'reserved'
      } else {
        return this._reservation.state
      }
    } else {
      if (this.isRecurrent()) {
        const dataVersion = this.getRecurrencyDatesTypes()
        if (dataVersion) {
          const occurency = this.getRecurrencyDates().find((o) => {
            if (dataVersion === 'object') {
              return o.date === occurencyDate
            } else {
              return o === occurencyDate
            }
          })
          if (occurency) {
            if (dataVersion === 'object') {
              return occurency.state
            } else {
              return 'reserved'
            }
          } else {
            if (dataVersion === 'object') {
              return null
            } else {
              return 'onsale'
            }
          }
        }
      } else {
        if (this._reservation.state === '') {
          return 'reserved'
        } else {
          return this._reservation.state
        }
      }
    }
    return this._reservation.state
  }

  getShortTitle (occurencyDate) {
    let startTime = null 
    let endTime = null 
    if (occurencyDate) {
      let startDay = moment.utc(occurencyDate)
      startTime = moment.utc(this._reservation.resourcegroups[0].start).year(startDay.year()).month(startDay.month()).date(startDay.date())
      endTime = moment.utc(this._reservation.resourcegroups[0].end).year(startDay.year()).month(startDay.month()).date(startDay.date())
    } else {
      startTime = moment.utc(this._reservation.resourcegroups[0].start)
      endTime = moment.utc(this._reservation.resourcegroups[0].end)
    }
    const payer = this.getFirstPayerSimple()
    return [payer.email, payer.firstname, payer.lastname, this._reservation.resourcegroups[0].resources[0].displayName, startTime.format('dddd D.M.YYYY [klo] HH:mm') + ' - ' +  endTime.format('HH:mm')].join(' ')
  }

  getShortShortTitle (occurencyDate) {
    let startTime = null 
    let endTime = null 
    if (occurencyDate) {
      let startDay = moment.utc(occurencyDate)
      startTime = moment.utc(this._reservation.resourcegroups[0].start).year(startDay.year()).month(startDay.month()).date(startDay.date())
      endTime = moment.utc(this._reservation.resourcegroups[0].end).year(startDay.year()).month(startDay.month()).date(startDay.date())
    } else {
      startTime = moment.utc(this._reservation.resourcegroups[0].start)
      endTime = moment.utc(this._reservation.resourcegroups[0].end)
    }
    // const payer = this.getFirstPayerSimple()
    let _uppercaseCat = this._reservation.resourcegroups[0].resources[0].category
    const _c = this._reservation.resourcegroups[0].resources[0].category
    if (_c) {
      _uppercaseCat = _c.charAt(0).toUpperCase() + _c.slice(1)
    }
    return [this._reservation.resourcegroups[0].resources[0].displayName, startTime.format('dddd D.M.YYYY [klo] HH:mm') + ' - ' +  endTime.format('HH:mm'), '/', _uppercaseCat].join(' ')
    // return [this._reservation.resourcegroups[0].resources[0].displayName, startTime.format('dddd D.M.YYYY [klo] HH:mm') + ' - ' +  endTime.format('HH:mm')].join(' ')
  }

  getInvoicingTitle () {
    let startTime = null 
    let endTime = null 
    if (this.isRecurrent()) {
      let occurencies = this.getRecurrencyDates()
      let startDay = moment.utc(occurencies[0].date)
      startTime = moment.utc(this._reservation.resourcegroups[0].start).year(startDay.year()).month(startDay.month()).date(startDay.date())
      endTime = moment.utc(this._reservation.resourcegroups[0].end).year(startDay.year()).month(startDay.month()).date(startDay.date())
    } else {
      startTime = moment.utc(this._reservation.resourcegroups[0].start)
      endTime = moment.utc(this._reservation.resourcegroups[0].end)
    }
    return [this._reservation.resourcegroups[0].resources[0].displayName, startTime.format('dddd [klo] HH:mm') + ' - ' +  endTime.format('HH:mm'), this.isRecurrent() ? 'Vakiovuoro' : this.isMovedRecurrent() ? 'Siirretty vakiovuoro' : 'Irtovuoro'].join(' ')
  }



  getVenue () {
    const resource = this.getResource()
    if (resource) {
      if (resource.venue) {
        if (typeof resource.venue === 'object') {
          return resource.venue.name
        } else {
          const m = venueArray.find((v) => v.id === resource.venue)
          if (m) {
            return m.fullName
          } else {
            return 'default'
          }
        }
      } else if (resource.category) {
        const specialIndex = specialCategories.findIndex((element) => doesContain(element, resource.category))
        if (specialIndex > -1) {
          let ve = venueArray.find((v) => v.category === specialCategories[specialIndex]) 
          return ve.fullName
        } else {
          return resource.category
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }

  getVenueId () {
    const resource = this.getResource()
    if (resource) {
      if (resource.venue) {
        if (typeof resource.venue === 'object') {
          return resource.venue.id
        } else {
          return resource.venue
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }

  getWallet (customerid) {
    const resource = this.getResource()
    let wallet = 'default'
    if (customerid.startsWith('tali-')) {
      wallet = 'tali'
      if (resource.category && resource.category.indexOf('taivallahti') > -1) {
        wallet = 'taivallahti'
      }
    } else if (customerid.startsWith('evs-')) {
      wallet = 'aktia'
      if (resource.category && resource.category.indexOf('martinmäki') > -1) {
        wallet = 'martinmäki'
      }
    } else if (customerid.startsWith('roimailapelikeskus-')) {
      wallet = 'roimailapelikeskus'
      if (resource.category && resource.category.indexOf('ulko') > -1) {
        wallet = 'rovs'
      }
    } else if (customerid.startsWith('lahdentennisjasquash-')) {
      wallet = 'default'
      if (resource.category && resource.category.indexOf('ulkotennis') > -1) {
        wallet = 'ulkotennis'
      }
    }
    return wallet
  }

  getSport(resourceArray) {
    if (resourceArray) {
      const resourceData = this.getResourceData(resourceArray)
      return resourceData.sport
    } else if (this._reservation.resourcegroups[0].resources[0].category) {
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('sulkapallo') > -1) {
        return 'Sulkapallo'
      }
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('sulis') > -1) {
        return 'Sulkapallo'
      }
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('padel') > -1) {
        return 'Padel'
      }
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('beach volley') > -1) {
        return 'Beach Volley'
      }
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('squash') > -1) {
        return 'Squash'
      }
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('pingis') > -1) {
        return 'Pingis'
      }
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('tennis') > -1) {
        return 'Tennis'
      }
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('golf') > -1) {
        return 'Golf'
      }
      if (this._reservation.resourcegroups[0].resources[0].category.indexOf('pickleball') > -1) {
        return 'Pickleball'
      }
      return 'Tennis'
    } else {
      return ''
    }
    // return ''
  }

  getTags () {
    return this._reservation.tags || []
  }

  getCreation () {
    const r = { channel: 'admin', date: null }
    if (this._reservation.via) {
      r.channel = this._reservation.via
    }
    if (this._reservation.viadate) {
      r.date = this._reservation.viadate
    } else if (this._reservation.log) {
      if (this._reservation.log[0].timestamp) {
        r.date = moment.unix(this._reservation.log[0].timestamp).format('YYYY-MM-DD HH:mm')
      }
    }
    return r
  }

  getStatsRow (venueArray, occurencyDate) {
    // id, reservationdate, channel, occurencydate, venue, sport, starttime, duration, isrecurring, occurencypaymentdate, paymenttype, cost, paid
    const { cost, paid } = this.getPaymentStatus(occurencyDate)
    const { channel, date } = this.getCreation()
    // const payment = this.getPayments()
    return [this.getId(), date, channel, this.getStartMoment(occurencyDate).format(('YYYY-MM-DD')), this.getVenue(venueArray), this.getSport(), this.getStartMoment(occurencyDate).format(('HH')), this.getDuration('minutes'), this.isRecurrent(), cost, paid ].join(';')
  }

  getStatsRows (venueArray) {
    const occurencyDates = this.getRecurrencyDates().filter((x) => x.state === 'reserved').map((x) => { return x.date })
    // id, reservationdate, channel, occurencydate, venue, sport, starttime, duration, isrecurring, occurencypaymentdate, paymenttype, cost, paid
    const rows = occurencyDates.map((od) => {
      const { cost, paid } = this.getPaymentStatus(od)
      const { channel, date } = this.getCreation()
      // const payment = this.getPayments()
      return [this.getId(), date, channel, this.getStartMoment(od).format(('YYYY-MM-DD')), this.getVenue(venueArray), this.getSport(), this.getStartMoment(od).format(('HH')), this.getDuration('minutes'), this.isRecurrent(), cost, paid].join(';')
    })
    return rows
  }

  toJSON () {
    return JSON.stringify(this._reservation, null, 2)
  }

  toObject () {
    return this._reservation
  }

}
export default Reservation
