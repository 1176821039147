import React from 'react'
// import infoimage from '../../assets/img/Tiketit-info.png'
import { Paper, Typography, Button, CircularProgress, TextField } from '@material-ui/core'
import TarmoLogo from './TarmoLogo'

function TarmoInfo({ onSubmit, saving }) {
  const [code, setCode] = React.useState(null)

  return (
    <div>
      <Typography variant='h4'>Tarmopalvelut -toiminnot</Typography>
      <Paper style={{ margin: 20, padding: 20 }}>
        <TarmoLogo />
        <Typography
          variant='body1'
          style={{ marginBottom: 25 }}
          component={'p'}
        >
          Palvelussa pääset hallitsemaan kiinteistösi palvelusopimuksia.
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: 25 }}
          component={'p'}
        >
          Pääset antamaan palautteen viikon tehtävistä.
          Tekemään palvelupyynnön.
          Tekemään Tavaratilaukset.
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: 30 }}
          component={'p'}
        >
          Voit ottaa ominaisuuden käyttöön tästä syöttämällä Tarmopalveluista saamasi koodin tähän.
        </Typography>
        <Typography
          variant='h5'
          style={{ marginBottom: 30 }}
        >
                    Palvelu on pilottivaiheessa lokakuun 2024 loppuun asti.
        </Typography>
        <div>
        <TextField
          label='Koodi'
          value={code}
            onChange={(e) => setCode(e.target.value)}
            style={{ marginBottom: 30 }}
        />
        </div>
        <Button
          disabled={saving || !code}
          variant='contained'
          color='primary'
          onClick={() => onSubmit(code)}
        >
          {saving ? <CircularProgress size={25} color='info' /> : 'Ota käyttöön'}
        </Button>
      </Paper>
      {/* <img src={infoimage} alt='Tarmo-info' /> */}
    </div>
  )
}

export default TarmoInfo
