import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import Select from 'react-select'
import { Typography } from '@material-ui/core'

const LANGUAGES = [
  { value: 'fi', label: 'Suomi' },
  { value: 'sv', label: 'Svensk' },
  { value: 'en', label: 'English' },
]

const INIT_TEMPLATE = {
  venue: 'all',
  lang: 'all',
  channel: 'mail',
  memberships: [],
  enabled: true,
  default: false,
  message: {
    subject: '',
    body: '',
  },
}

const DEFAULT_TERMS = {
  text: {
    fi:
      'Vuoron varaaminen\n' +
      'Vuoron varaaminen aloitetetaan klikkaamalla varauskalenterin Varaa-linkkiä, jonka jälkeen järjestelmä pyytää syöttämään varaukseen tarvittavat tiedot ja vahvistamaan varauksen. ' +
      'Ensimmäisen varauksen yhteydessä sinun on myös luotava itsellesi uusi käyttäjätunnus. Jatkossa voit tehdä varauksia nopeasti käyttäjätunnuksellasi. \n' +
      'Valitsemalla ”Omat varaukset” saat listauksen itse varaamistasi pelivuoroistasi, sekä pystyt tarvittaessa perumaan varaamiasi vuoroja.\n' +
      'Tuntivarauksia ja vuorojen peruutuksia emme ota vastaan sähköpostilla. Jos et pääse tekemään varausjärjestelmän kautta niin soita! \n' +
      'Huom. Varauksen peruminen on tehtävä irtovuorojen osalta viimeistään 24 tuntia ennen pelivuoron alkua (jos alle 24 tuntia vuoron alkuun, niin vuoron voi laittaa myyntiin). ' +
      'Mikäli irtovuoroa ei makseta tai peruta ajoissa, niin se laskutetaan seuraavan kuun alussa varaajalta ja laskuun lisätään laskutuslisä 5 €. ' +
      'Vakiovuoron voi laittaa myyntiin ennen pelivuoron alkua. Jos myyntiinlaitetun varauksen päälle tulee toinen varaus, niin myyntilaitettu varausta ei laskuteta siltä osalta joka myytiin.',

    sv: '',
    en: 'Default terms',
  },
  cancellationlimit: 24,
  quotatimes: 0,
  quotaminutes: 0,
  quotamessage: {
    fi: 'Sinulla on jo maksimimäärä tulevia irtovuoroja varattuna.',
    sv: 'Du har redan maximalt antal framtida.',
    en: 'You have already maximum number of future reservations.',
  },
}

class Terms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      terms: {},
      loading: true,
      saved: true,
      selectedLanguage: 'fi',
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.getTerms().onSnapshot((doc) => {
      if (doc.exists) {
        const _terms = { ...DEFAULT_TERMS, ...doc.data() }
        this.setState({
          terms: _terms,
          loading: false,
        })
      } else {
        this.setState({
          loading: false,
          terms: DEFAULT_TERMS,
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  setLanguage = (selection, action) => {
    this.setState({ selectedLanguage: selection.value })
  }

  handleTermsChange = (event) => {
    let _t = this.state.terms
    _t.text[this.state.selectedLanguage] = event.target.value
    this.setState({ terms: _t, saved: false })
  }

  handlecancellationlimitChange = (event) => {
    let _t = this.state.terms
    _t.cancellationlimit = parseInt(event.target.value)
    this.setState({ terms: _t, saved: false })
  }

  handleQuotatimesChange = (event) => {
    let _t = this.state.terms
    _t.quotatimes = parseInt(event.target.value)
    this.setState({ terms: _t, saved: false })
  }

  handleQuotaminutesChange = (event) => {
    let _t = this.state.terms
    _t.quotaminutes = parseInt(event.target.value)
    this.setState({ terms: _t, saved: false })
  }

  handleQuotatextChange = (event) => {
    let _t = this.state.terms
    _t.quotamessage[this.state.selectedLanguage] = event.target.value
    this.setState({ terms: _t, saved: false })
  }

  addResourceCategoryRow = () => {
    let j = this.state.confirmations
    j.push(JSON.parse(JSON.stringify(INIT_TEMPLATE)))
    this.setState({ confirmations: j })
  }

  onSave = (event) => {
    event.preventDefault()
    this.props.firebase.addTerms(this.state.terms).then((dataSnapshot) => {
      this.setState({ saved: true })
    })
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Varausehdot</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
                      <Col sm='2' md='2' xs='2'>
                        <FormGroup>
                          <Label for={'label.lang'}>Kieli</Label>
                          <Select
                            className='react-select primary'
                            classNamePrefix='react-select'
                            name={'lang.lang'}
                            id={'lang.lang'}
                            value={
                              this.state.selectedLanguage
                                ? LANGUAGES.find((x) => x.value === this.state.selectedLanguage)
                                : ''
                            }
                            defaultValue={LANGUAGES[0]}
                            onChange={this.setLanguage}
                            options={LANGUAGES}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm='2' md='2' xs='2' className='pt-3'>
                        <Typography variant='caption'>Tekstissä käytä {'{CANCELLATION_LIMIT}'} peruutusajan korvaajana.</Typography>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label for='terms'>Ehdot</Label>
                          <Input
                            rows='15'
                            value={this.state.terms.text[this.state.selectedLanguage]}
                            id='terms'
                            name='terms'
                            type='textarea'
                            style={{ maxHeight: 400 }}
                            onChange={this.handleTermsChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={6}>
                        <div style={{ whiteSpace: 'pre-line' }}>
                          {this.state.terms.text[this.state.selectedLanguage].replaceAll(
                            '{CANCELLATION_LIMIT}',
                            this.state.terms.cancellationlimit
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='2'>
                        <Label for='cancellationlimit'>Peruutusraja tunteina</Label>
                        <Input
                          value={this.state.terms.cancellationlimit}
                          id='cancellationlimit'
                          name='cancellationlimit'
                          type='number'
                          onChange={this.handlecancellationlimitChange}
                        />
                      </Col>
                    </Row>
                    <Row className='mt-4 pt-3 border-top'>
                      <Col>
                        <Typography variant='h5'>Tulevien irtovuorojen maksimi varausmäärä</Typography>
                        <Typography variant='caption'>Henkilön rajan voi asettaa ylittymään henkilön oikeudet kohdasta.</Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='2'>
                        <Label for='quotatimes'>Maksimi aktiivisten irtovuorojen määrä. 0 = ei rajaa</Label>
                        <Input
                          value={this.state.terms.quotatimes || 0}
                          id='quotatimes'
                          name='quotatimes'
                          type='number'
                          onChange={this.handleQuotatimesChange}
                        />
                      </Col>
                      <Col sm='2'>
                        <Label for='quotaminutes'>Maksimi aktiivisten irtovuorojen minuuttimäärä. 0 = ei rajaa</Label>
                        <Input
                          value={this.state.terms.quotaminutes || 0}
                          id='quotaminutes'
                          name='quotaminutes'
                          type='number'
                          onChange={this.handleQuotaminutesChange}
                        />
                      </Col>
                      <Col sm={8}>
                        <FormGroup>
                          <Label for='quotatext'>Viesti joka näytetään asiakkaalle rajan ylityttyä.</Label>
                          <Input
                            value={this.state.terms.quotamessage[this.state.selectedLanguage]}
                            id='quotatext'
                            name='quotatext'
                            type='text'
                            style={{ maxHeight: 400 }}
                            onChange={this.handleQuotatextChange}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button disabled={this.state.saved} color='primary' onClick={this.onSave}>
                          Tallenna muutokset
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default Terms
