import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button } from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import { getBenefitCardSchema, getBenefitCardUISchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from '../../components/Lists/ReactTableWrapper'
import { filterCaseInsensitive, getTdProps } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
import EditButton from '../../components/Buttons/EditButton'
import DeleteButton from '../../components/Buttons/DeleteButton'
import Infobox from '../../components/Infos/Infobox'

// core components
const DEFAULT_SPORTS = [
  { key: 'tennis', label: 'Tennis' },
  { key: 'padel', label: 'Padel' },
  { key: 'sulkapallo', label: 'Sulkapallo' },
  { key: 'squash', label: 'Squash' },
  { key: 'pingis', label: 'Pingis' },
  { key: 'biljardi', label: 'Biljardi' },
  { key: 'petanque', label: 'Petanque' },
  { key: 'keilaus', label: 'Keilaus' },
  { key: 'curling', label: 'Curling' },
  { key: 'darts', label: 'Darts' },
  { key: 'jousiammunta', label: 'Jousiammunta' },
  { key: 'pickleball', label: 'Pickleball' },
  { key: 'jalkapallo', label: 'Jalkapallo' },
  { key: 'koripallo', label: 'Koripallo' },
  { key: 'salibandy', label: 'Salibandy' },
  { key: 'lentopallo', label: 'Lentopallo' },
  { key: 'kuntosali', label: 'Kuntosali' },
  { key: 'kuntonyrkkeily', label: 'Kuntonyrkkeily'},
  { key: 'ryhmaliikunta', label: 'Ryhmäliikunta' },
  { key: 'beachvolley', label: 'Beach volley' },
  { key: 'golf', label: 'Golf' },
  { key: 'jääkiekko', label: 'Jääkiekko' },
  { key: 'luistelu', label: 'Luistelu' },
  { key: 'uinti', label: 'Uinti' },
  { key: 'sukellus', label: 'Sukellus' },
  { key: 'ratsastus', label: 'Ratsastus' },
  { key: 'hiihto', label: 'Hiihto' },
  { key: 'laskettelu', label: 'Laskettelu' },
  { key: 'pyöräily', label: 'Pyöräily' },
  { key: 'juoksu', label: 'Juoksu' },
  { key: 'kävely', label: 'Kävely' },
  { key: 'melonta', label: 'Melonta' },
  { key: 'soutu', label: 'Soutu' },
  { key: 'purjehdus', label: 'Purjehdus' },
  { key: 'purjelautailu', label: 'Purjelautailu' },
  { key: 'kajakki', label: 'Kajakki' },
  { key: 'sähly', label: 'Sähly' },
  { key: 'frisbeegolf', label: 'Frisbeegolf' },
  { key: 'pilates', label: 'Pilates' },
  { key: 'muu', label: 'Muu' },
]

class BenefitCardsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, cardModalOpen: false, cards: null }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    Promise.all([this.props.firebase.getSports(), this.props.firebase.getBenefitCards()]).then((values) => {
      const cards = []
      const sports = [...DEFAULT_SPORTS]
      const sportdoc = values[0]
      // eslint-disable-next-line
      if (sportdoc.exists) {
        sports.push(...sportdoc.data().list)
      }
      // unique array of sports
      const uniqueSports = [...new Map(sports.map((item) => [item['key'], item])).values()]
      uniqueSports.sort((a, b) => a.label.localeCompare(b.label))
      // benefit cards
      if (!values[1].empty) {
        let _d = values[1].docs
          .map((sh) => {
            return { ...sh.data(), id: sh.id }
          }).filter((sh) => !sh.collection || sh.collection === 'activities')
        this.setState(
          {
            raw: _d,
            sports: uniqueSports,
          },
          this.filterData
        )
      } else {
        this.setState({ raw: cards, sports }, this.filterData)
      }
    })
  }

  handleRowSelect = (e) => {
    const _result = this.state.cards.filter((r) => r.id === e)
    this.setState({ selectedResource: _result[0] }, this.toggleCardModal)
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      data.push({
        ...this.state.raw[i],
        price: this.state.raw[i].price ? this.state.raw[i].price / 100 : 0,
        actions: this.getActions(this.state.raw[i]),
      })
    }
    this.setState({ loading: false, cards: data })
  }

  deleteCard = async (id) => {
    await this.props.firebase.deleteBenefitCard(id)
    this.setState({ loading: true }, this.updateData)
  }

  getActions = (row) => {
    return (
      <>
        <div className='actions-right'>
          {}
          <Button
            color='danger'
            size='sm'
            onClick={(el) => {
              this.deleteCard(row.id)
            }}
          >
            <span className='btn-label'>
              <i className='nc-icon nc-simple-remove' />
            </span>
          </Button>
        </div>
      </>
    )
  }

  addNewCard = () => {
    this.setState({ selectedResource: {} }, this.toggleCardModal)
  }

  toggleCardModal = () => {
    this.setState({
      cardModalOpen: !this.state.cardModalOpen,
    })
  }

  getEnhancedCardSchema = () => {
    const schema = getBenefitCardSchema()
    const sport = { ...schema.properties.sport }
    if (this.state.sports) {
      for (let i = 0; i < this.state.sports.length; i++) {
        sport.enum.push(this.state.sports[i].label)
        sport.enumNames.push(this.state.sports[i].label)
      }
    }
    schema.properties.sport = sport
    return schema
  }

  createCardModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.updateBenefitCard}
        onCreate={this.props.firebase.createBenefitCard}
        modifiedCallback={this.updateData}
        toggle={this.toggleCardModal}
        resource={this.state.selectedResource}
        isOpen={this.state.cardModalOpen}
        uiSchema={getBenefitCardUISchema()}
        dataSchema={this.getEnhancedCardSchema()}
        title={
          this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa sarjakorttia' : 'Uusi sarjakortti'
        }
        size='lg'
      />
    )
  }

  render() {
    if (this.state.loading) {
      return <FalconLoading />
    } else {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.addNewCard}>Lisää aktiviteetin sarjakortti</Button>
              </Col>
              <Col>
                <Infobox index={'0'}/>
                {/* <Alert severity='info'>
                  <strong>Info!</strong> Sarjakortit ovat aktiviteetteihin/tapahtumiin liittyviä kortteja.
                </Alert> */}
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.cards}
                  filterable
                  noDataText='Ei löytynyt yhtään sarjakorttia'
                  defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                  columns={[
                    {
                      Header: 'Nimi',
                      accessor: 'name',
                    },
                    {
                      Header: 'Laji',
                      accessor: 'sport',
                    },
                    {
                      Header: 'Hinta',
                      accessor: 'price',
                      renderCell: (params) => {
                        return <span>{params.value} €</span>
                      }
                    },
                    {
                      Header: 'Tyyppi',
                      accessor: 'type',
                      renderCell: (params) => {
                        return <span>{params.value === 'time' ? 'Aikapohjainen' : 'Kertapohjainen'}</span>
                      }
                    },
                    {
                      Header: 'Muokkaa',
                      accessor: 'edit',
                      filterable: false,
                      sortable: false,
                      width: 100,
                      renderCell: (params) => {
                        return (
                          <EditButton
                            onClick={(el) => {
                              this.handleRowSelect(params.row.id)
                            }}
                          />
                        )
                      },
                    },
                    {
                      Header: 'Poista',
                      accessor: 'actions',
                      filterable: false,
                      sortable: false,
                      width: 100,
                      renderCell: (params) => {
                        return (
                          <DeleteButton
                            onClick={(el) => {
                              this.deleteCard(params.row.id)
                            }}
                          />
                        )
                      },
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect)}
                  className='-striped -highlight primary-pagination'
                />
              </Col>
            </Row>
            {this.state.alert}
            {this.createCardModal()}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(BenefitCardsContainer)
