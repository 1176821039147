import React, { Component } from 'react'
import {
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import TinyEditor from '../Editor/TinyEditor'
import moment from 'moment'
import Reservation from '../Classes/Reservation'
import { withFirebase } from '../Fb'

const _findMessageTemplate = (messagetemplates, reservation, templateName) => {
  console.log('_findMessageTemplate', {
    messagetemplates,
    reservation,
    templateName,
  })

  const messagetype = templateName
  // const venue = reservation.getVenueId()
  const resource = reservation.getResource()
  const category = resource.category
  const resourceid = resource.id
  const starttime = parseInt(reservation.getStartMoment().format('HHmm'))
  const day = reservation.getStartMoment().isoWeekday()
  console.log(
    'day startime',
    day,
    starttime,
    reservation.getStartMoment().format('YYYY-MM-DD HH:mm dddd')
  )
  const temps = messagetemplates
    .filter((x) => x.enabled && x.type === messagetype)
    .map((x) => {
      let isCategoryBased = true
      if (x.resources && x.resources.length > 0) {
        isCategoryBased = false
      }
      let oldNoSchedule = false
      if (!x.schedule) {
        oldNoSchedule = true
        x.schedule = []
      }
      if (!x.resources) x.resources = []
      let emptySchedule = false
      if (x.schedule.length === 0) {
        emptySchedule = true
      }
      return { ...x, isCategoryBased, emptySchedule, oldNoSchedule }
    })
    .map((x) => {
      return {
        ...x,
        schedule: x.schedule.map((y) => {
          return {
            ...y,
            start: parseInt(y.start.replace(':', '')),
            end: parseInt(y.end.replace(':', '')),
          }
        }),
      }
    })

  let _default = null
  let _category = null
  let _resource = null

  for (let messagetemplate of temps) {
    if (messagetemplate.default && messagetemplate.category === 'all') {
      _default = messagetemplate
      continue
    }
    if (
      messagetemplate.category === category &&
      messagetemplate.isCategoryBased
    ) {
      if (messagetemplate.oldNoSchedule) {
        _category = messagetemplate
        continue
      } else {
        if (messagetemplate.emptySchedule) {
          _category = messagetemplate
          continue
        } else {
          if (
            messagetemplate.schedule.find(
              (x) => x.day === day && x.start <= starttime && x.end > starttime
            )
          ) {
            _category = messagetemplate
            continue
          }
        }
      }
    }
    if (
      !messagetemplate.isCategoryBased &&
      messagetemplate.resources &&
      messagetemplate.resources.includes(resourceid)
    ) {
      if (messagetemplate.emptySchedule) {
        _resource = messagetemplate
        continue
      } else {
        if (
          messagetemplate.schedule.find(
            (x) => x.day === day && x.start <= starttime && x.end > starttime
          )
        ) {
          _resource = messagetemplate
          continue
        }
      }
    }
  }

  if (_resource) {
    console.log('resource specific template found', { template: _resource })
    return _resource.message
  } else if (_category) {
    console.log('category specific template found', { template: _category })
    return _category.message
  } else if (_default) {
    console.log('default template found', { template: _default })
    return _default.message
  } else {
    console.error('no message template found', reservation.getId(), {
      messagetemplates,
    })
    return null
  }

  // let _f = null
  // let defaultTemplate = null
  // if (messagetemplates && messagetemplates.length > 0) {
  //   const venue = reservation.getVenueId()
  //   const resource = reservation.getResource()
  //   const category = resource.category
  //   const resourceid = resource.id
  //   const starttime = parseInt(reservation.getStartMoment().format('HHmm'))
  //   const day = reservation.getStartMoment().isoWeekday()
  //   console.log('day startime', day, starttime, reservation.getStartMoment().format('YYYY-MM-DD HH:mm dddd'))

  //   const _temps = messagetemplates.filter((x) => x.enabled && x.type === messagetype).map((x) => {
  //     let isCategoryBased = true
  //     if (x.resources && x.resources.length > 0) {
  //       isCategoryBased = false
  //     }
  //     let oldNoSchedule = false
  //     if (!x.schedule) {
  //       oldNoSchedule = true
  //       x.schedule = []
  //     }
  //     if (!x.resources) x.resources = []
  //     let emptySchedule = false
  //     if (x.schedule.length === 0) {
  //       emptySchedule = true
  //     }
  //     return { ...x, isCategoryBased, emptySchedule, oldNoSchedule }
  //   })

  //   console.log('_temps', { t: _temps })
  //   defaultTemplate = _temps.find((x) => x.default && x.category === 'all')
  //   console.log('defaultTemplate', defaultTemplate)

  //   const __temps = _temps.filter((x) => !(x.default && x.category === 'all'))

  //   // old categorybased template
  //   let _g = __temps.find((x) => category && x.category === category && x.isCategoryBased && x.oldNoSchedule) //_temps.find((x) => venue && x.venue === venue && category && x.category === category)
  //   console.log('_g', _g)

  //   const scheduledTemps = __temps.filter((x) => !x.oldNoSchedule)
  //     .filter((x) => !x.emptySchedule)
  //     .map((x) => { return { ...x, schedule: x.schedule.map((y) => { return { ...y, start: parseInt(y.start.replace(':', '')), end: parseInt(y.end.replace(':', '')) } }) } })
  //   console.log('scheduledTemps', { scheduledTemps })

  //   let _matchingScheduled = scheduledTemps.filter((x) => x.schedule.find((y) => y.day === day && y.start <= starttime && y.end > starttime))
  //   console.log('_matchingScheduled', { s: _matchingScheduled })

  //   if (_matchingScheduled && _matchingScheduled.length > 0) {
  //     // check if there is resource or category specific template to match
  //     const _resourceMatch = _matchingScheduled.find((x) => x.resources && x.resources.includes(resourceid))
  //     const _categoryMatch = _matchingScheduled.find((x) => category && x.category === category && (!x.resources || x.resources.length === 0))
  //     if (_resourceMatch) {
  //       console.log('scheduled resource specific template found', { template: _resourceMatch })
  //       _f = _resourceMatch
  //     } else if (_categoryMatch) {
  //       console.log('scheduled category specific template found', { template: _categoryMatch })
  //         _f = _categoryMatch
  //     }
  //   }

  //   if (!_f) {
  //     if (_g) {
  //       console.log('no scheduled template found, using venue or category specific template', { g: _g })
  //       _f = _g
  //     } else {
  //       console.log('no scheduled template found, using default template', { defaultTemplate })
  //       _f = defaultTemplate
  //     }
  //   }
  // } else {
  //   console.error('no message templates found', reservation.getId(), { messagetemplates })
  //   return null
  // }
  // console.log('_f before', _f)
  // if (_f) {
  //   console.log('_f to return', _f)
  //   return _f.message
  // } else {
  //   console.log('local template')
  //   return null
  // }
}

class MessageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMessageModal: this.props.open,
      messageTemplateName: this.props.initName,
      sendMessagePressed: false,
      message: {
        htmlbody: '',
        subject: '',
      },
      messagetemplates: this.props.messagetemplates,
      reservation: this.props.reservation,
      loading: true,
      accesscodeFetched: false,
      accesscode: '',
      occurency: this.props.occurency,
    }
  }

  componentDidUpdate(prevProps) {
    // console.log('MessageModal componentDidUpdate', this.props, prevProps)
    if (this.props.open !== prevProps.open) {
      this.setState({ showMessageModal: this.props.open })
    }
    if (this.props.messagetemplates !== prevProps.messagetemplates) {
      this.setState({ messagetemplates: this.props.messagetemplates })
    }
    if (this.props.initName !== prevProps.initName) {
      this.setState({ messageTemplateName: this.props.initName }, () => {
        // console.log('updateMessageTemplate from componentDidUpdate', this.state.messageTemplateName)
        if (this.state.messageTemplateName) {
          let tempname = this.state.messageTemplateName.replace('message', '')
          if (tempname === 'cancel') {
            tempname = 'cancellation'
          }
          this.updateMessageTemplate(tempname)
        }
      })
    }
    if (this.props.reservation !== prevProps.reservation) {
      this.setState({ reservation: this.props.reservation })
    }
    if (this.props.occurency !== prevProps.occurency) {
      this.setState({ occurency: this.props.occurency })
    }
  }

  fillTemplate = async (template, reservation, occurency) => {
    const _template = JSON.parse(JSON.stringify(template))
    // console.log('fillTemplate', reservation )
    let primaryContact = reservation.getFirstPayerSimple()
    let personalaccesscode = this.state.accesscode
    if (!this.state.accesscodeFetched) {
      if (primaryContact) {
        const pinresponse = await this.props.firebase.getPersonPin(
          primaryContact.id
        )
        if (pinresponse && pinresponse.data && !pinresponse.data.error) {
          personalaccesscode = pinresponse.data
        }
      }
      this.setState({ accesscodeFetched: true, accesscode: personalaccesscode })
      // console.log('fillTemplate', primaryContact, personalaccesscode)
    }
    const selectedOccurency = moment.utc(occurency).format('YYYY-MM-DD')
    console.log('fillTemplate', occurency, selectedOccurency)

    const reservationTimeTitle =
      reservation
        .getStartMoment(selectedOccurency)
        .format('dddd D.M.YYYY [klo] H:mm') +
      ' - ' +
      reservation.getEndMoment().format('H:mm')
    const hasHtml = _template.htmlbody ? true : false
    let body = _template.body
    if (hasHtml) {
      delete _template.body
      body = _template.htmlbody
    }

    const _body = body
      .replace(
        '{PERSON_NAME}',
        primaryContact.firstname + ' ' + primaryContact.lastname
      )
      .replace('{RESERVATION_DATE_TIME}', reservationTimeTitle)
      .replace('{RESERVATION_VENUE}', reservation.getVenue())
      .replace('{RESERVATION_SPORT}', reservation.getSport())
      .replace('{RESERVATION_COURT}', reservation.getResource().displayName)
      .replace('{RESERVATION_CODE}', personalaccesscode)
      .replace(
        '{RESERVATION_PRICE}',
        reservation.getPaymentStatus().cost + ' €'
      )
      .replace('{RESERVATION_EXTRAS}', '' /*_itemsMessage*/)
    _template.subject = _template.subject.replace(
      '{SUBJECT_SHORT}',
      reservation.getShortShortTitle(
        reservation.getStartMoment(selectedOccurency).format('YYYY-MM-DD')
      )
    )
    // _template.subject = _template.subject.replace('{SUBJECT_SHORT}', reservation.getShortShortTitle(oc.format('YYYY-MM-DD')))
    if (hasHtml) {
      _template.htmlbody = _body
    } else {
      _template.body = _body
    }
    return Promise.resolve(_template)
  }

  updateMessageTemplate = async (templateName) => {
    if (!this.state.reservation) {
      console.error('no reservation to update message template')
      return
    }
    const reservation = new Reservation({ ...this.state.reservation })
    const template = _findMessageTemplate(
      this.state.messagetemplates,
      reservation,
      templateName
    )
    // const template = this.state.messagetemplates.filter((t) => t.enabled).filter(
    //   (template) => template.type === templateName
    // )
    // console.log('updateMessageTemplate', templateName, template)
    if (template) {
      this.setState({ loading: true })
      const filled = await this.fillTemplate(template, reservation, this.state.occurency)
      // const _t = { ...template }
      // console.log('updateMessageTemplate', filled)
      this.setState({
        message: filled,
        messageTemplateName: templateName,
        loading: false,
      })
    }
  }

  handleMessageSubjectChange = (event) => {
    const message = this.state.message
    message.subject = event.target.value
    this.setState({ message })
  }

  handleMessageBodyChange = (value) => {
    const message = this.state.message
    message.htmlbody = value
    // console.log('handleMessageBodyChange', message)
    this.setState({ message })
  }

  sendMessage = () => {
    this.setState({ sendMessagePressed: true })
    // console.log('onSend sendMessage', this.state.message, this.props.onSend)
    if (this.props.onSend) {
      this.props.onSend(this.state.message)
    }
    this.setState({ sendMessagePressed: false })
  }

  closeDialog = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    // console.log('MessageModal message', this.state.message)
    if (this.state.message && this.state.message.htmlbody) {
      // console.log('MessageModal message htmlbody', this.state.message.htmlbody)
    } else if (this.state.message && this.state.message.body) {
      // console.log('MessageModal message body', this.state.message.body)
    } else {
      // console.log('MessageModal message empty')
    }
    const editorValue =
      this.state.message && this.state.message.htmlbody
        ? this.state.message.htmlbody
        : this.state.message && this.state.message.body
        ? this.state.message.body.replace(/\n/g, '<br/>')
        : ''
    // console.log('MessageModal editorValue', editorValue)
    return (
      <Modal
        isOpen={this.state.showMessageModal}
        toggle={this.closeDialog}
        size='xl'
      >
        <ModalHeader toggle={this.closeDialog}>Viesti</ModalHeader>
        <ModalBody>
          <div>
            <Label for='messagetemplates'>Viestipohjat</Label>
          </div>
          {this.state.loading ? (
            <Spinner size='sm' />
          ) : (
            <ButtonGroup className='flex-wrap' id='messagetemplates'>
              <Button
                key='cancelmessage'
                className='btn-round'
                color='info'
                outline
                type='button'
                onClick={() => this.updateMessageTemplate('cancellation')}
                active={this.state.messageTemplateName === 'cancellation'}
              >
                Peruutus
              </Button>
              <Button
                key='movemessage'
                className='btn-round'
                color='info'
                outline
                type='button'
                onClick={() => this.updateMessageTemplate('move')}
                active={this.state.messageTemplateName === 'move'}
              >
                Siirto
              </Button>
              <Button
                key='reservationmessage'
                className='btn-round'
                color='info'
                outline
                type='button'
                onClick={() => this.updateMessageTemplate('reservation')}
                active={this.state.messageTemplateName === 'reservation'}
              >
                Varaus
              </Button>
            </ButtonGroup>
          )}
          <Form>
            <FormGroup>
              <Label for='exampleEmail'>Otsikko</Label>
              <Input
                id='messageSubject'
                value={
                  this.state.message && this.state.message.subject
                    ? this.state.message.subject
                    : ''
                }
                name='messageSubject'
                onChange={this.handleMessageSubjectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for='messageBody'>Viesti</Label>
              <TinyEditor
                defaultValue={editorValue}
                onChange={this.handleMessageBodyChange}
                disableSave={true}
              />

              {/* <Input
                rows='15'
                value={this.state.messageBody ? this.state.messageBody : ''}
                id='messageBody'
                name='messageBody'
                type='textarea'
                style={{ maxHeight: 400 }}
                onChange={this.handleMessageBodyChange}
              /> */}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={this.sendMessage}>
            {this.state.sendMessagePressed ? <Spinner size={'sm'} /> : 'Lähetä'}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default withFirebase(MessageModal)
