/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import classnames from 'classnames'
import {
  Button,
  /*
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  */
  NavbarBrand,
  Navbar,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Row,
  Col,
  ButtonGroup,
  Alert,
} from 'reactstrap'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsIcon from '@material-ui/icons/Notifications'
import HelpCenterIcon from '@material-ui/icons/Help'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import Drawer from '@material-ui/core/Drawer'
import { Badge } from '@material-ui/core'
import VideoHelpButton from '../Buttons/VideoHelpButton'
// import routes from "routes.js";

const getWindowsize = () => {
  if (window.innerWidth) {
    if (window.innerWidth < 576) {
      return 'xs'
    }
    if (window.innerWidth < 768) {
      return 'sm'
    }
    if (window.innerWidth < 992) {
      return 'md'
    }
    if (window.innerWidth < 1200) {
      return 'lg'
    }
  }
}

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent',
      draweropen: false,
      news: props.news,
      lastread: props.lastread,
      unreadnews: 0,
      unpaidInvoiceAlert: props.unpaidInvoiceAlert,
      smallHelpButtons: ['xs', 'sm', 'md'].includes(getWindowsize())
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateColor)
    const unreadnews = this.props.news.reduce((acc, cur) => {
      if (cur.created > this.props.lastread) {
        return acc + 1
      } else {
        return acc
      }
    }, 0)
    this.setState({ unreadnews })
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
    if (e.news && e.news.length !== this.props.news.length) {
      const unreadnews = this.props.news.reduce((acc, cur) => {
        if (cur.created > this.props.lastread) {
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      this.setState({ news: this.props.news, unreadnews })
    }
    if (e.lastread !== this.props.lastread) {
      const unreadnews = this.props.news.reduce((acc, cur) => {
        if (cur.created > this.props.lastread) {
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      this.setState({ lastread: this.props.lastread, unreadnews })
    }
    if (e.videohelp !== this.props.videohelp) {
      console.log('videohelp changed')
      this.setState({ videohelp: this.props.videohelp })
    }
    if (e.unpaidInvoiceAlert !== this.props.unpaidInvoiceAlert) {
      console.log('unpaidInvoiceAlert changed', e.unpaidInvoiceAlert, this.props.unpaidInvoiceAlert)
      this.setState({ unpaidInvoiceAlert: this.props.unpaidInvoiceAlert })
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    const smallHelpButtons = ['xs', 'sm', 'md'].includes(getWindowsize())
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: 'bg-white',
        smallHelpButtons
      })
    } else {
      this.setState({
        color: 'navbar-transparent',
        smallHelpButtons
      })
    }
  }

  openFreshdesk = () => {
    const widget = window.FreshworksWidget
    if(widget) {
      widget('open')
    }
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open')
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    }
    if (!this.state.collapseOpen) {
      newState['color'] = 'bg-white'
    } else {
      newState['color'] = 'navbar-transparent'
    }
    this.setState(newState)
  }

  updateLastead = () => {
    if (this.props.updateLastread && this.state.draweropen) this.props.updateLastread()
  }

  getTitle() {
    let brandName = 'Falcon'
    this.props.routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name + (prop.betatext ? prop.betatext : '')
      }
      if (prop.views) {
        prop.views.map((v, k) => {
          if (window.location.href.indexOf(v.layout + v.path) !== -1) {
            brandName = prop.name + ' - ' + v.name
          }
          return null
        })
      }
      return null
    })
    return brandName
  }

  openHelp = (url) => {
    window.open(url, '_blank')
  }

  getVideohelps = () => {
    return this.state.videohelp.filter((v) => {
      return window.location.href.indexOf(v.path) !== -1
    }).map((v, i) => {
      return <Button size='sm' key={v.path + '_' + i}  onClick={() => this.openHelp(v.url)}>{' '}<HelpCenterIcon fontSize='small' />Help</Button>
    })
  }

  renderNews = (n) => {
    const contentParts = n.parts.map((p, i) => {
      if (p.type === 'text') {
        return (
          <CardText key={'part' + i} style={{ maxWidth: 500 }}>
            {p.value}
          </CardText>
        )
      } else if (p.type === 'image') {
        return <CardImg key={'part' + i} bottom src={p.value} alt='alt' style={{ maxWidth: 500 }} />
      }
      return null
    })
    return (
      <Card key={Math.random().toString()} className='mt-2 mb-2 ml-2 mr-2'>
        <CardBody>
          <CardTitle tag='h5'>
            {this.state.lastread > 0 && this.state.lastread < n.created ? <b>{n.title}</b> : n.title}
          </CardTitle>
          {contentParts}{' '}
        </CardBody>
      </Card>
    )
  }

  openNews = () => {
    this.setState({ draweropen: true })
    setTimeout(() => {
      this.updateLastead()
    }, 3000)
  }

  changeFontSize = (bigger) => {
    let font = parseInt(window.getComputedStyle(document.getElementsByTagName('body')[0]).fontSize)
    if (bigger) {
      font++
    } else {
      font--
    }
    document.getElementsByTagName('body')[0].style.fontSize = font.toString() + 'px'
    document.querySelectorAll('.importtitle').forEach((element) => {
      element.style.fontSize = font.toString() + 'px'
    })
  }


  render() {
    const unpaidAlert = this.state.unpaidInvoiceAlert ? true : false
    const hideHelps = this.state.smallHelpButtons && unpaidAlert
    return (
      <>
        <Navbar className={classnames('navbar-absolute fixed-top', this.state.color)} expand='lg'>
          <Container fluid style={{ backgroundColor: unpaidAlert ? '#ee936e': null }}>
            <div className='navbar-wrapper'>
              <div className='navbar-minimize'>
                <Button
                  className='btn-icon btn-round'
                  color='default'
                  id='minimizeSidebar'
                  onClick={this.props.handleMiniClick}
                >
                  <i className='nc-icon nc-minimal-right text-center visible-on-sidebar-mini' />
                  <i className='nc-icon nc-minimal-left text-center visible-on-sidebar-regular' />
                </Button>
              </div>
              <div
                className={classnames('navbar-toggle', {
                  toggled: this.state.sidebarOpen,
                })}
              >
                <button className='navbar-toggler' type='button' onClick={this.toggleSidebar}>
                  <span className='navbar-toggler-bar bar1' />
                  <span className='navbar-toggler-bar bar2' />
                  <span className='navbar-toggler-bar bar3' />
                </button>
              </div>
              <NavbarBrand href='#noop' onClick={(e) => e.preventDefault()}>
                <span className='d-none d-md-block'>{this.getTitle()}</span>
                <span className='d-block d-md-none'>{this.getTitle()}</span>
              </NavbarBrand>
            </div>
            {unpaidAlert ? (
              <div style={{ color: 'white', fontSize: 'small', textWrap: 'nowrap', textOverflow: 'ellipsis', width: '40vw', overflow: 'hidden' }}>
                <PriorityHighIcon fontSize='small' />{' '}{this.state.unpaidInvoiceAlert}
              </div>
            ) : null}
            {!hideHelps ? 
            <div>
              <VideoHelpButton />
              <Button size='sm' onClick={() => this.openFreshdesk()} style={{ marginRight: 10 }}>
                <HelpCenterIcon fontSize='small' />{' '}Tukipyyntö
              </Button>
              <ButtonGroup size='sm' style={{ marginRight: 15, marginBottom: 0, marginTop: 0, marginLeft: 15 }}>
                <Button onClick={() => this.changeFontSize(true)} style={{ margin: 0 }}>
                  A
                </Button>
                <Button onClick={() => this.changeFontSize(false)} style={{ fontSize: 'x-small', margin: 0 }}>
                  A
                </Button>
              </ButtonGroup>
              {this.state.news.length > 0 ? (
                <React.Fragment>
                  <Badge badgeContent={this.state.unreadnews} color='primary'>
                    {this.state.unreadnews > 0 ? (
                      <NotificationsActiveIcon onClick={() => this.openNews()} />
                    ) : (
                      <NotificationsIcon onClick={() => this.openNews()} />
                    )}
                  </Badge>
                  <Drawer
                    anchor={'right'}
                    open={this.state.draweropen}
                    onClose={() => this.setState({ draweropen: false })}
                  >
                    <Row>
                      <Col>
                        <div className='pl-3' style={{ fontSize: 'x-large', fontWeight: 'bold' }}>
                          Uutta
                        </div>
                      </Col>
                      <Col className='text-right'>
                        <Button onClick={() => this.setState({ draweropen: false })}>Sulje</Button>
                      </Col>
                    </Row>
                    <div style={{ minWidth: '30vw' }}>{this.state.news.map((n) => this.renderNews(n))}</div>
                  </Drawer>
                </React.Fragment>
              ) : null}
            </div> : null}
            {/* <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button> */}
            {/* <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
               <Nav navbar>
              </Nav>
            </Collapse> */}
          </Container>
        </Navbar>
      </>
    )
  }
}

export default AdminNavbar
