import React from 'react'
import { Col, Row, Button, Card, CardBody } from 'reactstrap'
import { withFirebase } from '../../components/Fb'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import 'moment/locale/fi'
import FalconModal from '../../components/Inputs/FalconModal'
import {
  getResourcePricingSchema,
  getResourcePricingSlotSchema,
  getResourcePricingUiSchema,
  getResourcePricingSlotUiSchema,
} from '../../components/Inputs/dataschemas'
import Select from 'react-select'
import Infobox from '../../components/Infos/Infobox'

const localizer = momentLocalizer(moment)
// const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

const weekdaysAsResources = [
  { resourceId: 1, resourceTitle: 'Maanantai' },
  { resourceId: 2, resourceTitle: 'Tiistai' },
  { resourceId: 3, resourceTitle: 'Keskiviikko' },
  { resourceId: 4, resourceTitle: 'Torstai' },
  { resourceId: 5, resourceTitle: 'Perjantai' },
  { resourceId: 6, resourceTitle: 'Lauantai' },
  { resourceId: 7, resourceTitle: 'Sunnuntai' },
]

const PricingToolBar = () => {
  return <></>
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

class GiftcodePricingCalendarContainer extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      loading: true,
      pricings: [],
      resourcePricingModalOpen: false,
      resourcePricingSlotModalOpen: false,
      pricingOptions: [],
      slots: [],
    }
  }

  setResource = (event) => {
    this.setState({ input: JSON.parse(event.target.value) })
  }

  addNew = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  componentDidMount() {
    this.updateSlotData()
  }

  updateSlotData = async () => {
    Promise.all([this.props.firebase.getResourses(), this.props.firebase.getGiftcodeResourcePricings()]).then(
      (values) => {
        let _rs = values[0].docs.map((sh) => {
          return { ...sh.data(), id: sh.id }
        })
        if (!values[1].empty) {
          let _d = values[1].docs
            .map((sh) => {
              return { ...sh.data(), id: sh.id }
            })
            .sort((a, b) => {
              return (b.priority || 0) - (a.priority || 0)
            })
          this.setState(
            {
              pricings: _d,
              resources: _rs,
            },
            this.filterData
          )
        } else {
          this.setState(
            {
              resources: _rs,
            },
            this.filterData
          )
        }
      }
    )
  }

  filterData = () => {
    if (this.state.pricings) {
      let _princingsOptions = this.state.pricings
        .map((p) => {
          return {
            value: p.id,
            label:
              (p.priority || '0') +
              ' - ' +
              p.name /*+ ' ' + moment(p.validstart).format('DD.MM.YYYY') + ' - ' + moment(p.validend).format('DD.MM.YYYY')*/,
            active: p.active,
          }
        })
        .reduce(
          (acc, current) => {
            if (current.active) {
              acc[0].options.push(current)
            } else {
              acc[1].options.push(current)
            }
            return acc
          },
          [
            { label: 'Active', options: [] },
            { label: 'Inactive', options: [] },
          ]
        )
      this.setState({ pricingOptions: _princingsOptions, loading: false })
    } else {
      this.setState({ loading: false })
    }
    if (this.state.selectedPricingId) {
      this.updateViewablePricing(this.state.selectedPricingId)
    }
  }

  getResourcePricing = () => {
    const schema = getResourcePricingSchema()
    const items = { enum: [], enumNames: [] }
    // eslint-disable-next-line array-callback-return
    this.state.resources.sort((a, b) => a.displayName.localeCompare(b.displayName)).forEach((res) => {
      items.enum.push(res.id)
      items.enumNames.push(res.displayName)
    })
    schema.properties.resources.items = items
    return schema
  }

  handleSelectEvent = (event) => {
    // console.log(event.data)
    this.setState({ selectedResource: JSON.parse(JSON.stringify(event.data)) }, this.toggleResourcePricingSlotModal())
  }

  handleSelectSlot = (slotInfo) => {
    // console.log('handleSelectSlot')
    // console.log(slotInfo)
    let s = moment(slotInfo.start)
    let e = moment(slotInfo.end)
    if (e.diff(s, 'minutes') < 30) {
      e = moment(s).add(30, 'minutes')
    }
    this.setState({ selectedResource: null })
    let _ePlaceholder = {
      id: 'placeholder',
      start: slotInfo.start,
      end: e.toDate(),
      resourceId: slotInfo.resourceId,
    }
    let newslots = [...this.state.slots]
    newslots.push(_ePlaceholder)
    this.setState({ slots: newslots })
    let _selectedResource = {
      configid: this.state.selectedPricingId,
      days: [slotInfo.resourceId],
      pricingunit: 'h',
      starttime: s.format('HH:mm'),
      endtime: e.format('HH:mm'),
    }
    this.setState({ selectedResource: _selectedResource }, this.toggleResourcePricingSlotModal)
  }

  updateFilter = (filter, status) => {
    let fss = {}
    if (filter === 'category') {
      fss = { categoryFilter: status }
    }
    this.setState(fss, this.filterData)
  }

  getCategoryFilters = () => {
    if (this.state.rawResources) {
      let _cs = this.state.rawResources.map((e) => e.category)
      let _dc = [...new Set(_cs)]
      const _c = _dc.map((e) => {
        return (
          <Button
            key={e}
            className='btn-round'
            color='info'
            outline
            type='button'
            onClick={() => this.updateFilter('category', e)}
            active={this.state.categoryFilter === e}
          >
            {e}
          </Button>
        )
      })
      _c.push(
        <Button
          className='btn-round'
          key='typeall'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('category', 'all')}
          active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
        >
          Kaikki
        </Button>
      )
      return _c
    } else {
      return [
        <Button
          key='typeall'
          className='btn-round'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('category', 'all')}
          active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
        >
          Kaikki
        </Button>,
      ]
    }
  }

  toggleResourcePricingModal = (state) => {
    if (state === 'new') {
      this.setState({
        resourcePricingModalOpen: !this.state.resourcePricingModalOpen,
      })
    } else if (this.state.selectedPricingId) {
      let _selectedPricing = this.state.pricings.filter((p) => p.id === this.state.selectedPricingId)
      if (_selectedPricing[0]) {
        this.setState({
          selectedPricing: _selectedPricing[0],
          resourcePricingModalOpen: !this.state.resourcePricingModalOpen,
        })
      }
    } else {
      this.setState({
        resourcePricingModalOpen: !this.state.resourcePricingModalOpen,
      })
    }
  }

  toggleResourcePricingSlotModal = () => {
    this.setState({
      resourcePricingSlotModalOpen: !this.state.resourcePricingSlotModalOpen,
    })
  }

  toggleResourcePricingSlotModalCancel = () => {
    let _e = this.state.slots.filter((e) => e.id !== 'placeholder')
    this.setState({ slots: _e }, this.toggleResourcePricingSlotModal)
  }

  createResourcePricingSlotModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.addGiftcodeResourcePricingSlot}
        onCreate={this.props.firebase.addGiftcodeResourcePricingSlot}
        toggle={this.toggleResourcePricingSlotModal}
        cancel={this.toggleResourcePricingSlotModalCancel}
        modifiedCallback={this.updateSlotData}
        resource={this.state.selectedResource}
        isOpen={this.state.resourcePricingSlotModalOpen}
        uiSchema={getResourcePricingSlotUiSchema()}
        dataSchema={getResourcePricingSlotSchema()}
        title={
          this.state.selectedResource && this.state.selectedResource.id
            ? 'Muokkaa etukoodin hinnoittelua'
            : 'Uusi etukoodin hinnoittelu'
        }
      />
    )
  }

  // pricing modal
  createResourcePricingModal = () => {
    return (
      <FalconModal
        onCreate={this.props.firebase.createGiftcodeResourcePricing}
        onUpdate={this.props.firebase.updateGiftcodeResourcePricing}
        modifiedCallback={this.updateSlotData}
        toggle={this.toggleResourcePricingModal}
        cancel={this.toggleResourcePricingModal}
        resource={this.state.selectedPricing}
        isOpen={this.state.resourcePricingModalOpen}
        uiSchema={getResourcePricingUiSchema()}
        dataSchema={this.getResourcePricing()}
        title={
          this.state.selectedPricing && this.state.selectedPricing.id
            ? 'Muokkaa etukoodin hinnoittelua'
            : 'Uusi etukoodin hinnoittelu'
        }
      />
    )
  }

  selectPricing = (event) => {
    this.updateViewablePricing(event.value)
  }

  updateViewablePricing = (id) => {
    let _selectedPricing = this.state.pricings.filter((p) => p.id === id)
    // console.log(_selectedPricing)
    if (_selectedPricing[0]) {
      let _slots = []
      if (_selectedPricing[0].slots) {
        _slots = Object.keys(_selectedPricing[0].slots)
          .map((slotKey) => {
            let slot = _selectedPricing[0].slots[slotKey]
            let _start = moment(slot.starttime, 'HH:mm').year(1970).month(0).date(1).toDate()
            let _end = moment(slot.endtime, 'HH:mm').year(1970).month(0).date(1).toDate()
            let _title = (slot.price ? slot.price.toString() + ' €. ' : 'no price set. ') + (slot.displayName || '')
            let _ev = slot.days.map((d) => {
              return { resourceId: d, data: { ...slot }, title: _title, start: _start, end: _end }
            })
            return _ev
          })
          .flat()
        // console.log(_slots)
      }
      this.setState({ selectedPricingId: _selectedPricing[0].id, slots: _slots })
    }
  }

  getSelectedPricingDetails = () => {
    if (this.state.selectedPricingId) {
      let _selectedPricing = this.state.pricings.filter((p) => p.id === this.state.selectedPricingId)
      if (_selectedPricing[0]) {
        return (
          <Card>
            <CardBody onClick={this.toggleResourcePricingModal}>
              <strong>{_selectedPricing[0].active ? '' : 'Not '}Active</strong>
              <br />
              <strong>Prioriteetti</strong>
              {': ' + (_selectedPricing[0].priority || 0)}
              <br />
              <strong>Aikaväli</strong>
              {': ' +
                moment(_selectedPricing[0].validstart).format('DD.MM.YYYY') +
                ' - ' +
                moment(_selectedPricing[0].validend).format('DD.MM.YYYY')}
              <br />
              <strong>Saako hinnasto sisältää 0€ hintoja</strong>
              {': ' + (_selectedPricing[0].zeroprice ? 'Kyllä' : 'Ei')}
            </CardBody>
          </Card>
        )
      }
    }
    return <></>
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content' style={{ height: '75vh' }}>
            <Row>
              <Col md='4'>
                <Card>
                  <CardBody>
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      closeMenuOnSelect={true}
                      name='resourcepricings'
                      options={this.state.pricingOptions}
                      formatGroupLabel={formatGroupLabel}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      placeholder='Hinnoittelut ...'
                      onChange={this.selectPricing}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Button onClick={(e) => this.toggleResourcePricingModal('new')}>Lisää hinnoittelu</Button>
              </Col>
              <Col>
                <Infobox index={0} />
              </Col>
            </Row>
            <Row>
              <Col>{this.getSelectedPricingDetails()}</Col>
            </Row>
            <Row>
              <Col md='12'>
                <Card>
                  <CardBody>
                    <Calendar
                      style={{ height: '85vh' }}
                      ref={this.myRef}
                      selectable
                      localizer={localizer}
                      events={this.state.slots ? this.state.slots : []}
                      views={['day']}
                      step={30}
                      min={new Date(1970, 0, 1, 0, 0, 0)}
                      defaultView={Views.DAY}
                      scrollToTime={new Date(1970, 0, 1, 12)}
                      defaultDate={new Date(1970, 0, 1, 12)}
                      onSelectEvent={(event) => this.handleSelectEvent(event)}
                      onSelectSlot={this.handleSelectSlot}
                      // onView={this.updateView}
                      components={{ toolbar: PricingToolBar }}
                      // eventPropGetter={this.eventStyleGetter}
                      resources={weekdaysAsResources}
                      resourceIdAccessor='resourceId'
                      resourceTitleAccessor='resourceTitle'
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.createResourcePricingSlotModal()}
            {this.createResourcePricingModal()}
          </div>
        </>
      )
    } else {
      return (
        <>
          <Row>
            <Col>Loading ...</Col>
          </Row>
        </>
      )
    }
  }
}

export default withFirebase(GiftcodePricingCalendarContainer)
