// const _membershipPersonClass = {
//   type: 'object',
//   properties: {
//     type: {
//       type: 'string',
//       title: 'Jäsenyys',
//       anyOf: [],
//     },
//     memberid: {
//       type: 'string',
//       title: 'Jäsentunnus',
//     },
//     validuntil: {
//       type: 'string',
//       format: 'date',
//       title: 'Voimassa',
//     },
//   },
// }

/*
{
  id: '1',
  image: 'https://www.mailat24.fi/_files/ProductMedia/Thumbnail_Small/10995_24748.jpg',
  desc: 'Vuokraa padel-maila',
  sku: 'padel-maila-vuokra',
  displayName: 'Padel-maila',
  price: { g: 450, m: { memid: 200 } },
  resources: ['padel-1', 'padel-2'],
  min: 1,
  max: 4,
  days: [
    { day: 0, s: '08:00', e: '23:00' },
    { day: 1, s: '08:00', e: '23:00' },
  ],
}
*/

export const _shopProductClass = {
  name: { type: 'string', title: 'Nimi' },
  desc: { type: 'string', title: 'Kuvaus' },
  productCode: { type: 'string', title: 'Tuotekoodi' },
  pricegeneral: { type: 'number', title: 'Hinta' },
  accounting: { type: 'string', title: 'Kirjanpitotili' },
  vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 24, 25.5], enumNames: ['0%', '10%', '14%', '24%','25,5%'] },
}

export const _shopProductUISchema = {
}
