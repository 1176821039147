import React, { Component, createRef } from 'react'
import { withFirebase } from '../Fb'
import algoliasearch from 'algoliasearch'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { Paper, Popper, MenuItem, MenuList, ClickAwayListener } from '@material-ui/core'

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f')
const algoliaindex = algoliaclient.initIndex('falcon_combined')

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
}

class PersonAndOrganisationSearch extends Component {
  constructor(props) {
    super(props)
    this.state = { values: [], hits: null, menuopen: false, search: '', searchresults: [], disabled: false }
    this.anchorEl = createRef()
  }

  updateAdornment = () => {
    this.setState({ startAdornment: this.getStartAdornment() })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.values !== this.props.values) {
      this.setState({ values: this.props.values }, this.updateAdornment)
    }
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ disabled: this.props.disabled }, this.updateAdornment)
    }
  }

  componentDidMount = () => {
    if (!this.props.values) return
    this.setState({ values: this.props.values, disabled: this.props.disabled, label: this.props.label, placeholder: this.props.placeholder }, this.updateAdornment)
  }

  clearAlgolia = (x) => {
    x.id = x.objectID
    delete x._highlightResult
    delete x.lastmodified
    delete x.reservationAlert
    delete x.objectID
    return x
  }


  performSearch = debounce((search) => {
    algoliaindex.search(search, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const persons = hits
        .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
        .map((x) => this.clearAlgolia(x))
      
      let organisations = this.props.firebase.searchCombined(search).map((p) => p.item)
      if (!organisations) organisations = []
      const searchresults = organisations.concat(persons)
      console.log(searchresults)
      if (searchresults.length > 0) {
        this.setState({ hits: searchresults, menuopen: true })
      } else {
        this.setState({ hits: null, menuopen: false })
      }
    })
  }, 300)

  handleChange = (e) => {
    const search = e.target.value
    this.setState({ search })
    if (search.length > 2) {
      this.performSearch(search)
    } else {
      this.setState({ hits: null, menuopen: false })
    }
      // algoliaindex.search(search, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      //   const persons = hits
      //     .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
      //     .map((x) => this.clearAlgolia(x))
        
      //   let organisations = this.props.firebase.searchCombined(search).map((p) => p.item)
      //   if (!organisations) organisations = []
      //   const searchresults = organisations.concat(persons)
      //   console.log(searchresults)
      //   if (searchresults.length > 0) {
      //     this.setState({ search, hits: searchresults, menuopen: true })
      //   } else {
      //     this.setState({ search, hits: null, menuopen: false })
      //   }
      // })
    // } else {
    //   this.setState({ search, hits: null, menuopen: false })
    // }
  }

  add = (option) => {
    const { values } = this.state
    if (values.indexOf(option) > -1) return

    values.push(option)
    this.setState({ values, hits: null, search: '', startAdornment: this.getStartAdornment() })
    if (this.props.onChange) this.props.onChange(null, values)
  }

  remove = (option) => {
    const { values } = this.state
    const index = values.indexOf(option)
    values.splice(index, 1)
    this.setState({ values, startAdornment: this.getStartAdornment() })
    if (this.props.onChange) this.props.onChange(null, values)
  }

  renderHit = (option) => {
    let title = option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
    if (option.businessId) {
      title = option.name
    }
    return (
      <MenuItem disabled={this.state.disabled} key={option.objectID} onClick={() => this.add(option)}>
        <Grid container alignItems='center'>
          <Grid item xs style={{ cursor: 'pointer' }}>
            <span>{title}</span>
            <Typography variant='body2' color='textSecondary'>
              {option.email}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>
    )
  }

  handleClose = () => {
    this.setState({ menuopen: false })
  }

  getStartAdornment = () => {
    return (
      <div
        style={{
          display: 'flex',
          listStyle: 'none',
          margin: 0,
        }}
      >
        {this.state.values.map((data) => {
          let label = data.firstname + ' ' + data.lastname
          if (data.businessId) {
            label = data.name
          }
          return (
            <li key={data.id}>
              <Chip
                disabled={this.state.disabled}
                label={label}
                onDelete={() => this.remove(data)}
                style={{ margin: 2 }}
              />
            </li>
          )
        })}
      </div>
    )
  }

  render = () => {
    return (
      <div>
        <div ref={this.anchorEl}>
          <TextField
            id='search'
            type='search'
            disabled={this.state.disabled}
            margin='normal'
            variant='standard'
            label={this.state.label || 'Lisää'}
            placeholder={this.state.placeholder || 'Etsi'}
            value={this.state.search}
            onChange={this.handleChange}
            fullWidth
            InputProps={{
              startAdornment: this.state.startAdornment,
            }}
          />
        </div>
        <Popper
          open={this.state.menuopen}
          anchorEl={this.anchorEl.current}
          role={undefined}
          placement={'bottom-start'}
          style={{ maxHeight: 300, zIndex: 9000 }}
        >
          <Paper style={{ maxHeight: 300 }}>
            <ClickAwayListener onClickAway={this.handleClose}>
              <MenuList id='menu-list-grow'style={{ maxHeight: 300, overflow: 'auto' }}>
                {this.state.hits && this.state.hits.map((hit) => this.renderHit(hit))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    )
  }
}

export default withFirebase(PersonAndOrganisationSearch)
