import React from 'react'
import { withFirebase } from '../../components/Fb'
import {
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'


const StyledRating = withStyles({
  iconFilled: {
    color: '#0f5389',
  },
  iconHover: {
    color: '#0f5389',
  },
})(Rating)

const DotRating = ({ rating, onChange }) => {
  return (
    <StyledRating
      value={rating ? rating : 0}
      precision={1}
      icon={<RadioButtonCheckedIcon fontSize='inherit' />}
      onChange={(event, value) => onChange(value)}
    />
  )
}

const MaintenanceTaskList = ({ firebase, contracts, date }) => {
  const [ratings, setRatings] = React.useState({})

  if (!contracts) return null
  if (!date) return null

  const dayOfWeek = date.isoWeekday()
  const weekNumber = date.week()

  const getTasks = (jobs) => {
    return jobs.filter((task) => {
      return (
        task.daysofweek.includes(dayOfWeek) &&
        !task.weeks.excluded.includes(weekNumber)
      )
    })
  }

  const onRatingChange = (contractId, taskId, value) => {
    console.log('onRatingChange', contractId, taskId, value)
    const newRatings = { ...ratings }
    if (!newRatings[contractId]) newRatings[contractId] = {}
    newRatings[contractId][taskId] = value
    setRatings(newRatings)
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button variant='contained' color='primary' onClick={() => console.log('save ratings', ratings)}>
            Tallenna päivän arviot
          </Button>
        </Grid>
        <Grid item xs={12}>
          {contracts.map((contract) => {
            return (
              <TableContainer component={Paper} style={{ marginTop: 30 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>{contract.name}</TableCell>
                      <TableCell align='right'>
                        <DotRating
                          rating={ratings?.[contract.id]?._all}
                          onChange={(value) =>
                            onRatingChange(contract.id, '_all', value)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getTasks(contract.jobs).map((task) => {
                      return (
                        <TableRow key={'task-' + task.id}>
                          <TableCell style={{ width: 150 }}></TableCell>
                          <TableCell align='left'>{task.name}</TableCell>
                          <TableCell align='right'>
                            <DotRating
                              rating={
                                ratings?.[contract.id]?.[task.id]
                                  ? ratings?.[contract.id]?.[task.id]
                                  : ratings?.[contract.id]?._all
                                  ? ratings?.[contract.id]?._all
                                  : 0
                              }
                              onChange={(value) =>
                                onRatingChange(contract.id, task.id, value)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          })}
        </Grid>
      </Grid>
    </div>
  )
}

export default withFirebase(MaintenanceTaskList)
