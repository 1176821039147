import React from 'react'
import { withFirebase } from '../Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'

import TarmoAdmin from './TarmoAdmin'
import TarmoInfo from './TarmoInfo'

const test = true

// core components

class BuildingMaintenanceAdminContainer extends React.Component {
  render() {
    if (test) {
      return (
        <>
          <div className='content'>
            <Row style={{ marginTop: 90 }}>
              <Col md='12'>
                <TarmoInfo />
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='content'>
            <Row style={{ marginTop: 90 }}>
              <Col md='12'>
                <TarmoAdmin />
              </Col>
            </Row>
            <Row>
              <Col md='12'>
              </Col>
            </Row>
          </div>
        </>
      )
    }
  }
}

export default withFirebase(BuildingMaintenanceAdminContainer)
