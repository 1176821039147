import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col,
  Button,
} from 'reactstrap'
// import Alert from '@material-ui/lab/Alert'

import moment from 'moment'
import FalconModal from '../../components/Inputs/FalconModal'
import { getGiftcodeSchema, getGiftcodeUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from '../../components/Lists/ReactTableWrapper'
import { filterCaseInsensitive, getTdProps } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
import EditButton from '../../components/Buttons/EditButton'
import DeleteButton from '../../components/Buttons/DeleteButton'
import Infobox from '../../components/Infos/Infobox'

// core components

class GiftcodeContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, giftcodeModalOpen: false, giftcodes: null }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    Promise.all([this.props.firebase.getGiftcodes(), this.props.firebase.getGiftcodeResourcePricings()]).then((values) => {
      const giftcodes = []
      if (!values[0].empty) {
        // eslint-disable-next-line
        values[0].docs.map((doc) => {
          if (doc.data().state !== 'deleted') {
            giftcodes.push({ ...doc.data(), id: doc.id })
          }
        })
      }
      if (!values[1].empty) {
        let _d = values[1].docs.map((sh) => {
          return { ...sh.data(), id: sh.id }
        }).sort((a, b) => {
          return (b.priority || 0) - (a.priority || 0)
        })
        this.setState(
          {
            pricings: _d,
            raw: giftcodes
          },
          this.filterData
        )
      } else {
        this.setState({ raw: giftcodes }, this.filterData)
      }
    })
  }

  handleRowSelect = (e) => {
    const _result = this.state.giftcodes.filter((r) => r.id === e)
    this.setState({ selectedResource: _result[0] }, this.toggleGiftcodeModal)
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      data.push({
        ...this.state.raw[i],
      actions: this.getActions(this.state.raw[i])})
    }
    this.setState({ loading: false, giftcodes: data })
  }

  deleteGiftcode = async (id) => {
    await this.props.firebase.deleteGiftcode(id)
    this.setState({ loading: true }, this.updateData)
  }

  getActions = (row) => {
    return (
        <>
          <div className="actions-right">
            {}
            <Button
              color="danger"
              size="sm"
              onClick={(el) => {
                this.deleteGiftcode(row.id)
              }}
            >
              <span className="btn-label">
                <i className="nc-icon nc-simple-remove" />
              </span>
            </Button>
          </div>
        </>
    )
  }

  addNewGiftcode = () => {
    this.setState({ selectedResource: {} }, this.toggleGiftcodeModal)
  }

  toggleGiftcodeModal = () => {
    this.setState({
      giftcodeModalOpen: !this.state.giftcodeModalOpen,
    })
  }

  getEnhancedGiftcodeSchema = () => {
    const schema = getGiftcodeSchema()
    const pricing = { ...schema.properties.benefit.items.dependencies.type.oneOf[3].properties.value }
    if (this.state.pricings) {
      for (let i = 0; i < this.state.pricings.length; i++) {
        pricing.enum.push(this.state.pricings[i].id)
        pricing.enumNames.push(this.state.pricings[i].name)
      }
    }
    schema.properties.benefit.items.dependencies.type.oneOf[3].properties.value = pricing
    return schema
  }

  createGiftcodeModal = () => {
    return (<FalconModal
      onUpdate={this.props.firebase.updateGiftcode}
      onCreate={this.props.firebase.createGiftcode}
      modifiedCallback={this.updateData}
      toggle={this.toggleGiftcodeModal}
      resource={this.state.selectedResource}
      isOpen={this.state.giftcodeModalOpen}
      uiSchema={getGiftcodeUiSchema()}
      dataSchema={this.getEnhancedGiftcodeSchema()}
      title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa etukoodia' : 'Uusi etukoodi'}
    />)
  }


  render() {
    if (this.state.loading) {
      return(<FalconLoading />)
    } else {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.addNewGiftcode}>
                  Lisää uusi etukoodi
                </Button>
              </Col>
              <Col>
                <Infobox index={'0'}/>
              {/* <Alert severity='info'>
                <strong>Info!</strong> Kampanjakoodin tulee koostua vähintään neljästä merkistä, jotka voivat olla isoja kirjaimia ja/tai numeroita.
              </Alert> */}
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.giftcodes}
                  filterable
                  noDataText='Ei löytynyt yhtään etukoodia'
                  defaultFilterMethod={(filter, row) =>
                    filterCaseInsensitive(filter, row)
                  }
                  columns={[
                    {
                      Header: 'Nimi',
                      accessor: 'name',
                    },
                    {
                      Header: 'Koodi',
                      accessor: 'code',
                    },
                    {
                      Header: 'Tyyppi',
                      id: 'type',
                      accessor: d => d.type === 'courtreservation' ? 'Kenttävaraus' : d.type === 'activity' ? 'Aktiviteetti' : 'Tuntematon',

                    },
                    {
                      Header: 'Tila',
                      id: 'state',
                      accessor: d => d.state === 'active' ? 'Aktiivinen' : d.state === 'paused' ? 'Pysäytetty' : 'Poistettu',
                    },
                    {
                      Header: 'Alkupäivä',
                      id: 'start',
                      accessor: d => moment(d.start).format('D.M.YY'),

                    },
                    {
                      Header: 'Loppupäivä',
                      id: 'end',
                      accessor: d => moment(d.end).format('D.M.YY'),
                    },
                    {
                      Header: 'Yksi per asiakas',
                      id: 'onepercustomer',
                      accessor: d => d.onepercustomer ? 'Kyllä' : 'Ei',
                    },
                    {
                      Header: 'Muokkaa',
                      accessor: 'edit',
                      renderCell: (params) => {
                        return (
                          <EditButton
                            onClick={(el) => {
                              this.handleRowSelect(params.row.id)
                            }}
                          />
                        )
                      }
                    },
                    {
                      Header: 'Poista',
                      accessor: 'actions',
                      renderCell: (params) => {
                        return (
                          <DeleteButton
                            onClick={(el) => {
                              this.deleteGiftcode(params.row.id)
                            }}
                          />
                        )
                      }
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect) }
                  className='-striped -highlight primary-pagination'
                />

              </Col>
            </Row>
            {this.state.alert}
            {this.createGiftcodeModal()}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(GiftcodeContainer)
