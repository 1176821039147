import React from 'react'
import {
  Grid,
  Typography,
  TextField,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import { Button } from 'reactstrap'
import RepeatEditor from '../Inputs/RepeatEditor'

/*
task
  - taskgroupid
  multiple languages
  - name
  - description
  - instructions (html)
    - text
    - images

  - orderer
  - orderdate
  - accepted
  - accepteddate
  - schedule
    - daysofweek
    - weeks
      - included
      - excluded
**/

function TaskEditor({ task, onSave, languages, taskgroupid }) {
  const [internaltask, setInternaltask] = React.useState(task || {})
  const [saving, setSaving] = React.useState(false)




  const handleChange = (e) => {
    const { name, value } = e.target
    setInternaltask({ ...internaltask, [name]: value })
  }

  const handleSave = () => {
    if (internaltask.taskgroupid === undefined) {
      internaltask.taskgroupid = taskgroupid
    }
    setSaving(true)
    if (onSave) {
      onSave(internaltask)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {languages.map((lang) => (
          <React.Fragment key={lang.code}>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>{lang.title}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={`name_${lang.code}`}
                  value={internaltask[`name_${lang.code}`]}
                  onChange={handleChange}
                  placeholder='Name'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={`description_${lang.code}`}
                  value={internaltask[`description_${lang.code}`]}
                  onChange={handleChange}
                  placeholder='Description'
                  multiline
                  maxRows={15}
                  minRows={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={`instructions_${lang.code}`}
                  value={internaltask[`instructions_${lang.code}`]}
                  onChange={handleChange}
                  placeholder='Instructions'
                  multiline
                  maxRows={40}
                  minRows={4}
                  fullWidth
                />
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Typography variant='h6'>Schedule</Typography>
        <RepeatEditor rule={internaltask.schedule} setRule={(r) => setInternaltask({ ...internaltask, schedule: r })} />
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Button color='primary' type='button' onClick={handleSave} disabled={saving}>
          {saving ? <CircularProgress size={10} /> : 'Save'}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <pre>{JSON.stringify(internaltask, null, 2)}</pre>
        <pre>{taskgroupid}</pre>
       </Grid>
    </Grid>
  )
}

export default TaskEditor
