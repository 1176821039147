import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField'

function TimeInput (props) {

  const [value, setValue] = useState();

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (props.onChange) {
        props.onChange(value)
      }
    }, 300);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, 300]);

  return (
    <TextField
      {...props}
      type="time"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
      value={value ? value : props.value}
      onChange={handleChange}
    />
  )
}

export default TimeInput