// const _membershipPersonClass = {
//   type: 'object',
//   properties: {
//     type: {
//       type: 'string',
//       title: 'Jäsenyys',
//       anyOf: [],
//     },
//     memberid: {
//       type: 'string',
//       title: 'Jäsentunnus',
//     },
//     validuntil: {
//       type: 'string',
//       format: 'date',
//       title: 'Voimassa',
//     },
//   },
// }


export const _personClass = {
  email: { type: 'string', title: 'Sähköposti' },
  firstname: { type: 'string', title: 'Etunimi' },
  lastname: { type: 'string', title: 'Sukunimi' },
  phone: { type: 'string', title: 'Puhelin' },
  address: { type: 'string', title: 'Katuosoite' },
  zip: { type: 'string', title: 'Postinumero' },
  city: { type: 'string', title: 'Postitoimipaikka' },
  // password: { type: 'string', title: 'Salasana' },
  role: {
    type: 'string',
    title: 'Rooli',
    enum: ['user', 'cashier', 'admin', 'partner', 'cooperative'],
    enumNames: ['Käyttäjä', 'Kassa', 'Admin', 'Partner', 'Yhteistyökumppani'],
  },
  // memberships: {
  //   type: 'array',
  //   items: _membershipPersonClass,
  // },
  // partner: partnerClass,
}

export const _personUISchema = {
  role: {
  'ui:placeholder': 'Role',
},
phone: {
  'ui:placeholder': '+358...',
},
password: {
  'ui:widget': 'password',
  }
}
