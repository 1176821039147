/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route /*Switch, Redirect*/ } from 'react-router-dom'

// import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
// import Footer from '../components/Footer/Footer'
import Login from '../views/Login'
// import routes from '../subroutes.js'
// var ps

import Background from '../assets/img/bg/william-dehoogh-BL9zi3MtORo-unsplash.jpg'
import One from '../assets/img/bg/1.jpg'
import Two from '../assets/img/bg/2.jpg'
import Three from '../assets/img/bg/3.jpg'
import Four from '../assets/img/bg/4.jpg'
import Five from '../assets/img/bg/5.jpg'
import Six from '../assets/img/bg/6.jpg'
import Seven from '../assets/img/bg/7.jpg'
import Eight from '../assets/img/bg/8.jpg'
import Nine from '../assets/img/bg/9.jpg'
import Ten from '../assets/img/bg/10.jpg'
import Eleven from '../assets/img/bg/11.jpg'
import Twelve from '../assets/img/bg/12.jpg'
import Thirteen from '../assets/img/bg/13.jpg'
import Fourteen from '../assets/img/bg/14.jpg'
import Forget from '../views/Forget'

const images = [
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
]

class AuthLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backgroundColor: 'black',
      activeColor: 'info',
      backgroundImage: Background,
    }
  }

  componentDidMount() {
    this.setState({ backgroundImage: images[Math.floor(Math.random() * 13)] })
    const intervalId = setInterval(() => {
      const random = Math.floor(Math.random() * 13)
      this.setState({ backgroundImage: images[random] })
    }, 60000)
    this.setState({ intervalId })

  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  render() {
    return (
      <>
        <div className='wrapper wrapper-full-page'>
          <div className='full-page section-image'>
            <div className='login-page'>
              {this.props.forget ?
                <Forget />
                :
                <Login />}
            </div>
            <div
              className='full-page-background'
              style={{
                backgroundImage: "url(" + this.state.backgroundImage + ")",
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
            />
          </div>
        </div>
      </>
    )
  }
  /*
              <Switch>{this.getRoutes(routes)}</Switch>
            <Footer fluid />
*/
}

export default AuthLayout
