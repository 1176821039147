import React from 'react'
// import DemoAlert from 'views/components/DemoAlert'
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, FormGroup, Label } from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import Select from 'react-select'
import { SwatchesPicker } from 'react-color'
import Infobox from '../Infos/Infobox'

const COLORING_TYPES = [
  { value: 'user', label: 'Käyttäjä' },
  { value: 'reservationtype', label: 'Varauksen tyyppi (vakio,osake,yksittäinen)' },
  { value: 'tag', label: 'Tunniste' },
]

class Coloring extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colorcategories: [],
      loading: true,
      saved: true,
      displayColorPicker: false,
      
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.getColorCategories().onSnapshot((doc) => {
      if (doc.exists) {
        let _c = doc.data().categories.map((r) => {
          if (r.visibility === undefined) r.visibility = true
          return r
        })
        this.setState({
          colorcategories: _c,
          loading: false,
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    })
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onChange = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.colorcategories
    _a[index].enabled = event.state.value
    this.setState({ colorcategories: _a, saved: false })
  }
  
  onChangeBilling = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.colorcategories
    _a[index].billing = event.state.value
    this.setState({ colorcategories: _a, saved: false })
  }

  onChangeVisibility = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.colorcategories
    _a[index].visibility = event.state.value
    this.setState({ colorcategories: _a, saved: false })
  }

  setBillingData = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.colorcategories
    _a[index].billingdata = event.target.value
    this.setState({ colorcategories: _a, saved: false })
  }

  setCategory = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.colorcategories
    _a[index].category = event.target.value
    this.setState({ colorcategories: _a, saved: false })
  }

  setLabel = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.colorcategories
    _a[index].label = event.target.value
    this.setState({ colorcategories: _a, saved: false })
  }

  setType = (selection, action) => {
    let index = Number.parseInt(action.name.split('.')[1])
    let _a = this.state.colorcategories
    let _s = selection.value
    _a[index].type = _s
    this.setState({ colorcategories: _a, saved: false })
  }

  setColor = (selection) => {
    // console.log('setColor', selection)
    let index = this.state.selectedRow
    let _a = this.state.colorcategories
    let _s = selection.rgb
    _a[index].color = _s
    this.setState({ colorcategories: _a, saved: false })
  }



  addColorCategoryRow = () => {
    let j = this.state.colorcategories
    j.push({ empty: true, enabled: true, color: { r: '61', g: '71', b: '165', a: '1' } })
    this.setState({ colorcategories: j })
  }

  onSave = (event) => {
    event.preventDefault()
    let _rows = this.state.colorcategories.map((r) => {
      delete r.empty
      return r
    })
    this.props.firebase
      .addColorCategories({
        categories: _rows,
      })
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  toggleColorPicker = (row) => {
    // console.log('toggleColorPicker')
    this.setState({ displayColorPicker: !this.state.displayColorPicker, selectedRow: row })
  }

  colorCategoryRows = () => {
    if (this.state.colorcategories) {
      return this.state.colorcategories.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col xs='2'>
              <FormGroup>
                <Label for={'label.' + i}>Tyyppi</Label>
                <Select
                  className='react-select primary'
                  classNamePrefix='react-select'
                  name={'label.' + i}
                  id={'label.' + i}
                  value={
                    this.state.colorcategories[i].type
                      ? { value: this.state.colorcategories[i].type, label: this.state.colorcategories[i].type }
                      : ''
                  }
                  defaultValue={COLORING_TYPES[0].value}
                  onChange={this.setType}
                  options={COLORING_TYPES.map((rc) => ({
                    value: rc.value,
                    label: rc.label,
                  }))}
                />
              </FormGroup>
            </Col>
            <Col md='3' sm='3' xs='3'>
              <FormGroup>
                <Label for={'nbr.' + i}>Arvo</Label>
                <Input
                  type='text'
                  name={'nbr.' + i}
                  id={'nbr.' + i}
                  onChange={this.setCategory}
                  // placeholder={()}'Tennis....'
                  value={this.state.colorcategories[i].category ? this.state.colorcategories[i].category : ''}
                />
              </FormGroup>
            </Col>
            <Col md='2' sm='2' xs='2'>
              <FormGroup>
                <Label for={'lbl.' + i}>Otsikko</Label>
                <Input
                  type='text'
                  name={'lbl.' + i}
                  id={'lbl.' + i}
                  onChange={this.setLabel}
                  // placeholder={()}'Tennis....'
                  value={this.state.colorcategories[i].label ? this.state.colorcategories[i].label : ''}
                />
              </FormGroup>
            </Col>
            <Col md='1' sm='1' xs='1'>
              <div className='swatch' onClick={() => this.toggleColorPicker(i)}>
                <div
                  style={{
                    marginTop: '20px',
                    padding: '20px',
                    width: '36px',
                    height: '36px',
                    borderRadius: '2px',
                    background: `rgba(${this.state.colorcategories[i].color.r}, ${this.state.colorcategories[i].color.g}, ${this.state.colorcategories[i].color.b}, ${this.state.colorcategories[i].color.a})`,
                  }}
                />
              </div>
              {/* <FormGroup>
                <Label for={'nbr.' + i}>Arvo</Label>
                <Input
                  type='text'
                  name={'nbr.' + i}
                  id={'nbr.' + i}
                  onChange={this.setCategory}
                  // placeholder={()}'Tennis....'
                  value={
                    this.state.colorcategories[i].category
                      ? this.state.colorcategories[i].category
                      : ''
                  }
                  disabled={
                    this.state.colorcategories[i].category &&
                    !this.state.colorcategories[i].empty
                  }
                />
              </FormGroup> */}
            </Col>
            <Col xs={1}>
              <Label for={'enabled.' + i}>Käytössä</Label>{' '}
              <div name={'enabled.' + i}>
                <Switch
                  defaultValue={this.state.colorcategories[i].enabled}
                  onColor='primary'
                  offColor='primary'
                  id={'enabled.' + i}
                  name={'enabled.' + i}
                  onChange={this.onChange}
                />
              </div>
            </Col>
            {this.state.colorcategories && this.state.colorcategories[i].type === 'tag' ? (
              <Col xs={1}>
                <Label for={'visibility.' + i}>Näkyvyys</Label>{' '}
                <div name={'visibility.' + i}>
                  <Switch
                    defaultValue={this.state.colorcategories[i].visibility ? true : false}
                    onColor='primary'
                    offColor='primary'
                    id={'visibility.' + i}
                    name={'visibility.' + i}
                    onChange={this.onChangeVisibility}
                  />
                </div>
              </Col>
            ) : null}
            <Col xs={2}>
            {this.state.colorcategories && this.state.colorcategories[i].type === 'tag' ? (
                <><Label for={'billing.' + i}>Laskutustag</Label>{' '}
                <div name={'billing.' + i}>
                  <Switch
                    defaultValue={this.state.colorcategories[i].billing ? true : false}
                    onColor='primary'
                    offColor='primary'
                    id={'billing.' + i}
                    name={'billing.' + i}
                    onChange={this.onChangeBilling}
                  />
                </div></>
            ) : null}
            {this.state.colorcategories && this.state.colorcategories[i].type === 'tag' && this.state.colorcategories[i].billing ? (
                <FormGroup>
                <Label for={'billingdata.' + i}>Viite laskulle</Label>{' '}
                <div name={'billingdata.' + i}>
                <Input
                  type='text'
                  name={'billingdata.' + i}
                  id={'billingdata.' + i}
                  onChange={this.setBillingData}
                  value={this.state.colorcategories[i].billingdata ? this.state.colorcategories[i].billingdata : ''}
                    />
                    </div>
              </FormGroup>
            ) : null}
            </Col>

          </Row>
        )
      })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          {this.state.displayColorPicker ? (
            <div
              style={{
                position: 'absolute',
                zIndex: '2',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={() => this.toggleColorPicker(-1)}
              />
              <SwatchesPicker color={this.state.color} onChange={(color, event) => this.setColor(color)} />
            </div>
          ) : null}
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Kalenterin väritykset</CardTitle>
                      </Col>
                      <Col>
                        <Infobox index={0} />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.colorCategoryRows()}
                    <Row>
                      <Col style={{ marginTop: '20px' }}>
                        <Button
                          className='btn-round btn-icon'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.addColorCategoryRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                        </Button>{' '}
                        Lisää väritys
                      </Col>
                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button disabled={this.state.saved} color='primary' onClick={this.onSave}>
                          Tallenna väritykset
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default Coloring
