import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'

class SearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: null,
    }
  }

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value })
    if (!e.target.value) {
      this.props.onSearch(null)
    }
  }

  keyPress = (e) => {
    if (e.keyCode === 13 && this.state.search) {
      this.props.onSearch(this.state.search)
    }
  }

  clear = () => {
    this.setState({ search: null })
    this.props.onSearch(null)
  }

  render() {
    return (
      <TextField
        onKeyDown={this.keyPress}
        id='findevents'
        // label='etsi'
        type='text'
        value={this.state.search || ''}
        onChange={this.handleSearchChange}
        InputProps={
          !this.state.search
            ? {
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon color='disabled' />
                  </InputAdornment>
                ),
              }
            : {
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={this.clear} edge='end'>
                      <CancelIcon color='disabled' />
                    </IconButton>
                  </InputAdornment>
                ),
              }
        }
      />
    )
  }
}

export default SearchInput
