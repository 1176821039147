import { Grid, Box, Typography, Divider, Table, TableRow, TableContainer, TableHead, TableBody, TextField, InputAdornment, IconButton } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React from 'react'
import MuiTableCell from "@material-ui/core/TableCell"
import { withStyles } from "@material-ui/core/styles"
import Delete from '@material-ui/icons/Delete';

const TableCell = withStyles({
  root: {
    // borderBottom: "none",
    fontSize: '0.75rem',
    paddingBottom: 3,
    paddingTop: 3,
  }
})(MuiTableCell);

function toCurrency(num) {
  return (num / 100).toFixed(2).replace('.', ',') + ' €'
}

function formatDate(dateString) {
  if(!dateString) return ''
  const date = new Date(dateString)
  return date.toLocaleDateString('fi-FI', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  })
}

function ContactDetails(props) {
  const {
    name,
    email,
    phone,
    address,
    businessId,
    contacts,
    firstname,
    lastname,
    billingEmail,
    billingOvt,
    billingOperator,
    city,
    zipcode,
  } = props.contact
  let _name = name ? name : firstname + ' ' + lastname

  // billingemail, infoemail, contactemail
  let _email = email
  let _contactname = null
  if (businessId) {
    if (billingEmail) {
      _email = billingEmail
    } else if (contacts && contacts.length > 0) {
      _email = contacts[0].email
    }
    if (contacts && contacts.length > 0) {
      _contactname = contacts[0].firstname + ' ' + contacts[0].lastname
    }
  }
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant='body1'>{_name}</Typography>
          {businessId && <Typography variant='caption'>Y-tunnus: {businessId}</Typography>}
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Laskutusosoite
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {address}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption'>Postinumero ja toimipaikka</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption'>
            {zipcode} {city}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Sähköposti
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {_email}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Yhteyshenkilö
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {_contactname}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Puhelin
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {phone}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Verkkolaskuosoite
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {billingOvt}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Verkkolaskuoperaattori
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {billingOperator}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

function InvoiceInfo(props) {
  const { invoice } = props
  const { invoiceNumber, billingdate, sent, invoiceReference, duedate, billingReference } = invoice

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Laskunumero
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {invoiceNumber}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Viitteenne
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {billingReference}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Laskun päivämäärä
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {formatDate(billingdate)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Eräpäivä
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {formatDate(duedate)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Laskun viite
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {invoiceReference}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Lähetyspäivä
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {formatDate(sent)}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const paymenttypes = 
  {
  reference: 'Viitemaksu',
  banktransfer: 'Tilisiirto',
  cash: 'Kassamaksu',
  manualpayment: 'Muu maksu',
  creditmemorandum: 'Hyvityslasku',
  cashwallet: 'Saldomaksu',
  online: 'Verkkomaksu',
  }

function PaymentInfo(props) {
  const { payments, sum } = props
  let paid = 0
  if (payments && payments.length > 0) {
    payments.forEach((payment) => {
      paid += payment.amount
    })
  }
  const unpaid = sum - paid
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>Maksusuoritukset</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Kokonaissumma
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {toCurrency(sum)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Avoinna
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {toCurrency(unpaid)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Suoritukset ({payments ? payments.length : 0 })
          </Typography>
        </Grid>
        {payments.map((payment, index) => {
          let label = paymenttypes[payment.type]
          if(payment.typelabel) label = payment.typelabel
          return (
            <React.Fragment>
              <Grid item xs={6}>
                <Typography variant='caption' gutterBottom component={'span'}>
                  {formatDate(payment.timestamp) + ' ' + label}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='caption' gutterBottom component={'span'}>
                  {toCurrency(payment.amount)}
                </Typography>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </React.Fragment>
  )
}

function Invoicerows(props) {
  const { rows, creditmemorandumOpen, onCreditMemorandumChange, invoiceNumber } = props
  const [creditmemorandumRows, setCreditmemorandumRows] = React.useState([])

  if (creditmemorandumOpen && rows && creditmemorandumRows.length === 0) {
    const _creditmemorandumRows = rows.map((row, index) => {
      const _r = { ...row }
      _r.amount = -row.amount
      _r.creditmemorandum = row.amount / 100
      _r.row = 'Hyvitys - ' + row.row
      return _r
    })
    _creditmemorandumRows.push({ row: 'Hyvitys laskuun ' + invoiceNumber, product: 'HYVITYS', vat: 0, sport: '', quantity: 1, amount: 0, id: 'creditmemorandumnote-' + invoiceNumber })
    setCreditmemorandumRows(_creditmemorandumRows)
    if(onCreditMemorandumChange) onCreditMemorandumChange(_creditmemorandumRows)
  }

  const handleChange = (value, index) => {
    console.log('handleChange', value, index)
    const row = creditmemorandumRows[index]
    const max = row.cost
    let newValue = value
    if (max < value) newValue = max
    if (value < 0) newValue = 0
    const newCreditmemorandumRows = creditmemorandumRows.map((row, i) => {
      if (i === index) {
        row.amount = -newValue * 100
        row.creditmemorandum = newValue
      }
      return row
    })
    setCreditmemorandumRows(newCreditmemorandumRows)
    if(onCreditMemorandumChange) onCreditMemorandumChange(newCreditmemorandumRows)
  }
  
  const deleteCreditmemorandumRow = (index) => {
    const newCreditmemorandumRows = creditmemorandumRows.filter((row, i) => i !== index)
    setCreditmemorandumRows(newCreditmemorandumRows)
    if(onCreditMemorandumChange) onCreditMemorandumChange(newCreditmemorandumRows)
  }
  const __rows = creditmemorandumOpen ? creditmemorandumRows : rows
  const _creditmemorandumSum = creditmemorandumRows.reduce((acc, row) => acc + row.amount, 0)
  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              Tuote
            </TableCell>
            <TableCell>
              Rivi
            </TableCell>
            <TableCell>
              ALV
            </TableCell>
            <TableCell>
              Laji
            </TableCell>
            <TableCell>
              Määrä
            </TableCell>
            <TableCell>
              ALV 0%
            </TableCell>
            <TableCell>
              ALV
            </TableCell>
            <TableCell>
              Yhteensä
            </TableCell>
            {creditmemorandumOpen && 
              <TableCell>Hyvitys</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
      {__rows.map((row, index) => {
        // get vat 0 price from amount
        const vat = (row.amount * row.vat) / (100 + row.vat)
        const vat0 = row.amount - vat
        // let _amount = row.amount
        // if(creditmemorandumOpen && creditmemorandum[index]) {
        //   _amount = creditmemorandum[index] * 100
        // }
        // const vat = (_amount * row.vat) / (100 + row.vat)
        // const vat0 = _amount - vat
        return (
          <TableRow key={index}>
            <TableCell>
              {row.product}
            </TableCell>
            <TableCell>
              {row.row}
            </TableCell>
            <TableCell>
              {row.vat}
            </TableCell>
            <TableCell>
              {row.sport}
            </TableCell>
            <TableCell>
              {row.quantity}
            </TableCell>
            <TableCell>
              {creditmemorandumOpen ? toCurrency(-vat0) : toCurrency(vat0)}
            </TableCell>
            <TableCell>
              {creditmemorandumOpen ? toCurrency(-vat) : toCurrency(vat)}
            </TableCell>
            <TableCell>
              {toCurrency(row.amount)}
            </TableCell>
            {creditmemorandumOpen && row.product !== 'HYVITYS' ? 
              <TableCell>
                <TextField
                  id={`creditmemorandum-${index}`}
                  name={`creditmemorandum-${index}`}
                  type='number'
                  onChange={(e) => handleChange(e.target.value, index)}
                  value={row.creditmemorandum || ''}
                  size='small'
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    startAdornment: <InputAdornment position="start">-</InputAdornment>,
                  }}
                  style={{ width: '60%' }}
                />
                <IconButton onClick={() => deleteCreditmemorandumRow(index)} size='small' style={{ marginLeft: 10 }}>
                  <Delete />
                </IconButton>
                </TableCell>
              : null}
            {creditmemorandumOpen && row.product === 'HYVITYS' ?
              <TableCell>
                {toCurrency(_creditmemorandumSum)}
              </TableCell>
              : null}
          </TableRow>
        )
      })}
          </TableBody>
      </Table>
    </TableContainer>
  )
}

const labels = { 'invoice': 'Lasku', 'reminder': 'Maksumuistutus', 'creditmemorandum': 'Hyvityslasku' }

function Attachments(props) {
  const { invoice } = props
  const attachmenturls = Object.keys(invoice).filter((key) => key.indexOf('pdfurl') > -1).map((key) => { return { url: invoice[key], name: key.replace('pdfurl', '').replace(/[0-9]/g, '') } }).sort((a, b) => a.name.localeCompare(b.name))
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
        {attachmenturls.map((object, index) => {
          return (
            <Typography variant='caption' gutterBottom component={'span'}>
              <a href={object.url} target='_blank' rel="noreferrer"><PictureAsPdfIcon fontSize="small" /> {labels[object.name]}</a>{' '} 
            </Typography>
          )
        })}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

function InvoiceDetails(props) {
  const { invoice, creditmemorandumOpen, onCreditMemorandumChange } = props
  const { contact, payments, rows } = invoice.details
  const { sum } = invoice

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          {/* customer info */}
          <ContactDetails contact={contact} />
        </Grid>
        <Grid item sm={4} xs={12}>
          {/* invoice info */}
          <InvoiceInfo invoice={invoice.details} />
        </Grid>
        <Grid item sm={4} xs={12}>
          {/* payment info */}
          <PaymentInfo payments={payments || []} sum={sum} />
        </Grid>
        <Grid item xs={12}>
          <Attachments invoice={invoice.details} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {/* line items */}
          <Invoicerows rows={rows} invoiceNumber={invoice.details.invoiceNumber} creditmemorandumOpen={creditmemorandumOpen} onCreditMemorandumChange={onCreditMemorandumChange} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default InvoiceDetails
