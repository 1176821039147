import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button } from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import {
  getResourceSchema,
  getResourceUISchema,
} from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from '../../components/Lists/ReactTableWrapper'
// core components
// import moment from 'moment'
// import 'moment/locale/fi'
import { filterCaseInsensitive } from '../../components/FalconTable'
import EditButton from '../../components/Buttons/EditButton'

class ResourcesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, resourceModalOpen: false, selected: null, images: [] }
  }

  componentDidMount() {
    this.initData()
  }


  initData = async () => {
    const [_resources, _resourceCategories, _resourceConfigurations, _venues] = await Promise.all([
      this.props.firebase.getResourses(),
      this.props.firebase.getResourceCategories().get(),
      this.props.firebase.getResourceConfigs(),
      this.props.firebase.getVenues()
    ])
    const resources = _resources.docs.map((sh) => { return { ...sh.data(), id: sh.id } }).sort((a, b) => a.displayName.localeCompare(b.displayName))
    let resourcecategories = {}
    if (_resourceCategories.data()) {
      console.log(_resourceCategories.data())
      resourcecategories = _resourceCategories.data().categories.reduce((acc, curr) => {
        if (!acc[curr.type]) {
          acc[curr.type] = []
        }
        acc[curr.type].push(curr.category)
        return acc
      }, {})
    }
    let resourceconfigurations = []
    if (!_resourceConfigurations.empty) {
      resourceconfigurations = _resourceConfigurations.docs.map((sh) => {
        return { ...sh.data(), id: sh.id }
      })
    }
    let venues = []
    if (!_venues.empty) {
      venues = _venues.docs.map((sh) => {
        return { name: sh.data().name.toLowerCase() , id: sh.id, displayName: sh.data().name }
      })
    }
    const images = await this.props.firebase.getImages()

    this.setState(
      {
        resources,
        resourcecategories,
        resourceconfigurations,
        venues,
        images
      },
      this.filterData
    )
  }


  updateData = async () => {
    const _resources = await this.props.firebase.getResourses()
    let _d = _resources.docs.map((sh) => {
      return { ...sh.data(), id: sh.id }
    }).sort((a, b) => a.displayName.localeCompare(b.displayName))
    this.setState(
      {
        resources: _d,
      },
      this.filterData
    )
  }


  filterData = () => {
    let _data = this.state.resources.map((r) => {
      if (r.resourceconfigid) {
        let _rc = this.state.resourceconfigurations.find((rc) => rc.id === r.resourceconfigid)
        if (_rc) {
          r.resourceconfigname = _rc.displayName
        } else {
          r.resourceconfigname = 'default'
        }
      } else {
        r.resourceconfigname = 'default'
      }
      if (r.multiuse && r.multiuse.length > 0) {
        r.multiusecourt = 'Kyllä'
      } else {
        r.multiusecourt = 'Ei'
      }
      return {
        ...r
      }
    })
    this.setState({ resources: _data, loading: false })
  }

  handleRowSelect = (e) => {
    const resource = this.state.resources.filter((r) => r.id === e)
    this.setState({ selectedResource: resource[0] }, this.toggleResourceModal)
  }

  addNewResource = () => {
    this.setState({ selectedResource: {} }, this.toggleResourceModal)
  }

  toggleResourceModal = () => {
    this.setState({
      resourceModalOpen: !this.state.resourceModalOpen,
    })
  }

  getCategoryDeps = () => {
    if (this.state.resourcecategories) {
      const keys = Object.keys(this.state.resourcecategories)
      return keys.map((key) => {
        const enumNames = this.state.resourcecategories[key]
        const enums = enumNames.map((e) => e.toLowerCase())
        const dep = {
          properties: {
            type: {
              enum: [key],
            },
            category: {
              type: 'string',
              title: 'Kategoria',
              enum: enums,
              enumNames: enumNames,
            },
          },
        }
        return dep
      })
    }
  }

  getVenueOptions = () => {
    if (this.state.venues) {
      console.log(this.state.venues)
      const enumNames = this.state.venues.map((e) => e.name)
      const enums = this.state.venues
      return {
        venues: {
          enumNames,
          enum: enums
        }
      }
    }
    return null
  }
      // const enumNames = this.state.venues.map((e) => e.name)
      // const enums = this.state.venues.map((e) => e.id)
      // const dep = {
      //   properties: {
      //     venueid: {



  createResourceModal = () => {
    const schema = getResourceSchema()
    schema.dependencies.type.oneOf = this.getCategoryDeps()
    // const venueOptions = this.getVenueOptions()
    // if (venueOptions) {
    //   schema.definitions = venueOptions
    //   schema.properties.venue = {
    //     title: 'Paikka',
    //     '$ref': '#/definitions/venues'
    //   }
    // }
    // console.log(this.state.resources)
    const items = { enum: [], enumNames: [] }
    this.state.resources.forEach((res) => {
      items.enum.push(res.id)
      items.enumNames.push(res.displayName)
    })
    schema.properties.multiuse.items = items

    const rcItems = { enum: [], enumNames: [] }
    this.state.resourceconfigurations.sort((a, b) => a.displayName.localeCompare(b.displayName)).forEach((res) => {
      rcItems.enum.push(res.id)
      rcItems.enumNames.push(res.displayName)
    })
    schema.properties.resourceconfigid.enum = rcItems.enum
    schema.properties.resourceconfigid.enumNames = rcItems.enumNames

    schema.properties.sponsor.items.images = this.state.images
    schema.properties.image.items.images = this.state.images

    return (
      <FalconModal
        onUpdate={this.props.firebase.updateResource}
        onCreate={this.props.firebase.createResource}
        toggle={this.toggleResourceModal}
        modifiedCallback={this.updateData}
        resource={this.state.selectedResource}
        isOpen={this.state.resourceModalOpen}
        uiSchema={getResourceUISchema()}
        dataSchema={schema}
        title={
          this.state.selectedResource && this.state.selectedResource.id
            ? 'Muokkaa resurssia'
            : 'Uusi resurssi'
        }
      />
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                Loading ...
              </Col>
            </Row>
          </div>
        </>
      )
    } else if (this.state.resourcecategories && Object.keys(this.state.resourcecategories).length > 0) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.addNewResource}>Lisää resurssi</Button>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.resources}
                  filterable
                  noDataText='Ei löytynyt yhtään resurssia'
                  defaultFilterMethod={(filter, row) =>
                    filterCaseInsensitive(filter, row)
                  }
                  columns={[
                    {
                      Header: 'Nimi',
                      accessor: 'displayName',
                    },
                    {
                      Header: 'Tyyppi',
                      accessor: 'type',
                    },
                    {
                      Header: 'Kategoria',
                      accessor: 'category',
                    },
                    {
                      Header: 'Tuotekoodi',
                      accessor: 'product',
                    },
                    {
                      Header: 'ALV %',
                      accessor: 'vat',
                    },
                    {
                      Header: 'Laji',
                      accessor: 'sport',
                    },
                    {
                      Header: 'Kirjanpidon tili',
                      accessor: 'accounting',
                    },
                    // {
                    //   Header: 'Konfiguraatio',
                    //   accessor: 'resourceconfigname',
                    // },
                    {
                      Header: 'Monikäyttöinen',
                      accessor: 'multiusecourt',
                    },
                    {
                      Header: 'Järjestys',
                      accessor: 'order',
                    },
                    { 
                      Header: 'Sponsori',
                      accessor: 'sponsor',
                      renderCell: (params) => {
                        if (params.row.sponsor) {
                          const { url } = params.row.sponsor
                          if (!url) return null
                          const _url = url.split('/').splice(0, url.split('/').length - 1).join('/') + '/60_' + url.split('/').splice(-1)
                          return (
                            <img height={20} src={_url} alt='sponsor' />
                          )
                        } else {
                          return null
                        }
                      }
                    },
                    {
                      Header: 'Muokkaa',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                      renderCell: (params) => {
                        return (
                          <EditButton
                            onClick={() => this.handleRowSelect(params.row.id)}
                          />
                        )
                      }
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.handleRowSelect(rowInfo.row._original.id)
                        },
                      }
                    } else {
                      return {}
                    }
                  }}
                  className='-striped -highlight primary-pagination'
                />
              </Col>
            </Row>
            <Row>
              <Col md='12'></Col>
            </Row>
            {this.state.alert}
            {this.createResourceModal()}
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                Lisää ensin resurssikategoria
              </Col>
            </Row>
          </div>
        </>
      )
    }
  }
}

export default withFirebase(ResourcesContainer)
