import React from 'react'
import { withFirebase } from '../../components/Fb'
import EditButton from '../../components/Buttons/EditButton'
import Infobox from '../../components/Infos/Infobox'

// reactstrap components
import {
  Row,
  Col,
  Button,
} from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import { getProviderSchema, getProviderUISchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import { filterCaseInsensitive } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
// import moment from 'moment'
import ReactTable from '../../components/Lists/ReactTableWrapper'
// import DeleteButton from '../../components/Buttons/DeleteButton'
// core components

class ProvidersContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, providerModalOpen: false, providers: null, selected: null, images: [] }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    this.props.firebase.getProviders().then((snapshot) => {
      const data = []
      // eslint-disable-next-line
      snapshot.docs.map(async (doc) => {
        if (doc.data().state !== 'deleted') {
          data.push({ ...doc.data(), id: doc.id })
        }
      })
      this.setState({ raw: data }, this.filterData)
    })
    this.props.firebase.getImages().then((images) => {
      this.setState({ images })
    })
  }

  handleRowSelect = (e) => {
    console.log(e)
    const _result = this.state.providers.find((r) => r.id === e)
    this.setState({ selected: _result }, this.toggleProviderModal)
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      data.push({
        ...this.state.raw[i],
        actions: this.getActions(this.state.raw[i]),
        downloadUrl: this.getDownloadUrl(this.state.raw[i])
      })
      
    }
    this.setState({ loading: false, providers: data })
  }

  deleteProvider = async (id) => {
    await this.props.firebase.deleteProvider(id)
    this.setState({ loading: true }, this.updateData)
  }

  getDownloadUrl = (row) => {
    if (row.file) {
      return (
        <>
          <a href={row.file} target='_blank' rel='noopener noreferrer'>Lataa</a>
          </>
      )
    } else {
      return (<></>)
    }
  }

  getActions = (row) => {
    return (
        <>
          <div className="actions-right">
            {}
            <Button
              color="danger"
              size="sm"
              onClick={(el) => {
                this.deleteProvider(row.id)
              }}
            >
              <span className="btn-label">
                <i className="nc-icon nc-simple-remove" />
              </span>
            </Button>
          </div>
        </>
    )
  }

  addNewProvider = () => {
    this.setState({ selected: {} }, this.toggleProviderModal)
  }

  toggleProviderModal = () => {
    this.setState({
      providerModalOpen: !this.state.providerModalOpen,
    })
  }


  createProviderModal = () => {
    const schema = getProviderSchema()
    schema.properties.logo.items.images = this.state.images

    return (<FalconModal
      onUpdate={this.props.firebase.updateProvider}
      onCreate={this.props.firebase.createProvider}
      modifiedCallback={this.updateData}
      toggle={this.toggleProviderModal}
      resource={this.state.selected}
      isOpen={this.state.providerModalOpen}
      uiSchema={getProviderUISchema()}
      dataSchema={schema}
      title={this.state.selected && this.state.selected.id ? 'Muokkaa Kumppani' : 'Uusi Kumppani'}
    />)
  }


  render() {
    if (this.state.loading) {
      return(<FalconLoading />)
    } else {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.addNewProvider}>
                  Lisää Kumppani
                </Button>
              </Col>
              <Col>
                <Infobox index={'0'}/>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.providers}
                  filterable
                  noDataText='Ei löytynyt yhtään kumppania'
                  defaultFilterMethod={(filter, row) =>
                    filterCaseInsensitive(filter, row)
                  }
                  columns={[
                    {
                      Header: 'Nimi',
                      accessor: 'name',
                    },
                    {
                      Header: 'Henkilö',
                      accessor: 'contactname',
                    },
                    {
                      Header: 'Sähköposti',
                      accessor: 'contactemail',
                    },
                    {
                      Header: 'Puhelin',
                      accessor: 'contactphone',
                    },
                    {
                      Header: 'Y-tunnus',
                      accessor: 'businessId',
                    },
                    {
                      Header: 'Tunnus',
                      accessor: 'code',
                    },
                    {
                      Header: 'Muokkaa',
                      accessor: 'edit',
                      filterable: false,
                      sortable: false,
                      width: 100,
                      renderCell: (params) => {
                        return (
                          <EditButton
                            onClick={(el) => {
                              this.handleRowSelect(params.row.id)
                            }}
                          />
                        )
                      },
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  noSumRow={true}
                  // getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect) }
                  className='-striped -highlight primary-pagination'
                  getRowClassName={(params) => {
                    if (!params.row.paytrail) {
                      return 'error-row'
                    } else {
                      return ''
                    }
                  }}
                />

              </Col>
            </Row>
            {this.state.alert}
            {this.createProviderModal()}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(ProvidersContainer)
