import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'
import RecurrentContractTerms from '../../components/Config/RecurrentContractTerms'

// core components

class ConfigRecurringContractTerms extends React.Component {
  render () {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='12'>
              <RecurrentContractTerms { ...this.props } />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(ConfigRecurringContractTerms)
