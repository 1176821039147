import { _personClass, _personUISchema } from './schemas/person'
import { _membershipClass, _membershipClassDeps, _membershipUISchema } from './schemas/membership'
import { _giftcodeClass, _giftcodeClassDeps, _giftcodeUISchema } from './schemas/giftcode'
import { _contractClass, _contractClassDeps, _contractUISchema } from './schemas/contract'
import { _resourceClass, _resourceClassDeps, _resourceUISchema } from './schemas/resource'
import { _resourceConfigClass, _resourceConfigUISchema } from './schemas/resourceconfig'
import { _resourcePricingSlotClass, _resourcePricingSlotUISchema, _resourcePricingSlotClassDeps, _resourcePricingClass, _resourcePricingUISchema } from './schemas/resourcepricing'
import { _partnerClass, _partnerUISchema } from './schemas/partner'
import { _organisationClass, _organisationUISchema } from './schemas/organisation'
import { _productClass, _productUISchema } from './schemas/product'
import { _shareholderSlotClass, _shareholderSlotUISchema } from './schemas/shareholder'
import { _infotextClass, _infotextUISchema } from './schemas/infotext'
import { _activityClass, _activityUISchema } from './schemas/activity'
import { _extraClass, _extraUISchema, _extraClassDeps } from './schemas/extra'
import { _bannerClass, _bannerUISchema } from './schemas/banner'
import { _textBannerClass, _textBannerUISchema, _textBannerClassDeps } from './schemas/textbanner'
import { _benefitCardClass, _benefitCardClassDeps, _benefitCardUISchema } from './schemas/benefitcard'
import { _reservationBenefitCardClass, _reservationBenefitCardClassDeps, _reservationBenefitCardUISchema } from './schemas/reservationbenefitcard'
import { _providerClass, _providerUISchema } from './schemas/provider'
import { _shopProductClass, _shopProductUISchema } from './schemas/shopproduct'
import { _proshopProductClass, _proshopProductClassDeps, _proshopProductUISchema, _proshopBrandClass, _proshopBrandUISchema, _proshopProductCategoryClass, _proshopProductCategoryUISchema } from './schemas/proshopproduct.js'
import { _recurringSaleClass, _recurringSaleUISchema } from './schemas/recurringsale'
import { _valueCardClass, _valueCardClassDeps, _valueCardUISchema } from './schemas/valuecard'
import { _timingClass, _timingSlotClass, _timingSlotUISchema, _timingUISchema } from './schemas/timing'
import { _maintenanceContractClass, _maintenanceContractUISchema, _maintenanceServicedescriptionClass, _maintenanceServicedescriptionClassDeps, _maintenanceServicedescriptionUISchema, _maintenanceTicketClass, _maintenanceTicketUISchema } from './schemas/buildingmaintenance'

// eslint-disable-next-line
// const _partnerClass = {
//   accounting: _accountingClass,
// }

const getInfotextSchema = () => {
  let _p = {
    type: 'object',
    required: ['name', 'verifyservice'],
  }
  _p.properties = JSON.parse(JSON.stringify(_infotextClass))

  return _p
}

const getInfotextUiSchema = () => {
  return _infotextUISchema
}

const getPersonSchema = (role, memberships) => {
  let _p = {
    type: 'object',
    required: ['firstname', 'lastname', 'email', 'role'],
  }
  _p.properties = JSON.parse(JSON.stringify(_personClass))
  if (memberships) {
    if (_p.properties?.memberships?.items?.properties?.type?.anyOf) {
      _p.properties.memberships.items.properties.type.anyOf.push(...memberships) 
    }
  }
  if (role && role === 'USER') {
    delete _p.properties.role
  } else {
    delete _p.properties.memberships
  }
  return _p
}

const getPersonUiSchema = () => {
  return _personUISchema
}



const getMembershipSchema = () => {
  let _p = {
    type: 'object',
    required: ['name', 'verifyservice'],
  }
  _p.properties = JSON.parse(JSON.stringify(_membershipClass))
  _p.dependencies = JSON.parse(JSON.stringify(_membershipClassDeps))

  return _p
}

const getMembershipUiSchema = () => {
  return _membershipUISchema
}



const getGiftcodeSchema = () => {
  let _p = {
    type: 'object',
    required: ['name']
  }
  _p.properties = JSON.parse(JSON.stringify(_giftcodeClass))
  _p.dependencies = JSON.parse(JSON.stringify(_giftcodeClassDeps))

  return _p
}

const getGiftcodeUiSchema = () => {
  return _giftcodeUISchema
}

const getContractSchema = () => {
  let _p = {
    type: 'object',
    required: ['name', 'verifyservice'],
  }
  _p.properties = JSON.parse(JSON.stringify(_contractClass))
  _p.dependencies = JSON.parse(JSON.stringify(_contractClassDeps))

  return _p
}



const getContractUiSchema = () => {
  return _contractUISchema
}


const getResourceSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_resourceClass))
  _p.dependencies = JSON.parse(JSON.stringify(_resourceClassDeps))
  return _p
}

const getResourceUISchema = () => { return _resourceUISchema }

const getPartnerSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_partnerClass))
  return _p
}

const getPartnerUISchema = () => { return _partnerUISchema }


const getOrganisationSchema = () => {
  let _p = {
    type: 'object',
    required: ['businessId', 'name'],
  }
  _p.properties = JSON.parse(JSON.stringify(_organisationClass))
  return _p
}

const getOrganisationUiSchema = () => {
  return _organisationUISchema
}

const getResourceConfigSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_resourceConfigClass))
  return _p
}

const getResourceConfigUiSchema = () => {
  return _resourceConfigUISchema
}

const getResourcePricingSlotSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_resourcePricingSlotClass))
  _p.required = ['name', 'price']
  _p.dependencies = JSON.parse(JSON.stringify(_resourcePricingSlotClassDeps))
  // _p.allOf = [
  //   {
  //     if: {
  //       properties: {
  //         'price': {
  //           const: 0,
  //         },
  //       },
  //     },
  //     then: {
  //       properties: {
  //         'zeroprice': {
  //           type: 'boolean',
  //           title: 'Vahvista, että hinta 0€ on oikein',
  //           default: false,
  //         },
  //       },
  //       required: ['zeroprice'],
  //     },
  //   }
  // ]
  return _p
}

const getResourcePricingSlotUiSchema = () => {
  return _resourcePricingSlotUISchema
}

const getResourcePricingSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_resourcePricingClass))
  return _p
}

const getResourcePricingUiSchema = () => {
  return _resourcePricingUISchema
}

const getProductSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_productClass))
  return _p
}

const getProductUiSchema = () => {
  return _productUISchema
}

const getShareholderSlotSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_shareholderSlotClass))
  return _p
}

const getShareholderSlotUiSchema = () => {
  return _shareholderSlotUISchema
}

const getActivitySchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_activityClass))
  return _p 
}

const getActivityUISchema = () => {
  return _activityUISchema
}

const getExtraSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_extraClass))
  _p.dependencies = JSON.parse(JSON.stringify(_extraClassDeps))
  return _p
}

const getExtraUISchema = () => {
  return _extraUISchema
} 


const getBannerSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_bannerClass))
  return _p
}

const getBannerUISchema = () => {
  return _bannerUISchema
}

const getTextBannerSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_textBannerClass))
  _p.dependencies = JSON.parse(JSON.stringify(_textBannerClassDeps))

  return _p
}

const getTextBannerUISchema = () => {
  return _textBannerUISchema
}

const getBenefitCardSchema = () => {
  let _p = {
    type: 'object',
    required: ['name', 'price', 'type', 'validuntil', 'sport'],
  }
  _p.properties = JSON.parse(JSON.stringify(_benefitCardClass))
  _p.dependencies = JSON.parse(JSON.stringify(_benefitCardClassDeps))
  return _p
}

const getBenefitCardUISchema = () => {
  return _benefitCardUISchema
}

const getReservationBenefitCardSchema = () => {
  let _p = {
    type: 'object',
    required: ['name', 'price', 'type', 'validuntil', 'sport'],
  }
  _p.properties = JSON.parse(JSON.stringify(_reservationBenefitCardClass))
  _p.dependencies = JSON.parse(JSON.stringify(_reservationBenefitCardClassDeps))
  return _p
}

const getReservationBenefitCardUISchema = () => {
  return _reservationBenefitCardUISchema
}


const getReservationBenefitCardTimingSlotSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_timingSlotClass))
  _p.required = ['name']
  return _p
}

const getReservationBenefitCardTimingSlotUISchema = () => {
  return _timingSlotUISchema
}

const getReservationBenefitCardTimingSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_timingClass))
  return _p
}

const getReservationBenefitCardTimingUISchema = () => {
  return _timingUISchema
}



const getValueCardSchema = () => {
  let _p = {
    type: 'object',
    required: ['name', 'price', 'type'],
  }
  _p.properties = JSON.parse(JSON.stringify(_valueCardClass))
  _p.dependencies = JSON.parse(JSON.stringify(_valueCardClassDeps))
  return _p
}

const getValueCardUISchema = () => {
  return _valueCardUISchema
}


const getProviderSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_providerClass))
  return _p
}

const getProviderUISchema = () => {
  return _providerUISchema
}

const getShopProductSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_shopProductClass))
  return _p
}

const getShopProductUISchema = () => {
  return _shopProductUISchema
}

const getRecurringSaleSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_recurringSaleClass))
  return _p
}

const getRecurringSaleUISchema = () => {
  return _recurringSaleUISchema
}

const getMaintenanceContractSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_maintenanceContractClass))
  return _p
}

const getMaintenanceContractUISchema = () => {
  return _maintenanceContractUISchema
}

const getMaintenanceServicedescriptionSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_maintenanceServicedescriptionClass))
  _p.dependencies = JSON.parse(JSON.stringify(_maintenanceServicedescriptionClassDeps))
  return _p
}

const getMaintenanceServicedescriptionUISchema = () => {
  return _maintenanceServicedescriptionUISchema
}


const getMaintenanceTicketUISchema = () => {
  return _maintenanceTicketUISchema
}

const getMaintenanceTicketSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_maintenanceTicketClass))
  return _p
}


const getProshopProductSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_proshopProductClass))
  _p.dependencies = JSON.parse(JSON.stringify(_proshopProductClassDeps))
  return _p
}

const getProshopProductUISchema = () => {
  return _proshopProductUISchema
}

const getProshopBrandSchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_proshopBrandClass))
  return _p
}

const getProshopBrandUISchema = () => {
  return _proshopBrandUISchema
}

const getProshopProductCategorySchema = () => {
  let _p = {
    type: 'object',
  }
  _p.properties = JSON.parse(JSON.stringify(_proshopProductCategoryClass))
  return _p
}

const getProshopProductCategoryUISchema = () => {
  return _proshopProductCategoryUISchema
}



export {
  getPersonSchema,
  getPersonUiSchema,
  getMembershipSchema,
  getMembershipUiSchema,
  getContractSchema,
  getContractUiSchema,
  getResourceSchema,
  getResourceUISchema,
  getPartnerSchema,
  getPartnerUISchema,
  getOrganisationSchema,
  getOrganisationUiSchema,
  getResourceConfigSchema,
  getResourceConfigUiSchema,
  getResourcePricingSchema,
  getResourcePricingUiSchema,
  getResourcePricingSlotSchema,
  getResourcePricingSlotUiSchema,
  getProductSchema,
  getProductUiSchema,
  getShareholderSlotSchema,
  getShareholderSlotUiSchema,
  getInfotextSchema,
  getInfotextUiSchema,
  getGiftcodeSchema,
  getGiftcodeUiSchema,
  getActivitySchema,
  getActivityUISchema,
  getExtraSchema,
  getExtraUISchema,
  getBannerSchema,
  getBannerUISchema,
  getTextBannerSchema,
  getTextBannerUISchema,
  getBenefitCardSchema,
  getBenefitCardUISchema,
  getProviderSchema,
  getProviderUISchema,
  getShopProductSchema,
  getShopProductUISchema,
  getRecurringSaleSchema,
  getRecurringSaleUISchema,
  getReservationBenefitCardSchema,
  getReservationBenefitCardUISchema,
  getValueCardSchema,
  getValueCardUISchema,
  getReservationBenefitCardTimingSlotSchema,
  getReservationBenefitCardTimingSlotUISchema,
  getReservationBenefitCardTimingSchema,
  getReservationBenefitCardTimingUISchema,
  getMaintenanceContractSchema,
  getMaintenanceContractUISchema,
  getMaintenanceServicedescriptionSchema,
  getMaintenanceServicedescriptionUISchema,
  getMaintenanceTicketSchema,
  getMaintenanceTicketUISchema,
  getProshopProductSchema,
  getProshopProductUISchema,
  getProshopBrandSchema,
  getProshopBrandUISchema,
  getProshopProductCategorySchema,
  getProshopProductCategoryUISchema
}
