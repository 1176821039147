export const _textBannerClass = {
  name: { type: 'string', title: 'Bannerin nimi' },
  start: {
    type: 'string',
    format: 'date',
    title: 'Ensimmäinen päivä jossa näkyy',
  },
  end: {
    type: 'string',
    format: 'date',
    title: 'Viimeinen päivä jossa näkyy',
  },
  state: {
    type: 'string',
    title: 'Tila',
    enum: ['active', 'paused'],
    enumNames: ['Aktiivinen', 'Pysäytetty'],
  },
  format: {
    type: 'string',
    title: 'Muoto',
    enum: ['info', 'textad'],
    enumNames: ['Infoteksti', 'Mainos'],
  },
  content: { type: 'string', title: 'Teksti, max 280 merkkiä' },
  sections: {
    type: 'string',
    title: 'Missä näkyy',
    enum: ['all', 'reservations', 'activities'],
    enumNames: ['Kaikkialla', 'Varausnäkymä', 'Aktiviteettinäkymä'],
  },
  categories: {
    type: 'string',
    format: 'string',
    title: 'Varausnäkymän kategoriat. Erottele pilkulla. Tyhjä = kaikki',
  },
}

export const _textBannerClassDeps = {
  format: {
    oneOf: [
      {
        properties: {
          format: {
            enum: ['info'],
          },
        },
      },
      {
        properties: {
          format: {
            enum: ['textad'],
          },
          linktitle: {
            type: 'string',
            title: 'Otsikko',
          },
          link: {
            type: 'string',
            title: 'Linkki',
          },
          externalctt: {
            type: 'string',
            title: 'Ulkoinen clickthrough linkki',
          },
        },
      },
    ],
  }
}

export const _textBannerUISchema = {
  content: {
    'ui:widget': 'textarea',
  },
}
