import React from 'react'
import {
  Col,
  Row,
  // Alert,
  Button,
  // ButtonGroup
  Card,
  CardBody,
} from 'reactstrap'

import { withFirebase } from '../../components/Fb'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import 'moment/locale/fi'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
// import ReactBSAlert from 'react-bootstrap-sweetalert'
// import ReservationModal from '../../components/Inputs/ReservationModal'
// import ResourceModal from '../../components/Inputs/ResourceModal'
import FalconModal from '../../components/Inputs/FalconModal'
import {
  getResourcePricingSchema,
  getResourcePricingSlotSchema,
  getResourcePricingUiSchema,
  getResourcePricingSlotUiSchema,
} from '../../components/Inputs/dataschemas'
import Select from 'react-select'
import EditIcon from '@material-ui/icons/Edit'

const localizer = momentLocalizer(moment)
// const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

const weekdaysAsResources = [
  { resourceId: 1, resourceTitle: 'Maanantai' },
  { resourceId: 2, resourceTitle: 'Tiistai' },
  { resourceId: 3, resourceTitle: 'Keskiviikko' },
  { resourceId: 4, resourceTitle: 'Torstai' },
  { resourceId: 5, resourceTitle: 'Perjantai' },
  { resourceId: 6, resourceTitle: 'Lauantai' },
  { resourceId: 7, resourceTitle: 'Sunnuntai' },
]

const PricingToolBar = () => {
  return <></>
}

// function AgendaEvent({ event }) {
//   return (
//     <span>
//       <div>
//         {event.title} {event.employeename} - {event.message}
//       </div>
//     </span>
//   )
// }

// const ResourceHeaderContainer = ({ lightsOnOff, lightStatus }) => (props) => {
//   // console.log('ResourceHeaderContainer')
//   // console.log(props)
//   return (
//     <ResourceHeader
//       event={props}
//       lightsOnOff={lightsOnOff}
//       lightStatus={lightStatus}
//     />
//   )
// }

// { label, index, resource }

// function ResourceHeader(props) {
//   let event = props.event
//   // console.log(props.lightStatus[event.resource.id])
//   return (
//     <div>
//       {event.label}
//       <span
//         style={{ marginLeft: '20px' }}
//         onClick={(e) => props.lightsOnOff(event.resource.id)}
//       >
//         <FontAwesomeIcon
//           icon={faLightbulb}
//           color={props.lightStatus[event.resource.id] ? 'gold' : 'gray'}
//         />
//       </span>
//     </div>
//   )
// }

// function Event({ event }) {
//   // console.log(event);
//   // console.log('getting state');
//   let title = event.title
//   return (
//     <span>
//       <div>
//         {title} {event.away ? '[+]' : ''}
//       </div>
//     </span>
//   )
// }

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

class PricingCalendarContainer extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.filterRef = React.createRef()
    this.state = {
      loading: true,
      pricings: [],
      resourcePricingModalOpen: false,
      resourcePricingSlotModalOpen: false,
      pricingOptions: [],
      slots: [],
      calendarHeight: '60vh',
    }
  }

  // filterData = () => {
  //   let reservationsLoaded = false
  //   let pricingsLoaded = false
  //   let _fpricings = null
  //   // if (this.state.pricings) {
  //   //   pricingsLoaded = true
  //   //   _fpricings = this.state.rawpricings.filter((e) => {
  //   //     if (this.state.categoryFilter && this.state.categoryFilter !== 'all') {
  //   //       return this.state.categoryFilter === e.category
  //   //     }
  //   //     return true
  //   //   })
  //   // }
  //   // if (this.state.rawReservations) {
  //   //   reservationsLoaded = true
  //   //   let _resEs = []
  //   //   let _es = this.state.rawReservations.filter((e) => e.resources)
  //   //   for (let i = 0; i < _es.length; i++) {
  //   //     let _reservation = _es[i]
  //   //     for (let k = 0; k < _reservation.resources.length; k++) {
  //   //       let _resource = _reservation.resources[k]
  //   //       let _e = {}
  //   //       _e.id = _reservation.id
  //   //       _e.title = _reservation.persons.payers[0].person.lastname
  //   //       _e.start = moment(_resource.start).toDate()
  //   //       _e.end = moment(_resource.end).toDate()
  //   //       _e.allDay = false
  //   //       _e.resourceId = _resource.resource
  //   //       _e.data = _es[i]
  //   //       _resEs.push(_e)
  //   //     }
  //   //   }
  //     this.setState({
  //       // slots: _resEs,
  //       loading: false //reservationsLoaded && pricingsLoaded,
  //     })
  //   // }
  // }

  // storeDocument = async () => {
  //   let dReference = await this.props.firebase.createResource(this.state.input)
  //   let ds = await dReference.get()
  //   console.log(ds.data())
  // }

  setResource = (event) => {
    this.setState({ input: JSON.parse(event.target.value) })
  }

  addNew = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  // async componentDidMount() {
  //   this.unsubscribeResources = this.props.firebase
  //     .listenResourses()
  //     .onSnapshot((snapshot) => {
  //       let _r = snapshot.docs.map((sh) => {
  //         // console.log(sh.data())
  //         return { ...sh.data(), id: sh.id }
  //       })
  //       this.setState({ rawResources: _r }, this.filterData)
  //     })
  //   // this.unsubscribeReservations = this.props.firebase
  //   //   .listenReservations()
  //   //   .onSnapshot((snapshot) => {
  //   //     let _r = snapshot.docs.map((sh) => {
  //   //       // console.log(sh.data())
  //   //       return { ...sh.data(), id: sh.id }
  //   //     })
  //   //     this.setState({ rawReservations: _r }, this.filterData)
  //   //   })
  // }

  // componentWillUnmount() {
  //   this.unsubscribeResources()
  //   // this.unsubscribeReservations()
  // }

  componentDidMount() {
    this.updateSlotData()
  }

  updateSlotData = async () => {
    Promise.all([this.props.firebase.getResourses(), this.props.firebase.getResourcePricings()]).then((values) => {
      let _rs = values[0].docs.map((sh) => {
        return { ...sh.data(), id: sh.id }
      })
      if (!values[1].empty) {
        let _d = values[1].docs
          .map((sh) => {
            return { ...sh.data(), id: sh.id }
          })
          .sort((a, b) => {
            return  (a.priority || 0) - (b.priority || 0)
          })
        this.setState(
          {
            pricings: _d,
            resources: _rs,
          },
          this.filterData
        )
      } else {
        this.setState(
          {
            resources: _rs,
          },
          this.filterData
        )
      }
    })
  }

  filterData = () => {
    if (this.state.pricings) {
      let _princingsOptions = this.state.pricings
        .map((p) => {
          return {
            value: p.id,
            label:
              (p.priority || '0') +
              ' - ' +
              p.name /*+ ' ' + moment(p.validstart).format('DD.MM.YYYY') + ' - ' + moment(p.validend).format('DD.MM.YYYY')*/,
            active: p.active,
          }
        })
        .reduce(
          (acc, current) => {
            if (current.active) {
              acc[0].options.push(current)
            } else {
              acc[1].options.push(current)
            }
            return acc
          },
          [
            { label: 'Aktiiviset', options: [] },
            { label: 'Ei käytössä', options: [] },
          ]
        )
      this.setState({ pricingOptions: _princingsOptions, loading: false })
    } else {
      this.setState({ loading: false })
    }
    if (this.state.selectedPricingId) {
      this.updateViewablePricing(this.state.selectedPricingId)
    }
  }

  // toggleResourceModal = () => {
  //   // if (this.state.resourceModal) {
  //   //   let _e = this.state.slots.filter((e) => e.id !== 'placeholder')
  //   //   this.setState({ resourceModal: !this.state.resourceModal, slots: _e })
  //   // } else {
  //   this.setState({ resourceModal: !this.state.resourceModal })
  //   // }
  // }

  // toggleReservationModal = () => {
  //   if (this.state.reservationModal) {
  //     let _e = this.state.slots.filter((e) => e.id !== 'placeholder')
  //     this.setState({
  //       reservationModal: !this.state.reservationModal,
  //       slots: _e,
  //     })
  //   } else {
  //     this.setState({ reservationModal: !this.state.reservationModal })
  //   }
  // }

  // clearState = () => {
  //   this.setState({
  //     selectedResource: null,
  //   })
  // }

  // setReservationProperty = (property, value) => {
  //   console.log(property + ' => ' + value)
  //   let w = this.state.selectedResource ? this.state.selectedResource : {}
  //   w[property] = value
  //   this.setState({ selectedResource: w })
  // }

  // reservationModal = () => {
  //   return (
  //     <ReservationModal
  //       isOpen={this.state.reservationModal}
  //       toggle={this.toggleReservationModal}
  //       resource={this.state.selectedResource}
  //     />
  //   )
  // }

  getResourcePricing = () => {
    const schema = getResourcePricingSchema()
    const items = { enum: [], enumNames: [] }
    // eslint-disable-next-line array-callback-return
    this.state.resources.sort((a, b) => a.displayName.localeCompare(b.displayName)).forEach((res) => {
      items.enum.push(res.id)
      items.enumNames.push(res.displayName)
    })
    schema.properties.resources.items = items
    return schema
  }

  handleSelectEvent = (event) => {
    // console.log(event.data)
    this.setState({ selectedResource: JSON.parse(JSON.stringify(event.data)) }, this.toggleResourcePricingSlotModal())
  }

  handleSelectSlot = (slotInfo) => {
    // console.log('handleSelectSlot')
    // console.log(slotInfo)
    let s = moment(slotInfo.start)
    let e = moment(slotInfo.end)
    if (e.diff(s, 'minutes') < 30) {
      e = moment(s).add(30, 'minutes')
    }
    this.setState({ selectedResource: null })
    let _ePlaceholder = {
      id: 'placeholder',
      start: slotInfo.start,
      end: e.toDate(),
      resourceId: slotInfo.resourceId,
    }
    let newslots = [...this.state.slots]
    newslots.push(_ePlaceholder)
    this.setState({ slots: newslots })
    let _selectedResource = {
      configid: this.state.selectedPricingId,
      days: [slotInfo.resourceId],
      pricingunit: 'h',
      starttime: s.format('HH:mm'),
      endtime: e.format('HH:mm'),
    }
    this.setState({ selectedResource: _selectedResource }, this.toggleResourcePricingSlotModal)
  }

  updateFilter = (filter, status) => {
    let fss = {}
    if (filter === 'category') {
      fss = { categoryFilter: status }
    }
    this.setState(fss, this.filterData)
  }

  getCategoryFilters = () => {
    if (this.state.rawResources) {
      let _cs = this.state.rawResources.map((e) => e.category)
      let _dc = [...new Set(_cs)]
      const _c = _dc.map((e) => {
        return (
          <Button
            key={e}
            className='btn-round'
            color='info'
            outline
            type='button'
            onClick={() => this.updateFilter('category', e)}
            active={this.state.categoryFilter === e}
          >
            {e}
          </Button>
        )
      })
      _c.push(
        <Button
          className='btn-round'
          key='typeall'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('category', 'all')}
          active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
        >
          Kaikki
        </Button>
      )
      return _c
    } else {
      return [
        <Button
          key='typeall'
          className='btn-round'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('category', 'all')}
          active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
        >
          Kaikki
        </Button>,
      ]
    }
  }

  toggleResourcePricingModal = (state) => {
    if (state === 'new') {
      this.setState({
        resourcePricingModalOpen: !this.state.resourcePricingModalOpen,
        selectedPricing: null
      })
    } else if (this.state.selectedPricingId) {
      let _selectedPricing = this.state.pricings.filter((p) => p.id === this.state.selectedPricingId)
      if (_selectedPricing[0]) {
        this.setState({
          selectedPricing: _selectedPricing[0],
          resourcePricingModalOpen: !this.state.resourcePricingModalOpen,
        })
      }
    } else {
      this.setState({
        resourcePricingModalOpen: !this.state.resourcePricingModalOpen,
        selectedPricing: null
      })
    }
  }

  toggleResourcePricingSlotModal = () => {
    this.setState({
      resourcePricingSlotModalOpen: !this.state.resourcePricingSlotModalOpen,
    })
  }

  toggleResourcePricingSlotModalCancel = () => {
    let _e = this.state.slots.filter((e) => e.id !== 'placeholder')
    this.setState({ slots: _e }, this.toggleResourcePricingSlotModal)
  }

  createResourcePricingSlotModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.addResourcePricingSlot}
        onCreate={this.props.firebase.addResourcePricingSlot}
        toggle={this.toggleResourcePricingSlotModal}
        cancel={this.toggleResourcePricingSlotModalCancel}
        modifiedCallback={this.updateSlotData}
        resource={this.state.selectedResource}
        isOpen={this.state.resourcePricingSlotModalOpen}
        uiSchema={getResourcePricingSlotUiSchema()}
        dataSchema={getResourcePricingSlotSchema()}
        title={
          this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa hinnoittelua' : 'Uusi hinnoittelu'
        }
      />
    )
  }

  // pricing modal
  createResourcePricingModal = () => {
    return (
      <FalconModal
        onCreate={this.props.firebase.createResourcePricing}
        onUpdate={this.props.firebase.updateResourcePricing}
        modifiedCallback={this.updateSlotData}
        toggle={this.toggleResourcePricingModal}
        cancel={this.toggleResourcePricingModal}
        resource={this.state.selectedPricing}
        isOpen={this.state.resourcePricingModalOpen}
        uiSchema={getResourcePricingUiSchema()}
        dataSchema={this.getResourcePricing()}
        title={
          this.state.selectedPricing && this.state.selectedPricing.id ? 'Muokkaa hinnoittelua' : 'Uusi hinnoittelu'
        }
      />
    )
  }

  selectPricing = (event) => {
    this.updateViewablePricing(event.value)
    if (this.filterRef && this.filterRef.current) {
      console.log(window.innerHeight, this.filterRef.current.clientHeight)
      let calendarSpace = Math.round(
        ((window.innerHeight - this.filterRef.current.clientHeight - 150)/ window.innerHeight) * 100
      )
      calendarSpace = calendarSpace > 80 ? 80 : calendarSpace
      this.setState({ calendarHeight: calendarSpace + 'vh' })
    }
  }

  updateViewablePricing = (id) => {
    let _selectedPricing = this.state.pricings.filter((p) => p.id === id)
    // console.log(_selectedPricing)
    if (_selectedPricing[0]) {
      let _slots = []
      if (_selectedPricing[0].slots) {
        _slots = Object.keys(_selectedPricing[0].slots)
          .map((slotKey) => {
            let slot = _selectedPricing[0].slots[slotKey]
            let _start = moment(slot.starttime, 'HH:mm').year(1970).month(0).date(1).toDate()
            let _end = moment(slot.endtime, 'HH:mm').year(1970).month(0).date(1).toDate()
            let _title = (slot.price ? slot.price.toString() + ' €. ' : 'no price set. ') + (slot.displayName || '')
            let _ev = slot.days.map((d) => {
              return { resourceId: d, data: { ...slot }, title: _title, start: _start, end: _end, allDay: false }
            })
            return _ev
          })
          .flat()
        // console.log(_slots)
      }
      this.setState({ selectedPricingId: _selectedPricing[0].id, slots: _slots })
    }
  }

  getSelectedPricingDetails = () => {
    if (this.state.selectedPricingId) {
      let _selectedPricing = this.state.pricings.filter((p) => p.id === this.state.selectedPricingId)
      if (_selectedPricing[0]) {
        return (
          <Card>
            <CardBody onClick={this.toggleResourcePricingModal}>
              <strong>{_selectedPricing[0].active ? '' : 'Ei '}Aktiivinen</strong>
              <br />
              <strong>Prioriteetti</strong>
              {': ' + (_selectedPricing[0].priority || 0)}
              <br />
              <strong>Aikaväli</strong>
              {': ' +
                moment(_selectedPricing[0].validstart).format('DD.MM.YYYY') +
                ' - ' +
                moment(_selectedPricing[0].validend).format('DD.MM.YYYY')}
              <br />
              <strong>Saako hinnasto sisältää 0€ hintoja</strong>
              {': ' + (_selectedPricing[0].zeroprice ? 'Kyllä' : 'Ei')}
              <EditIcon color="action" fontSize="small" /*onClick={() => this.onEditNote(note.id)}*/ /> Muokkaa
            </CardBody>
          </Card>
        )
      }
    }
    return <></>
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content' style={{ height: '75vh' }}  >
          <div ref={this.filterRef}>
            <Row>
              <Col md='4'>
                <Card>
                  <CardBody>
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      closeMenuOnSelect={true}
                      name='resourcepricings'
                      options={this.state.pricingOptions}
                      formatGroupLabel={formatGroupLabel}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      placeholder='Hinnoittelut ...'
                      onChange={this.selectPricing}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Button onClick={(e) => this.toggleResourcePricingModal('new')}>Lisää hinnoittelu</Button>
              </Col>
            </Row>
            <Row>
              <Col>{this.getSelectedPricingDetails()}</Col>
              </Row>
              </div>
            <Row >
              <Col md='12'>
                <Card>
                  <CardBody>
                    
                    <Calendar
                      // style={{ height: '85vh' }}
                      style={{ height: this.state.calendarHeight }}
                      ref={this.myRef}
                      selectable
                      localizer={localizer}
                      events={this.state.slots ? this.state.slots : []}
                      views={['day']}
                      step={30}
                      min={new Date(1970, 0, 1, 0, 0, 0)}
                      // max={new Date(0, 0, 0, 23, 0, 0)}
                      defaultView={Views.DAY}
                      // scrollToTime={new Date(1970, 0, 1, 23)}
                      defaultDate={new Date(1970, 0, 1, 12)}
                      onSelectEvent={(event) => this.handleSelectEvent(event)}
                      onSelectSlot={this.handleSelectSlot}
                      // onView={this.updateView}
                      components={{ toolbar: PricingToolBar }}
                      // components={{
                      //   resourceHeader: ResourceHeaderContainer({
                      //     lightsOnOff: this.lightsOnOff,
                      //     lightStatus: this.state.lights,
                      //   }),
                      //   event: Event,
                      //   agenda: {
                      //     event: AgendaEvent,
                      //   },
                      // }}
                      // eventPropGetter={this.eventStyleGetter}
                      resources={weekdaysAsResources}
                      resourceIdAccessor='resourceId'
                      resourceTitleAccessor='resourceTitle'
                      />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.createResourcePricingSlotModal()}
            {this.createResourcePricingModal()}
          </div>
        </>
      )
    } else {
      return (
        <>
          <Row>
            <Col>Loading ...</Col>
          </Row>
        </>
      )
    }
  }
}

export default withFirebase(PricingCalendarContainer)
