import React from 'react'
// reactstrap components
import {  Jumbotron } from 'reactstrap'
// import jumbotronBg from '../../assets/img/bg/chris-karidisz.jpg'
// core components

class TBDContainer extends React.Component {
  render() {
    // const path = this.props.path.split('/')[2]
    // const release = this.props.releases.filter((r) => r.features.includes(path))
    return (
      <>
        <div className='content'>
          <Jumbotron style={{ background: 'rgba(256, 256, 256, 0.3)' }}>
            <h4 className='display-4'>
              Hallisarjat julkaistaan Q1/2024.
            </h4>
            <p className='lead'></p>
            <hr className='my-2' />
            <p></p>
            <p className='lead'></p>
          </Jumbotron>
          {/* <Row>
            {this.props.releases.map((release, index) => {
              return (
                <Col key={index}>
                  <Card>
                  <CardBody>
                    <CardTitle tag='h5'>{release.releasename}</CardTitle>
                    <div><List type="unstyled">{release.features.map((f,i) => { return (<li key={i}>{f}</li>)})}</List></div>
                    </CardBody>
                    </Card>
                </Col>
              )
            })}
          </Row> */}
        </div>
      </>
    )
  }
}

export default TBDContainer
