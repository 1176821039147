import { Drawer, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { isMobile } from 'react-device-detect'
import { DataGrid } from '@material-ui/data-grid'
import moment from 'moment-timezone'
import EditButton from '../Buttons/EditButton'

const flattenBookings = (bookings, resources) => {
  if (!bookings) return []
  if (!resources) return []
  const _b = bookings.filter((x) => !(x.id.startsWith('ph-') || x.id.startsWith('placeholder-')))
  const _flattened = _b.map((x) => {
    // console.log(x)
    let _court = resources.find((y) => y.id === x.resourceId)
    const courtname = _court ? _court.displayName : ''
    return {
      id: x.id,
      start: moment.utc(x.start).format('HH:mm'),
      end: moment.utc(x.end).format('HH:mm'),
      court: x.resourceId,
      courtname: courtname,
      isrecurring: x.data.isrecurring,
      cost: x.paymentstatus.cost / 100,
      paid: x.paymentstatus.cost - x.paymentstatus.paid > 0 ? 'Ei' : 'Kyllä',
      title: x.title,
      occurency: { date: moment.utc(x.start).format('YYYY-MM-DD') },
    }
  })
  return _flattened
}

class BookingsSideDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      draweropen: props.open,
      selections: [],
      selectionModel: [],
      bookings: [],
      filterTitle: '',
      resources: [],
      reservationModalOpen: false,
      loading: {},
    }
  }

  componentDidMount() {
    this.setState({ bookings: flattenBookings(this.props.bookings, this.props.resources) })
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({ draweropen: this.props.open })
    }
    if (this.props.bookings !== prevProps.bookings) {
      this.setState({ bookings: flattenBookings(this.props.bookings, this.props.resources) })
    }
    if (this.props.resources !== prevProps.resources) {
      this.setState({ bookings: flattenBookings(this.props.bookings, this.props.resources) })
    }
  }

  getColDefinitionsForReservations = () => {
    const cols = [
      {
        headerName: 'Otsikko',
        field: 'title',
      },
      {
        headerName: 'Alku',
        field: 'start',
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Loppu',
        field: 'end',
        disableClickEventBubbling: true,
      },
      { headerName: 'Kenttä', field: 'courtname' },
      {
        headerName: 'Vakio/Irto',
        field: 'isrecurring',
        valueFormatter: (params) => {
          return params.value === true ? 'Vakio' : 'Irto'
        },
      },
      {
        headerName: 'Hinta',
        field: 'cost',
        valueFormatter: (params) => {
          return params.value + ' €'
        },
      },
      {
        headerName: 'Maksettu',
        field: 'paid',
      },
      {
        headerName: 'Muokkaa',
        field: 'edit',
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <EditButton
              loading={this.state.loading[params.row.id]}
              onClick={() => {
                this.openReservationModal(params.row.id, params.row.occurency)
              }}
            />
          )
        },
      },
    ]
    return cols.map((x) => {
      return { ...x, flex: 1 / cols.length }
    })
  }

  toggleSelectAll = () => {
    if (this.state.selections.length === this.state.bookings.length) {
      this.setState({ selections: [] })
    } else {
      const se = this.state.bookings.map((i) => i.id)
      this.setState({ selections: se })
    }
    if (this.state.selectionModel.length === this.state.bookings.length) {
      this.setState({ selectionModel: [] })
    } else {
      const se = this.state.bookings.map((i) => i.id)
      this.setState({ selectionModel: se })
    }
  }

  openReservationModal = async (id, occurency) => {
    this.setState({ loading: { ...this.state.loading, [id]: true } })
    await this.props.onReservationOpen(id, occurency)
    this.setState({ loading: { ...this.state.loading, [id]: false } })
    this.props.onClose()
  }

  // openReservationModal = async (id, occurency) => {
  //   const reid = id.split('---').pop()
  //   const reservation = await this.props.firebase.getReservation(reid)
  //   const start = moment.utc(reservation.data().resourcegroups[0].start)
  //   const end = moment.utc(reservation.data().resourcegroups[0].end)
  //   const selectedReservation = {
  //     id: reservation.id,
  //     start: moment.utc(occurency.date).hour(start.hour()).minutes(start.minutes()).format(),
  //     end: moment.utc(occurency.date).hour(end.hour()).minutes(end.minutes()).format(),
  //   }
  //   selectedReservation.data = { ...reservation.data(), id: reservation.id }
  //   // console.log('selectedReservation', selectedReservation)
  //   this.setState({ selectedReservationResource: selectedReservation, reservationModalOpen: true }, this.props.onClose)
  //   // this.setState({ reservationModalOpen: true, selectedReservationResource: resource })
  // }

  render() {
    let _width = '50vw'
    if (isMobile) {
      _width = '100vw'
    }
    return (
      <React.Fragment>
        <Drawer anchor={'right'} open={this.state.draweropen} onClose={this.props.onClose}>
          <div style={{ margin: 4 }}>
            <Row style={{ width: _width }}>
              {/* <Col>
                <Button color='warning' size='sm' onClick={() => this.toggleSelectAll()}>
                  {this.state.selectionModel.length === this.state.bookings.length
                    ? this.state.selectionModel.length > 0
                      ? 'Poista valinnat'
                      : 'Valitse ...'
                    : 'Valitse kaikki'}
                </Button>
              </Col> */}
              <Col className='text-right'>
                <Button size={'xs'} onClick={this.props.onClose}>
                  Sulje
                </Button>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Button size={'xs'} onClick={this.props.onClose} disabled={this.state.selections.length === 0}>
                  Valot pois valituista
                </Button>
                <Button size={'xs'} onClick={this.props.onClose} disabled={this.state.selections.length === 0}>
                  Peruuta valitut vuorot
                </Button>
                <Button size={'xs'} onClick={this.props.onClose} disabled={this.state.selections.length === 0}>
                  Laita myyntiin valitut vuorot
                </Button>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <TextField
                  id='outlined-basic'
                  label='Otsikko'
                  variant='outlined'
                  size='small'
                  value={this.state.filterTitle}
                  onChange={(e) => this.setState({ filterTitle: e.target.value })}
                  style={{ marginBottom: 10 }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 150px)', backgroundColor: '#FFFFFF' }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      componentsProps={{ panel: { disablePortal: true } }}
                      rows={this.state.bookings}
                      columns={this.getColDefinitionsForReservations()}
                      density='compact'
                      pageSize={20}
                      disableSelectionOnClick={true}
                      rowsPerPageOptions={[20, 50, 100]}
                      pagination
                      filterable
                      checkboxSelection
                      onSelectionModelChange={(newSelectionModel) => {
                        this.setState({ selectionModel: newSelectionModel, selections: newSelectionModel })
                      }}
                      selectionModel={this.state.selectionModel}
                      filterModel={{
                        items: [{ columnField: 'title', operatorValue: 'contains', value: this.state.filterTitle }],
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row>
            <Col>
              <pre>{JSON.stringify(this.state.bookings, null, 2)}</pre>
            </Col>
          </Row> */}
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}

export default BookingsSideDrawer
