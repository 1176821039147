import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'
import ReservationExtras from '../../components/Lists/ReservationExtras'
// import ExtrasSchedules from '../../components/Lists/ExtrasSchedules'

// core components

class ReservationExtrasContainer extends React.Component {
  render () {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='12'>
              <ReservationExtras {...this.props} />
            </Col>
            {/* <Col md='6'>
              <ReservationExtras { ...this.props } />
            </Col>
            <Col md='6'>
              <ExtrasSchedules {...this.props} />
            </Col> */}
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(ReservationExtrasContainer)
