import React from 'react'
import { Col, Row, Modal, ModalHeader, Alert } from 'reactstrap'

import ResourceInput from '../../components/Inputs/ResourceInput'
import { withFirebase } from '../../components/Fb'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import 'moment/locale/fi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import ReactBSAlert from "react-bootstrap-sweetalert"
// import CashierActionBar from './CashierActionBar'
import ReservationModal from '../../components/Inputs/ReservationModal'
import ResourceModal from '../../components/Inputs/ResourceModal'

const localizer = momentLocalizer(moment)
const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

function AgendaEvent({ event }) {
  return (
    <span>
      <div>
        {event.title} {event.employeename} - {event.message}
      </div>
    </span>
  )
}

const ResourceHeaderContainer = ({ lightsOnOff, lightStatus }) => props => {
  // console.log('ResourceHeaderContainer')
  // console.log(props)
  return <ResourceHeader event={props} lightsOnOff={lightsOnOff} lightStatus={lightStatus}/>;
}

// { label, index, resource }

function ResourceHeader(props) {
  let event = props.event
  // console.log(props.lightStatus[event.resource.id])
  return (
    <div>{event.label}
      <span style={{ marginLeft: '20px' }} onClick={(e) => props.lightsOnOff(event.resource.id)}>
        <FontAwesomeIcon icon={faLightbulb} color={(props.lightStatus[event.resource.id] ? 'gold' : 'gray')}  />
      </span>
    </div>
  )
}


function Event({ event }) {
  // console.log(event);
  // console.log('getting state');
  let title = event.title
  return (
    <span>
      <div>
        {title} {event.away ? '[+]' : ''}
      </div>
    </span>
  )
}

class CourtsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = { loading: true, resources: [], reservationAlert: null, lights: {}, reservationModal: false, resourceModal: false }
  }

  filterData = () => {
    let reservationsLoaded = false
    let resourcesLoaded = false
    if (this.state.resources) {
      resourcesLoaded = true
    }
    if (this.state.rawReservations) {
      reservationsLoaded = true
      let _resEs = []
      let _es = this.state.rawReservations.filter((e) => e.resources)
      for (let i = 0; i < _es.length; i++) {
        let _reservation = _es[i]
        for (let k = 0; k < _reservation.resources.length; k++) {
          let _resource = _reservation.resources[k]
          let _e = {}
          _e.id = _reservation.id
          _e.title = _reservation.persons.payers[0].person.lastname
          _e.start = moment(_resource.start).toDate()
          _e.end = moment(_resource.end).toDate()
          _e.allDay = false
          _e.resourceId = _resource.resource
          _e.data = _es[i]
          _resEs.push(_e)
        }
      }
      this.setState({ events: _resEs, loaded: (reservationsLoaded && resourcesLoaded) })
    }
  }


  // storeDocument = async () => {
  //   let dReference = await this.props.firebase.createResource(this.state.input)
  //   let ds = await dReference.get()
  //   console.log(ds.data())
  // }

  setResource = (event) => {
    this.setState({ input: JSON.parse(event.target.value) })
  }

  addNew = () => {
    this.setState({
      modalNotice: !this.state.modalNotice
    })
  }

  toggleLights = (id) => {
    let _l = this.state.lights
    _l[id] = !_l[id]
    this.setState({
      lights: _l,
      lightsDialog: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={(_l[id] ? 'Sytytetty' : 'Sammutettu')}
          onConfirm={() => this.hideLightsAlert()}
          onCancel={() => this.hideLightsAlert()}
          confirmBtnBsStyle="info"
        >
          Done.
        </ReactBSAlert>
      )})
  }

  hideLightsAlert = () => {
    this.setState({
      lightsDialog: null
    });
  }

  lightsOnOff = (id) => {
    // console.log('lightsOnOff ' + id)
    this.setState({
      lightsDialog: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={(this.state.lights[id] ? 'Sammutetaanko valot?' : 'Sytytetäänkö valot?')}
          onConfirm={() => this.toggleLights(id)}
          onCancel={() => this.hideLightsAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="Peruuta"
          showCancel
        >
        </ReactBSAlert>
      )
    })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice
    })
  }

  async componentDidMount() {
    this.unsubscribeResources = this.props.firebase.listenResourses()
      .onSnapshot((snapshot) => { 
        let _r = snapshot.docs.map((sh) => {
          // console.log(sh.data())
          return { ...sh.data(), id: sh.id }
        })
        this.setState({ resources: _r }, this.filterData)
      })
    this.unsubscribeReservations = this.props.firebase.listenReservations()
      .onSnapshot((snapshot) => { 
        let _r = snapshot.docs.map((sh) => {
          // console.log(sh.data())
          return { ...sh.data(), id: sh.id }
        })
        this.setState({ rawReservations: _r }, this.filterData)
      })
    
  }

  componentWillUnmount() {
    this.unsubscribeResources()
    this.unsubscribeReservations()
  }

  toggleResourceModal = () => {
    // if (this.state.resourceModal) {
    //   let _e = this.state.events.filter((e) => e.id !== 'placeholder')
    //   this.setState({ resourceModal: !this.state.resourceModal, events: _e })
    // } else {
      this.setState({ resourceModal: !this.state.resourceModal })
    // }
  }


  toggleReservationModal = () => {
    if (this.state.reservationModal) {
      let _e = this.state.events.filter((e) => e.id !== 'placeholder')
      this.setState({ reservationModal: !this.state.reservationModal, events: _e })
    } else {
      this.setState({ reservationModal: !this.state.reservationModal })
    }
  }
  toggleReservationAlert = () => this.setState({ reservationAlert: !this.state.reservationAlert })

  clearState = () => {
    this.setState({
      selectedResource: null,
    })
  }

  setReservationProperty = (property, value) => {
    // console.log(property + ' => ' + value)
    let w = this.state.selectedResource
      ? this.state.selectedResource
      : { }
    w[property] = value
    this.setState({ selectedResource: w })
  }

  reservationAlert = () => {
    return(<Alert color="danger" isOpen={this.state.reservationAlert} toggle={this.toggleReservationAlert} fade={true}>
      Vanhaa tapahtumaa ei voi muuttaa.
    </Alert>)
  }

  reservationModal = () => {
    return (
      <ReservationModal
        isOpen={this.state.reservationModal}
        toggle={this.toggleReservationModal}
        resource={this.state.selectedResource} />
    )
  }

  resourceModal = () => {
    return (
      <ResourceModal
        isOpen={this.state.resourceModal}
        toggle={this.toggleResourceModal}
        resource={this.state.selectedResource} />
    )
  }


  handleSelectEvent = (event) => {
    // console.log(event)
    if(moment(event.end).isBefore()) {
      this.toggleReservationAlert()
    } else {
      this.setState({selectedResource: JSON.parse(JSON.stringify(event))}, this.toggleReservationModal())
    }
  }

  handleSelectSlot = (slotInfo) => {
    // console.log('handleSelectSlot')
    // console.log(slotInfo)
    let s = moment(slotInfo.start)
    let e = moment(slotInfo.end)
    if (e.diff(s, 'minutes') < 60) {
      e = moment(s).add(60, 'minutes')
    }
    this.setState({ selectedResource: null })
    let _ePlaceholder = { id: 'placeholder', start: slotInfo.start, end: e.toDate(), resourceId: slotInfo.resourceId }
    let newEvents = [...this.state.events]
    newEvents.push(_ePlaceholder)
    this.setState({ events: newEvents })
    this.setReservationProperty('start', s.format(TIMEFORMAT))
    this.setReservationProperty('end', e.format(TIMEFORMAT))
    this.setReservationProperty('resourceid', slotInfo.resourceId)
    this.toggleReservationModal()
  }


  render() {
    const today = new Date()
    // const minTime = new Date();
    // minTime.setHours(8,30,0);
    // const maxTime = new Date();
    // maxTime.setHours(20,30,0)

    if (this.state.loaded) {
      return (
        <>
         {this.reservationAlert()}
          <Row>
            <Col md='12'>
            <Calendar
              ref={this.myRef}
              selectable
              localizer={localizer}
                events={this.state.events ? this.state.events : []}
                views={['day', 'week']}
                step={30}
                min={new Date(1972, 0, 1, 5, 0, 0)}
                // max={new Date(0, 0, 0, 23, 0, 0)}
              defaultView={Views.DAY}
              scrollToTime={new Date(1970, 1, 1, 23)}
              defaultDate={today}
              onSelectEvent={(event) => this.handleSelectEvent(event)}
              onSelectSlot={this.handleSelectSlot}
              onView={this.updateView}
              components={{
                resourceHeader: ResourceHeaderContainer({
                  lightsOnOff: this.lightsOnOff,
                  lightStatus: this.state.lights
                }),
                event: Event,
                agenda: {
                  event: AgendaEvent,
                },
              }}
              eventPropGetter={this.eventStyleGetter}
              resources={this.state.resources}
              resourceIdAccessor='id'
              resourceTitleAccessor='displayName'
              />
            </Col>
          </Row>
          {this.reservationModal()}
          {this.resourceModal()}
          <Row>
            {/* 
            <Col md="1">
            <Button color="info" className="float-right" onClick={() => this.toggleResourceModal()}>
                        <span className="btn-label">
                          <i className="nc-icon nc-simple-add" />
                        </span>
                        Lisää uusi resurssi
                      </Button>
            </Col>
            <Col md="11">
                <FormGroup row>
                  <Label for='exampleText' sm={2}>
                    Text Area
                  </Label>
                  <Col sm={10}>
                    <Input onChange={this.setResource} type='textarea' name='text' id='exampleText' />
                  </Col>
                </FormGroup>
              </Col>
          </Row>
          <Row>
            <FormGroup check row>
        <Col sm={{ size: 10, offset: 2 }}>
          <Button onClick={this.storeDocument}>Submit</Button>
        </Col>
            </FormGroup>
            </Row>
            <Row>
              <Col>
                <code>{JSON.stringify(this.state.resources, null, 2)}</code>
              </Col> */}
          </Row> 
          <Modal
            isOpen={this.state.modalNotice}
            toggle={this.toggleModalNotice}
          >
            <ModalHeader toggle={this.toggleModalNotice}>Lisää uusi resurssi</ModalHeader>
            <ResourceInput {...this.props} onDone={this.toggleModalNotice} onCancel={this.toggleModalNotice}/>
          </Modal>
          {this.state.lightsDialog}
        </>
      )
    } else {
      return (
        <>
            <Row>
              <Col>Loading ...</Col>
            </Row>
        </>
      )
    }
  }
}

export default withFirebase(CourtsContainer)
