import React, { Component } from 'react'
import { withFirebase } from '../Fb'
import {
  Button,
  Input,
  Progress,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Select from 'react-select'
// import ReactDatetime from "react-datetime"
// import { uuid } from 'uuidv4'

class ResourceInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendenable: false,
      resourceCategories: []
    }
    this.handleInput = this.handleInput.bind(this)
  }

  async componentDidMount() {
    const _cats = await this.props.firebase.getResourceCategories()
    this.setState( { resourceCategories: _cats })
  }

  validateInput = () => {
    let mandatories = ['name', 'category'].map((e) => this.state[e] ? true : false).every(x => x === true)
    if (mandatories) {
      this.setState({ sendenable: true })
    } else {
      this.setState({ sendenable: false })
    }
  }

  handleInfoChange = event => {
    this.setState({ [event.target.name]: event.target.value }, this.validateInput)
  }

  handleSelectChange = (selectObject) => {
    const _s = {}
    _s[selectObject.type] = selectObject.value
    this.setState(_s, this.validateInput)
  }


  handleDueChange = documentdate => this.setState({ documentdate: documentdate.format('DD.MM.YYYY'), docprefix: documentdate.format('YYYY-MM') }, this.validateInput)

  handleInput = async () => {
    // const id = uuid()
    const dd = this.props.firebase.getToday()
    // let documentid = this.state.docprefix + '-' + id
    /*let dReference = */await this.props.firebase.createResource({ created: dd, name: this.state.name, category: this.state.category })
    // let ds = await dReference.get()
    // console.log(ds.data())
    this.props.onDone()
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  render() {
  //   const style = {
  //     // height: '100vh',
  //     // display: 'flex',
  //     flexDirection: 'column',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     // marginBottom: 10
  //   };
    return (
      <>
        <ModalBody>
          <Progress max="100" value={this.state.progress} />
          <br />
          <Input className="manualinput cashflow" name="name" placeholder="Nimi" type="text" onChange={this.handleInfoChange} />
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="category"
            // value={timeValue}
            defaultValue={this.state.resourceCategories[0]}
            onChange={this.handleSelectChange}
            options={this.state.resourceCategories}
            placeholder="Kategoria"
          />

          {/* <Input className="manualinput cashflow" name="expenses" placeholder="Expenses" type="text" onChange={this.handleInfoChange} />
          <Input className="manualinput cashflow" name="saldo" placeholder="Saldo" type="text" onChange={this.handleInfoChange} />
          <ReactDatetime
            inputProps={{
              className: "form-control manualinput",
              placeholder: "Päivämäärä",
              name: "documentdate",
            }}
            timeFormat={false}
            onChange={this.handleDueChange}
          /> */}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={this.handleInput} disabled={!this.state.sendenable}>Lähetä</Button>
          <Button color="secondary" onClick={this.handleCancel}>Cancel</Button>
        </ModalFooter>
      </>
    )
  }
}

export default withFirebase(ResourceInput)