import React, { Component } from 'react'
import { Col, Row, /*Button, Modal, ModalHeader, Alert*/ } from 'reactstrap'
import CourtsContainer from './CourtsContainer';

class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return (
      <>
      <Row>
          <Col>
            <CourtsContainer />
        </Col>
      </Row>
      <Row>
          <Col>
            Live
          </Col>
        </Row>
        </>
    )
  }

}
 

export default MainContainer