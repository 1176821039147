import React, { Component } from 'react'
// import { Col, Row, Spinner, Modal, ModalBody, Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap'
import { withFirebase } from '../Fb'
import moment from 'moment'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Tabs from '@material-ui/core/Tabs'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ListItem, Typography, ListItemText, ListItemSecondaryAction, Divider, Avatar, Switch } from '@material-ui/core'
// import TabPanel from '@material-ui/lab/TabPanel'
import WarningIcon from '@material-ui/icons/Warning'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import ActivityCreator from './ActivityCreator'
import EditIcon from '@material-ui/icons/Edit'
import Autocomplete from '@material-ui/lab/Autocomplete'
import algoliasearch from 'algoliasearch'
import DeleteButton from '../Buttons/DeleteButton'
import { DataGrid, GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid'
import MoveButton from '../Buttons/MoveButton'
import CheckInoutButton from '../Buttons/CheckInOutButton'
import PersonSearch from '../Inputs/PersonSearch'
import ImageSelector from '../Inputs/ImageSelector'
import MessageDialog from '../Editor/MessageDialog'

const ACL_FIELDS = ['allowpostpayment', 'blocked', 'blockedMessage']
const GENDERS = [
  { label: 'Avoin', value: 'open' },
  { label: 'Miehet', value: 'men' },
  { label: 'Naiset', value: 'female' },
]
const STATES = [
  { label: 'Luonnos', value: 'draft' },
  { label: 'Julkaistu', value: 'published' },
  { label: 'Peruttu', value: 'cancelled' },
  { label: 'Poistettu', value: 'deleted' },
]

const paymenttypes = [
  {
    value: 'transfer',
    label: 'Siirto',
  },
  {
    value: 'compensation',
    label: 'Hyvitys',
  },
  {
    value: 'deposit',
    label: 'Talletus',
  },
]

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f')
const algoliaindex = algoliaclient.initIndex('falcon_combined')

function objectsAreSame(x, y) {
  var objectsAreSame = true
  for (var propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false
      break
    }
  }
  return objectsAreSame
}

const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsAreSame(o, a2[idx]))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  )
}

function EditIconButton(props) {
  return (
    <IconButton aria-label='Muokkaa' onClick={props.onClick}>
      <EditIcon />
    </IconButton>
  )
}

function ExportToolbar({ name, date, suffix }) {
  let _suffix = suffix || 'activity'
  let safename = ''
  if (name) {
    safename = name.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '-'
  }
  if (date) {
    safename += moment(date, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD') + '-'
  }

  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: safename + _suffix,
          delimiter: ';',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const clearAlgolia = (x) => {
  x.id = x.objectID
  delete x._highlightResult
  delete x.lastmodified
  delete x.reservationAlert
  delete x.objectID
  return x
}

class ActivityDetailsWizard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      reservationModalOpen: false,
      loadingModalOpen: false,
      reservations: [],
      formattedReservations: [],
      saldoloading: true,
      storageloading: true,
      membershipsloading: true,
      membershippermanent: false,
      aclsloading: true,
      acls: {},
      showAddMembershipRow: false,
      venues: [],
      activeTab: '1',
      removePersonConfirmation: false,
      saldodescription: null,
      saldotype: null,
      saldowallet: null,
      saldovenue: null,
      saldoamount: 0,
      savingsaldo: false,
      storagedescription: null,
      storagetype: null,
      storagewallet: null,
      storagevenue: null,
      storageamount: 0,
      savingstorage: false,
      tabValue: 0,
      messages: [],
      messagesloading: true,
      messageopen: [],
      messaging: { isOpen: false },
      copied: false,
      passwordResetLink: '',
      edits: {
        nameEditOpen: false,
      },
      activityEdits: {},
      activity: this.props.activity,
      newparticipant: [],
      newqueued: [],
      sports: this.props.sports,
      activitytypes: this.props.activitytypes,
      priceInputs: {
        price: this.props.activity && this.props.activity.price ? this.props.activity.price.toFixed(2) : '0.00',
      } 
    }
  }

  componentDidMount() {
    // console.log('mount')
    // this.getReservations(this.props.person.id)
    // this.getSaldo(this.props.person.id)
    this.updateData()
    this.setState({ tabValue: this.props.initTab || 0 })
  }

  componentDidUpdate(prevProps) {
    if (this.props.initTab !== prevProps.initTab) {
      this.setState({ tabValue: this.props.initTab })
    }
    if (this.props.activity && prevProps.activity) {
      const participants = this.props.activity.participants || []
      const prevParticipants = prevProps.activity.participants || []
      const queue = this.props.activity.queue || []
      const prevQueue = prevProps.activity.queue || []
      // check if participants or queue changed
      if (arraysEqual(participants, prevParticipants) && arraysEqual(queue, prevQueue)) return
      this.updateData()
    }
  }

  updateData = () => {
    if (this.props.activity) {
      if (this.props.activity.vat === undefined) {
        this.props.activity.vat = 10
      }
      if (this.props.activity.vat === null) {
        this.props.activity.vat = 10
      }
      if (this.props.activity.vat === '') {
        this.props.activity.vat = 10
      }
    }
    this.setState({
      loading: false,
      activity: this.props.activity,
      activityEdits: {},
      edits: {},
      checkin: {},
    })
  }

  handleTextChange = (e, property) => {
    // console.log('handleTextChange', e.target.value, property)
    const { activity } = this.state
    if (property.indexOf('.') > -1) {
      const [parent, child] = property.split('.')
      activity[parent][child] = e.target.value
    } else {
      activity[property] = e.target.value
    }
    this.setState({ activity, activityEdits: { ...this.state.activityEdits, [property]: true } })
  }

  handlePriceChange = (e, property) => {
    const { priceInputs } = this.state
    priceInputs[property] = e.target.value
    this.setState({ priceInputs, activityEdits: { ...this.state.activityEdits, [property]: true } })
  }

  handleNumberChange = (e, property) => {
    const { activity } = this.state
    if (typeof e.target.value !== 'number') {
      let value = e.target.value
      if (value.indexOf(',') > -1) {
        value = value.replace(',', '.')
      }
      value = value.replace(/[^0-9.]/g, '')
      if (property.indexOf('.') > -1) {
        const [parent, child] = property.split('.')
        activity[parent][child] = parseFloat(value)
      } else {
        activity[property] = parseFloat(value)
      }
    } else {
      if (property.indexOf('.') > -1) {
        const [parent, child] = property.split('.')
        activity[parent][child] = e.target.value
      } else {
        activity[property] = e.target.value
      }
    }
    this.setState({ activity, activityEdits: { ...this.state.activityEdits, [property]: true } })
  }

  handleSwitchChange = (e, property) => {
    const { activity } = this.state
    if (property.indexOf('.') > -1) {
      const [parent, child] = property.split('.')
      activity[parent][child] = e.target.checked
    } else {
      activity[property] = e.target.checked
    }
    this.setState({ activity, activityEdits: { ...this.state.activityEdits, [property]: true } })
  }

  handlePosterChange = (e) => {
    const { activity } = this.state
    const _image = this.props.images.find((image) => image.id === e.target.value)
    activity.poster = _image.url
    activity.media = _image
    this.setState({ activity, activityEdits: { ...this.state.activityEdits, poster: true } }, this.togglePosterEdit)
  }

  togglePosterEdit = () => {
    this.setState({ edits: { ...this.state.edits, posterEditOpen: !this.state.edits.posterEditOpen } })
  }

  setEditState = (property, value) => {
    this.setState({ edits: { ...this.state.edits, [property]: value } })
  }

  handleSave = async () => {
    this.setState({ saving: true, edits: {}, activityEdits: {} })
    // fix prices
    const { activity, priceInputs } = this.state
    if (priceInputs.price) {
      // clear price 
      let _price = priceInputs.price.replace(',', '.').replace(/[^0-9.]/g, '')
      activity.price = parseFloat(_price)
    } else {
      activity.price = 0
    }
    await this.props.firebase.updateActivity(activity)
    this.setState({ saving: false })
  }

  searchParticipants = (input) => {
    algoliaindex.search(input, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const searchresults = hits
        .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
        .map((x) => clearAlgolia(x))
      this.setState({ searchresults })
    })
  }

  removeParticipant = async (userid) => {
    let activityid = this.props.activity.id
    this.setState({ removing: true })
    await this.props.firebase.removeParticipantsFromActivity(activityid, [userid])
    this.setState({ removing: false })
    this.props.onUpdate()
  }

  movePtoQ = async (userid) => {
    let activityid = this.props.activity.id
    this.setState({ moving: true })
    await this.props.firebase.moveParticipantToQueue(activityid, userid)
    this.setState({ moving: false })
    this.props.onUpdate()
  }

  moveQtoP = async (userid) => {
    let activityid = this.props.activity.id
    this.setState({ moving: true })
    await this.props.firebase.moveParticipantFromQueue(activityid, userid)
    this.setState({ moving: false })
    this.props.onUpdate()
  }

  checkinoutParticipant = async (userid) => {
    let activityid = this.props.activity.id
    this.setState({ checkin: { [userid]: true } })
    await this.props.firebase.checkinoutParticipant(activityid, userid, 'in')
    this.props.onUpdate()
  }

  renderActivityParticipants = () => {
    const { participants, payments } = this.state.activity
    if (!participants) return null
    const columns = [
      {
        field: 'initials',
        headerName: '',
        disableClickEventBubbling: true,
        width: 30,
        renderCell: (params) => (
          <IconButton
            style={{ padding: 0 }}
            disabled={params.row.checkin ? true : false}
            onClick={() => this.checkinoutParticipant(params.row.id)}
          >
            <Avatar style={{ backgroundColor: params.row.checkin ? 'green' : 'gray' }}>
              {this.state.checkin[params.row.id] ? <CircularProgress size={10} /> : params.value}
            </Avatar>
          </IconButton>
        ),
      },
      { field: 'name', headerName: 'Name', disableClickEventBubbling: true, width: 200 },
      { field: 'email', headerName: 'Email', disableClickEventBubbling: true, width: 200 },
      {
        field: 'entry',
        headerName: 'Ilmoittautunut',
        width: 150,
        disableClickEventBubbling: true,
        valueFormatter: (params) => moment(params.value).format('DD.MM.YYYY HH:mm'),
      },
      {
        field: 'paymenttime',
        headerName: 'Maksettu',
        width: 150,
        disableClickEventBubbling: true,
        valueFormatter: (params) =>
          moment(params.value).isValid() ? moment(params.value).format('DD.MM.YYYY HH:mm') : '',
      },
      {
        field: 'paymenttype',
        headerName: 'Maksutapa',
        width: 150,
        disableClickEventBubbling: true,
      },
      {
        field: 'checkin',
        headerName: 'Kirjattu sisään',
        width: 150,
        disableClickEventBubbling: true,
        valueFormatter: (params) =>
          params.value && moment(params.value).isValid() ? moment(params.value).format('DD.MM.YYYY HH:mm') : '',
      },
      {
        field: 'note',
        headerName: 'Lisätieto',
        width: 250,
        disableClickEventBubbling: true,
      },
      {
        field: 'checkinout',
        headerName: ' ',
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <CheckInoutButton
            disabled={params.row.checkin ? true : false}
            loading={this.state.checkin[params.row.id]}
            onClick={() => this.checkinoutParticipant(params.row.id)}
          />
        ),
      },
      {
        field: 'mov',
        headerName: ' ',
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => <MoveButton onClick={() => this.movePtoQ(params.row.id)} />,
      },
      {
        field: 'del',
        headerName: ' ',
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => <DeleteButton onClick={() => this.removeParticipant(params.row.id)} />,
      },
    ]
    const _ps = participants.map((participant) => {
      const initials =
        (participant.firstname ? participant.firstname.charAt(0) : '') +
        '' +
        (participant.lastname ? participant.lastname.charAt(0) : '')
      const name = participant.firstname + ' ' + participant.lastname
      participant.initials = initials
      participant.name = name
      if (payments && payments.length > 0) {
        const payment = payments.find((x) => x.uid === participant.id)
        if (payment) {
          participant.paymenttime = payment.timestamp
          participant.paymenttype = payment.typelabel || ''
        } else {
          participant.paymenttime = ''
          participant.paymenttype = ''
        }
      } else {
        participant.paymenttime = ''
        participant.paymenttype = ''
      }
      return participant
    })
    console.log(_ps)
    return (
      <React.Fragment>
        <div style={{ display: 'flex', minHeight: 'calc(100vh - 400px)', backgroundColor: '#FFFFFF' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid rows={_ps} columns={columns} pageSize={20} components={{ Toolbar: ExportToolbar }} componentsProps={{ toolbar: { suffix: 'participants',  name: this.state.activity.name.fi, date: this.state.activity.start } }}/>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderActivityQueue = () => {
    const { queue } = this.state.activity
    if (!queue) return null
    const columns = [
      {
        field: 'initials',
        headerName: '',
        disableClickEventBubbling: true,
        width: 30,
        renderCell: (params) => <Avatar>{params.value}</Avatar>,
      },
      { field: 'name', headerName: 'Name', disableClickEventBubbling: true, width: 200 },
      { field: 'email', headerName: 'Email', disableClickEventBubbling: true, width: 200 },
      {
        field: 'entry',
        headerName: 'Ilmoittautunut',
        width: 150,
        disableClickEventBubbling: true,
        valueFormatter: (params) => moment(params.value).format('DD.MM.YYYY HH:mm'),
      },
      {
        field: 'mov',
        headerName: ' ',
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => <MoveButton onClick={() => this.moveQtoP(params.row.id)} />,
      },
      {
        field: 'del',
        headerName: ' ',
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => <DeleteButton onClick={() => this.removeQueued(params.row.id)} />,
      },
    ]
    const _ps = queue.map((participant) => {
      const initials =
        (participant.firstname ? participant.firstname.charAt(0) : '') +
        '' +
        (participant.lastname ? participant.lastname.charAt(0) : '')
      const name = participant.firstname + ' ' + participant.lastname
      participant.initials = initials
      participant.name = name
      return participant
    })
    return (
      <React.Fragment>
        <div style={{ display: 'flex', minHeight: 'calc(100vh - 400px)', backgroundColor: '#FFFFFF' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid rows={_ps} columns={columns} pageSize={20} components={{ Toolbar: ExportToolbar }} componentsProps={{ toolbar: { suffix: 'queue',  name: this.state.activity.name.fi, date: this.state.activity.start } }} />
          </div>
        </div>
      </React.Fragment>
    )
  }

  handlePersonSearch = (event, value) => {
    const { activity } = this.state
    if (!activity.organisers) {
      activity.organisers = []
    }
    if (value) {
      activity.organisers = value.map((x) => {
        return { id: x.id, firstname: x.firstname, lastname: x.lastname, email: x.email, phone: x.phone || '' }
      })
      console.log(activity.organisers)
    }
    this.setState({ activity, activityEdits: { ...this.state.activityEdits, 'organisers': true } })
  }

  renderActivityGeneralData = () => {
    const {
      nameEditOpen,
      startEditOpen,
      endEditOpen,
      priceEditOpen,
      typeEditOpen,
      sportEditOpen,
      genderEditOpen,
      ingressEditOpen,
      descEditOpen,
      minpEditOpen,
      entrystartEditOpen,
      maxpEditOpen,
      entryendEditOpen,
      stateEditOpen,
      posterEditOpen,
    } = this.state.edits
    const {
      id,
      description,
      ingress,
      // poster,
      start,
      end,
      entrystart,
      entryend,
      maxp,
      minp,
      state,
      hasqueue,
      price,
      type,
      sport,
      gender,
      name,
      media,
      showusers,
      hideentrycount,
      pinned,
      
    } = this.state.activity
    const isEdited = Object.values(this.state.activityEdits).some((value) => value)
    // console.log('state', state)
    return (
      <>
        <Row id={id}>
          <Col xs={3}>Nimi</Col>
          <Col xs={3}>
            {nameEditOpen ? (
              <TextField
                id='name-local'
                value={name.fi || ''}
                onChange={(e) => this.handleTextChange(e, 'name.fi')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              <div>
                {name.fi} <EditIconButton onClick={() => this.setEditState('nameEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row id={id}>
          <Col xs={3}>Alkuaika</Col>
          <Col xs={3}>
            {startEditOpen ? (
              <TextField
                id='start-datetime-local'
                type='datetime-local'
                value={start || ''}
                onChange={(e) => this.handleTextChange(e, 'start')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              <div>
                {moment(start).format('D.M.YY HH:mm')}{' '}
                <EditIconButton onClick={() => this.setEditState('startEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Loppuaika</Col>
          <Col xs={3}>
            {endEditOpen ? (
              <TextField
                id='end-datetime-local'
                type='datetime-local'
                value={end || ''}
                onChange={(e) => this.handleTextChange(e, 'end')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              <div>
                {moment(end).format('D.M.YY HH:mm')}{' '}
                <EditIconButton onClick={() => this.setEditState('endEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Hinta</Col>
          <Col xs={3}>
            {priceEditOpen ? (
              <TextField
                id='price-local'
                value={this.state.priceInputs.price || ''}
                onChange={(e) => this.handlePriceChange(e, 'price')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              <div>
                {price} €
                <EditIconButton onClick={() => this.setEditState('priceEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Aktiviteetti tyyppi</Col>
          <Col xs={3}>
            {typeEditOpen ? (
              <TextField
                id='standard-select-type'
                select
                required
                label='Tyyppi'
                value={type}
                helperText='Aktiviteetin tyyppi'
                onChange={(e) => this.handleTextChange(e, 'type')}
              >
                {this.state.activitytypes.map((option) => (
                  <MenuItem key={option.key} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <div>
                {type}
                <EditIconButton onClick={() => this.setEditState('typeEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Laji</Col>
          <Col xs={3}>
            {sportEditOpen ? (
              <TextField
                id='standard-select-sport'
                select
                required
                label='Laji'
                value={sport}
                helperText='Laji'
                onChange={(e) => this.handleTextChange(e, 'sport')}
              >
                {this.state.sports.map((option) => (
                  <MenuItem key={option.key} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <div>
                {sport}
                <EditIconButton onClick={() => this.setEditState('sportEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Sukupuoli</Col>
          <Col xs={3}>
            {genderEditOpen ? (
              <TextField
                id='standard-select-gender'
                select
                required
                label='Sukupuoli'
                value={gender}
                helperText='Onko aktiviteetti vain miehille vai naisille vai kaikille'
                onChange={(e) => this.handleTextChange(e, 'gender')}
              >
                {GENDERS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <div>
                {GENDERS.find((g) => g.value === gender)?.label || gender}
                <EditIconButton onClick={() => this.setEditState('genderEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Lyhyt kuvaus</Col>
          <Col xs={3}>
            {' '}
            {ingressEditOpen ? (
              <TextField
                required
                multiline={true}
                fullWidth
                minRows={2}
                id='ingress-fi'
                label='Lyhyt kuvaus'
                size='small'
                value={ingress.fi}
                variant='outlined'
                onChange={(e) => this.handleTextChange(e, 'ingress.fi')}
              />
            ) : (
              <div>
                {ingress.fi}
                <EditIconButton onClick={() => this.setEditState('ingressEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Kuvaus</Col>
          <Col xs={3}>
            {descEditOpen ? (
              <TextField
                required
                multiline={true}
                fullWidth
                minRows={5}
                id='desc-fi'
                label='Pitempi kuvaus'
                size='small'
                value={description.fi}
                variant='outlined'
                onChange={(e) => this.handleTextChange(e, 'description.fi')}
              />
            ) : (
              <div>
                {description.fi}
                <EditIconButton onClick={() => this.setEditState('descEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row id={id}>
          <Col xs={3}>Ilmoittautumisen alkuaika</Col>
          <Col xs={3}>
            {entrystartEditOpen ? (
              <TextField
                id='entrystart-datetime-local'
                type='datetime-local'
                value={entrystart || ''}
                onChange={(e) => this.handleTextChange(e, 'entrystart')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              <div>
                {moment(entrystart).format('D.M.YY HH:mm')}{' '}
                <EditIconButton onClick={() => this.setEditState('entrystartEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Ilmoittautumisen loppuaika</Col>
          <Col xs={3}>
            {entryendEditOpen ? (
              <TextField
                id='entryend-datetime-local'
                type='datetime-local'
                value={entryend || ''}
                onChange={(e) => this.handleTextChange(e, 'entryend')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              <div>
                {moment(entryend).format('D.M.YY HH:mm')}{' '}
                <EditIconButton onClick={() => this.setEditState('entryendEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={3}>Minimi osallistujat</Col>
          <Col xs={3}>
            {minpEditOpen ? (
              <TextField
                required
                id='minp-required'
                label='Minimimäärä'
                size='small'
                value={minp}
                onChangeCapture={(e) => this.handleNumberChange(e, 'minp')}
              />
            ) : (
              <div>
                {minp} <EditIconButton onClick={() => this.setEditState('minpEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={3}>Maksimi osallistujat</Col>
          <Col xs={3}>
            {maxpEditOpen ? (
              <TextField
                required
                id='maxp-required'
                label='Minimimäärä'
                size='small'
                value={maxp}
                onChangeCapture={(e) => this.handleNumberChange(e, 'maxp')}
              />
            ) : (
              <div>
                {maxp} <EditIconButton onClick={() => this.setEditState('maxpEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Kuva</Col>
          <Col xs={3}>
            {posterEditOpen ? (
              <div>
                <Spinner />
              {/* <TextField
                id='standard-select-poster'
                select
                required
                label='Poster'
                value={media?.id}
                helperText='Valitse kuvituskuva'
                onChange={(e) => this.handlePosterChange(e)}
              >
                {this.props.images.map((image) => (
                  <MenuItem key={image.id} value={image.id}>
                    <img src={image.url} alt={image.originalName} width='20px' height='20px' />
                    {image.originalName}
                  </MenuItem>
                ))}
                </TextField> */}
              </div>
            ) : (
              <div>
                {media?.id ? <img src={media?.url} alt={media?.originalName} /> : ''} {media?.originalName}
                <EditIconButton onClick={() => this.setEditState('posterEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Onko jonotus käytössä</Col>
          <Col xs={3}>
            <FormControlLabel
              control={<Switch checked={hasqueue} onChange={(e) => this.handleSwitchChange(e, 'hasqueue')} />}
              label='Jono käytössä'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Tila</Col>
          <Col xs={3}>
            {stateEditOpen ? (
              <TextField
                id='standard-select-state'
                select
                required
                label='Tila'
                value={state}
                helperText=''
                onChange={(e) => this.handleTextChange(e, 'state')}
              >
                {STATES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <div>
                {STATES.find((s) => s.value === state)?.label || state}
                <EditIconButton onClick={() => this.setEditState('stateEditOpen', true)} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Ohjaaja(t)</Col>
          <Col xs={3}>
            <PersonSearch onChange={this.handlePersonSearch} values={this.state?.activity?.organisers || null} />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Näytetäänkö ilmoittautuneiden nimet</Col>
          <Col xs={3}>
            <FormControlLabel
              control={<Switch checked={showusers} onChange={(e) => this.handleSwitchChange(e, 'showusers')} />}
              label='Näytä ilmoittautuneiden nimet'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Piilotetaanko ilmoittautuneiden määrät</Col>
          <Col xs={3}>
            <FormControlLabel
              control={<Switch checked={hideentrycount} onChange={(e) => this.handleSwitchChange(e, 'hideentrycount')} />}
              label='Piilota ilmoittautuneiden määrät'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Nostetaanko tapahtumaa</Col>
          <Col xs={3}>
            <FormControlLabel
              control={<Switch checked={pinned} onChange={(e) => this.handleSwitchChange(e, 'pinned')} />}
              label='Nosta tapahtuma ylimmäksi asiakasnäkymässä'
            />
          </Col>
        </Row>
        <Row>
        <Col xs={3}>Sisäinen tapahtumaluokka</Col>
          <Col xs='3'>
            <TextField
              id='providertype'
              label=''
              size='small'
              onChange={(e) => this.handleTextChange(e, 'providertype')}
              value={this.state.activity.providertype}
            />
          </Col>
        </Row>
        <Row>
        <Col xs={3}>Alv %</Col>
          <Col xs={3}>
            <TextField
              id='standard-select-vat'
              select
              required
              label='Alv%'
              value={this.state.activity.vat}
              helperText='Alv prosentti'
              onChange={(e) => this.handleNumberChange(e, 'vat')}
            >
              {[0, 10, 14, 24, 25.5].map((option) => (
                <MenuItem key={option} value={option}>
                  {option} %
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row>
        <Col xs={3}>Tuotekoodi</Col>
          <Col xs={3}>
            <TextField
              fullWidth
              id='productCode'
              label='Tuotekoodi'
              size='small'
              onChange={(e) => this.handleTextChange(e, 'productCode')}
              value={this.state.activity.productCode}
            />
          </Col>
        </Row>
        <Row>
        <Col xs={3}>Kirjanpidon tili</Col>
          <Col xs={3} >
            <TextField
              fullWidth
              id='accounting'
              label='Kirjanpitotili'
              size='small'
              onChange={(e) => this.handleTextChange(e, 'accounting')}
              value={this.state.activity.accounting}
            />
          </Col>
        </Row>

        {this.state.pin ? (
          <Row>
            <Col xs={3}>PIN</Col>
            <Col xs={3}>{this.state.pin}</Col>
          </Row>
        ) : null}

        <Row>
          <Col xs={12} className='text-right'>
            <Button
              disabled={!isEdited}
              color='success'
              onClick={() => this.handleSave()} //this.props.firebase.updateActivity(this.state.activity)}
            >
              {this.state.saving ? <Spinner size={'sm'} /> : 'Tallenna'}
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  toggleNav = (tab) => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab })
  }

  removeCustomershipFromPerson = async () => {
    await this.props.firebase.removeCustomership(this.props.person.id)
    this.toggleRemoveDialog()
  }

  renderAddSaldoRow = () => {
    return (
      <React.Fragment>
        <Row style={{ marginTop: 20 }}>
          {this.state.venues.length > 0 ? (
            <Col md='3'>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id='venue'>Paikka</InputLabel>
                <Select
                  fullWidth
                  labelId='venue-label'
                  id='venue'
                  value={this.state.saldovenue || ''}
                  onChange={this.handleSaldoVenue}
                >
                  {this.state.venues.map((v, i) => {
                    return (
                      <MenuItem key={'venue' + i} value={v.toLowerCase()}>
                        {v}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Col>
          ) : null}
          <Col md='3'>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='saldotype'>Saldo</InputLabel>
              <Select
                labelId='saldotype-label'
                id='saldotype'
                value={this.state.saldowallet || ''}
                onChange={this.handleSaldoWallet}
              >
                <MenuItem value={'cash'}>Käteinen</MenuItem>
                {/* <MenuItem value={'sportmoney'}>Liikuntaraha</MenuItem> */}
              </Select>
            </FormControl>
          </Col>
          <Col md='3'>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='paymenttype'>Tapahtuma</InputLabel>
              <Select
                fullWidth
                labelId='paymenttype-label'
                id='paymenttype'
                value={this.state.saldotype || ''}
                onChange={this.handleSaldoType}
              >
                <MenuItem key={'none'}></MenuItem>
                {paymenttypes.map((pt) => {
                  return (
                    <MenuItem key={pt.value} value={pt.value}>
                      {pt.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Col>
          <Col md='2'>
            <TextField
              id='paymentamount'
              label='Summa'
              type='text'
              value={this.state.saldoamount || ''}
              InputProps={{
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
              }}
              onChange={this.handleSaldoAmount}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col md='8'>
            <TextField
              fullWidth
              id='saldodescription'
              label='Kuvaus'
              type='text'
              value={this.state.saldodescription || ''}
              onChange={this.handleSaldoDescription}
            />
          </Col>
          <Col md='4'>
            <Button
              outline
              size='sm'
              color='success'
              disabled={!(this.state.saldowallet && this.state.saldoamount && this.state.saldotype)}
              onClick={this.onSaveSaldoPayment}
            >
              {this.state.savingsaldo ? <Spinner size='sm' /> : 'Lisää'}
            </Button>
            <Button outline color='primary' size='sm' onClick={this.toggleAddSaldo}>
              Peruuta
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderAddSaldoButton = () => {
    return (
      <Row style={{ marginTop: 5, marginBottom: 10 }}>
        <Col>
          <AddCircleIcon fontSize='small' onClick={this.toggleAddSaldo} /> Lisää saldoa
        </Col>
      </Row>
    )
  }

  renderAddStorageRow = () => {
    return (
      <React.Fragment>
        <Row style={{ marginTop: 20 }}>
          {this.state.venues.length > 0 ? (
            <Col md='3'>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id='venue'>Paikka</InputLabel>
                <Select
                  fullWidth
                  labelId='venue-label'
                  id='venue'
                  value={this.state.storagevenue || ''}
                  onChange={this.handleStorageVenue}
                >
                  {this.state.venues.map((v, i) => {
                    return (
                      <MenuItem key={'venue' + i} value={v.toLowerCase()}>
                        {v}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Col>
          ) : null}
          <Col md='3'>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='saldotype'>Pankki/Varasto</InputLabel>
              <Select
                labelId='saldotype-label'
                id='saldotype'
                value={this.state.storagewallet || ''}
                onChange={this.handleStorageWallet}
              >
                <MenuItem value={'cash'}>Käteinen</MenuItem>
                <MenuItem value={'minutes'}>Aika</MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col md='3'>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='paymenttype'>Tapahtuma</InputLabel>
              <Select
                fullWidth
                labelId='paymenttype-label'
                id='paymenttype'
                value={this.state.storagetype || ''}
                onChange={this.handleStorageType}
              >
                <MenuItem key={'none'}></MenuItem>
                {paymenttypes.map((pt) => {
                  return (
                    <MenuItem key={pt.value} value={pt.value}>
                      {pt.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Col>
          <Col md='2'>
            <TextField
              id='paymentamount'
              label='Määrä'
              type='text'
              value={this.state.storageamount || ''}
              InputProps={{
                endAdornment: <InputAdornment position='end'></InputAdornment>,
              }}
              onChange={this.handleStorageAmount}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col md='8'>
            <TextField
              fullWidth
              id='saldodescription'
              label='Kuvaus'
              type='text'
              value={this.state.storagedescription || ''}
              onChange={this.handleStorageDescription}
            />
          </Col>
          <Col md='4'>
            <Button
              outline
              size='sm'
              color='success'
              disabled={!(this.state.storagewallet && this.state.storageamount && this.state.storagetype)}
              onClick={this.onSaveStoragePayment}
            >
              {this.state.savingstorage ? <Spinner size='sm' /> : 'Tallenna'}
            </Button>
            <Button outline color='primary' size='sm' onClick={this.toggleAddStorage}>
              Peruuta
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderAddStorageButton = () => {
    return (
      <Row style={{ marginTop: 5, marginBottom: 10 }}>
        <Col>
          <AddCircleIcon fontSize='small' onClick={this.toggleAddStorage} /> Lisää pankkiin/varastoon tapahtuma
        </Col>
      </Row>
    )
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue })
  }

  renderAddMembershipRow = () => {
    if (this.state.showAddMembershipRow) {
      return (
        <>
          <Row style={{ marginTop: 10 }}>
            {this.state.membershipoptions.length > 0 ? (
              <Col md='4'>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id='membership'>Jäsenyys</InputLabel>
                  <Select
                    fullWidth
                    labelId='membership-label'
                    id='membership'
                    value={this.state.membershipselection || ''}
                    onChange={this.handleMembershipSelection}
                  >
                    {this.state.membershipoptions.map((v, i) => {
                      return (
                        <MenuItem key={'membrs' + i} value={v.id}>
                          {v.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Col>
            ) : null}
            <Col md='3'>
              <TextField
                id='membershipstart'
                label='Alkupäivä'
                type='date'
                value={this.state.membershipstart || moment.utc().format('YYYY-MM-DD')}
                onChange={this.handleMembershipStart}
              />
            </Col>
            <Col md='3'>
              <TextField
                id='membershipend'
                label='Loppupäivä'
                type='date'
                value={this.state.membershipend || moment.utc().endOf('year').format('YYYY-MM-DD')}
                onChange={this.handleMembershipEnd}
                disabled={this.state.membershippermanent}
              />
            </Col>
            <Col md='2'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.membershippermanent}
                    onChange={this.handleMembershipPermanent}
                    name='membershippermanent'
                    color='primary'
                  />
                }
                label='Pysyvä'
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md='8'>
              <TextField
                fullWidth
                id='membershipnote'
                label='Muistiinpano'
                type='text'
                value={this.state.membershipnote || ''}
                onChange={this.handleMembershipNote}
              />
            </Col>
            <Col md='4'>
              <Button
                outline
                size='sm'
                color='success'
                disabled={!(this.state.membershipselection && this.state.membershipstart && this.state.membershipend)}
                onClick={this.onSaveMembership}
              >
                {this.state.savingmembership ? <Spinner size='sm' /> : 'Lisää'}
              </Button>
              <Button outline color='primary' size='sm' onClick={this.toggleAddMembership}>
                Peruuta
              </Button>
            </Col>
          </Row>
        </>
      )
    } else {
      return (
        <Row style={{ marginTop: 5, marginBottom: 10 }}>
          <Col md='1'>
            <AddCircleIcon fontSize='small' onClick={this.toggleAddMembership} />
          </Col>
          <Col>Lisää jäsenyys</Col>
        </Row>
      )
    }
  }

  renderAclsTab = () => {
    if (this.state.acls) {
      // console.log('this.state.acls', this.state.acls)
      return (
        <React.Fragment>
          {ACL_FIELDS.map((k, i) => {
            if (k === 'allowpostpayment') {
              return (
                <Row style={{ marginTop: 10 }} key={'acl' + i}>
                  <Col md='12'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.acls[k]}
                          onChange={(e) => this.setAcl({ allowpostpayment: e.target.checked })}
                          color='primary'
                        />
                      }
                      label='Maksupakotuksen ohitus'
                    />
                  </Col>
                </Row>
              )
            } else if (k === 'blocked') {
              return (
                <Row style={{ marginTop: 10 }} key={'acl' + i}>
                  <Col md='12'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.acls[k]}
                          onChange={(e) => this.setAcl({ blocked: e.target.checked })}
                          color='primary'
                        />
                      }
                      label='Varauskielto'
                    />
                  </Col>
                </Row>
              )
            } else if (k === 'blockedMessage') {
              return (
                <Row style={{ marginTop: 10 }} key={'acl' + i}>
                  <Col md='12'>
                    <TextField
                      fullWidth
                      id='blockedMessage'
                      label='Varauskiellon syy (näkyy käyttäjälle)'
                      type='text'
                      value={this.state.acls[k] || ''}
                      onChange={(e) => this.setState({ acls: { blockedMessage: e.target.value } })}
                    />
                    <Button
                      outline
                      color='primary'
                      size='sm'
                      onClick={() => this.setAcl({ blockedMessage: this.state.acls[k] })}
                    >
                      Tallenna viesti
                    </Button>
                  </Col>
                </Row>
              )
            }
            return null
          }).filter((v) => v)}
        </React.Fragment>
      )
    } else {
    }
  }

  renderMemberShipTab = () => {
    return (
      <React.Fragment>
        {this.renderPersonMemberships()}
        {this.renderAddMembershipRow()}
      </React.Fragment>
    )
  }

  handleMessageCollapse = (index) => {
    let open = this.state.messageopen
    open[index] = !open[index]
    this.setState({ messageopen: open })
  }

  renderMessages = () => {
    return this.state.messages.map((m, i) => {
      return (
        <React.Fragment key={'msg' + i}>
          <ListItem onClick={() => this.handleMessageCollapse(i)}>
            {this.state.messageopen && this.state.messageopen[i] ? <ExpandLess /> : <ExpandMore />}
            <ListItemText
              primary={m.subject}
              secondary={moment.unix(m.timestamp.seconds).format('DD.MM.YYYY HH:mm') + ' - ' + m.recipient}
            />
            {m.state === 'failed' ? (
              <ListItemSecondaryAction>
                <WarningIcon color='error' />
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
          <Collapse in={this.state.messageopen[i]} timeout='auto' unmountOnExit>
            <Row style={{ marginTop: 15, marginBottom: 30, marginLeft: 30 }}>
              <Col md='12'>
                <Typography variant='body1' color='textSecondary' component='pre'>
                  {m.body}
                </Typography>
              </Col>
            </Row>
          </Collapse>
          <Divider />
        </React.Fragment>
      )
    })
  }

  openMessageModal = (type, id) => {
    let _m = this.state.messaging
    _m.isOpen = !_m.isOpen
    _m.type = type
    if (type === 'passwordreset') {
      this.generatePasswordReset(id)
    }
    // const person = this.state.searchpersons.find((r) => r.id === id)
    this.setState({ messaging: _m })
  }

  generatePasswordReset = async (email) => {
    // console.log(email, ' to reset')
    let response = await this.props.firebase.getPasswordResetLink(email)
    // console.log(response)
    if (response.data.error) {
      this.setState({ passwordResetLink: response.data.error })
    } else {
      this.setState({ passwordResetLink: response.data.link })
    }
  }

  setNewEmail = (event) => {
    const newemail = event.target.value
    this.setState({ newemail })
  }

  setMessagebody = (event) => {
    let _m = this.state.messaging
    _m.body = event.target.value
    this.setState({ messaging: _m })
  }

  setMessageSubject = (event) => {
    let _m = this.state.messaging
    _m.subject = event.target.value
    if (_m.type === 'notification') {
      _m.toolong = event.target.value.length > 80
    }
    this.setState({ messaging: _m })
  }

  sendMessage = () => {
    this.setState({ sending: true })
    this.props.firebase
      .sendMessage(
        this.state.messaging.body,
        this.state.messaging.subject,
        this.props.person.email,
        this.props.person.id,
        ['usersearch']
      )
      .then((response) => {
        // console.log(response)
        this.closeMessageModal()
      })
  }

  sendConfirmation = () => {
    this.setState({ sending: true })
    this.props.firebase.sendConfirmation(this.state.newemail, this.props.person.id, 'fi').then((response) => {
      // console.log(response)
      this.closeMessageModal()
    })
  }

  closeMessageModal = () => {
    let _m = this.state.messaging
    _m.isOpen = false
    this.setState({ messaging: _m, passwordResetLink: 'Luodaan linkkiä ...', sending: false })
  }

  copyClicked = () => {
    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false })
    }, '6000')
  }

  createNewActivity = async (activity) => {
    const savedActivity = await this.props.firebase.createActivity(activity)
    this.props.onChangeActivity(savedActivity)
  }

  handleOptionLabel = (opt) => {
    return opt.firstname && opt.lastname ? opt.firstname + ' ' + opt.lastname : ''
  }

  handleParticipantChange = (event, value) => {
    this.setState({ newparticipant: value })
  }

  addParticipants = async () => {
    let participants = this.state.newparticipant
    let activityid = this.props.activity.id
    this.setState({ saving: true })
    await this.props.firebase.addParticipantsToActivity(activityid, participants)
    this.setState({ newparticipant: [], saving: false })
    this.props.onUpdate()
  }

  toggleMessageDialog = (toparticipants) => {
    this.setState({ showmessagedialog: !this.state.showmessagedialog, toparticipants: toparticipants })
  }

  renderAddParticipant = () => {
    const { participants, maxp } = this.props.activity
    if (participants && participants.length >= maxp) {
      return (
        <React.Fragment>
          <Row>
            <Col xs={12} md={4}>
              <Button
                variant='contained'
                color='primary'
                disabled={
                  this.state.activity && this.state.activity.participants && this.state.activity.participants.length > 0
                    ? false
                    : true
                }
                onClick={() => {
                  this.toggleMessageDialog(true)
                }}
              >
                Lähetä viesti osallistujille
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col xs={12} md={4}>
              <Autocomplete
                multiple
                options={this.state.searchresults ? this.state.searchresults : []}
                getOptionLabel={this.handleOptionLabel} // {option => option.name}
                onChange={this.handleParticipantChange}
                getOptionSelected={(option, val) => {
                  return option.id === val.id
                }}
                value={this.state.newparticipant}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue.length > 2) {
                    this.searchParticipants(newInputValue)
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Lisää osallistuja'
                      placeholder='Osallistuja'
                      margin='normal'
                    // fullWidth
                    />
                  )
                }}
                renderOption={(option) => {
                  const title = option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
                  return (
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <span>{title}</span>
                        <Typography variant='body2' color='textSecondary'>
                          {option.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                }}
              />
            </Col>
            <Col xs={12} md={4}>
              <Button
                variant='contained'
                color='primary'
                disabled={this.state.activity && this.state.activity.participants && this.state.activity.participants.length > 0 ? false : true}
                onClick={() => {
                  this.toggleMessageDialog(true)
                }}
              >Lähetä viesti osallistujille</Button>
            </Col>
          </Row>
          {this.state.newparticipant && this.state.newparticipant.length > 0 && (
            <Row>
              <Col xs={12} md={4}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    this.addParticipants()
                  }}
                >
                  {this.state.saving ? <Spinner size={'sm'} /> : 'Tallenna'}
                </Button>
              </Col>
            </Row>
          )}
        </React.Fragment>
      )
    }
  }

  handleQueueChange = (event, value) => {
    this.setState({ newqueued: value })
  }

  addQueued = async () => {
    let queued = this.state.newqueued
    let activityid = this.props.activity.id
    this.setState({ saving: true })
    await this.props.firebase.addQueuedToActivity(activityid, queued)
    this.setState({ newqueued: [], saving: false })
    this.props.onUpdate()
  }

  renderAddQueued = () => {
    const { hasqueue } = this.props.activity
    if (!hasqueue) return null
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={4}>
            <Autocomplete
              multiple
              options={this.state.searchresults ? this.state.searchresults : []}
              getOptionLabel={this.handleOptionLabel} // {option => option.name}
              onChange={this.handleQueueChange}
              getOptionSelected={(option, val) => {
                return option.id === val.id
              }}
              value={this.state.newqueued}
              onInputChange={(event, newInputValue) => {
                if (newInputValue.length > 2) {
                  this.searchParticipants(newInputValue)
                }
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Lisää jonottajia'
                    placeholder='jonottaja'
                    margin='normal'
                    // fullWidth
                  />
                )
              }}
              renderOption={(option) => {
                const title = option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
                return (
                  <Grid container alignItems='center'>
                    <Grid item xs>
                      <span>{title}</span>
                      <Typography variant='body2' color='textSecondary'>
                        {option.email}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              }}
            />
          </Col>
          <Col xs={12} md={4}>
            <Button
              variant='contained'
              color='primary'
              disabled={this.state.activity && this.state.activity.queue && this.state.activity.queue.length > 0 ? false : true}
              onClick={() => {
                this.toggleMessageDialog(false)
              }}
            >Lähetä viesti jonottajille</Button>
          </Col>
        </Row>
        {this.state.newqueued && this.state.newqueued.length > 0 && (
          <Row>
            <Col xs={12} md={4}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  this.addQueued()
                }}
              >
                {this.state.saving ? <Spinner size={'sm'} /> : 'Tallenna'}
              </Button>
            </Col>
          </Row>
        )}
      </React.Fragment>
    )
  }

  render() {
    if (!this.props.activity) {
      return (
        <ActivityCreator
          onCreate={
            this.props.recurrent ? this.props.firebase.createRecurrentActivity : this.props.firebase.createActivity
          }
          activitytypes={this.state.activitytypes}
          sports={this.state.sports}
          images={this.props.images}
          recurrent={this.props.recurrent}
        />
      )
    }
    const a = this.props.activity
    return (
      <div style={{ padding: 20, backgroundColor: 'white' }}>
        <Row>
          <Col>
            <Typography variant='h4' gutterBottom>
              {a.name.fi}
            </Typography>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <MuiButton
              variant='outlined'
              size='small'
              onClick={(el) => {
                this.openMessageModal('call', id)
              }}
            >
              <PhoneIcon fontSize='small' /> Soita
            </MuiButton>{' '}
            <MuiButton
              variant='outlined'
              size='small'
              onClick={(el) => {
                this.openMessageModal('email', email)
              }}
            >
              <MailOutlineIcon fontSize='small' /> Lähetä email
            </MuiButton>{' '}
            <MuiButton
              variant='outlined'
              size='small'
              onClick={(el) => {
                this.openMessageModal('passwordreset', email)
              }}
            >
              <VpnKeyIcon fontSize='small' /> Salasanan vaihto
            </MuiButton>{' '}
            <MuiButton
              variant='outlined'
              size='small'
              onClick={(el) => {
                this.openMessageModal('emailchange', id)
              }}
            >
              <AlternateEmailIcon fontSize='small' /> Vaihda email
            </MuiButton>
          </Col>
        </Row> */}
        <Row className='mt-2 border-bottom mb-2'>
          <Col>
            <Tabs
              value={this.state.tabValue}
              indicatorColor='primary'
              // textColor="primary"
              onChange={this.handleTabChange}
              // aria-label="disabled tabs example"
              variant='scrollable'
            >
              <Tab label='Yleistiedot' />
              {!a.isexternal && <Tab label='Osallistujat' />}
              {a.hasqueue && <Tab label='Jono' />}
              {/* <Tab label='Varaukset' />
              <Tab label='Viestit' />
              <Tab label='Oikeudet' />
              <Tab label='Laskutettavaa' />
              <Tab label='Laskut' /> */}
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabPanel value={this.state.tabValue} index={0}>
              {this.renderActivityGeneralData()}
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
              {this.renderAddParticipant()}
              {this.renderActivityParticipants()}
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={2}>
              {this.renderAddQueued()}
              {this.renderActivityQueue()}
            </TabPanel>
          </Col>
        </Row>
        <Modal size='sm' isOpen={this.state.loadingModalOpen} toggle={this.toggleLoadingModal} backdrop={'static'}>
          <ModalBody>
            <Spinner />
          </ModalBody>
        </Modal>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.removePersonConfirmation}
          onClose={this.toggleRemoveDialog}
          aria-labelledby='max-width-dialog-title'
          style={{ zIndex: '1000' }}
        >
          <DialogTitle id='max-width-dialog-title'>Vahvista poisto</DialogTitle>
          <DialogContent>
            Oletko aivan varma, että haluat poistaa asiakkuuden tältä käyttäjältä? Muista tarkistaa onko käyttäjällä
            maksamattomia varauksia ennen kuin poistat asiakkuuden. Poistamiseen menee hetken aikaa jonka jälkeen
            henkilö ei löydy enää hausta.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.removeCustomershipFromPerson()} color='danger'>
              Poista asiakkuus (piilota)
            </Button>
            <Button onClick={this.toggleRemoveDialog} color='primary'>
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.edits.posterEditOpen}
          onClose={this.togglePosterEdit}
          aria-labelledby='max-width-dialog-title'
          style={{ zIndex: '1000' }}
        >
          <DialogTitle id='max-width-dialog-title'>Valitse kuva</DialogTitle>
          <DialogContent>
            <ImageSelector images={this.props.images} onSelect={this.handlePosterChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.togglePosterEdit} color='primary'>
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
        <MessageDialog onClose={this.toggleMessageDialog} open={this.state.showmessagedialog} recipientids={this.state.toparticipants ? this.state.activity?.participants?.map((a) => a.id) : this.state.activity?.queue?.map((a) => a.id) } />
      </div>
    )
  }
  // }
}

export default withFirebase(ActivityDetailsWizard)
