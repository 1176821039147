import React from 'react'

import {
  AuthUserContext,
  UserProfileContext,
  ModulesContext,
  CustomerdataContext,
} from './context'
import { withFirebase } from '../Fb'
import LogRocket from 'logrocket'
import { sortResourcesByOrder } from './utils'

const isVenueUser = (claims) => {
  if (!claims) return false
  if (!claims.role) return false
  if (
    claims.role === 'ADMIN' ||
    claims.role === 'CASHIER' ||
    claims.role === 'PARTNER' ||
    claims.role === 'COOPERATIVE'
  )
    return true
}

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        authUser: null,
        profile: null,
        modules: null,
        // customerdata: { resources: [] },
        features: null,
        resources: []
      }
    }

    listenModules = () => {
      if (this.state.modules === null) {
        this.modulelistener = this.props.firebase
          .listenModules()
          .onSnapshot((snapshot) => {
            this.setState({ modules: snapshot.data() })
          })
      }
    }

    listenResources = () => {
      if (this.state.customerdata.resources.length === 0) {
        this.props.firebase.listenResourses().onSnapshot((snapshot) => {
          const _resources = []
          snapshot.forEach((doc) => {
            _resources.push({ id: doc.id, ...doc.data() })
          })
          const resources = _resources.filter((r) => {
            if (r.state) {
              return r.state === 'active'
            } else {
              return true
            }
          }).sort(sortResourcesByOrder)
          this.setState({ resources: resources })
        })
      }
    }

    listenFeatures = () => {
      if (this.state.features === null) {
        this.featurelistener = this.props.firebase
          .listenFeatures()
          .onSnapshot((snapshot) => {
            const features = snapshot.data()
            this.setState({ features: features })
          })
      }
    }

    async componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (authUser) {
            authUser
              .getIdTokenResult()
              .then((idTokenResult) => {
                let cIds = [idTokenResult.claims.customerId]
                this.props.firebase.customerid = idTokenResult.claims.customerId
                if (isVenueUser(idTokenResult.claims)) {
                  console.log('venue user detected')
                  this.setState({
                    authUser: authUser,
                    profile: {
                      role: idTokenResult.claims.role,
                      customerIds: cIds,
                    },
                    modules: null,
                    customerdata: { resources: [] },
                  })
                  this.listenModules()
                  this.listenResources()
                  this.listenFeatures()
                  LogRocket.identify(authUser.email)
                } else {
                  this.props.firebase.doSignOut()
                }
              })
              // if (!idTokenResult.claims.role) {
              //   this.props.firebase.doSignOut()
              // } else if (!isVenueUser(idTokenResult.claims)) { //.role === 'ADMIN' || idTokenResult.claims.role === 'CASHIER' || idTokenResult.claims.role === 'PARTNER')) {
              //   this.props.firebase.doSignOut()
              // } else {
              //   this.setState({
              //     authUser: authUser,
              //     profile: { role: idTokenResult.claims.role, customerIds: cIds },
              //   })
              //   LogRocket.identify(authUser.email)
              // }
              // })
              .catch((error) => {
                console.error(error)
              })
          } else {
            delete this.props.firebase.customerid
            this.setState({
              authUser: null,
              profile: null,
              modules: null,
              customerdata: { resources: [] },
            })
          }
        }
      )
    }

    componentWillUnmount() {
      this.listener()
      if (this.modulelistener) {
        this.modulelistener()
      }
      if (this.resourcelistener) {
        this.resourcelistener()
      }
      if (this.featurelistener) {
        this.featurelistener()
      }
    }

    render() {
      const customerdata = { resources: this.state.resources, features: this.state.features }
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <UserProfileContext.Provider value={this.state.profile}>
            <ModulesContext.Provider value={this.state.modules}>
              <CustomerdataContext.Provider value={customerdata}>
                <Component
                  {...this.props}
                  authUser={this.state.authUser}
                  profile={this.state.profile}
                  modules={this.state.modules}
                  customerdata={customerdata}
                />
              </CustomerdataContext.Provider>
            </ModulesContext.Provider>
          </UserProfileContext.Provider>
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
