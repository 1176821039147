import React, { Component } from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'
// import AddCircleIcon from '@material-ui/icons/AddCircle'
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
//import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
//import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
// import { Select, MenuItem, FormControl, InputLabel, TextField } from '@material-ui/core'
import { withFirebase } from '../Fb'
import { DataGrid } from '@mui/x-data-grid'
// import PlusMinusButton from '../Buttons/PlusMinusButton'
import moment from 'moment'

// class AddCardRow extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       selected: '',
//       note: '',
//       saving: this.props.saving,
//       benefitcards: this.props.benefitcards,
//     }
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.saving !== prevProps.saving) {
//       this.setState({ saving: this.props.saving })
//     }
//     if (this.props.benefitcards !== prevProps.benefitcards) {
//       this.setState({ selected: this.props.benefitcards[0].id })
//     }
//   }

//   onChangeId = (event) => {
//     this.setState({
//       selected: event.target.value,
//     })
//   }

//   onChangeNote = (event) => {
//     this.setState({
//       note: event.target.value,
//     })
//   }

//   handleCancel = () => {
//     this.setState({
//       selected: '',
//       note: '',
//     })
//     this.props.onCancel()
//   }


//   render() {
//     const { onSubmit, onCancel } = this.props
//     // if (!this.state.benefitcards) return <div>Ei sarjakortteja</div>
//     return (
//       <>
//         <Row>
//           <Col xs={12} md={4}>
//             <FormControl style={{ width: '100%' }}>
//               <InputLabel id='benefitcard-label'>Sarjakortti</InputLabel>
//               <Select
//                 id='demo-simple-select'
//                 value={this.state.selected}
//                 onChange={this.onChangeId}
//                 required
//                 labelId='benefitcard-label'
//                 fullWidth
//               >
//                 {this.state.benefitcards &&
//                   this.state.benefitcards.map((benefitcard) => (
//                     <MenuItem key={benefitcard.id} value={benefitcard.id}>
//                       {benefitcard.name}
//                     </MenuItem>
//                   ))}
//               </Select>
//             </FormControl>
//           </Col>
//           <Col xs={12} md={4}>
//             <Button disable={this.state.saving} outline color='success' size='sm' onClick={() => onSubmit(this.state.selected, this.state.note)}>
//               {this.state.saving ? <Spinner size={'sm'}/> : 'Lisää'}
//             </Button>{' '}
//             <Button disabled={this.state.saving}  outline color='primary' size='sm' onClick={onCancel}>
//               Peruuta
//             </Button>
//           </Col>
//         </Row>
//         <Row>
//           <Col xs={12} md={6}>
//             <TextField
//               fullWidth
//               id='note'
//               label='Muistiinpano'
//               type='text'
//               value={this.state.note || ''}
//               onChange={this.onChangeNote}
//             />
//           </Col>
//         </Row>
//       </>
//     )
//   }
// }


class PersonProductOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: this.props.orders || [],
      saving: false,
      loadingPlusMinus: {},
      deleting: false,
    }
  }

  removePersonProductOrder = (id) => {
    this.setState({ deleting: true })
    this.props.firebase.removePersonProductOrder(id).then(() => {
      this.setState({ deleting: false })
      if(this.props.onUpdate) this.props.onUpdate()
    })
  }

  getColumns = () => {
    const columns = [
      { field: 'productCode', headerName: 'Tuotekoodi', width: 130 },
      { field: 'name', headerName: 'Nimi', width: 200 },
      // { field: 'desc', headerName: 'Kuvaus', width: 200 },
      {
        field: 'vat',
        headerName: 'Alv',
        width: 60,
        valueFormatter: (params) => {
          return params.value  + '%'
        },
      },
      // {
      //   field: 'remaining', headerName: 'Jäljellä', width: 160, renderCell: (params) => {
      //     if (params.value === 0) return 'Käytetty'
      //     if (params.row.type === 'count') {
      //       return <>{params.value + ' kertaa '} <span style={{ marginLeft: 10 }}><PlusMinusButton onPlus={() => this.handlePlus(params.row.id)} onMinus={() => this.handleMinus(params.row.id)} loading={this.state.loadingPlusMinus[params.row.id]} /></span></>
      //     } else {
      //       return ''
      //     }
      //   }
      // },
      { field: 'quantity', headerName: 'Määrä', width: 60 },
      {
        field: 'price',
        headerName: 'Hinta',
        width: 100,
        valueFormatter: (params) => {
          return (params.value / 100).toFixed(2).replace('.', ',') + ' €'
        },
      },
      {
        field: 'Summa',
        headerName: 'Summa',
        width: 100,
        valueGetter: (params) => (params.row.quantity * params.row.price)  / 100,
        valueFormatter: (params) => {
          return params.value.toFixed(2).replace('.', ',') + ' €'
        },
      },
      { field: 'paymentstate', headerName: 'Maksu', width: 170 },
      { 
        field: 'remove',
        headerName: 'Poista',
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          if (params.row.canRemove) {
            return (
              <Button
                color='danger'
                size='sm'
                disabled={this.state.deleting}
                onClick={() => this.removePersonProductOrder(params.row.id)}
              >
                Poista {this.state.deleting ? <Spinner size={'sm'} /> : ''}
              </Button>
            )
          } else {
            return ''
          }
        }
      }
    ]
    return columns
  }

  filterData = () => {
    const data = this.state.orders
    const d = data.map((item) => {
      let isInvoiced = false
      let isPaid = false
      let invoiceDate = ''
      if (item.payments) {
        const sum = item.payments.reduce((a, b) => a + b.amount, 0)
        if (sum >= item.price) {
          isPaid = true
        }
        const invoice = item.payments.find((p) => p.type === 'invoice')
        invoiceDate = invoice ? moment(invoice.timestamp, 'YYYY-MM-DD HH:mm').format('D.M.YY') : ''
        if(invoice) {
          isInvoiced = true
        }
      }
      if (isInvoiced) {
        item.paymentstate = 'Laskutettu (' + invoiceDate + ')'
      } else if (isPaid) {
        item.paymentstate = 'Maksettu'
      } else {
        item.paymentstate = 'Avoin'
      }
      if (isPaid || isInvoiced) {
        item.canRemove = false
      } else {
        item.canRemove = true
      }
      return {
        ...item,
        isInvoiced: isInvoiced,
        isPaid: isPaid,
      }
    }).sort((a, b) => {
      // sort by created seconds
      return b.created._seconds - a.created._seconds
    })
    this.setState({ orders: d })
  }

  componentDidUpdate(prevProps) {
    if (this.props.orders !== prevProps.orders) {
      this.setState({
        orders: this.props.orders,
      }, this.filterData)
    }
  }

  componentDidMount() {
    this.filterData()
  }

  // toggleAddCard = () => {
  //   this.setState({
  //     showAddCardRow: !this.state.showAddCardRow,
  //   })
  // }

  // handleSaved = () => {
  //   this.toggleAddCard()
  //   this.props.onSaved()
  // }

  // onSaveBenefitCard = async (cardid, note) => {
  //   this.setState({ saving: true })
  //   const userid = this.props.personId
  //   await this.props.firebase.addNewPersonBenefitCard(userid, cardid, note)
  //   this.setState(
  //     {
  //       saving: false,
  //     },
  //     this.handleSaved
  //   )
  // }

  // handlePlus = async (id) => {
  //   this.setState({ loadingPlusMinus: { ...this.state.loadingPlusMinus, [id]: true } })
  //   let remaining = this.state.orders.find((card) => card.id === id).remaining
  //   if (!remaining) {
  //     remaining = 1
  //   } else {
  //     remaining++
  //   }
  //   await this.props.firebase.updatePersonBenefitCard(this.props.personId, id, remaining)
  //   this.setState({ loadingPlusMinus: { ...this.state.loadingPlusMinus, [id]: false } }, this.props.onSaved)
  // }

  // handleMinus = async (id) => {
  //   this.setState({ loadingPlusMinus: { ...this.state.loadingPlusMinus, [id]: true } })
  //   let remaining = this.state.orders.find((card) => card.id === id).remaining
  //   if (!remaining) {
  //     remaining = 0
  //   } else {
  //     remaining--
  //   }

  //   await this.props.firebase.updatePersonBenefitCard(this.props.personId, id, remaining)
  //   this.setState({ loadingPlusMinus: { ...this.state.loadingPlusMinus, [id]: false } }, this.props.onSaved)
  // }


  render() {
      return (
        <div className='animated fadeIn'>
          {/* <Row style={{ marginTop: 5, marginBottom: 10 }}>
            <Col>
              <div style={{ cursor: 'pointer' }} onClick={this.toggleAddCard}>
                <AddCircleIcon fontSize='small' onClick={this.toggleAddCard} /> Lisää kortti
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <div style={{ display: 'flex', minHeight: 'calc(100vh - 400px)', backgroundColor: '#FFFFFF' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={this.state.orders}
                    columns={this.getColumns()}
                    density='compact'
                    pageSize={20}
                    disableSelectionOnClick={true}
                    rowsPerPageOptions={[20, 50, 100]}
                    pagination
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <pre>
                {JSON.stringify(this.state.orders, null, 2)}
              </pre>
            </Col>
          </Row> */}
        </div>
      )
    }
}
export default withFirebase(PersonProductOrders)
