export const _recurringSaleClass = {
  name: { type: 'string', title: 'Myyntikampanjan nimi (esim Tennis 24/25)' },
  seasonstart: {
    type: 'string',
    format: 'date',
    title: 'Kauden ensimmäinen päivä',
    required: true,
  },
  seasonend: {
    type: 'string',
    format: 'date',
    title: 'Kauden viimeinen päivä',
    required: true,
  },
  campaignstart: {
    type: 'string',
    format: 'date',
    title: 'Myyntikampanjan aloituspäivä',
    required: true,
  },
  campaignend: {
    type: 'string',
    format: 'date',
    title: 'Myyntikampanjan päättymispäivä',
    required: true,
  },
  description: { type: 'string', title: 'Kuvaus' },
  payment: {
    type: 'string',
    title: 'Maksu',
    enum: ['postpaidcreditcard', 'prepaidfull', 'prepaidpartly', 'postpaidinvoice'],
    enumNames: ['Jälkikäteen luottokortti', 'Etukäteen kokokausi', 'Etukäteen osakausi', 'Jälkikäteen lasku', ],
  },
  confirmation: {
    type: 'boolean',
    title: 'Vahvistetaanko vuoron myynti?',
  },
  contract: {
    type: 'string',
    title: 'Sopimus',
    enum: ['creditcard', 'esignature', 'prepaid', 'none'],
    enumNames: ['Talletettu luottokortti', 'Sähköinen allekirjoitus', 'Maksu etukäteen', 'Ei mitään'],

  },
  // resources: {
  //   type: "array",
  //   title: "Resurssit",
  //   items: {
  //     type: "string",
  //     "enum": [],
  //     'enumNames': []
  //   },
  //   "uniqueItems": true
  // },
  pricing: {
    title: 'Hinnoittelu',
    type: 'string',
    enum: [],
    enumNames: [],
  },
  confirmationMessage: { type: 'string', title: 'Vahvistusviestin sisältö' },
  state: {
    type: 'string',
    title: 'Tila',
    enum: ['draft', 'active', 'paused'],
    enumNames: ['Luonnos', 'Käynnissä', 'Keskeytetty'],
  },
}

export const _textBannerClassDeps = {
  format: {
    oneOf: [
      {
        properties: {
          format: {
            enum: ['info'],
          },
        },
      },
      {
        properties: {
          format: {
            enum: ['textad'],
          },
          linktitle: {
            type: 'string',
            title: 'Otsikko',
          },
          link: {
            type: 'string',
            title: 'Linkki',
          },
          externalctt: {
            type: 'string',
            title: 'Ulkoinen clickthrough linkki',
          },
        },
      },
    ],
  }
}

export const _recurringSaleUISchema = {
  content: {
    'ui:widget': 'textarea',
  },
  confirmationMessage: {
    'ui:widget': 'editorWidget',
  },
  description: {
    'ui:widget': 'editorWidget',
  },
}
