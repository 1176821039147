import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button } from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import { getValueCardSchema, getValueCardUISchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import ReactTable from '../../components/Lists/ReactTableWrapper'
import { filterCaseInsensitive, getTdProps } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
import EditButton from '../../components/Buttons/EditButton'
import DeleteButton from '../../components/Buttons/DeleteButton'
import Infobox from '../../components/Infos/Infobox'


class ValueCardsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, cardModalOpen: false, cards: null }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    const cards = []
    this.props.firebase.getValueCards().then((values) => {
      if (!values.empty) {
        let _d = values.docs
          .map((sh) => {
            return { ...sh.data(), id: sh.id }
          })
        this.setState(
          {
            raw: _d,
          },
          this.filterData
        )
      } else {
        this.setState({ raw: cards }, this.filterData)
      }
    })
  }

  handleRowSelect = (e) => {
    const _result = this.state.cards.filter((r) => r.id === e)
    this.setState({ selectedResource: _result[0] }, this.toggleCardModal)
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      data.push({
        ...this.state.raw[i],
        price: this.state.raw[i].price ? this.state.raw[i].price / 100 : 0,
        actions: this.getActions(this.state.raw[i]),
      })
    }
    this.setState({ loading: false, cards: data })
  }

  deleteCard = async (id) => {
    await this.props.firebase.deleteValueCard(id)
    this.setState({ loading: true }, this.updateData)
  }

  getActions = (row) => {
    return (
      <>
        <div className='actions-right'>
          {}
          <Button
            color='danger'
            size='sm'
            onClick={(el) => {
              this.deleteCard(row.id)
            }}
          >
            <span className='btn-label'>
              <i className='nc-icon nc-simple-remove' />
            </span>
          </Button>
        </div>
      </>
    )
  }

  addNewCard = () => {
    this.setState({ selectedResource: {} }, this.toggleCardModal)
  }

  toggleCardModal = () => {
    this.setState({
      cardModalOpen: !this.state.cardModalOpen,
    })
  }


  createCardModal = () => {
    return (
      <FalconModal
        onUpdate={this.props.firebase.updateValueCard}
        onCreate={this.props.firebase.createValueCard}
        modifiedCallback={this.updateData}
        toggle={this.toggleCardModal}
        resource={this.state.selectedResource}
        isOpen={this.state.cardModalOpen}
        uiSchema={getValueCardUISchema()}
        dataSchema={getValueCardSchema()}
        title={
          this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa arvokorttia' : 'Uusi arvokortti'
        }
        size='lg'
      />
    )
  }

  render() {
    if (this.state.loading) {
      return <FalconLoading />
    } else {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.addNewCard}>Lisää arvokortti</Button>
              </Col>
              <Col>
                <Infobox index={'0'}/>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.cards}
                  filterable
                  noDataText='Ei löytynyt yhtään arvokorttia'
                  defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                  columns={[
                    {
                      Header: 'Nimi',
                      accessor: 'name',
                    },
                    {
                      Header: 'Kuvaus',
                      accessor: 'description',
                    },
                    {
                      Header: 'Tyyppi',
                      accessor: 'type',
                      renderCell: (params) => {
                        return <span>{params.value === 'single' ? 'Yksiarvo' : 'Moniarvo'}</span>
                      }
                    },
                    {
                      Header: 'ALV',
                      accessor: 'vat',
                      renderCell: (params) => {
                        return <span>{params.value} %</span>
                      }
                    },
                    {
                      Header: 'Hinta',
                      accessor: 'price',
                      renderCell: (params) => {
                        return <span>{params.value} €</span>
                      }
                    },
                    {
                      Header: 'Muokkaa',
                      accessor: 'edit',
                      filterable: false,
                      sortable: false,
                      width: 100,
                      renderCell: (params) => {
                        return (
                          <EditButton
                            onClick={(el) => {
                              this.handleRowSelect(params.row.id)
                            }}
                          />
                        )
                      },
                    },
                    {
                      Header: 'Poista',
                      accessor: 'actions',
                      filterable: false,
                      sortable: false,
                      width: 100,
                      renderCell: (params) => {
                        return (
                          <DeleteButton
                            onClick={(el) => {
                              this.deleteCard(params.row.id)
                            }}
                          />
                        )
                      },
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect)}
                  className='-striped -highlight primary-pagination'
                />
              </Col>
            </Row>
            {this.state.alert}
            {this.createCardModal()}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(ValueCardsContainer)
