import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col,
  Button,
} from 'reactstrap'
import FalconModal from '../../components/Inputs/FalconModal'
import { getContractSchema, getContractUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
import { filterCaseInsensitive, getTdProps } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
// import moment from 'moment'
import ReactTable from '../../components/Lists/ReactTableWrapper'
import DeleteButton from '../../components/Buttons/DeleteButton'
// core components

class ContractsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, contractModalOpen: false, contracts: null }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = () => {
    this.props.firebase.getContracts().then((snapshot) => {
      const data = []
      // eslint-disable-next-line
      snapshot.docs.map(async (doc) => {
        if (doc.data().state !== 'deleted') {
          data.push({ ...doc.data(), id: doc.id })
        }
      })
      this.setState({ raw: data }, this.filterData)
    })
  }

  handleRowSelect = (e) => {
    const _result = this.state.contracts.filter((r) => r.id === e)
    this.setState({ selectedResource: _result[0] }, this.toggleContractModal)
  }

  filterData = async () => {
    const data = []
    for (let i = 0; i < this.state.raw.length; i++) {
      data.push({
        ...this.state.raw[i],
        actions: this.getActions(this.state.raw[i]),
        downloadUrl: this.getDownloadUrl(this.state.raw[i])
      })
      
    }
    this.setState({ loading: false, contracts: data })
  }

  deleteContract = async (id) => {
    await this.props.firebase.deleteContract(id)
    this.setState({ loading: true }, this.updateData)
  }

  getDownloadUrl = (row) => {
    if (row.file) {
      return (
        <>
          <a href={row.file} target='_blank' rel='noopener noreferrer'>Lataa</a>
          </>
      )
    } else {
      return (<></>)
    }
  }

  getActions = (row) => {
    return (
        <>
          <div className="actions-right">
            {}
            <Button
              color="danger"
              size="sm"
              onClick={(el) => {
                this.deleteContract(row.id)
              }}
            >
              <span className="btn-label">
                <i className="nc-icon nc-simple-remove" />
              </span>
            </Button>
          </div>
        </>
    )
  }

  addNewContract = () => {
    this.setState({ selectedResource: {} }, this.toggleContractModal)
  }

  toggleContractModal = () => {
    this.setState({
      contractModalOpen: !this.state.contractModalOpen,
    })
  }


  createContractModal = () => {
    return (<FalconModal
      onUpdate={this.props.firebase.updateContract}
      onCreate={this.props.firebase.createContract}
      modifiedCallback={this.updateData}
      toggle={this.toggleContractModal}
      resource={this.state.selectedResource}
      isOpen={this.state.contractModalOpen}
      uiSchema={getContractUiSchema()}
      dataSchema={getContractSchema()}
      title={this.state.selectedResource && this.state.selectedResource.id ? 'Muokkaa sopimusta' : 'Uusi sopimus'}
    />)
  }


  render() {
    if (this.state.loading) {
      return(<FalconLoading />)
    } else {
      return (
        <>
          <div className='content'>
            <Row>
              <Col>
                <Button onClick={this.addNewContract}>
                  Lisää sopimus
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <ReactTable
                  data={this.state.contracts}
                  filterable
                  noDataText='Ei löytynyt yhtään sopimusta'
                  defaultFilterMethod={(filter, row) =>
                    filterCaseInsensitive(filter, row)
                  }
                  columns={[
                    {
                      Header: 'Asiakas',
                      accessor: 'user',
                    },
                    {
                      Header: 'Kuvaus',
                      accessor: 'displayName',
                    },
                    {
                      Header: 'Tyyppi',
                      accessor: 'type',
                    },
                    {
                      Header: 'Allekirjoitustapa',
                      accessor: 'signingtype',
                    },
                    {
                      Header: 'Tila',
                      accessor: 'status',
                    },
                    {
                      Header: 'Voimassa asti',
                      accessor: 'validuntil',
                    },
                    {
                      Header: 'Tiedosto',
                      accessor: 'downloadUrl',
                      renderCell: (params) => {
                        return (<div><a href={params.row.file} target='_blank' rel='noopener noreferrer'>Lataa</a></div>)
                      }
                    },
                    {
                      Header: 'Delete',
                      accessor: 'actions',
                      renderCell: (params) => {
                        return (<DeleteButton
                          onClick={() => {
                            this.deleteContract(params.row.id)
                          }}
                        />)
                      }
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect) }
                  className='-striped -highlight primary-pagination'
                />

              </Col>
            </Row>
            {this.state.alert}
            {this.createContractModal()}
          </div>
        </>
      )
    }
  }
}

export default withFirebase(ContractsContainer)
