const sortResourcesByOrder = (a, b) => {
  if (!a.order) a.order = 1000
  if (!b.order) b.order = 1000
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

const sortResourcesByName = (a, b) => {
  if (a.displayName < b.displayName) {
    return -1
  }
  if (a.displayName > b.displayName) {
    return 1
  }
  return 0
}

export {
  sortResourcesByOrder,
  sortResourcesByName
}
