import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col,
  Button,
  Spinner
} from 'reactstrap'

// core components

class LightsForce extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: false,
    }
  }

  handleClick = () => {
    this.setState({
      clicked: !this.state.clicked,
    })
    this.props.firebase.forceLights().then(() => {
      this.setState({
        clicked: false,
      })
    })
  }

  render () {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='12'>
              <h6>Lights Force</h6>
              <Button
                disabled={this.state.clicked}
                color='danger'
                onClick={this.handleClick}
              >{this.state.clicked ? <Spinner /> : 'Päivitä valojen tilanne'}</Button>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(LightsForce)
