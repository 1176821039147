export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id
  return row[id] !== undefined
    ? String(String(row[id]).toLowerCase()).includes(filter.value.toLowerCase())
    : true
}


export const getTdProps = (rowInfo, col, callback) => {
  if (rowInfo && rowInfo.row) {
    if (col && col.id !== 'actions') {
      return {
        onClick: (e) => {
          callback(rowInfo.row._original.id)
        },
      }
    } else {
      return {}
    }
  } else {
    return {}
  }
}