import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import { SwatchesPicker } from 'react-color'
import Infobox from '../Infos/Infobox'
import { withFirebase } from '../Fb'
import DeleteButton from '../Buttons/DeleteButton'
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import QRCode from 'react-qr-code'

class MaintenanceTicketTargets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      targets: [],
      loading: true,
      saved: true,
      displayColorPicker: false,
      qrCodeUrl: '',
      qrOpen: false,
    }
  }

  async loadExtension() {
    const extension = await this.props.firebase.getLinksExtension('maintenance')
    if (!extension.error) {
      this.setState({ qrCodeUrl: 'https://links.cintoia.com/e/' + extension.ext, })
    } else {
      alert('Error loading extension')
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase
      .getMaintenanceTicketTargets()
      .onSnapshot((doc) => {
        if (doc.exists) {
          let _c = doc.data().targets.map((r) => {
            if (r.visibility === undefined) r.visibility = true
            return r
          })
          this.setState({
            targets: _c,
            loading: false,
          })
        } else {
          this.setState({
            loading: false,
          })
        }
      })
    this.loadExtension()
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onChange = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.targets
    _a[index].enabled = event.state.value
    this.setState({ targets: _a, saved: false })
  }

  onChangeVisibility = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.targets
    _a[index].visibility = event.state.value
    this.setState({ targets: _a, saved: false })
  }

  setName = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.targets
    _a[index].name = event.target.value
    this.setState({ targets: _a, saved: false })
  }

  addTargetRow = () => {
    let j = this.state.targets
    j.push({ empty: true, enabled: true, name: '' })
    this.setState({ targets: j })
  }

  deleteTargetRow = (index) => {
    let j = this.state.targets
    j.splice(index, 1)
    this.setState({ targets: j, saved: false })
  }

  onSave = (event) => {
    event.preventDefault()
    let _rows = this.state.targets.map((r) => {
      delete r.empty
      return r
    })
    this.props.firebase
      .updateMaintenanceTicketTarget({
        targets: _rows,
      })
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  targetRows = () => {
    if (this.state.targets) {
      return this.state.targets.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col md='2' sm='2' xs='2'>
              <FormGroup>
                <Label for={'lbl.' + i}>Kohde</Label>
                <Input
                  type='text'
                  name={'name.' + i}
                  id={'name.' + i}
                  onChange={this.setName}
                  value={
                    this.state.targets[i].name ? this.state.targets[i].name : ''
                  }
                />
              </FormGroup>
            </Col>
            {this.state.targets ? (
              <>
                <Col xs={1}>
                  <Label for={'visibility.' + i}>Näkyvyys</Label>{' '}
                  <div name={'visibility.' + i}>
                    <Switch
                      defaultValue={
                        this.state.targets[i].visibility ? true : false
                      }
                      onColor='primary'
                      offColor='primary'
                      id={'visibility.' + i}
                      name={'visibility.' + i}
                      onChange={this.onChangeVisibility}
                    />
                  </div>
                </Col>
                {/* <Col xs={1}>
                  <Button onClick={() => this.setState({ qrOpen: true })}>
                    Kohteeen suora QR koodi
                  </Button>
                </Col> */}
                <Col xs={1} style={{ paddingTop: 20, marginLeft: 40 }}>
                  <DeleteButton onClick={() => this.deleteTargetRow(i)} />
                </Col>
              </>
            ) : null}
          </Row>
        )
      })
    }
  }

  handleCloseQr = () => {
    this.setState({ qrOpen: false })
  }

  handleDownloadQr = () => {
    const svg = document.getElementById('QRCode')
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      const pngFile = canvas.toDataURL('image/png')
      const downloadLink = document.createElement('a')
      downloadLink.download = 'QRCode'
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          {this.state.displayColorPicker ? (
            <div
              style={{
                position: 'absolute',
                zIndex: '2',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={() => this.toggleColorPicker(-1)}
              />
              <SwatchesPicker
                color={this.state.color}
                onChange={(color, event) => this.setColor(color)}
              />
            </div>
          ) : null}
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>
                          Asiakkaiden tikettien raportointikohteet
                        </CardTitle>
                      </Col>
                      <Col>
                        <Infobox index={0} />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.targetRows()}
                    <Row>
                      <Col style={{ marginTop: '20px' }}>
                        <Button
                          className='btn-round btn-icon'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.addTargetRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                        </Button>{' '}
                        Lisää raportointikohde
                      </Col>
                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='6'>
                        <Button
                          disabled={this.state.saved}
                          color='primary'
                          onClick={this.onSave}
                        >
                          Tallenna kohteet
                        </Button>
                      </Col>
                      {this.state.saved && this.state.targets.length > 0 ? (
                        <Col className='ml-auto mr-auto' sm='6'>
                          <Button
                            onClick={() => this.setState({ qrOpen: true })}
                          >
                            QR koodi lisättäväksi ilmoituksiin
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <Dialog
            open={this.state.qrOpen}
            onClose={this.handleCloseQr}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle id='form-dialog-title'>QR koodi</DialogTitle>
            <QRCode
              id={'QRCode'}
              size={512}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={this.state.qrCodeUrl}
              viewBox={`0 0 512 512`}
            />
            <DialogActions>
              <Button onClick={this.handleDownloadQr} color='primary'>
                Lataa kuvana
              </Button>

              <Button onClick={this.handleCloseQr} color='primary'>
                Sulje
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default withFirebase(MaintenanceTicketTargets)
