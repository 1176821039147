import React, { Component } from 'react'
import { ImageList, ImageListItem } from '@material-ui/core'

const classes = {
  imageList: {
    width: '100%',
    height: 450,
    // backgroundColor: '#0f0',
    // borderWidth: 1,
    // borderColor: '#000',
    // borderStyle: 'solid',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: '#f00',
  },
}

class ImageSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // selected: null,
      images: [],
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.images !== this.props.images) {
      this.updateData()
    }
  }

  onSelect = (id) => {
    // this.setState({ selected: id })
    this.props.onSelect({ target: { value: id }})
  }

  updateData = () => {
    this.setState({ images: this.props.images })
  }

  render() {
    return (
      <div style={classes.root}>
        <ImageList rowHeight={120} style={classes.imageList} cols={3}>
          {this.state.images.map((item) => {
            if (item.sizes && item.sizes.length > 1) {
              const u = item.url.split('/').splice(0, item.url.split('/').length - 1).join('/') + '/150_' + item.url.split('/').splice(-1)
              return (
                <ImageListItem key={item.id} cols={item.cols || 1} onClick={() => this.onSelect(item.id)}>
                  <img src={u} alt={item.originalName} />
                </ImageListItem>
              )
            } else {
              return (
                <ImageListItem key={item.id} cols={item.cols || 1} onClick={() => this.onSelect(item.id)}>
                  <img src={item.url} alt={item.originalName} />
                </ImageListItem>
              )
            }
          })}
        </ImageList>
      </div>
    )
  }
}

export default ImageSelector
