const algoliasearch = require('algoliasearch')
const algoliaclient = algoliasearch('6WV1D95IXL', 'e846f481f3a947316c99726f264ff44e')
const profileindex = algoliaclient.initIndex('profile')

export const getAlgoliaUser = async (userid) => {
  let user = await profileindex.getObject(userid)
  return user
}

export const getAlgoliaUsers = async (userids, attributesToRetrieve) => {
  let users = await profileindex.getObjects(userids, { attributesToRetrieve: attributesToRetrieve })
  return users
}

export const getAlgoliaUsersByCustomer = async (customerid) => {
  let indexUsers = []
  await profileindex.browseObjects({
    query: '', // Empty query will match all records
    filters: '_tags:' + customerid,
    attributesToRetrieve: ['email', 'objectID'],
    batch: (batch) => {
      indexUsers = indexUsers.concat(batch)
    },
  })
  return indexUsers
}


