import React from 'react'
import withAppdata from '../AppConfig/withAppdata'
import Alert from '@material-ui/lab/Alert'

function Infobox({ appdata, index }) {
  if(!index) index = '0'
  if (appdata && appdata.infoboxes && appdata.infoboxes.length > 0) {
    const path = window.location.href
    const infobox = appdata.infoboxes.find((v) => {
      if (!v.index) v.index = '0'
      return path.indexOf(v.path) !== -1 && v.index === index
    })
    if (infobox) {
      return (
        <Alert severity={infobox.color} style={{ fontSize: infobox.fontSize }}><span dangerouslySetInnerHTML={{__html: infobox.text}}></span></Alert>
      )
    }
  }
  return null
}

export default withAppdata(Infobox)
