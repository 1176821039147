import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button } from 'reactstrap'
// import FalconModal from '../../components/Inputs/FalconModal'
// import { getContractSchema, getContractUiSchema } from '../../components/Inputs/dataschemas'
// import ReactTable from 'react-table'
// import { filterCaseInsensitive, getTdProps } from '../../components/FalconTable'
import FalconLoading from '../../components/Inputs/FalconLoading'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
// import ReactTable from '../../components/Lists/ReactTableWrapper'
import AggregateTable from '../../components/AggregateTable/AgTable'

// core components

const isFiltered = (invoicerFilter, invoicer) => {
  const invoiceOk = invoicerFilter ? invoicer === invoicerFilter : true
  return invoiceOk
}

const toCurrency = (value) => {
  return (Math.round(value * 100) / 100).toFixed(2).replace('.', ',') + ' €'
}

const filterInvoices = (invoices, venue, paymentEndDate) => {
  const data = []
  for (let invoice of invoices) {
    if (isFiltered(venue, invoice.invoicer)) {
      const sum = invoice.rows.reduce((acc, cur) => {
        return acc + cur.amount
      }, 0)
      let contactDisplayName = ''
      let externals = ''
      if (invoice.contact) {
        if (invoice.contact.businessId) {
          contactDisplayName = invoice.contact.name
        } else {
          contactDisplayName = [invoice.contact.firstname, invoice.contact.lastname].join(' ')
        }
        if (invoice.contact.netvisor) {
          externals = JSON.stringify(invoice.contact.netvisor, null, 2)
        }
      }

      let invoicingmethod = ''
      if (invoice.contact.billingOvt) {
        invoicingmethod = 'elasku'
      } else if (invoice.contact.email) {
        invoicingmethod = 'sähköposti'
      }

      let paid = 0
      if (invoice.payments) {
        if (paymentEndDate) {
          paid = invoice.payments.reduce((a, c) => {
            if (moment(c.timestamp).isSameOrBefore(paymentEndDate, 'D')) {
              return a += c.amount
            } else {
              return a
            }
          }, 0)
        } else {
          paid = invoice.payments.reduce((a, c) => a += c.amount, 0)
        }
      }

      //invoicingstatus = 'paid', 'open', 'overdue', 'reminded', 'debtcollection'
      const isSent = invoice.sent ? true : false
      const isPaid = paid >= sum ? true : false
      const isOverdue = invoice.duedate && moment(invoice.duedate).isBefore(moment(), 'D') ? true : false
      const isReminded = invoice.reminds ? true : false
      const isDebtCollection = invoice.reminds && invoice.reminds.length > 2 ? true : false

      let invoicingstate = ''
      let invoicingstatelabel = ''
      if (isPaid) {
        invoicingstate = 'paid'
        invoicingstatelabel = 'Maksettu'
      } else {
        if (isSent) {
          if (isDebtCollection) {
            invoicingstate = 'debtcollection'
            invoicingstatelabel = 'Perinnässä'
          } else if (isReminded) {
            invoicingstate = 'reminded'
            invoicingstatelabel = 'Muistutettu'
          } else if (isOverdue) {
            invoicingstate = 'overdue'
            invoicingstatelabel = 'Erääntynyt'
          } else {
            invoicingstate = 'open'
            invoicingstatelabel = 'Avoin'
          }
        } else {
          invoicingstate = 'unsend'
          invoicingstatelabel = 'Lähettämätön'
        }
      }
      const regex = /remind\d+/g
      const reminds = Object.keys(invoice || {}).filter((k) => k.indexOf('AsTimestamp') < 0).filter((k) => k.match(regex)).length

      data.push({
        id: invoice.id,
        contact: contactDisplayName,
        sumeur: Number((sum / 100).toFixed(2)),
        sum: Math.floor(sum),
        invoiceNumber: invoice.invoiceNumber,
        invoicer: invoice.invoicer !== 'default' ? invoice.invoicer : '',
        billingdate: invoice.billingdate,
        duedate: invoice.duedate,
        status: invoice.status || 'notsynced',
        externals,
        invoicingmethod,
        paid,
        paideur: Number((paid / 100).toFixed(2)),
        // unpaid: Math.max((sum - paid), 0),
        // unpaideur: Number(Math.max(((sum - paid) / 100), 0).toFixed(2)),
        unpaid: (sum - paid),
        unpaideur: Number(((sum - paid) / 100).toFixed(2)),
        invoicingstate,
        invoicingstatelabel,
        details: invoice,
        sent: invoice.sent,
        reminds: reminds > 0 ? reminds : null,
        remind: invoice.remind,
      
      })
    }
  }
  return data
}

class InvoicesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      invoiceDialogOpen: false,
      invoices: null,
      startDate: moment().subtract(1, 'month').startOf('month'),
      endDate: moment(),
      venues: [],
      selectedVenue: null,
      paymentEndDate: null
    }
  }

  componentDidMount() {
    // this.listenData()
    this.getVenues()
  }

  componentWillUnmount() {
    if (this.invoicesRef) {
      this.unsubscribeInvoices()
    }
  }

  getVenues = async () => {
    const _v = await this.props.firebase.getVenues()
    if (!_v.empty) {
      this.setState({ venues: _v.docs.map((doc) => ({ ...doc.data(), id: doc.id })) })
    }
  }

  // listenData = () => {
  //   if (this.invoicesRef) {
  //     this.unsubscribeInvoices()
  //   }
  //   this.invoicesRef = this.props.firebase.listenInvoices()
  //   this.unsubscribeInvoices = this.invoicesRef.onSnapshot((snap) => {
  //     const data = []
  //     snap.forEach((doc) => {
  //       if (doc.data().state !== 'deleted') {
  //         data.push({ ...doc.data(), id: doc.id })
  //       }
  //     })
  //     this.setState({ raw: data }, this.filterData)
  //   })
  // }

  setStart = (e) => {
    this.setState({ startDate: moment.utc(e.target.value) })
  }

  setEnd = (e) => {
    this.setState({ endDate: moment.utc(e.target.value) /*.hour(23).minute(59).seconds(59)*/ })
  }

  setPaymentEnd = (e) => {
    this.setState({ paymentEndDate: moment.utc(e.target.value) /*.hour(23).minute(59).seconds(59)*/ })  
  }

  handleRowSelect = (e) => {
    const _result = this.state.raw.find((r) => r.id === e.id)
    this.setState({ selectedResource: _result }, this.toggleInvoicingModal)
  }

  setVenue = (e) => {
    const selectedVenue = e.target.value
    this.setState({ selectedVenue })
  }

  queryData = async () => {
    this.setState({ loading: true, invoices: null })
    console.log(this.state.startDate, this.state.endDate)
    const invoices = await this.props.firebase.getInvoicesByDates(this.state.startDate, this.state.endDate)
    console.log('empty', invoices.empty)
    if (!invoices.empty) {
      console.log(invoices.docs.length)
      const _invoices = invoices.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      const data = filterInvoices(_invoices, this.state.selectedVenue, this.state.paymentEndDate)
      this.setState({ loading: false, invoices: data })
    } else {
      this.setState({ loading: false, invoices: [] })
    }
  }

  refreshData = async () => {
    const invoices = await this.props.firebase.getInvoicesByDates(this.state.startDate, this.state.endDate)
    if(!invoices.empty) {
      const _invoices = invoices.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      const data = filterInvoices(_invoices, this.state.selectedVenue, this.state.paymentEndDate)
      this.setState({ invoices: data })
    } else {
      this.setState({ invoices: [] })
    }
  }

  // filterData = async () => {
  //   const data = []
  //   for (let i = 0; i < this.state.raw.length; i++) {
  //     // console.log(this.state.raw[i].billingdate, this.state.startDate, this.state.endDate, this.state.selectedVenue, this.state.raw[i].invoicer)
  //     if (isFiltered(this.state.raw[i].billingdate, this.state.startDate, this.state.endDate, this.state.selectedVenue, this.state.raw[i].invoicer)) {
  //       const sum = this.state.raw[i].rows.reduce((acc, cur) => {
  //         return acc + cur.amount
  //       }, 0)
  //       let contactDisplayName = ''
  //       let externals = ''
  //       if (this.state.raw[i].contact) {
  //         if (this.state.raw[i].contact.businessId) {
  //           contactDisplayName = this.state.raw[i].contact.name
  //         } else {
  //           contactDisplayName = [this.state.raw[i].contact.firstname, this.state.raw[i].contact.lastname].join(' ')
  //         }
  //         if (this.state.raw[i].contact.netvisor) {
  //           externals = JSON.stringify(this.state.raw[i].contact.netvisor, null, 2)
  //         }
  //       }

  //       let invoicingmethod = ''
  //       if (this.state.raw[i].contact.billingOvt) {
  //         invoicingmethod = 'elasku'
  //       } else if (this.state.raw[i].contact.email) {
  //         invoicingmethod = 'sähköposti'
  //       }

  //       data.push({
  //         id: this.state.raw[i].id,
  //         contact: contactDisplayName,
  //         sum: Number((sum / 100).toFixed(2)),
  //         invoiceNumber: this.state.raw[i].invoiceNumber,
  //         invoicer: this.state.raw[i].invoicer !== 'default' ? this.state.raw[i].invoicer : '',
  //         billingdate: this.state.raw[i].billingdate,
  //         status: this.state.raw[i].status || 'notsynced',
  //         externals,
  //         invoicingmethod
  //       })
  //     }
  //   }
  //   this.setState({ loading: false, invoices: data })
  // }

  toggleInvoicingModal = () => {
    this.setState({
      invoiceDialogOpen: !this.state.invoiceDialogOpen,
    })
  }

  closeRowDetails = () => {
    // clear State
    this.setState({ selectedResource: null }, this.toggleInvoicingModal)
  }

  renderInvoiceExtras = () => {
    return <></>
  }

  renderInvoiceHeader = () => {
    return (
      <React.Fragment>
        <Row>
          <Col>
            {this.state.selectedResource.contact.name}{' '}
            {this.state.selectedResource.contact.contacts
              ? this.state.selectedResource.contact.contacts[0].email
              : null}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.selectedResource.contact.businessId
              ? 'Y-tunnus: ' + this.state.selectedResource.contact.businessId
              : null}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.selectedResource.contact.billingReference
              ? 'Laskutusreferenssi: ' + this.state.selectedResource.contact.billingReference
              : null}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.selectedResource.contact.netvisor
              ? 'Netvisor tunnus: ' + this.state.selectedResource.contact.netvisor[this.state.selectedResource.invoicer]
              : null}
          </Col>
        </Row>
        <Row>
          <Col>Laskunnumero: {this.state.selectedResource.invoiceNumber}</Col>
        </Row>
        <Row>
          <Col>Laskun päivä: {this.state.selectedResource.billingdate}</Col>
        </Row>
      </React.Fragment>
    )
  }

  renderRows = () => {
    let sum = 0
    const rows = this.state.selectedResource.rows
      .map((_r) => {
        sum += _r.amount
        return (
          <Row key={Math.random()}>
            <Col md='8'>{_r.row}</Col>
            <Col md='1'>{(_r.amount / 100).toFixed(2)} €</Col>
            <Col md='1'>{_r.vat} %</Col>
            <Col md='1'>{_r.tags ? _r.tags[0] : 'ei tagia'}</Col>
          </Row>
        )
      })
      .filter((x) => x)
    return (
      <>
        <Row>
          <Col md='8'>Rivi</Col>
          <Col md='1'>Summa</Col>
          <Col md='1'>VAT</Col>
          <Col md='1'>Tags</Col>
        </Row>
        {rows}
        <Row>
          <Col md='8'>{''}</Col>
          <Col md='1'>{(sum / 100).toFixed(2)} €</Col>
          <Col md='1'>{''}</Col>
          <Col md='1'>{''}</Col>
        </Row>
      </>
    )
  }

  confirmedAction = async (action, markAsInvoiced, toggle) => {
    const row = this.state.invoices.find((r) => r.id === this.state.selectedInvoicingId)
    await this.props.firebase.invoiceAction(
      this.state.selectedInvoicingId,
      row.keys,
      action,
      markAsInvoiced,
      this.state.selectedVenue
    )
    // console.log(response)
    toggle()
  }

  render() {
    if (this.state.loading) {
      return <FalconLoading />
    } else {
      return (
        <>
          <div className='content'>
            {/* <Row>
            </Row> */}
            <Row style={{ marginTop: 80, marginBottom: 5 }}>
              <Col>Laskunpäivä</Col>
              <Col>
                <TextField
                  id='recurstart'
                  label='Alkupäivä'
                  type='date'
                  // defaultValue={this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : ''}
                  value={
                    this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD')
                  }
                  onChange={this.setStart}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id='recurstart'
                  label='Loppupäivä'
                  type='date'
                  value={
                    this.state.endDate ? this.state.endDate.format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD')
                  }
                  onChange={this.setEnd}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
              {this.state.venues && this.state.venues.length > 0 ? (
                <Col>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id='venue'>Paikka</InputLabel>
                    <Select
                      fullWidth
                      labelId='venue-label'
                      id='venue'
                      value={this.state.selectedVenue || ''}
                      onChange={this.setVenue}
                    >
                      {this.state.venues.map((v, i) => {
                        return (
                          <MenuItem key={v.name.toLowerCase()} value={v.name.toLowerCase()}>
                            {v.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Col>
              ) : null}
              <Col>
                <TextField
                  id='recurstart'
                  label='Maksujen loppupäivä'
                  type='date'
                  value={
                    this.state.paymentEndDate ? this.state.paymentEndDate.format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD')
                  }
                  onChange={this.setPaymentEnd}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText="Voit valita päivän minkä maksutilanteen haluat nähdä."
                  fullWidth
                />
              </Col>
              <Col>
                <Button onClick={this.queryData}>Hae</Button>
              </Col>
            </Row>
            {/* <Row>
              <Col md='12'>
                <ReactTable
                  countTotal={true}
                  data={this.state.invoices || []}
                  filterable
                  noDataText='Tee haku päivämäärillä'
                  defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                  columns={[
                    {
                      Header: 'Asiakas',
                      accessor: 'contact',
                      width: 300,
                    },
                    // {
                    //   Header: 'Asiakkaan laskutusid',
                    //   accessor: 'externals',
                    //   width: 300,
                    // },
                    {
                      Header: 'Laskunnumero',
                      accessor: 'invoiceNumber',
                      filterable: true,
                      maxWidth: 200,
                      minWidth: 100,
                      width: 100,
                      Cell: (props) => <div style={{ textAlign: 'right' }}>{props.value}</div>,
                    },
                    {
                      Header: 'Summa',
                      accessor: 'sum',
                      filterable: false,
                      maxWidth: 300,
                      minWidth: 100,
                      width: 150,
                      valueFormatter: (params) => {
                        return toCurrency(params.value)
                      },
                    },
                    {
                      Header: 'Laskunpäivä',
                      accessor: 'billingdate',
                      filterable: false,
                      sortable: true,
                      sortType: (a, b) => {
                        return moment(b.billingdate).unix() - moment(a.billingdate).unix()
                      },
                      maxWidth: 400,
                      minWidth: 100,
                      width: 150,
                      valueFormatter: (params) => {
                        return moment(params.value).format('D.M.YYYY')
                      },
                      // Cell: props => <div style={{ textAlign: "right" }}>{moment(props.value).format('D.M.YYYY')}</div>
                    },
                    {
                      Header: 'Status',
                      accessor: 'status',
                      filterable: false,
                      maxWidth: 200,
                      minWidth: 100,
                      width: 130,
                      Cell: (props) => <div style={{ textAlign: 'right' }}>{props.value}</div>,
                    },
                    {
                      Header: 'Paikka',
                      accessor: 'invoicer',
                      filterable: false,
                      maxWidth: 200,
                      minWidth: 100,
                      width: 100,
                    },
                    {
                      Header: 'Laskutustapa',
                      accessor: 'invoicingmethod',
                      filterable: false,
                      maxWidth: 200,
                      minWidth: 100,
                      width: 100,
                    },
                  ]}
                  defaultPageSize={100}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTdProps={(state, rowInfo, col) => getTdProps(rowInfo, col, this.handleRowSelect)}
                  className='-striped -highlight primary-pagination'
                  defaultSorted={[{ id: 'invoiceNumber', desc: true }]}
                  onRowClick={this.handleRowSelect}
                />
              </Col>
            </Row> */}
            <Row>
              <AggregateTable
                rows={this.state.invoices || []}
                onRefresh={this.refreshData}
                aggregateBy={'invoicingstatelabel'}
                sortBy={'invoiceNumber'}
                cols={[
                  {
                    Header: 'Asiakas',
                    headerName: 'Asiakas',
                    accessor: 'contact',
                    field: 'contact',
                    aggregateBy: true
                    // width: 300,
                  },
                  {
                    Header: 'Laskunnumero',
                    accessor: 'invoiceNumber',
                    headerName: 'Laskunnumero',
                    field: 'invoiceNumber',
                    details: true,
                  },
                  {
                    Header: 'Laskunpäivä',
                    accessor: 'billingdate',
                    headerName: 'Laskunpäivä',
                    field: 'billingdate',
                    format: (value) => {
                      return moment(value).format('D.M.YYYY')
                    },
                    aggregateBy: true
                  },
                  {
                    Header: 'Eräpäivä',
                    accessor: 'duedate',
                    headerName: 'Eräpäivä',
                    field: 'duedate',
                    format: (value) => {
                      if(!value) return ''
                      return moment(value).format('D.M.YYYY')
                    },
                    aggregateBy: true
                  },
                  {
                    Header: 'Summa',
                    accessor: 'sumeur',
                    format: (value) => {
                      return toCurrency(value)
                    },
                    headerName: 'Summa',
                    field: 'sumeur',
                    addpayment: true

                  },
                  { 
                    Header: 'Maksettu',
                    accessor: 'paideur',
                    headerName: 'Maksettu',
                    format: (value) => {
                      return toCurrency(value)
                    },
                    field: 'paideur',
                    color: 'green',
                    paymentbuttons: false,
                    defaultValue: 0
                  },
                  { 
                    Header: 'Maksamatta',
                    accessor: 'unpaideur',
                    headerName: 'Maksamatta',
                    format: (value) => {
                      return toCurrency(value)
                    },
                    field: 'unpaideur',
                    color: 'red',
                    paymentbuttons: true,
                    defaultValue: 0
                  },
                  { 
                    Header: 'Laskutuksentila',
                    accessor: 'invoicingstatelabel',
                    headerName: 'Laskutuksentila',
                    field: 'invoicingstatelabel',
                    aggregateBy: true
                  },
                  { 
                    Header: 'Lähetetty',
                    accessor: 'sent',
                    headerName: 'Lähetetty',
                    field: 'sent',
                    aggregateBy: true,
                    format: (value) => {
                      if (!value) return ''
                      return moment(value).format('D.M.YYYY')
                    }
                  },
                  { 
                    Header: 'Muistutettu',
                    accessor: 'remind',
                    headerName: 'Muistutettu',
                    field: 'remind',
                    format: (value) => {
                      if (!value) return ''
                      return moment(value).format('D.M.YYYY')
                    }
                  },
                  { 
                    Header: 'Muistutuksia',
                    accessor: 'reminds',
                    headerName: 'Muistutuksia',
                    field: 'reminds'
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                    headerName: 'Status',
                    field: 'status',
                    aggregateBy: true
                  },

                  // {
                  //   Header: 'Paikka',
                  //   accessor: 'invoicer',
                  //   headerName: 'Paikka',
                  //   field: 'invoicer',
                  //   aggregateBy: true
                  // },
                  // {
                  //   Header: 'Laskutustapa',
                  //   accessor: 'invoicingmethod',
                  //   headerName: 'Laskutustapa',
                  //   field: 'invoicingmethod',
                  //   aggregateBy: true
                  // },
                ]}

              />
            </Row>
            {/* <Row>
              <Col md='12'>
                <pre>{JSON.stringify(this.state.invoices, null, 2)}</pre>
              </Col>
            </Row> */}

            {this.state.alert}
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={this.state.invoiceDialogOpen}
              onClose={this.closeRowDetails}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>
                {/* {this.state.selectedResource ? this.state.selectedResource.contact : null}{' '} */}
                {/* {this.state.startDate.format('D.M.YYYY') + ' - ' + this.state.endDate.format('D.M.YYYY')}{' '} */}
              </DialogTitle>
              <DialogContent>{this.state.selectedResource ? this.renderInvoiceHeader() : null}</DialogContent>
              <DialogContent>{this.state.selectedResource ? this.renderRows() : null}</DialogContent>
              <DialogActions>
                <Button onClick={this.closeRowDetails} color='primary'>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              // open={this.state.externalConfirmationOpen}
              open={false}
              onClose={this.toggleExternalDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>Vahvista lähetys</DialogTitle>
              <DialogContent>{this.renderInvoiceExtras()}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.confirmedAction('external', true, this.toggleExternalDialog)}
                  color='primary'
                >
                  Lähetä ja merkkaa laskutetuksi
                </Button>
                <Button onClick={this.toggleExternalDialog} color='primary'>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              // open={this.state.finvoiceConfirmationOpen}
              open={false}
              onClose={this.toggleFinvoiceDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>Lataa</DialogTitle>
              <DialogContent>{this.renderInvoiceExtras()}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.confirmedAction('finvoice', true, this.toggleFinvoiceDialog)}
                  color='primary'
                >
                  Lataa ja merkkaa laskutetuksi
                </Button>
                <Button
                  onClick={() => this.confirmedAction('finvoice', false, this.toggleFinvoiceDialog)}
                  color='seconday'
                >
                  Lataa
                </Button>
                <Button onClick={this.toggleFinvoiceDialog} color='secondary'>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              // open={this.state.emailInvoiceConfirmationOpen}
              open={false}
              onClose={this.toggleEmailInvoiceDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>Vahvista lähetys</DialogTitle>
              <DialogContent>{this.renderInvoiceExtras()}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.confirmedAction('email', true, this.toggleEmailInvoiceDialog)}
                  color='primary'
                >
                  Lähetä ja merkkaa laskutetuksi
                </Button>
                <Button onClick={this.toggleEmailInvoiceDialog} color='primary'>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              // open={this.state.onlineConfirmationOpen}
              open={false}
              onClose={this.toggleOnlineDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>Vahvista lähetys</DialogTitle>
              <DialogContent>Lähetä käyttäjälle muistutus maksaa vuorot online maksuna.</DialogContent>
              <DialogActions>
                <Button onClick={this.toggleOnlineDialog} color='primary'>
                  Lähetä
                </Button>
                <Button onClick={this.toggleOnlineDialog} color='primary'>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      )
    }
  }
}

export default withFirebase(InvoicesContainer)
